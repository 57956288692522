import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import ChartistGraph from "react-chartist";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { getPerformanceScoreListData, performanceRequestAction } from '../../../store/actions/PerformanceScoreAction';


const PerformanceScore = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)

    const performanceOverallavg = useSelector(state => state.performance.performanceOverallavg);
    ChartJS.register(ArcElement, Tooltip, Legend);
    var labels = [
        'ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9', 'ch2', 'ch4'
    ];
    const data = {
        // labels: ["Below 70%", "70% to 79%", "80% to 89%", "90% and above"],
        labels: labels,
        datasets: [{
            label: 'My First Dataset',
            // data: [60,60, 70, 80, 90],
            data: [60, 60, 60, 60, 60, 60, 70, 70, 70, 80, 80, 80, 80, 90, 90, 90, 90],
            // data: ['ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9','ch2', 'ch4'],
            backgroundColor: [
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#C00000',
                '#C00000',
                '#C00000',
                '#FFC000',
                '#FFC000',
                '#FFC000',
                '#FFC000',
                '#00B050',
                '#00B050',
                '#00B050',
                '#00B050'
            ],
            hoverBackgroundColor: [
                '#FF5733',
                '#FF5733',
                '#FF5733',
                '#FF5733'
            ],
            borderWidth: 2,
            hoverOffset: 2,
            borderJoinStyle: 'round',

        }],
    };
    const options = {
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: "Math PCS set",
                padding: {
                    bottom: 10
                },
                weight: "bold",
                color: "#00325c",
                font: {
                    size: 13
                },
                align: "center"
            },

            datalabels: {
                labels: {
                    name: {
                        align: 'center',
                        font: { size: 14 },
                        color: "#fff",
                        formatter: function (value, ctx) {
                            return ctx.chart.data.labels[ctx.dataIndex];
                        },
                        anchor: "center", //start, center, end
                        rotation: function (ctx) {
                            const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
                            const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                            const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
                            return rotation < 180 ? rotation - 90 : rotation + 90;
                        },
                    },
                }
            }
        }
    };

    const data1 = {
        labels: ["Below 70%", "70% to 79%", "80% to 89%", "90% and above"],
        datasets: [{
            label: 'My First Dataset',
            data: [300, 50, 100],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)'
            ],
            count: data,
            borderWidth: 1,
            hoverOffset: 2,
            borderJoinStyle: 'round',

        }],
        options: {
            plugins: {
                datalabels: {
                    formatter: (value) => {
                        return value + '%';
                    },
                },
            },
        },

    };

    const data3 = {
        datasets: [
            {
                data: [55, 45],
                backgroundColor: ["rgb(80, 170, 80)", "#ccc"],
                // These labels and labelSuffix use the custom tooltips callbacks
                // They will also not trigger the legend
                labels: ["On track", "Remaining"],
                labelSuffix: "%",
                pointStyle: "circle"
            },
            {
                data: [120, 245],
                backgroundColor: ["goldenrod", "#ccc"],
                // hoverBackgroundColor: ["gold", "#059"],
                labels: ["Time elapsed", "Time remaining"],
                labelSuffix: " days"
            }
        ]
    };

    const data4 = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        dispatch(performanceRequestAction(true))
        dispatch(getPerformanceScoreListData(1, props.history))
    }, [performanceOverallavg]);


    return (
        <>
            <div className='performance_parent_container'>
                <div className='score_container'>

                    <div className="text-center"><h4>Scorecard</h4></div>

                    <div className='score_card_parent_container'>
                        <table>
                            <tr>
                                <th rowspan="2"></th>
                                <th colspan="2" className='text-center'>SWA</th>
                                <th colspan="2" className='text-center'>HOTS</th>
                                <th colspan="2" className='text-center'>DES</th>
                                <th colspan="2" className='text-center'>TOT</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Correct</th>
                                <th>Marks</th>
                                <th>Correct</th>
                                <th>Marks</th>
                                <th>Correct</th>
                                <th>Marks</th>
                                <th>Correct</th>
                                <th>Marks</th>
                                <th>Percent</th>
                                <th>Avg%</th>
                            </tr>
                            <tbody>
                                {props.performanceList != "" ?
                                    Object.keys(props.performanceList).map((keyName, i) => (

                                        props.performanceList[keyName].map((item, index) => (
                                            (index == 0) ?
                                                <tr className={`${i % 2 == 0 ? `tr-background` : null}`}>
                                                    <td rowspan={props.performanceList[keyName].length}>{keyName}</td>
                                                    <td>{item.question_type_swa}</td>
                                                    <td>{item.question_type_swa_marks}</td>
                                                    <td>{item.question_type_hot}</td>
                                                    <td>{item.question_type_hot_marks}</td>
                                                    <td>{item.question_type_des}</td>
                                                    <td>{item.question_type_des_marks}</td>
                                                    <td>{item.question_type_total}</td>
                                                    <td>{item.question_type_total_marks}</td>
                                                    <td>{item.set_average}</td>
                                                    <td rowspan={props.performanceList[keyName].length}>{item.total_avg}</td>
                                                </tr>
                                                :
                                                <tr className={`${i % 2 == 0 ? `tr-background` : null}`}>
                                                    <td>{item.question_type_swa}</td>
                                                    <td>{item.question_type_swa_marks}</td>
                                                    <td>{item.question_type_hot}</td>
                                                    <td>{item.question_type_hot_marks}</td>
                                                    <td>{item.question_type_des}</td>
                                                    <td>{item.question_type_des_marks}</td>
                                                    <td>{item.question_type_total}</td>
                                                    <td>{item.question_type_total_marks}</td>
                                                    <td>{item.set_average}</td>
                                                </tr>
                                        ))
                                    ))
                                    : null}
                            </tbody>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Avg%</th>
                                <th>{performanceOverallavg}</th>
                            </tr>

                        </table>
                    </div>

                </div>
                <div className='chart_container'><Doughnut data={data} options={options} plugins={[ChartDataLabels]} /></div>
            </div>
            <div style={{ width: 400 }}>
                <Doughnut data={data1} />
            </div>
            <div style={{ width: 400 }}>
                <Doughnut data={data4} />
            </div>
            <div style={{ width: 400 }}>
                <Doughnut data={data3} />
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        performanceList: state.performance.performanceList,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(PerformanceScore);
