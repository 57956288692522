import React, { useState, useEffect } from 'react';
import IconTint from 'react-icon-tint';
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, } from 'react-redux';
import * as utility from '../../../../utility/Utility';

import { getOnlineCompetitiveSubscriptionDetailsData, getOnlineCompetitiveQuestionListData } from '../../../../store/actions/OnlineExamAction';


export default function CategorySetCardOne(props) {

	const history = useHistory();
	const dispatch = useDispatch();

	const [purchsedSet, setPurchsedSet] = useState('');
	const [matPurchsedSet, setMatPurchsedSet] = useState('');

	const onExamTypeHandler = () => {

		if (props.isSubscribe == 1) {
			history.push({
				// pathname: "/e-library-subject",
				pathname: "/e-library-competitive-list",
				state: props.id
			})
		} else if (props.isSubscribe == 0) {
			utility.showError('No elibrary exsits');
		}
	}

	useEffect(() => {

		const setTotalData = [];
		var setText = null;
		for (let i = 0; i < props.satCount.no_set; i++) {
			let setText = {};
			setText['name'] = "ComSet " + (i + 1);
			setText['com_set_no'] = i + 1;
			setTotalData.push(setText);
		}
		setPurchsedSet(setTotalData)

	}, []);

	const setlectIndividualSetData = (subscription_id, set_no, subtype) => {
		// console.log("------**---", "NTSE", subscription_id, set_no, subtype,  )
		dispatch(getOnlineCompetitiveQuestionListData("NTSE", subscription_id, set_no, subtype, history))
	}

	return (
		<>
			<div className="row">
				<div className="exam_inside_container" >

					{/* <Link
						onClick={onExamTypeHandler}
					> */}

					<div className="col-md-6 contain_container_top" style={{ backgroundColor: props.bgColor }}>
						<div className="left_container">
							{props.icon != '' && props.icon != null ?
								<IconTint
									src={props.icon}
									alt=""
									color={props.leftColor}
									className={props.iconClass}
								/>
								:
								<div>
									No Image
								</div>
							}
						</div>
						<div className="middle_container">
							<div className="middle_inside_container">
								<h3 className="font_white">{props.title}</h3>
								<p className="font_white">{props.description}</p>
							</div>
						</div>
						<div className="right_container">
							<IconTint
								src={props.icon}
								alt=""
								color="#fff"
								className={props.iconClassRgt} />
						</div>
					</div>
					{/* </Link> */}
					<div className='set_parent_container'>

						{purchsedSet != '' ?
							purchsedSet.map((item, index) => {
								return (
									index + 1 == props.satCount.current_exam_set ?
										<div className='dflex jcc aic set_container cursorPointer' key={index} style={{ backgroundColor: '#02879B', color: '#fff' }} onClick={props.goToExam}
										>
											Set {index + 1} {props.satCount.interm_count == 0 ? null : <>({props.satCount.interm_count}/3)</>}
										</div>
										: index + 1 < props.satCount.current_exam_set ?
											<div className='dflex jcc aic set_container' key={index} style={{ backgroundColor: '#8389E0', color: '#fff' }} >
												Set {index + 1}  <i className="bi bi-check2-circle"></i>
											</div>
											:
											<div className='dflex jcc aic set_container disable_set' key={index} >
												Set {index + 1}
											</div>
								)
							})
							: null}
					</div>
				</div>
			</div>
			{/* onClick={() => setlectIndividualSetData( props.satCount.no_set, (index + 1),  1  )} */}
		</>
	)
}