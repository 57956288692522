import React, { Fragment, useContext, useEffect, useState, useRef, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { Alert, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import * as utility from '../../../utility/Utility';
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardActions';
import { updateProfileDetails, loadingProfile, profileDataUpdatedAction, profileImageUpdatedAction, profileImageFileUpdatedAction } from '../../../store/actions/ProfileAction';
import { getClassStandardData, loadingClassStandardAction } from '../../../store/actions/ClassStandardAction';
import { showingLoaderStudentAction, demoUserRecordExistsOrNot, sendVerificationOtp, verificationCodeAction, recordExistsSuccess } from '../../../store/actions/StudentAction';


import profile from "../../../images/profile/profile.png";
import testImage from "../../../images/1.jpg";
import AvatarEditor from "react-avatar-editor";
import Nouislider from "nouislider-react";
import ProfileImgCrop from "./ProfileImgCrop"

const Profile = (props) => {


	const calendarRef = useRef();

	const status = useSelector(state => state.student.status);
	const profileDataUpdated = useSelector(state => state.profile.profileDataUpdated);
	const profileImg = useSelector(state => state.profile.profileImg);
	const profileImageFile = useSelector(state => state.profile.profileImageFile);
	const profile_image = useSelector(state => state.profile.profilePic);

	let errorsObj = {
		fname: '', lname: '', dob: '', email: '', gender: '',
		address: '', pincode: '', mobile: '', standard: '',
		board: '', schoolName: '', schoolAddress: '', pws: '', cpws: ''
	};

	const [errors, setErrors] = useState(errorsObj);
	const [proImg, setProImg] = useState('');
	const [localImg, setLocalImg] = useState('');
	const [proImgFile, setProImgFile] = useState(null);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [dob, setDob] = useState('');
	const [email, setEmail] = useState('');
	const [gender, setGender] = useState('');
	const [address, setAddress] = useState('');
	const [pincode, setPincode] = useState('');
	const [mobile, setMobile] = useState('');
	const [standard, setStandard] = useState('');
	const [board, setBoard] = useState('');
	const [schoolName, setSchoolName] = useState('');
	const [schoolAddress, setSchoolAddress] = useState('');
	const [pws, setPws] = useState('');
	const [cpws, setCpws] = useState('');
	const [date, setDate] = useState();
	const [defalutSelectValue, setDefalutSelectValue] = useState('');
	const [previousMobile, setPreviousMobile] = useState('');
	const [otpVerificationModal, setOtpVerificationModal] = useState(false)
	const [verifyButtonShow, setVerifyButtonShow] = useState(true)
	const [showResendOtp, setShowResendOtp] = useState(false)
	const [showOtpValidationTimer, setShowOtpValidationTimer] = useState(false)
	const [profileUpdatedWithMobile, setProfileUpdatedWithMobile] = useState('')
	const [updatedAllData, setUpdatedAllData] = useState('')

	const [userDetails, setUserDetails] = useState({
		motp: '',
	})

	const [uploadFile, setUploadFile] = useState('')
	const [cropImageModal, setCropImageModal] = useState(false)


	const [viewPassword, setViewPassword] = useState("password");
	const [infoBox, setInfoBox] = useState(false)

	const mobileOtpRef = useRef();

	const dispatch = useDispatch();

	const [crop, setCrop] = useState({ x: 0, y: 0 })
	// const [zoom, setZoom] = useState(1)
	const [base64ToImage, setBase64ToImage] = useState('')
	const [showSaveButton, setShowSaveButton] = useState(false)
	const [showFileInput, setShowFileInput] = useState(true)
	/* croppedImg:
	"https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png" */

	useEffect(() => {
		
		setProfileUpdatedWithMobile('')
		onGetDetails();
		dispatch(loadingBoardAction(true));
		dispatch(getBoardData(props.history));
		dispatch(getClassStandardData(props.history));
	}, []);

	useEffect(() => {
		if (status == 0 && status != null) {
			setOtpVerificationModal(true)
			dispatch(showingLoaderStudentAction(true));
			dispatch(sendVerificationOtp(mobile, '', props.history))
		}
	}, [status]);

	useEffect(() => {
		if (profileUpdatedWithMobile == 1) {
			profileUpdate()
			setProfileUpdatedWithMobile(0)
		}

	}, [profileUpdatedWithMobile]);

	useEffect(() => {
		if (profileDataUpdated == 1) {
			onGetDetails();
			// console.log("------111133--------", profileDataUpdated)
			dispatch(profileDataUpdatedAction(0))
		}
		// 
	}, [profileDataUpdated]);

	useEffect(() => {
		console.log("profileImageFile-----------------------", profileImageFile)
		setLocalImg(profileImg)
		setProImgFile(profileImageFile[0])
		setCropImageModal(profileImageFile[1])
		
	}, [profileImg, profileImageFile, profile_image]);


	function onSubmit(e) {
		// {console.log("=======@@111=====", `M: ${utility.decryptAES(props.mOtp)}`) }

		e.preventDefault();
		
		let error = false;
		const errorObj = { ...errorsObj };
		const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;


		if (fname === '') {
			errorObj.fname = 'First name is required';
			error = true;
		} else if (fname.length < 3) {
			errorObj.fname = 'Minimum 3 Character is required';
			error = true;
		}

		if (lname === '') {
			errorObj.lname = 'Last name is required';
			error = true;
		} else if (lname.length < 3) {
			errorObj.lname = 'Minimum 3 Character is required';
			error = true;
		}

		if (date === '' || date === undefined) {
			errorObj.dob = 'DOB is required';
			error = true;
		}

		if (gender === '') {
			errorObj.gender = 'Gender is required';
			error = true;
		}

		if (mobile === '') {
			errorObj.mobile = 'Mobile is required';
			error = true;
		} else if (mobile.length < 10) {
			errorObj.mobile = 'Check Mobile No.';
			error = true;
		} else if (mobile == '0000000000') {
			errorObj.mobile = 'Not a valid number';
			error = true;
		} /* else if (previousMobile != mobile) {
			setOtpVerificationModal(true)
		} */


		if (address === '') {
			errorObj.address = 'Address is required';
			error = true;
		} else if (address.length < 3) {
			errorObj.address = 'Minimum 3 Character is required';
			error = true;
		}

		if (pincode === '') {
			errorObj.pincode = 'Pin is required';
			error = true;
		} else if (pincode.length < 6) {
			errorObj.pincode = 'Minimum 6 Character is required';
			error = true;
		} else if (pincode == '000000') {
			errorObj.pincode = 'Not a valid pin';
			error = true;
		}

		if (standard === '') {
			errorObj.standard = 'Standard is required';
			error = true;
		}
		if (board === '') {
			errorObj.board = 'Board is required';
			error = true;
		}
		/* if (pws != '' && !regularExpression.test(pws)) {
			errorObj.pws = 'Password not in pattern';
			error = true;
		} */

		if (pws !== cpws) {
			errorObj.cpws = 'Password and confirm password does not match';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}
		if (previousMobile != mobile) {
			// setOtpVerificationModal(true)
			dispatch(showingLoaderStudentAction(true));
			dispatch(demoUserRecordExistsOrNot(mobile, '', props.history))
		} else {
			dispatch(loadingProfile(true));

			dispatch(updateProfileDetails(
				proImgFile == null ? '' : proImgFile,
				fname,
				lname,
				date ? moment(date).format('YYYY-MM-DD') : '',
				email,
				gender,
				address,
				pincode,
				previousMobile,
				standard,
				board,
				schoolName,
				schoolAddress,
				cpws,
				props.history
			));
		}
	}

	const profileUpdate = () => {
		dispatch(loadingProfile(true));

		dispatch(updateProfileDetails(
			proImgFile == null ? '' : proImgFile,
			fname,
			lname,
			date ? moment(date).format('YYYY-MM-DD') : '',
			email,
			gender,
			address,
			pincode,
			profileUpdatedWithMobile == 1 ? mobile : previousMobile,
			standard,
			board,
			schoolName,
			schoolAddress,
			cpws,
			props.history
		));
	}

	const onGetDetails = () => {

		let getData = localStorage.getItem('userDetails');

		// console.log("userDetails--------22-------", getData)

		let profilePic = JSON.parse(getData).profile_pic;
		let fname = JSON.parse(getData).fname;
		let lname = JSON.parse(getData).lname;
		let dob = JSON.parse(getData).dob;
		let email = JSON.parse(getData).email;
		let gender = JSON.parse(getData).gender;
		let address = JSON.parse(getData).address;
		let pincode = JSON.parse(getData).pincode;
		let mobile = JSON.parse(getData).mobile;
		let standard = JSON.parse(getData).standard;
		let board = JSON.parse(getData).board;
		let schoolName = JSON.parse(getData).school_name;
		let schoolAddress = JSON.parse(getData).school_address;

		setProImg(profilePic);
		setFname(fname);
		setLname(lname);
		setDate(new Date(dob));
		setEmail(email);
		setGender(gender.toLowerCase());
		setAddress(address);
		setPincode(pincode);
		setMobile(mobile);
		setStandard(standard);
		setBoard(board);
		setSchoolName(schoolName);
		setSchoolAddress(schoolAddress);
		setPreviousMobile(mobile)
	}

	const setdata = (e) => {
		const { name, value } = e.target
		setUserDetails((preValue) => {
			return {
				...preValue,
				[name]: value
			};
		})
	}






	const onImageHandle = (event) => {
		setCropImageModal(true)
	}


	const closeOtpVerificationModal = () => {
		dispatch(recordExistsSuccess(null));
		setOtpVerificationModal(false)
		setUserDetails(userDetails => ({
			...userDetails,
			motp: '',
			eotp: '',
		}));

		setShowResendOtp(false)
		setVerifyButtonShow(true)
		setProfileUpdatedWithMobile(0)
		/*  */
		// setOtpVerificationModal(false)
	}

	const verifyOtp = (e) => {
		e.preventDefault();

		const onlyNumber = /^[0-9]+$/;

		const { motp } = userDetails;
		let error = false;
		const errorObj = { ...errorsObj };

		if (motp === '') {
			mobileOtpRef.current.focus();
			errorObj.motp = 'Mobile OTP is required';
			error = true;
		} else if (!onlyNumber.test(motp)) {
			mobileOtpRef.current.focus();
			errorObj.motp = 'Allow only numeric';
			error = true;
		} else if (motp.length < 6) {
			mobileOtpRef.current.focus();
			errorObj.motp = 'Verify your OTP';
			error = true;
		}


		setErrors(errorObj);
		if (error) {
			return;
		} else {
			
			if (userDetails.motp == utility.decryptAES(props.mOtp)) {
				setOtpVerificationModal(false);
				setProfileUpdatedWithMobile(1)
				dispatch(recordExistsSuccess(null))
			} else {
				alert("Verify your OTP")
			}
		}
	}

	const renderTime = ({ remainingTime }) => {
		if (remainingTime === 0) {
			// return <div className="timer">Too lale...</div>;
			const removeOtp = {
				email_otp: '',
				mobile_otp: '',
			}
			setUserDetails(userDetails => ({
				...userDetails,
				motp: '',
				eotp: '',
			}))
			// setMobile('')
			setShowResendOtp(true)
			// setShowOtpValidationTimer(true)
			setVerifyButtonShow(false)
			dispatch(verificationCodeAction(removeOtp))
		}

		return (
			<div className="timer">
				{/* <div className="small_text">Remaining</div> */}
				<div className="remainingTime">{remainingTime}</div>
				{/* <div className="small_text">seconds</div> */}
			</div>
		);
	};

	const resendOtp = () => {
		dispatch(showingLoaderStudentAction(true));
		dispatch(sendVerificationOtp(mobile, '', props.history))
		setShowResendOtp(false)
		setVerifyButtonShow(true)
	}

	const showPassword = () => {
		if (viewPassword == "password") {
			setViewPassword("text")
		} else {
			setViewPassword("password")
		}
	}

	const handleMouseOver = () => {
		setInfoBox(true)

	};

	const handleMouseOut = () => {
		setInfoBox(false)
	};

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	const handleChange = (e) => {
		e.preventDefault();
	};

	return (
		<Fragment>
			{/* {console.log("=======@@111=====", `M: ${utility.decryptAES(props.mOtp)}`) } */}
			<div className="row">
				<div className="col-lg-3"></div>
				<div className="col-lg-6">
					<div className="card card-body">

						<div className="profile-container">
							{/* <img src={proImg ? proImg : profile} alt="" className="rounded" /> */}
							<div className="profile-header-image">
								{localImg != '' && localImg != null ?
									<img src={localImg} alt="" className="rounded" />
									:
									<img src={profile_image != null && profile_image != ''  ? profile_image : proImg != null && proImg != '' ? proImg : profile} alt="" className="rounded" />
								}
								<div className="custom-file-input" onClick={onImageHandle}>
									{/* <div className="custom-file-input"> */}
									<label htmlFor="imgForm"><i className="fa fa-camera m-0" /></label>
									{/* <input
										type="file"
										id="imgForm"
										multiple
										accept="image/*"
										onChange={onImageHandle}
										hidden
									/> */}
								</div>
							</div>

							<div className="profile-form-content">

								<form onSubmit={onSubmit}>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>First name</strong>
										</label>
										<input
											className="form-control"
											placeholder="First name"
											value={fname}
											onChange={(e) => setFname(e.target.value)}
											// onKeyDown={utility.inputAlphabetOnly}
											onKeyDown={utility.inputAlphabetOnlyWithSpace}
										/>
										{errors.fname && <div className="text-danger fs-12">{errors.fname}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Last name</strong>
										</label>
										<input
											className="form-control"
											placeholder="Last name"
											value={lname}
											onChange={(e) => setLname(e.target.value)}
											onKeyDown={utility.inputAlphabetOnly}
										/>
										{errors.lname && <div className="text-danger fs-12">{errors.lname}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Date of birth</strong>
										</label>
										<div className="date-control-view">
											<DatePicker
												ref={calendarRef}
												className="form-control input_value_date disable_input"
												name="dob"
												selected={date}
												// onChange={(date) => setDobFormat(date)}
												// onChange={(date) => setUserdob(Date.parse(date))}
												onChange={(date) => setDate(date)}
												// minDate={new Date()} 
												maxDate={new Date()}
												placeholderText="DOB"
												dateFormat="dd/MM/yyyy"
												showYearDropdown
												yearDropdownItemNumber={100}
												scrollableYearDropdown
												/* onKeyDown={(e) => {
													e.preventDefault();
												}} */
												readOnly
												onChangeRaw={handleDateChangeRaw}
												preventOpenOnFocus={true}
											/>
											{/* <DatePicker
												ref={calendarRef}
												selected={date}
												onChange={(date) => setDate(date)}
											/> */}
											{/* <button type='button' className="picker-btn"
												onClick={() => calendarRef.current.setOpen(true)}
											>
												<i className="fa-solid fa-calendar-days"></i>
											</button> */}
										</div>
										{errors.dob && <div className="text-danger fs-12">{errors.dob}</div>}
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Email</strong>
										</label>
										{/* email-disable */}
										<input
											className="form-control disable_input"
											placeholder="Email"
											value={email}
											disabled
											onChange={(e) => setEmail(e.target.value)} />
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Gender</strong>
										</label>
										<div className="profile-basic-dropdown">
											<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
											<select
												// defaultValue={"option"}
												className="form-control"
												value={gender}
												onChange={(e) => setGender(e.target.value)}
											>
												<option value="">Select Gender</option>
												<option value="male">Male</option>
												<option value="female">Female</option>
												<option value="other">Other</option>
											</select>
										</div>
										{errors.gender && <div className="text-danger fs-12">{errors.gender}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Mobile</strong>
										</label>
										<input
											className="form-control"
											placeholder="Mobile"
											value={mobile}
											// onChange={(e) => setMobile(e.target.value)}
											onChange={(e) => setMobile(e.target.value)}
											maxLength={10}
											onKeyPress={utility.inputNumberOnly}
										/>
										{errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Address</strong>
										</label>
										<input className="form-control"
											placeholder="Address"
											value={address}
											onChange={(e) => setAddress(e.target.value)} />
										{errors.address && <div className="text-danger fs-12">{errors.address}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Pin</strong>
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="Pin"
											value={pincode}
											onChange={(e) => setPincode(e.target.value)}
											maxLength={6}
											onKeyPress={utility.inputNumberOnly}
										/>
										{errors.pincode && <div className="text-danger fs-12">{errors.pincode}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Standard</strong>
										</label>
										<div className="profile-basic-dropdown">
											{/* <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span> */}
											<input
												className="form-control disable_input"
												placeholder="Select Standard"
												value={`Class ` + props.standard}
												onChange={(e) => setStandard(e.target.value)}
												disabled
											/>
											{/* <select
												defaultValue={"option"}
												className="form-control"
												value={standard}
												onChange={(e) => setStandard(e.target.value)}

											>
												<option value="">Select Standard</option>
												{
													/* props.classList.map((item, index) => {
														return <option key={index} value={item.class_no}>{item.short_code}</option>
													}) 
													<option value={props.standard}>Class {props.standard}</option>
												}
											</select> */}
										</div>
										{errors.standard && <div className="text-danger fs-12">{errors.standard}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Board</strong>
										</label>
										<div className="profile-basic-dropdown">
											{/* <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span> */}
											<input
												className="form-control disable_input"
												placeholder="Select Board"
												value={props.board_name}
												onChange={(e) => setBoard(e.target.value)}
												disabled
											/>
											{/* <select
												defaultValue={"option"}
												className="form-control disable_input"
												value={board}
												onChange={(e) => setBoard(e.target.value)}
												
											>
												<option value="">Select Board</option>
												{
													/* props.boardList.map((item, index) => {
														return <option key={index} value={item.id}>{item.name}</option>
													}) 
													<option value={props.board}>{props.board_name}</option>
												}
											</select> */}
										</div>
										{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>School name</strong>
										</label>
										<input
											className="form-control disable_input"
											placeholder="School Name"
											value={schoolName}
											onChange={(e) => setSchoolName(e.target.value)}
											disabled
										/>
										{errors.schoolName && <div className="text-danger fs-12">{errors.schoolName}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>School address</strong>
										</label>
										<input
											className="form-control disable_input"
											placeholder="School Address"
											value={schoolAddress}
											onChange={(e) => setSchoolAddress(e.target.value)}
											disabled
										/>
										{errors.schoolAddress && <div className="text-danger fs-12">{errors.schoolAddress}</div>}
									</div>

									<div className="form-group mb-3 eye_parent_container">

										<div className='d-flex justify-content-between align-items-center flex-row'>
											{infoBox ?
												<div className='info_box'>
													Must contain at least one number and one uppercase and one special character, and min 8  and max 16 characters require
												</div>
												: null}
											<div>
												<label className="mb-2">
													<strong>New password <span className='label_note'>(If you wish not to change your password, keep this blank)</span></strong>
												</label>
											</div>
											{/* <div
												onMouseOver={handleMouseOver}
												onMouseOut={handleMouseOut}
											>
												<i className="bi bi-info-circle-fill blue_color"></i>
											</div> */}

										</div>
										<div className='d-flex align-items-center'>
											<div className="view_password_reset" onClick={() => showPassword()}><i className={viewPassword == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></div>
											<input
												// type="password"
												type={viewPassword}
												className="form-control"
												placeholder="Password"
												value={pws}
												onChange={(e) => setPws(e.target.value)}
												minLength={6}
												maxLength={15}
											/>
										</div>
										{errors.pws && <div className="text-danger fs-12">{errors.pws}</div>}
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>New confirm password</strong>
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="confirm password"
											value={cpws}
											onChange={(e) => setCpws(e.target.value)}
											onCut={handleChange}
											onCopy={handleChange}
											onPaste={handleChange}
										/>
										{errors.cpws && <div className="text-danger fs-12">{errors.cpws}</div>}
									</div>



												{/* btn-primary */}
									<div className="text-center mt-4">
										<button type="submit" className="btn btn-block profile-update-btn">Update
											{props.loading ?
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												: null}
										</button>
									</div>


								</form>


							</div>

						</div>

					</div>
				</div>

				<div className="col-lg-3"></div>
				<Modal className="fade" show={otpVerificationModal}>
					<Modal.Header>
						{/* <Modal.Title className="modal_title">OTP Verify</Modal.Title> (M:{props.mOtp}, E: {props.eOtp}) */}
						<Modal.Title className="modal_title">Please Verify your Mobile Number </Modal.Title> {/* (M:{utility.decryptAES(props.mOtp)}, E: {utility.decryptAES(props.eOtp)}) */}
						<div className="optLoader">
							{props.mOtp != '' ?
								<>
									<div className="timer-wrapper">
										<CountdownCircleTimer
											isPlaying
											duration={props.otpValidTime * 60}
											// duration={1 * 60}
											colors={["#004777", "#F7B801", "#ff0000", "#ff0000"]}
											colorsTime={[props.otpValidTime * 60, 51, 50, 0]}
											onComplete={() => ({ shouldRepeat: false, delay: 1 })}
											size={30}
											strokeWidth={3}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
									<div className="small_text">Seconds Remaining </div>
								</>
								: null}
						</div>

					</Modal.Header>
					<Modal.Body>
						<div className="col-lg-12">

							<div className="basic-form">
								<form onSubmit={(e) => e.preventDefault()}>
									<div className="row justify-content-md-center">
										<div className="col-sm-6 mb-2">
											<label className="col-sm-6 col-form-label col-form-label-md">
												Mobile OTP
											</label>
											<input
												ref={mobileOtpRef}
												type="text"
												className="form-control input_value"
												placeholder="Mobile OTP"
												onChange={setdata}
												value={userDetails.motp}
												name="motp"
												autocomplete="off"
												maxLength={6}
											/>
											{errors.motp && <div className="text-danger fs-12">{errors.motp}</div>}
										</div>
									</div>
								</form>

							</div>
						</div>

					</Modal.Body>
					<Modal.Footer>
						<div className="modal_footer_container_info">
							<div className="modal_footer_left_container">
								<p className="footer_text">Good Luck</p>
								<p className="footer_text">Team Crestest</p>
							</div>
							<div className="modal_footer_right_container">

								<Button
									variant="danger text-center modal_footer_button"
									// onClick={() => setOtpVerificationModal(false)}
									onClick={() => closeOtpVerificationModal()}
								>
									Close
								</Button>
								{verifyButtonShow ?
									<Button
										variant="warning text-center modal_footer_button"
										onClick={verifyOtp}
									>
										Verify
									</Button>
									: null}
								{showResendOtp ?
									<Button
										variant="warning text-center modal_footer_button"
										onClick={resendOtp}
									>
										Resend OTP
									</Button>
									: null}
							</div>
						</div>

					</Modal.Footer>
				</Modal>

				<Modal className="fade" show={cropImageModal} >
					<Modal.Header>
						<Modal.Title className="modal_title">Resize your image </Modal.Title>
						<Button
							variant=""
							className="btn-close"
							onClick={() => setCropImageModal(false)}
						>
						</Button>
					</Modal.Header>
					<Modal.Body>
						<div className='image-change_container'>
							<div className="d-flex mt-3">
								<ProfileImgCrop />
							</div>
						</div>

					</Modal.Body>
					<Modal.Footer>
						<div className="modal_footer_container_info">
						</div>
					</Modal.Footer>
				</Modal>
			</div>

		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		boardList: state.board.boardList,
		classList: state.standard.classStandardList,
		loading: state.profile.showLoading,
		standard: state.auth.standard,
		board: state.auth.board,
		board_name: state.auth.board_name,
		mOtp: state.student.mobile_otp,
		otpValidTime: state.student.otpValidTime,
	};
};

export default connect(mapStateToProps)(Profile);