import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

//Import Components
import ExamList from '../components/exam/ExamList';


const DemoExam = () => {

	const [isDemo, setIsDemo] = useState('')

	const demoExamSubmit = useSelector((state) => state.questionNo.demoExamDoneOrNot);

	useEffect(() => {
		let getData = localStorage.getItem('userDetails');
		let token = JSON.parse(getData).token;
		let user_id = JSON.parse(getData).id;
		setIsDemo(user_id);
	}, []);

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Demo Exams  <i className="bi bi-chevron-right"></i>{isDemo == 0 ? `Guest User` : `Registered User`} </div>
				</div>
			</div>
			<div className="row">
				<div className="exam_list_container" >
					<ExamList />
				</div>
			</div>
		</>
	)
}
export default DemoExam;