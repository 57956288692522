import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { Pie, } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
	Row,
	Col,
	Modal
} from "react-bootstrap";


const ChartCard16 = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const [subject, setSubject] = useState(props.subject)
	const [dataSets, setDataSets] = useState(props.dataValue)
	const [showTableModal, setShowTableModal] = useState(false)
	const [tableData, setTableData] = useState(props.tableData)
	const [filterTableData, setFilterTableData] = useState([])
	const [board_name, setBoard_name] = useState('')
	const [standard, setStandard] = useState('')


	const [backgroundColor, setBackgroundColor] = useState(['#CBF1FA',])

	const [overallAvg, setOverallAvg] = useState(0)
	const chartRef = useRef();

	ChartJS.register(ArcElement, Tooltip, Legend);

	useEffect(() => {

		let getData = localStorage.getItem('userDetails');
		let token = JSON.parse(getData).token;
		let board_name = JSON.parse(getData).board_name;
		let standard = JSON.parse(getData).standard;
		setBoard_name(board_name);
		setStandard(standard);

	}, [dataSets, tableData, filterTableData, props.dataValue]);


	const data = {
		labels: ["Correct", "Incorrect", "Not Attempted"],
		datasets: [
			{
				// data: [10, 2, 2],
				// data: [dataSets[2], dataSets[1], dataSets[4],],
				data: [props.dataValue[2], props.dataValue[1], props.dataValue[4],],
				// data: setData0,
				borderWidth: 0,
				backgroundColor: [
					"#00B050",
					"#C00000",
					"#A6A6A6",
				],
				/* hoverBackgroundColor: [
					"#000000",
					"#C00000",
					"#A6A6A6",
				], */
			},
		],
	};


	const options = {
		plugins: {
			legend: {
				legend: true,
				position: "left",
				labels: {
					boxWidth: 20,
					padding: 13,
					useSeriesColors: false,
					usePointStyle: true,
					// pointStyleWidth: 10,
				}
			},
			// legend: false,
			// responsive: true,
			datalabels: {
				color: '#000',
				display: true,
				align: 'center',
				backgroundColor: '#D9D9DB',
				borderRadius: 100,
				padding: 3,
				font: {
					size: 12,
				},
			},
		},
		maintainAspectRatio: false,
		// responsive: true,
		/* dataLabels: {
			name: {
				show: false,
			},
			value: {
				show: false,
			}
		},
		legend: {
			fontSize: "12px",
			show: true,
			position: "bottom",
			labels: {
				useSeriesColors: true,
			},
			markers: {
				size: 10
			},
			itemMargin: {
				vertical: 2
			},
			formatter: function (seriesName, opts) {
				return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
			},
		}, */
	};

	const showTableData = () => {
		// console.log("Show Table Data");

		setShowTableModal(true)
		setFilterTableData([tableData])
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}


	return (
		<>

			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						{/* <div className="chart_card_heading">{props.chapter_heading} {`> Ch Test ${props.pageHeading}`} {`> ${props.ChartCountData[props.pageHeading].chapter_name }`}</div> */}
						<div className="chart_card_heading mb-3">
						<div className='chart_heading'>Grip on Chapter - CTL</div>
							{props.subjectName} {props.pageHeading == 3 ? `> Case Study` : `> Ch Test ${props.pageHeading}`} {`> ${props.ChartCountData[props.pageHeading].chapter_name}`}
						</div>
						<div className="chart_body" >
							<Pie data={data} width={100} height={50} options={options} plugins={[ChartDataLabels]} />
						</div>
						<div className="bottom_details">
							{/* <div className="btn_details back_btn_color text_black">Back</div> */}
							{/* <div className="btn_details details_btn_color text_white cursorPointer" onClick={props.showTableData}>Details</div> */}
							<div className="btn_details details_btn_color text_white cursorPointer" onClick={showTableData}>Details</div>
						</div>
					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								<div className='performer_table_heading'>Finished Figures - CTL</div>
								<p>Scholastic <i className="fa fa-angle-right assessment_icon_color"></i> {board_name}: {standard} <i className="fa fa-angle-right assessment_icon_color"></i> Chapter Test <i className="fa fa-angle-right assessment_icon_color"></i> {props.subjectName} {props.pageHeading == 3 ? `> Case Study` : `> Ch Test ${props.pageHeading}`} {`> ${props.ChartCountData[props.pageHeading].chapter_name}`} </p>
							</div>
							{/* <i className="fa fa-angle-right assessment_icon_color"></i> Polynomial</p> */}
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="5px" className="performance_analysis_table_data">
								<thead>
									<tr>
										<th className="text-center"></th>
										<th className="text-center light_sky2" >Correct</th>
										<th className="text-center light_sky2" >Incorrect</th>
										<th className="text-center light_sky2" >Not attempted</th>
										<th className="text-center light_sky2" >Total</th>
									</tr>
								</thead>
								{props.ChartCountData != '' && props.ChartCountData != 'undefined' && props.ChartCountData != undefined ?
									<tbody>
										<tr>
											<td className="text-center light_red2">{props.pageHeading == 3 ? `Case Study` : `Ch Test ${props.pageHeading}`}</td>
											<td className="text-center light_gray_3" >{props.ChartCountData[props.pageHeading].total_correct_ans}</td>
											<td className="text-center light_gray_3" >{props.ChartCountData[props.pageHeading].total_incorrect_ans}</td>
											<td className="text-center light_gray_3" >{props.ChartCountData[props.pageHeading].total_not_attempted}</td>
											<td className="text-center light_gray_3" >{props.ChartCountData[props.pageHeading].total_question}</td>
										</tr>
									</tbody>
									: null}
							</table>
						</div>
					</div>
				</>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	return {

	};
};
export default connect(mapStateToProps)(ChartCard16);