import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
} from "react-bootstrap";
import Loader from "../../components/Loader"

import SubcribeCompetitiveCard from '../../components/subcriptionCard/SubcribeCompetitiveCard';
import SubcribeScholasticCard from '../../components/subcriptionCard/SubcribeScholasticCard';
import SubcribeIntegratedCard from '../../components/subcriptionCard/SubcribeIntegratedCard';
import PurchaseSubcribeScholasticCard from '../../components/subcriptionCard/PurchaseSubcribeScholasticCard';
import { getCategoryData, getAllexamCategories } from '../../../store/actions/ExamCategoryAction';
import { getExamTypeData } from '../../../store/actions/ExamTypeAction';
import { getClassStandardData } from '../../../store/actions/ClassStandardAction';
import { getBoardData } from '../../../store/actions/BoardActions';
import { getScSubjectData, getCmSubjectData, subscribeLoading, removeSubjectListAction, subscriptionPreviousValueNTSEAction, subscriptionPreviousValueNSTSEAction, getIntegratedScSubjectData, eLibrarySelectSubjectsListAction, checkBoxStatusAction, scholasticSubscriptionSourceAction, compititiveSubscriptionSourceAction, scholasticCombinationPriceIdAction } from '../../../store/actions/SubscribeAction';


const TabExam = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [categoty, setCategory] = useState('');
	const [type, setType] = useState('');
	const [classValue, setClassName] = useState('');
	const [board, setBoard] = useState('');
	const [isClass, setIsClass] = useState(true);
	const [defalutSelectValue, setDefalutSelectValue] = useState('');
	const [prePruchasedValue, setPrePruchasedValue] = useState();
	const [selectElibrarySubjectId, setSelectElibrarySubjectId] = useState([]);
	const [isSelected, setIsSelected] = useState();
	const [subjectSelectWithSource, setSubjectSelectWithSource] = useState([]);
	const [selectSubId, setSelectSubId] = useState()

	const SubStatus = useSelector(state => state.subscribe.scSubscriptionStatus);
	const scholasticCombinePrice = useSelector((state) => state.subscribe.scholasticCombinePrice);
	const scholasticCombinePriceId = useSelector((state) => state.subscribe.scholasticCombinePriceId);
	
	const scholasticSubscriptionSourceData = useSelector(state => state.subscribe.scholasticSubscriptionSourceData);


	useEffect(() => {
		dispatch(getCategoryData(history));
		dispatch(getAllexamCategories(history));
		dispatch(getExamTypeData(2, history));
		dispatch(getClassStandardData(history));
		dispatch(getBoardData(history));
		

		if (SubStatus === 1) {
			
			if (type != '') {
				dispatch(getCmSubjectData(type, type == '1' ? 0 : classValue, history));
			} else {
				dispatch(getScSubjectData(board, classValue, history));
				setType('');
			}
		}
		if (scholasticCombinePriceId != null) {
			props.scsubjectlist.map(u => u.cart_amount = u.id === scholasticCombinePriceId ? scholasticCombinePrice : u.cart_amount);
			dispatch(scholasticCombinationPriceIdAction(null));
		}
		
	}, [SubStatus, scholasticCombinePrice, scholasticCombinePriceId]);


	useEffect(() => {
		if (props.cmSubjectList != '') {
			let previousNTSEPurcahseValue;
			let previousNSTSEPurcahseValue;
			let previousNTSEPurcahseDetails;
			let previousNSTSEPurcahseDetails;

			if (props.cmSubjectList.filter(i => i.is_purchased == 1 && i.exam_type_id == 1).length > 0) {
				previousNTSEPurcahseDetails = props.cmSubjectList.filter(i => i.is_purchased == 1 && i.exam_type_id == 1)
				previousNTSEPurcahseValue = previousNTSEPurcahseDetails[previousNTSEPurcahseDetails.length - 1].amount;
			} else if (props.cmSubjectList.filter(i => i.is_purchased == 1 && i.exam_type_id == 2).length > 0) {
				previousNSTSEPurcahseDetails = props.cmSubjectList.filter(i => i.is_purchased == 1 && i.exam_type_id == 2)
				previousNSTSEPurcahseValue = previousNSTSEPurcahseDetails[previousNSTSEPurcahseDetails.length - 1].amount;
			}

			dispatch(subscriptionPreviousValueNTSEAction(previousNTSEPurcahseValue != undefined && previousNTSEPurcahseValue != null ? previousNTSEPurcahseValue : 0))
			dispatch(subscriptionPreviousValueNSTSEAction(previousNSTSEPurcahseValue != undefined && previousNSTSEPurcahseValue != null ? previousNSTSEPurcahseValue : 0))

			const subscriptionPreviousDetails = {
				alreadyPurchasedNTSE: previousNTSEPurcahseValue,
				alreadyPurchasedNSTSE: previousNSTSEPurcahseValue
			}
			localStorage.setItem('subscriptionPreviousValue', JSON.stringify(subscriptionPreviousDetails));
		}

	}, [props.cmSubjectList]);

	/* select Category  */
	const onExamCategoryHandler = (event) => {
		setCategory(event.target.value);
		setBoard('');
		setType('');
		setClassName('');
		setIsClass(true);
		dispatch(removeSubjectListAction());
	}

	/* select Exam Type  */
	const onExamtypedHandler = (event) => {
		dispatch(compititiveSubscriptionSourceAction([]))
		if (event.target.value != "Select Exam Type") {
			let is_class_value = props.typelist.filter(i => i.id == event.target.value)

			setType(event.target.value);
			
			setIsClass(is_class_value[0].is_class == 0 ? true : false);

		}
		setClassName('');
		if (event.target.value !== '2') {
			dispatch(subscribeLoading(true));
			dispatch(getCmSubjectData(event.target.value, 0, history));
		} else {
			dispatch(removeSubjectListAction());
		}
	}

	/* select Board  */
	const onBoardHandler = (event) => {

		setBoard(event.target.value);
		setIsClass(event.target.value ? false : true);
		setClassName('');
		dispatch(removeSubjectListAction());
	}

	const onClassHandler = (event) => {
		setClassName(event.target.value);
		if (categoty === '1' && event.target.value) {
			dispatch(subscribeLoading(true));
			dispatch(getScSubjectData(board, event.target.value != "Select Class" ? event.target.value : '', history));
		} else if (categoty === '2' && event.target.value) {
			dispatch(subscribeLoading(true));
			dispatch(getCmSubjectData(type, event.target.value != "Select Class" ? event.target.value : '', history));
		} else if (categoty === '3' && event.target.value) {
			dispatch(subscribeLoading(true));
			dispatch(getIntegratedScSubjectData(board, event.target.value, history));
		}
	}

	const previousPurchasedDetails = () => {
		const previousPurcahseValue = props.cmSubjectList.filter(i => i.is_purchased == 1 && i.exam_type_id == 2).map(item => item.amount).reduce((prev, next) => prev + next)
		setPrePruchasedValue(previousPurcahseValue)
	}

	/* select scholastic e_library  */
	const e_library_select_handaler = (comboIds, subjectId) => {//, d
		
		/* new data set create ---------- */

		let subjectCard = {};
		subjectCard.category_id = categoty;
		subjectCard.subject_id = subjectId;
		subjectCard.combo_subject_ids = [];
		subjectCard.source = "online";


		let test_new_data = {};
		let test_new_data_array = [];
		for (let i = 0; i < comboIds.length; i++) {
			//test_new_data['subject_id'] = comboIds[i];
			test_new_data_array.push(comboIds[i]);
		}
		subjectCard.combo_subject_ids = test_new_data_array;


		
		let temp_data = scholasticSubscriptionSourceData.concat(subjectCard);
		setSubjectSelectWithSource(temp_data)
		dispatch(scholasticSubscriptionSourceAction(temp_data))
		
	}

	/* unselect scholastic e_library  */
	const e_library_unselect_handaler = (comboIds, subjectId) => {
		let differenceNew = scholasticSubscriptionSourceData.filter(x => x.subject_id != subjectId);
		
		setSubjectSelectWithSource(differenceNew)
		dispatch(scholasticSubscriptionSourceAction(differenceNew))
	}


	const unselectMethod = (id, d) => {
		// console.log("unselect avijit : ", id, d);
		// setIsSelected(d);
	}

	/* select compititive e_library  */
	const competitiv_e_library_select_handaler = (e, id, exam_type) => {
		
		setSelectSubId(id)
		dispatch(compititiveSubscriptionSourceAction({ e, id, exam_type }))
	}

	/* unselect compititive e_library  */
	const competitiv_e_library_unselect_handaler = (e, id, exam_type) => {
		
		setSelectSubId(0)
		dispatch(compititiveSubscriptionSourceAction([]))
	}

	/* find select group e_library  */
	const hasItemWithKeyAndValue = (arr, key, value) => {
		return arr.some(item => item[key] === value);
	}

	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					<div className="subscribe-dropdown-section">
						<div className="subscribe-basic-dropdown">
							<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
							{props.examAllCategoryList == "" || props.examAllCategoryList == undefined ?

								<select
									className="form-control form-control"
								>
									<option value={defalutSelectValue}>Select exam category</option>
								</select>

								:
								<select
									// defaultValue={"option"}
									className="form-control form-control"
									value={categoty}
									onChange={onExamCategoryHandler}
								>
									<option value={defalutSelectValue}>Select exam category</option>
									{
										props.examAllCategoryList.map((item, index) => {
											return <option className='text-capitalize' key={index} value={item.id}>{item.category}</option>
										})
									}
								</select>
							}
						</div>
						{categoty === "2" ?
							<div className="subscribe-basic-dropdown">
								<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
								<select
									// defaultValue={"option"}
									className="form-control form-control"
									value={type}
									disabled={categoty ? false : true}
									onChange={onExamtypedHandler}
								>
									<option value={defalutSelectValue}>Select exam type</option>
									{
										props.typelist.map((item, index) => {
											return <option key={index} value={item.id}>{item.type_name}</option>
										})
									}

								</select>
							</div>
							:
							<div className="subscribe-basic-dropdown">
								<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
								<select
									// defaultValue={"option"}
									className="form-control form-control"
									value={board}
									disabled={categoty ? false : true}
									onChange={onBoardHandler}
								>
									<option value={defalutSelectValue}>Select board</option>
									{
										<option value={props.board}>{props.board_name}</option>
									}

								</select>
							</div>
						}

						<div className="subscribe-basic-dropdown">
							<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
							<select
								// defaultValue={"option"}
								className="form-control form-control"
								value={classValue}
								disabled={isClass}
								onChange={onClassHandler}
							>
								<option value={defalutSelectValue}>Select class</option>
								{
									<option value={props.standard}>Class {props.standard}</option>
								}
							</select>
						</div>

					</div>
				</Col>
			</Row>
			{props.showLoading ?
				<>
					<Loader />
				</>
				: null}
			{
				categoty === "1" ?
					<Row>
						{
							props.scsubjectlist.map((item, index) => {
								return <Col xl={4} key={index}>
									<SubcribeScholasticCard
										key={item.id}
										id={item.id}
										data={item}
										subjectName={item.subject_name}
										
										subject_cards={scholasticSubscriptionSourceData}

										selectSubscriptionCard={hasItemWithKeyAndValue(props.cartList, 'subscription_id', item.id) ? item.id : 0}
										setCount={item.set_count}
										category_id={categoty}
										board={board}
										class_no={classValue}

										isPurchased={item.is_purchased}
										combinePrice={item.cart_amount}
										sticker_text={item.sticker_text}
										subject_image={item.subject_image}
										test_count={item.test_count}
										combo_subject_names={item.combo_subject_names}
										combo_subject_ids={item.combo_subject_ids}
										e_library_select_handaler={e_library_select_handaler}
										e_library_unselect_handaler={e_library_unselect_handaler}
									// unselectMethod={unselectMethod}
									/>
								</Col>
							})
						}
					</Row>
					: categoty === "2" ?
						<Row>

							{props.cmSubjectList.length > 0 ?
								<div>
									<h4 className='text-center mt-5 bg-info p-1'>Total Set Purchased : {props.cmSubjectList[0].total_set_purchased} / {props.cmSubjectList[0].max_set_no}</h4>
								</div>
								: null}
							{
								props.cmSubjectList.map((item, index) => {
									return <Col xl={4} key={index}>
										<SubcribeCompetitiveCard
											key={item.id}
											selectSubId={selectSubId}
											id={item.id}
											data={item}
											amount={item.amount}
											setCount={item.set_count}
											questionPerset={item.question_per_set}
											category_id={categoty}
											type={type}
											class_no={classValue}
											exam_type_id={item.exam_type_id}
											library={item.library_price}
											isPurchased={item.is_purchased}
											sticker_text={item.sticker_text}
											competitiv_e_library_select_handaler={competitiv_e_library_select_handaler}
											competitiv_e_library_unselect_handaler={competitiv_e_library_unselect_handaler}

										/>
									</Col>
								})
							}
						</Row>
						: categoty === "3" ?
							<Row>
								{
									props.integratedSubscriptionList.map((item, index) => {
										return <Col xl={4} key={index}>
											<SubcribeIntegratedCard
												key={item.id}
												id={item.id}
												data={item}
												amount={item.amount}
												setCount={item.sch_no_set}
												questionPerset={item.question_per_set}
												category_id={categoty}
												type={type}
												class_no={classValue}
												exam_type_id={item.exam_type_id}
												library={item.library_price}
												isPurchased={item.is_purchased}
												sticker_text={item.sticker_text}
												integrated_name={item.integrated_name}
												subheading={item.subheading}
												details={item.details}
												price={item.price}
											/>
										</Col>
									})
								}
							</Row>
							: null
			}

		</Fragment >
	)
}

const mapStateToProps = (state) => {
	// console.log("******----", state.subscribe.scSubjectList,)
	return {
		standard: state.auth.standard,
		board: state.auth.board,
		board_name: state.auth.board_name,
		categorylist: state.category.examcategoryList,
		examAllCategoryList: state.category.examAllCategoryList,
		typelist: state.examtype.examTypeList,
		classlist: state.standard.classStandardList,
		boardlist: state.board.boardList,
		scsubjectlist: state.subscribe.scSubjectList,
		cmSubjectList: state.subscribe.cmSubjectList,
		showLoading: state.subscribe.showLoading,
		integratedSubscriptionList: state.subscribe.integratedSubscriptionList,
		cartList: state.subscribe.cartList,
	};
};
export default connect(mapStateToProps)(TabExam);