import React, { useEffect, useState, useLayoutEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"
import {
	Row,
	Col,

} from "react-bootstrap";

//Import Components
import ChartCard16 from './performanceComponent/ChartCard16';
import ChartCard17 from './performanceComponent/ChartCard17';
import ChartCard17Set from './performanceComponent/ChartCard17Set';
import ChartCard16SetCSS from './performanceComponent/ChartCard16SetCSS';
import ChartCard17SetCSS from './performanceComponent/ChartCard17SetCSS';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'

import { getScholasticChapterwiseAnalysisDataDetails, performanceRequestAction, getScholasticChapterwiseAnalysisDataAction, getScholasticGetchapterwiseAnalysisCaseStudyDataDetails } from '../../../store/actions/PerformanceScoreAction';

const ChapterwiseAnalysisOnSet = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [showTableModal, setShowTableModal] = useState(false)
	const [previousValue, setPrevousValue] = useState(location.state)
	const [sets, setSets] = useState([])
	const [chapterName, setChapterName] = useState()
	const [chapterNo, setChapterNo] = useState()
	const [set, setSet] = useState('Set ')

	const scholasticChaptersAnalysisDataPiechart = useSelector(state => state.performance.scholasticChaptersAnalysisDataPiechart);

	useLayoutEffect(() => {
		dispatch(getScholasticChapterwiseAnalysisDataAction([]));
		dispatch(performanceRequestAction(true))

		// dispatch(getScholasticChapterwiseAnalysisDataDetails(previousValue.chapter_id, previousValue.subject_id, 1, props.history))
		dispatch(getScholasticChapterwiseAnalysisDataDetails(location.state.chapter_id, location.state.subject_id, 1, location.state.group_subject_id, props.history))
		dispatch(getScholasticGetchapterwiseAnalysisCaseStudyDataDetails(location.state.chapter_id, location.state.subject_id, 1, location.state.group_subject_id, props.history))

		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
		};
	}, []);

	useEffect(() => {

		setSets([]);

		if (props.scholasticChaptersAnalysisDataPiechart != '' && props.scholasticChaptersAnalysisDataPiechart != undefined) {
			let chapterSet = [];
			let dataValue = props.scholasticChaptersAnalysisDataPiechart
			Object.keys(dataValue).forEach(function (key) {
				// Object.keys(dd).forEach(function (key) {
				let labels = [];
				let vals = [];
				Object.keys(dataValue[key]).forEach(function (key2) {
					labels.push(key2);
					vals.push(dataValue[key][key2]);
				});
				setChapterName(dataValue[key]['chapter_heading'])
				setChapterNo(dataValue[key]['chapter_name'])
				let obj = {
					labelName: labels,
					value: vals,
				}
				chapterSet.push({
					[key]: obj
				})
			});


			setSets(chapterSet)
		}

	}, [props.scholasticChaptersAnalysisDataPiechart, scholasticChaptersAnalysisDataPiechart,]);

	const showTableData = () => {
		// console.log("Show Table Data");
		setShowTableModal(true)
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	const goPrevious = () => {
		// console.log("chapterwise-analysis-on-set-->", history)
		history.goBack()
	}


	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Online Exam Performance Score <i className="bi bi-chevron-right"></i> Scholastic Performance Score({location.state && location.state.group_name}) <i className="bi bi-chevron-right"></i> Overall Scholastic Performance Score <i className="bi bi-chevron-right"></i> Subjectwise Scholastic Score <i className="bi bi-chevron-right"></i> Subjectwise Analysis CTL <i className="bi bi-chevron-right"></i>Chapterwise Analysis CTL</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<Row>
				{props.scholasticChaptersAnalysisDataPiechart != "" && props.scholasticChaptersAnalysisDataPiechart != undefined && sets != "" ?
					sets.map((item, index) => {
						// console.log("----------------------item", Object.keys(item))
						// console.log("----------------------item", Object.values(item))
						/* // console.log("----------------------item", item) */
						// console.log("*****-----", Object.keys(item)[0])
						return (
							<React.Fragment key={index}>
								<Col xl={6} >
									<ChartCard16
										pageHeading={Object.keys(item)[0]}
										// pageHeading={`Name Of The Chapter Set` + Object.keys(item)[0]}
										dataValue={item[Object.keys(item)].value}
										showTableData={() => showTableData()}
										tableData={props.scholasticChaptersAnalysisDataTabledata}
										subjectName={location.state.subject}
										// subjectName={previousValue.subject}
										chapter_heading={chapterName}
										ChartCountData={props.scholasticChaptersAnalysisDataPiechart}
										group_subject_id={location.state.group_subject_id}
									/>
								</Col>

								<Col xl={6}>
									<ChartCard17Set
										testNo={Object.keys(item)[0]}
										pageHeading={chapterName}
										// allDdataValue={sets}
										tableData={props.scholasticChaptersAnalysisDataTabledata}
										chapter_heading={chapterName}
										type_of="Ch Test "
										// subjectName={previousValue.subject}
										subjectName={location.state.subject}
										group_subject_id={location.state.group_subject_id}
									/>
								</Col>
							</React.Fragment>
						)
					})
					: null}
					{/* Case study----------- */}
					{/* {console.log("$$$$$$---", props.scholasticChaptersAnalysisCaseStudyDataPiechart)} */}
					{/* Object.keys(props.scholasticChaptersAnalysisCaseStudyDataPiechart).length > 0 &&  */}
				{!!props.scholasticChaptersAnalysisCaseStudyDataPiechart && Object.keys(props.scholasticChaptersAnalysisCaseStudyDataPiechart).length > 0 && props.scholasticChaptersAnalysisCaseStudyDataPiechart != undefined  ?
					<React.Fragment >
						<Col xl={6} >
							<ChartCard16SetCSS
								// pageHeading={Object.keys(item)[0]}
								// pageHeading={`Name Of The Chapter Set` + Object.keys(item)[0]}
								// dataValue={item[Object.keys(item)].value}
								dataValue={props.scholasticChaptersAnalysisCaseStudyDataPiechart}
								// showTableData={() => showTableData()}
								tableData={props.scholasticChaptersAnalysisCaseStudyDataTabledata}
								subjectName={location.state.subject}
								// subjectName={previousValue.subject}
								chapter_heading={props.scholasticChaptersAnalysisCaseStudyDataPiechart[0].chapter_test}
								chapter_name={props.scholasticChaptersAnalysisCaseStudyDataPiechart[0].chapter_name}
								ChartCountData={props.scholasticChaptersAnalysisCaseStudyDataPiechart}
								group_subject_id={location.state.group_subject_id}
							/>
						</Col> 

						<Col xl={6}>
							<ChartCard17SetCSS
								// testNo={Object.keys(item)[0]}
								// pageHeading={chapterName}
								// allDdataValue={sets}
								tableData={props.scholasticChaptersAnalysisCaseStudyDataTabledata}
								chapter_heading={props.scholasticChaptersAnalysisCaseStudyDataPiechart[0].chapter_heading}
								table_heading={props.scholasticChaptersAnalysisCaseStudyDataPiechart[0].chapter_name}
								type_of="Ch Test "
								// subjectName={previousValue.subject}
								subjectName={location.state.subject}
								group_subject_id={location.state.group_subject_id}
							/>
						</Col>
					</React.Fragment>

					: null}
			</Row>

		</>
	)
}

const mapStateToProps = (state) => {
	// console.log("******--222---", state.performance.scholasticChaptersAnalysisCaseStudyDataPiechart)
	// console.log("******-111----", state.performance.scholasticChaptersAnalysisCaseStudyDataTabledata)
	return {
		performanceLoader: state.performance.loading,
		scholasticChaptersAnalysisDataPiechart: state.performance.scholasticChaptersAnalysisDataPiechart,
		scholasticChaptersAnalysisDataTabledata: state.performance.scholasticChaptersAnalysisDataTabledata,
		scholasticChaptersAnalysisCaseStudyDataPiechart: state.performance.scholasticChaptersAnalysisCaseStudyDataPiechart,
		scholasticChaptersAnalysisCaseStudyDataTabledata: state.performance.scholasticChaptersAnalysisCaseStudyDataTabledata,
	};
};
export default connect(mapStateToProps)(ChapterwiseAnalysisOnSet);