import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
	loadingToggleAction, loginAction, demoLogin
} from '../../store/actions/AuthActions';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import * as utility from '../../utility/Utility';
import { Button, Modal, } from "react-bootstrap";
import Loader from "../components/Loader"
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { BS5FloatingAutocompleteList } from 'react-bootstrap-autocomplete-list';

import { getBoardData, loadingBoardAction, getSchoolListData, getSchoolListFailureAction, selectSchoolNameListAction, schoolNameShowingLoaderAction } from '../../store/actions/BoardActions';
import { getClassStandardData, loadingClassStandardAction } from '../../store/actions/ClassStandardAction';
import { newStudentRegistrationWithoutDemo, showingLoaderStudentAction, demoUserRecordExistsOrNot, sendVerificationOtp, verificationCodeAction, recordExistsSuccess, registrationStatusDetails } from '../../store/actions/StudentAction';
import { goToTab } from '../../store/actions/AuthActions';

//defalutValue
import footerIcon from '../../images/policy-icon.png';
import loginbg from '../../images/background-login.png'

import logo from '../../images/log2-Registration.png'
import logofull from '../../images/clv_logo.png';

import loginLeftImage from '../../images/registration_left_image.png';
import Moment from 'react-moment';

import TermsCondition from './TermsCondition';
import PrivacyPolicy from './PrivacyPolicy';

import CreatableSelect from 'react-select/creatable';

import ReactGA from 'react-ga'; // Add Google Analytics

function RegistrationNew(props) {
	const status = useSelector(state => state.student.status);
	const schoolList = useSelector(state => state.board.schoolList);

	const dispatch = useDispatch();
	const [otpVerificationModal, setOtpVerificationModal] = useState(false)
	const [showResendOtp, setShowResendOtp] = useState(false)
	const [showOtpValidationTimer, setShowOtpValidationTimer] = useState(false)
	const [verifyButtonShow, setVerifyButtonShow] = useState(true)
	const [isOpen, setIsOpen] = useState(false);
	const [viewPassword, setViewPassword] = useState("password");
	const [viewCPassword, setViewCPassword] = useState("password");
	const [schoolNameList, setSchoolNameList] = useState([]);
	const [onlySchoolName, setOnlySchoolName] = useState([]);
	const [schoolAddress, setSchoolAddress] = useState([]);
	const [blankSchoolNameList, setBlankSchoolNameList] = useState(0);
	const [defalutValue, setDefalutValue] = useState('option');
	const [defalutValueBlank, setDefalutValueBlank] = useState(null);

	const [decryptValue, setDecryptValue] = useState(null);

	const history = useHistory();
	const tabName = useSelector(state => state.auth.tabName);
	const isShowSchoolList = useSelector(state => state.board.isShowSchoolList);
	const [termsAndConditionModal, SetTermsAndConditionModal] = useState(false)
	const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)
	var d = new Date();

	const [selectedOption, setSelectedOption] = useState(null);
	const [options, setOptions] = useState(null);
	const [infoBox, setInfoBox] = useState(false)
	const [schoolNameLoading, setSchoolNameLoading] = useState()

	useEffect(() => {
		dispatch(loadingBoardAction(true)); /* showing board data loader */
		dispatch(getBoardData(history)); /* get board data */
		dispatch(loadingClassStandardAction(true)); /* showing board class loader */
		dispatch(getClassStandardData(history)); /* get class data */

		if (status == 0 && status != null) {
			setOtpVerificationModal(true); /* open verification modal */
			dispatch(showingLoaderStudentAction(true)); /* verfication loader showing */
			dispatch(sendVerificationOtp(userDetails.mobile, userDetails.email, history)) /* call verfication OTP */
		}

		/* if (schoolList != '') {
			setSchoolNameList(props.schoolList)
		} */
		if (isShowSchoolList == 1) {
			setSchoolNameList(props.schoolList) /* school list store into state */
		}

		// if (schoolNameList != '') {
		if (isShowSchoolList == 1) {
			/* setOnlySchoolName([])
			let schoolName = [];
			schoolNameList.forEach((elm) => {
				schoolName.push(elm.school_name);
			})
			setOnlySchoolName(schoolName) */


			let selectSchoolNameBoardWise = [];
			let selectSchoolName = {}
			schoolNameList.forEach((elm) => {
				selectSchoolName = {}
				selectSchoolName['value'] = elm.school_name
				selectSchoolName['label'] = elm.school_name
				selectSchoolNameBoardWise.push(selectSchoolName);
			})
			setOptions(selectSchoolNameBoardWise)
			dispatch(schoolNameShowingLoaderAction(false))
		}

		if (blankSchoolNameList == 1) {
			// dispatch(getSchoolListFailureAction(''));
			setUserDetails(userDetails => ({
				...userDetails,
				fname: '',
				lname: '',
				dob: '',
				gender: '',
				address: '',
				pincode: '',
				mobile: '',
				email: '',
				password: '',
				cpassword: '',
				std: '',
				board: '',
				schoolname: '',
				schooladd: '',
				motp: '',
				eotp: '',
			}))
			setUserdob('')
			setOnlySchoolName([]);
			dispatch(getSchoolListFailureAction([]));
		}
		setBlankSchoolNameList(0);

		return () => {
			window.history.pushState(null, null, window.location.href);
			window.onpopstate = function () {
				history.go();
			};
		};

	}, [status, schoolNameList, schoolList, isShowSchoolList]);

	useEffect(() => {
		dispatch(selectSchoolNameListAction(0));
		if (fnameRef.current) {
			fnameRef.current.focus();
		}
	}, []);

	// Add Google Analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);


	let errorsObj = {
		fname: '', lname: '', dob: '', gender: '', address: '', pincode: '', mobile: '', email: '', password: '', cpassword: '', std: '', board: '', schoolname: '', schooladd: '', userdob: '', motp: '', eotp: '',
	};
	const [errors, setErrors] = useState(errorsObj);
	const [userdob, setUserdob] = useState();
	// const [dateDob, setDateDob] = useState();
	const [userDetails, setUserDetails] = useState({
		fname: '',
		lname: '',
		dob: '',
		gender: '',
		address: '',
		pincode: '',
		mobile: '',
		email: '',
		password: '',
		cpassword: '',
		std: '',
		board: '',
		schoolname: '', //Bidhan School
		schooladd: '', //Ispat Palli Bidhan Nagar Durgapur Purba Bardhaman-713 212
		// motp: props.mOtp == '' ? '' : props.mOtp,
		// eotp: props.eOtp == '' ? '' : props.eOtp,
		motp: '',
		eotp: '',
	})
	const fnameRef = useRef();
	const lnameRef = useRef();
	const addressRef = useRef();
	const pincodeRef = useRef();
	const mobileRef = useRef();
	const emailRef = useRef();
	const passwordRef = useRef();
	const cpasswordRef = useRef();
	const stdRef = useRef();
	const boardRef = useRef();
	const schoolnameRef = useRef();
	const schooladdRef = useRef();
	const mobileOtpRef = useRef();
	const emailOtpRef = useRef();
	const InstructionExamModalRef = useRef(true);


	/* updated form state value */
	const setdata = (e) => {
		const { name, value } = e.target
		setUserDetails((preValue) => {
			return {
				...preValue,
				[name]: value
			};
		})

		if (e.target.name === 'board') {

			if (e.target.value != '') {
				// dispatch(loadingBoardAction(true));
				setUserDetails(userDetails => ({
					...userDetails,
					schoolname: '',
					schooladd: '',
				}))
				dispatch(selectSchoolNameListAction(0));

				if (e.target.value == "Select Board") {
					setUserDetails(userDetails => ({
						...userDetails,
						schoolname: '',
						schooladd: '',
					}))
					dispatch(selectSchoolNameListAction(0));
				} else {
					dispatch(schoolNameShowingLoaderAction(true))
					setSchoolNameList([])
					setOnlySchoolName([])
					dispatch(getSchoolListData(e.target.value == "Select Board" ? '' : e.target.value, history));
				}

			} else {
				dispatch(getSchoolListFailureAction([]));

				setUserDetails(userDetails => ({
					...userDetails,
					schooladd: '',
				}))
			}
		}
	}

	/* form validation  */
	const UserRegistration = (e) => {
		e.preventDefault();

		const re = /^[A-Za-z\s]+$/;
		const onlyNumber = /^[0-9]+$/;
		const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

		const { fname, lname, gender, dob, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd } = userDetails;

		let error = false;

		const errorObj = { ...errorsObj };
		if (fname === '') {
			fnameRef.current.focus();
			errorObj.fname = 'First name is Required';
			error = true;
		} else if (fname.length < 3) {
			fnameRef.current.focus();
			errorObj.fname = 'Minimum 3 Character is Required';
			error = true;
		} else if (!re.test(fname)) {
			fnameRef.current.focus();
			errorObj.fname = 'Allow only alphabets';
			error = true;
		} else {
			lnameRef.current.focus();
		}
		if (lname === '') {
			errorObj.lname = 'Last name is Required';
			lnameRef.current.focus();
			error = true;
		} else if (lname.length < 3) {
			errorObj.lname = 'Minimum 3 Character is Required';
			lnameRef.current.focus();
			error = true;
		} else {
			if (!re.test(lname)) {
				errorObj.lname = 'Allow only alphabets';
				lnameRef.current.focus();
				error = true;
			}
		}

		if (userdob === '' || userdob === undefined) {
			errorObj.userdob = 'DOB is Required';
			error = true;
		}

		if (gender === '') {
			errorObj.gender = 'Gender is Required';
			error = true;
		}
		if (address === '') {
			errorObj.address = 'Address is Required';
			addressRef.current.focus();
			error = true;
		} else if (address.length < 3) {
			errorObj.address = 'Minimum 3 Character is Required';
			addressRef.current.focus();
			error = true;
		} else if (!isNaN(address)) {
			errorObj.address = 'Please enter a valid Address';
			addressRef.current.focus();
			error = true;
		}
		if (pincode === '') {
			errorObj.pincode = 'Pin is Required';
			pincodeRef.current.focus();
			error = true;
		} else if (!onlyNumber.test(pincode)) {
			errorObj.pincode = 'Allow only numeric';
			pincodeRef.current.focus();
			error = true;
		} else if (pincode.length < 6) {
			errorObj.pincode = 'Minimum 6 Character is Required';
			pincodeRef.current.focus();
			error = true;
		} else if (pincode == '000000') {
			errorObj.pincode = 'Not a Valid Pin';
			pincodeRef.current.focus();
			error = true;
		}
		if (mobile === '') {
			errorObj.mobile = 'Mobile is Required';
			mobileRef.current.focus();
			error = true;
		} else if (mobile.length < 10) {
			errorObj.mobile = 'Check Mobile No.';
			mobileRef.current.focus();
			error = true;
		} else if (!onlyNumber.test(mobile)) {
			errorObj.mobile = 'Allow only numeric';
			mobileRef.current.focus();
			error = true;
		} else if (mobile == '0000000000') {
			errorObj.mobile = 'Not a valid number';
			mobileRef.current.focus();
			error = true;
		}
		if (email === '') {
			errorObj.email = 'Email is Required';
			emailRef.current.focus();
			error = true;
		} else if (utility.validateEmail(email)) {
			errorObj.email = 'Not a Valid Email';
			emailRef.current.focus();
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			passwordRef.current.focus();
			error = true;
		} else if (password.length < 6 || password.length > 15) {
			errorObj.password = 'Password minimum 6 and maximum 15 characters Required';
			passwordRef.current.focus();
			error = true;
		} /* else if (!regularExpression.test(password)) {
			errorObj.password = 'Password not in pattern';
			passwordRef.current.focus();
			error = true;
		} */

		if (cpassword === '') {
			errorObj.cpassword = 'Confirm password is Required';
			cpasswordRef.current.focus();
			error = true;
		} else if (cpassword !== password) {
			errorObj.cpassword = 'Mismatched with password';
			cpasswordRef.current.focus();
			error = true;
		}
		if (std === '') {
			errorObj.std = 'Standard is Required';
			stdRef.current.focus();
			error = true;
		}
		if (board === '') {
			errorObj.board = 'Board is Required';
			boardRef.current.focus();
			error = true;
		}
		if (schoolname === '') {
			errorObj.schoolname = 'School name is Required';
			// schoolnameRef.current.focus();
			error = true;
		}
		if (schooladd === '') {
			errorObj.schooladd = 'School address is Required';
			// schooladdRef.current.focus();
			error = true;
		}


		setErrors(errorObj);
		if (error) {
			return;
		} else {
			SetTermsAndConditionModal(true)
			studentDetailsBeforeOtpVerify()
			// dispatch(recordExistsSuccess(1));
			// dispatch(showingLoaderStudentAction(true));------------
			// dispatch(demoUserRecordExistsOrNot(mobile, email, history))--------------
			// setOtpVerificationModal(true);
		}
	}

	/* check user details exits or not */
	const checkUserRecord = () => {
		SetTermsAndConditionModal(false)
		dispatch(showingLoaderStudentAction(true));
		dispatch(demoUserRecordExistsOrNot(userDetails.mobile, userDetails.email, history))

	}

	/* OTP from validation */
	const verifyOtp = (e) => {
		e.preventDefault();
		const { fname, lname, gender, dob, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd } = userDetails;

		const re = /^[A-Za-z]+$/;
		const onlyNumber = /^[0-9]+$/;

		const { motp, eotp } = userDetails;
		let error = false;
		const errorObj = { ...errorsObj };

		if (motp === '') {
			mobileOtpRef.current.focus();
			errorObj.motp = 'Mobile OTP is required';
			error = true;
		} else if (!onlyNumber.test(motp)) {
			mobileOtpRef.current.focus();
			errorObj.motp = 'Allow only numeric';
			error = true;
		} else if (motp.length < 6) {
			mobileOtpRef.current.focus();
			errorObj.motp = 'Verify your OTP';
			error = true;
		}

		if (eotp === '') {
			emailOtpRef.current.focus();
			errorObj.eotp = 'Email OTP is required';
			error = true;
		} else if (!onlyNumber.test(eotp)) {
			emailOtpRef.current.focus();
			errorObj.eotp = 'Allow only numeric';
			error = true;
		} else if (eotp.length < 6) {
			emailOtpRef.current.focus();
			errorObj.eotp = 'Verify your OTP';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		} else {

			// if ((userDetails.eotp == props.eOtp) && (userDetails.motp == props.mOtp)) {
			if ((userDetails.eotp == utility.decryptAES(props.eOtp)) && (userDetails.motp == utility.decryptAES(props.mOtp))) {

				dispatch(showingLoaderStudentAction(true));
				dispatch(newStudentRegistrationWithoutDemo(fname.trim(), lname.trim(), dob, gender, address.trim(), pincode.trim(), mobile.trim(), email, password.trim(), cpassword.trim(), std, board, schoolname.trim(), schooladd.trim(), userdob, history));

				const removeOtp = {
					email_otp: '',
					mobile_otp: '',
				}

				dispatch(verificationCodeAction(removeOtp))
				setOtpVerificationModal(false);
				setBlankSchoolNameList(1)

			} else {
				/* if otp not match */
				dispatch(registrationStatusDetails(mobile.trim(), email, `${fname.trim()} ${lname.trim()}`, userDetails.motp == utility.decryptAES(props.mOtp) ? 1 : 0, userDetails.eotp == utility.decryptAES(props.eOtp) ? 1 : 0, 1, userDetails.std, userDetails.board, userDetails.pincode, userDetails.schoolname));

				alert("Verify your OTP")
			}
		}
	}

	const studentDetailsBeforeOtpVerify = () => {
		dispatch(registrationStatusDetails(userDetails.mobile.trim(), userDetails.email, `${userDetails.fname.trim()} ${userDetails.lname.trim()}`, userDetails.motp == utility.decryptAES(props.mOtp) ? 1 : 0, userDetails.eotp == utility.decryptAES(props.eOtp) ? 1 : 0, 1, userDetails.std, userDetails.board, userDetails.pincode, userDetails.schoolname));
	}

	/* counting OTP timing */
	const renderTime = ({ remainingTime }) => {
		if (remainingTime === 0) {
			// return <div className="timer">Too lale...</div>;
			const removeOtp = {
				email_otp: '',
				mobile_otp: '',
			}
			setUserDetails(userDetails => ({
				...userDetails,
				motp: '',
				eotp: '',
			}))

			dispatch(verificationCodeAction(removeOtp))
			setShowResendOtp(true)
			setShowOtpValidationTimer(true)
			setVerifyButtonShow(false)
			dispatch(registrationStatusDetails(userDetails.mobile, userDetails.email, `${userDetails.fname} ${userDetails.lname}`, 0, 0, 2, userDetails.std, userDetails.board, userDetails.pincode, userDetails.schoolname));
		}

		return (
			<div className="timer">
				{/* <div className="small_text">Remaining</div> */}
				<div className="remainingTime">{remainingTime}</div>
				{/* <div className="small_text">seconds</div> */}
			</div>
		);
	};

	/* resend otp  */
	const resendOtp = () => {
		dispatch(showingLoaderStudentAction(true));
		dispatch(sendVerificationOtp(userDetails.mobile, userDetails.email, history))
		setShowResendOtp(false)
		setVerifyButtonShow(true)
	}

	/* show password */
	const showPassword = () => {
		if (viewPassword == "password") {
			setViewPassword("text")
		} else {
			setViewPassword("password")
		}
	}

	/* show confirm password */
	const showCPassword = () => {
		if (viewCPassword == "password") {
			setViewCPassword("text")
		} else {
			setViewCPassword("password")
		}
	}

	/* school name select */
	const getSelectedValue = (item) => {
		if (item != null) {
			setUserDetails(userDetails => ({
				...userDetails,
				schoolname: item.value,
			}))
		}

		if (item != null) {
			let selectSchoolAddress = schoolNameList.filter(i => i.school_name === item.value)

			if (selectSchoolAddress.length <= 0) {
				setUserDetails(userDetails => ({
					...userDetails,
					schooladd: '',
				}))
			} else {
				setUserDetails(userDetails => ({
					...userDetails,
					schooladd: selectSchoolAddress[0].school_address,
				}))
			}
		} else {
			setUserDetails(userDetails => ({
				...userDetails,
				schooladd: '',
			}))
		}
	}

	const schoolNameChange = (item) => {
		setUserDetails(userDetails => ({
			...userDetails,
			schoolname: item,
		}))
	}

	/* after verification otp modal close */
	const closeOtpVerificationModal = () => {
		dispatch(recordExistsSuccess(null));
		setOtpVerificationModal(false)
		setUserDetails(userDetails => ({
			...userDetails,
			// fname: '',
			// lname: '',
			// dob: '',
			// gender: '',
			// address: '',
			// pincode: '',
			// mobile: '',
			// email: '',
			password: '',
			cpassword: '',
			// std: '',
			// board: '',
			// schoolname: '',
			// schooladd: '',
			motp: '',
			eotp: '',
		}));
		// setUserdob('')
		// setOnlySchoolName([]);
		// dispatch(getSchoolListFailureAction([]));

		setShowResendOtp(false)
		setVerifyButtonShow(true)
	}

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const handleMouseOver = () => {
		setInfoBox(true)

	};

	const handleMouseOut = () => {
		setInfoBox(false)
	};


	return (
		<>
			<div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")", backgroundSize: 'cover' }} >
				<div className="login-wrapper">
					<div className="container h-100">
						<div className="row h-100 align-items-center justify-contain-center">
							<div className="col-xl-12 mt-3">
								<div className="card">
									<div className="card-body p-0">
										<div className="row m-0">
											<div className="col-xl-6 col-md-6 sign registration_left_side">
												<img src={loginLeftImage} className="education-img" ></img>
												{/* <div>
													<div className="my-5 ms-4">
														<Link to={{ pathname: "/page-login" }} >
															<img width="180" src={logofull} alt="" />
														</Link>
													</div>
													<img src={logo} className="education-img"></img>
												</div> */}
											</div>
											<div className="col-xl-6 col-md-6">
												<div className="sign-in-your">
													{/* <h4 className="fs-22 font-w600 text-pimary-c">Sign up your account <span className='font-w500 register_text'>Please register by using this form</span></h4> */}
													<h4 className="fs-22 font-w600 text-pimary-c">Sign up your account <span className='font-w500 register_text'>(<span className='mandatory_color'>*</span>) Marked fields are mandatory to fill</span></h4>

													<div className="col-lg-12">

														<div className="basic-form registration">
															<form onSubmit={UserRegistration} > {/* onSubmit={(e) => e.preventDefault()} */}
																<div className="row">
																	<div className="col-sm-6 mb-2">
																		<label>First name <span className="mandatory_color">*</span></label>
																		<input
																			ref={fnameRef}
																			selectTextOnFocus={true}
																			type="text"
																			className="form-control input_value"
																			placeholder="Enter first name"
																			onChange={setdata}
																			value={userDetails.fname}
																			name="fname"
																			id="fname"
																			autoComplete="off"
																			// onKeyDown={utility.inputAlphabetOnly}
																			onKeyDown={utility.inputAlphabetOnlyWithSpace}
																		/>
																		{errors.fname && <div className="text-danger fs-12">{errors.fname}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Last name <span className="mandatory_color">*</span></label>
																		<input
																			ref={lnameRef}
																			selectTextOnFocus={true}
																			type="text"
																			className="form-control input_value"
																			placeholder="Enter last name"
																			onChange={setdata}
																			value={userDetails.lname}
																			name="lname"
																			id="lname"
																			autoComplete="off"
																			onKeyDown={utility.inputAlphabetOnly}
																		// onKeyDown={utility.inputAlphabetOnlyWithSpace}
																		/>
																		{errors.lname && <div className="text-danger fs-12">{errors.lname}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Date of birth <span className="mandatory_color">*</span></label>
																		<DatePicker
																			className="form-control input_value"
																			name="dob"
																			selected={userdob}
																			// onChange={(date) => setDobFormat(date)}
																			// onChange={(date) => setUserdob(Date.parse(date))}
																			onChange={(date) => setUserdob(date)}
																			// minDate={new Date()} 
																			maxDate={new Date()}
																			placeholderText="DOB"
																			dateFormat="dd/MM/yyyy"
																			showYearDropdown
																			yearDropdownItemNumber={100}
																			scrollableYearDropdown
																			enableTabLoop={false}
																			/* onKeyDown={(e) => {
																				e.preventDefault();
																			}} */
																			onChangeRaw={handleDateChangeRaw}
																			preventOpenOnFocus={true}
																		/>
																		{errors.userdob && <div className="text-danger fs-12">{errors.userdob}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Gender <span className="mandatory_color">*</span></label>

																		<div className="form-control select_div_gender">
																			<select
																				// defaultValue={defalutValue}
																				className="select_option_gender input_value"
																				value={userDetails.gender}
																				onChange={setdata}
																				name="gender"
																			>
																				<option color={'#ff0000'} value={defalutValueBlank}>Select gender</option>
																				<option value="male">Male</option>
																				<option value="female">Female</option>
																				<option value="other">Other</option>
																			</select>
																		</div>
																		{errors.gender && <div className="text-danger fs-12">{errors.gender}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Address <span className="mandatory_color">*</span></label>
																		<input
																			ref={addressRef}
																			type="text"
																			className="form-control input_value"
																			placeholder="Enter your address"
																			onChange={setdata}
																			value={userDetails.address}
																			name="address"
																			autoComplete="off"
																			onKeyPress={utility.addressSpecialCharacter}
																		/>
																		{errors.address && <div className="text-danger fs-12">{errors.address}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Pin <span className="mandatory_color">*</span></label>
																		<input
																			ref={pincodeRef}
																			type="text"
																			className="form-control input_value"
																			placeholder="Enter pin"
																			onChange={setdata}
																			value={userDetails.pincode}
																			name="pincode"
																			autoComplete="off"
																			maxLength={6}
																			onKeyPress={utility.inputNumberOnly}
																		/>
																		{errors.pincode && <div className="text-danger fs-12">{errors.pincode}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Mobile <span className="mandatory_color">*</span></label>
																		<input
																			ref={mobileRef}
																			type="text"
																			className="form-control input_value"
																			placeholder="Enter mobile no"
																			onChange={setdata}
																			value={userDetails.mobile}
																			name="mobile"
																			autoComplete="off"
																			maxLength={10}
																			onKeyPress={utility.inputNumberOnly}
																		/>
																		{errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Email <span className="mandatory_color">*</span></label>
																		<input
																			ref={emailRef}
																			type="text"
																			className="form-control input_value"
																			placeholder="Enter email Id"
																			onChange={setdata}
																			value={userDetails.email}
																			name="email"
																			autoComplete="off"
																			onKeyDown={utility.whiteSpacenotAllow}
																		/>
																		{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
																	</div>
																	<div className="col-sm-6 mb-2 eye_parent_container">
																		<div className='d-flex justify-content-between align-items-center flex-row'>
																			{infoBox ?
																				<div className='info_box'>
																					Minimum length of 6 characters and maximum length of at least 15 characters
																				</div>
																				: null}
																			<div>
																				<label>Password <span className="mandatory_color">*</span></label>
																			</div>
																			<div
																				onMouseOver={handleMouseOver}
																				onMouseOut={handleMouseOut}
																			>
																				<i className="bi bi-info-circle-fill blue_color"></i>
																			</div>

																		</div>

																		<div className="view_password" onClick={() => showPassword()}><i className={viewPassword == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></div>
																		<input
																			ref={passwordRef}
																			type={viewPassword}
																			className="form-control input_value"
																			placeholder="Password"
																			onChange={setdata}
																			value={userDetails.password}
																			name="password"
																			autoComplete="off"
																			minLength={6}
																			maxLength={15}
																			onKeyDown={utility.whiteSpacenotAllow}
																			onPaste={(e) => {
																				e.preventDefault()
																				return false;
																			}} onCopy={(e) => {
																				e.preventDefault()
																				return false;
																			}}
																		/>
																		{errors.password && <div className="text-danger fs-12">{errors.password}</div>}

																	</div>

																	<div className="col-sm-6 mb-2 eye_parent_container">
																		<label>Confirm password <span className="mandatory_color">*</span></label>
																		{/* <div className="view_password" onClick={() => showCPassword()}><i className={viewCPassword == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></div> */}
																		<input
																			ref={cpasswordRef}
																			// type="password"
																			type={viewCPassword}
																			className="form-control input_value"
																			placeholder="Confirm password"
																			onChange={setdata}
																			value={userDetails.cpassword}
																			name="cpassword"
																			autoComplete="off"
																			minLength={6}
																			maxLength={15}
																			onKeyDown={utility.whiteSpacenotAllow}
																			onPaste={(e) => {
																				e.preventDefault()
																				return false;
																			}} onCopy={(e) => {
																				e.preventDefault()
																				return false;
																			}}
																		/>
																		{errors.cpassword && <div className="text-danger fs-12">{errors.cpassword}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">
																		<label>Standard <span className="mandatory_color">*</span></label>
																		<div className="form-control select_div_standard">
																			<select
																				ref={stdRef}
																				// defaultValue={defalutValue}
																				className="select_option_gender input_value"
																				value={userDetails.std}
																				onChange={setdata}
																				name="std"
																			>
																				<option value={defalutValueBlank}>Select standard</option>
																				{
																					props.classStandardList.map((item, index) => {
																						return <option key={index} value={item.class_no}>{item.short_code}</option>
																					})
																				}
																			</select>
																		</div>
																		{errors.std && <div className="text-danger fs-12">{errors.std}</div>}
																	</div>

																	<div className="col-sm-6 mb-2">
																		<label>Board <span className="mandatory_color">*</span></label>
																		<div className="form-control select_div_board">
																			<select
																				ref={boardRef}
																				//  defaultValue={defalutValue}
																				className="select_option_gender input_value"
																				value={userDetails.board}
																				onChange={setdata}
																				name="board"
																			>
																				<option value={defalutValueBlank}>Select board</option>
																				{
																					props.boardList.map((item, index) => {
																						return <option key={index} value={item.id}>{item.name}</option>
																					})
																				}
																			</select>
																		</div>
																		{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
																	</div>
																	<div className="col-sm-6 mb-2">

																		<label>School Name <span className="mandatory_color">*</span></label>
																		{/* {console.log("==options====", options)} */}
																		{/* {schoolList != '' ? */}
																		{isShowSchoolList == 1 ?
																			<>
																				{/* <BS5FloatingAutocompleteList
																				list={onlySchoolName}
																				selectedValue={getSelectedValue}
																				label={''}
																				valueOnChange={schoolNameChange}
																			>
																			</BS5FloatingAutocompleteList> */}
																				{/* <Select
																					defaultValue={selectedOption}
																					// onChange={setSelectedOption}
																					onChange={getSelectedValue}
																					options={options}
																				/> */}
																				<CreatableSelect
																					isClearable
																					onChange={getSelectedValue}
																					options={options} />

																			</>
																			:
																			<>
																				{/* {props.showLoading ? */}
																				{props.schoolNameShowingLoader ?
																					<Spinner
																						as="span"
																						animation="border"
																						size="sm"
																						role="status"
																						aria-hidden="true"
																					/>
																					: null}
																				<input
																					ref={schoolnameRef}
																					type="text"
																					className="form-control input_value school_name"
																					placeholder="Enter school name"
																					onChange={setdata}
																					value={userDetails.schoolname}
																					name="schoolname"
																					autoComplete="off"
																					disabled
																				/>
																			</>
																		}
																		{errors.schoolname && <div className="text-danger fs-12">{errors.schoolname}</div>}
																	</div>

																	<div className="col-sm-6 mb-2">
																		<label>School Address <span className="mandatory_color">*</span></label>
																		<input
																			ref={schooladdRef}
																			type="text"
																			className="form-control input_value school_name"
																			placeholder="Enter school address"
																			onChange={setdata}
																			value={userDetails.schooladd}
																			name="schooladd"
																			autoComplete="off"
																			disabled={isShowSchoolList == 1 ? false : true}
																		/>
																		{errors.schooladd && <div className="text-danger fs-12">{errors.schooladd}</div>}
																	</div>
																	<div className="mb-3 text-center">
																		{/* <span className="text-secondary-c font-w600 fs-15">Already have an account? <Link to="./login" className="text-secondary-c">Sign in</Link></span> */}
																		<span className="text-secondary-c font-w600 fs-15">Already have an account? <Link to="./page-login" className="text-secondary-c select_text">Sign in</Link></span>
																	</div>

																	<div className="login_button_container">
																		<div className="singin_container">{/* btn btn-primary-c btn-block */}
																			<button type="submit" autoFocus={true} className="btn btn-primary-c btn-block select_button" >Sign me up <span className="ml-3">
																				{props.showLoading ?
																					<Spinner
																						as="span"
																						animation="border"
																						size="sm"
																						role="status"
																						aria-hidden="true"
																					/>
																					: null}
																			</span>
																			</button>
																		</div>
																	</div>
																</div>
															</form>
															{/* <div onClick={() => closeOtpVerificationModal()}> Test Button</div> */}
														</div>
													</div>


												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="text-center">
									{/* <p className="text-white mb-0 fs-12">©2022 Crestest Learning Ventures LLP. All Rights Reserved.</p> */}
									<p className="text-white mb-0 fs-12">{" "}{<Moment format="YYYY" withTitle>{d}</Moment>}  Crestest Learning Ventures LLP. All Rights Reserved.</p>
									<p className="font-w500 mb-0 fs-13"><Link to="#" className="privacy-text select_text" onClick={() => setPrivacyPolicyModal(true)}>Privacy Policy</Link> <img src={footerIcon} /></p>
								</div>
							</div>
						</div>
					</div>
				</div >
				<Modal className="fade" show={otpVerificationModal}>
					<Modal.Header>
						{/* <Modal.Title className="modal_title">OTP Verify</Modal.Title> (M:{props.mOtp}, E: {props.eOtp}) */}
						{/* {console.log("=======@@111=====", `M: ${utility.decryptAES(props.mOtp)}, E: ${utility.decryptAES(props.eOtp)}`) } */}
						<Modal.Title className="modal_title">Verify Your Contact Information </Modal.Title> {/* (M:{utility.decryptAES(props.mOtp)}, E: {utility.decryptAES(props.eOtp)}) */}
						<div className="optLoader">
							{props.mOtp != '' && props.eOtp != '' ?
								<>
									<div className="timer-wrapper">
										<CountdownCircleTimer
											isPlaying
											duration={props.otpValidTime * 60}
											colors={["#004777", "#F7B801", "#ff0000", "#ff0000"]}
											colorsTime={[50, 50, 50, 0]}
											onComplete={() => ({ shouldRepeat: false, delay: 1 })}
											size={30}
											strokeWidth={3}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
									<div className="small_text">Seconds Remaining </div>
								</>
								: null}
						</div>

					</Modal.Header>
					<Modal.Body>
						<div className="col-lg-12">

							<div className="basic-form">
								<form onSubmit={(e) => e.preventDefault()}>
									<div className="row">
										<div className="col-sm-6 mb-2">
											<label className="col-sm-6 col-form-label col-form-label-md">
												Mobile OTP
											</label>
											<input
												ref={mobileOtpRef}
												type="text"
												className="form-control input_value"
												placeholder="Mobile OTP"
												onChange={setdata}
												value={userDetails.motp}
												name="motp"
												autocomplete="off"
												maxLength={6}
											/>
											{errors.motp && <div className="text-danger fs-12">{errors.motp}</div>}
										</div>
										<div className="col-sm-6 mb-2">
											<label className="col-sm-6 col-form-label col-form-label-md">
												Email OTP
											</label>
											<input
												ref={emailOtpRef}
												type="text"
												className="form-control input_value"
												placeholder="Email OTP"
												onChange={setdata}
												value={userDetails.eotp}
												name="eotp"
												autocomplete="off"
												maxLength={6}
											/>
											{errors.eotp && <div className="text-danger fs-12">{errors.eotp}</div>}
										</div>
									</div>
								</form>

							</div>
						</div>

					</Modal.Body>
					<Modal.Footer>
						<div className="modal_footer_container_info">
							<div className="modal_footer_left_container">
								<p className="footer_text">Good Luck</p>
								<p className="footer_text">Team Crestest</p>
							</div>
							<div className="modal_footer_right_container">

								<Button
									variant="danger text-center modal_footer_button"
									// onClick={() => setOtpVerificationModal(false)}
									onClick={() => closeOtpVerificationModal()}
								>
									Close
								</Button>
								{verifyButtonShow ?
									<Button
										variant="warning text-center modal_footer_button"
										onClick={verifyOtp}
									>
										Verify
									</Button>
									: null}
								{showResendOtp ?
									<Button
										variant="warning text-center modal_footer_button"
										onClick={resendOtp}
									>
										Resend OTP
									</Button>
									: null}
							</div>
						</div>

					</Modal.Footer>
				</Modal>
			</div >
			<div>
				<TermsCondition
					termsAndConditionModal={termsAndConditionModal}
					termsConditionSubmit={() => checkUserRecord()}
					closeTermsConsition={() => SetTermsAndConditionModal(false)}
				/>
			</div>
			<div>
				<PrivacyPolicy
					privacyPolicyModal={privacyPolicyModal}
					closePrivacyPolicyModal={() => setPrivacyPolicyModal(false)}
				/>
			</div>

			<div>
				{props.studentShowLoading ?
					<Loader />
					: null}
			</div>
			<div><ToastContainer /></div>
		</>

	)
}

const mapStateToProps = (state) => {
	// console.log("state.board.schoolNameShowingLoader----", state.board.schoolNameShowingLoader)
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,

		newuserId: state.student.newStudentid,
		// otp: state.student.verificationList,
		eOtp: state.student.email_otp,
		mOtp: state.student.mobile_otp,
		otpValidTime: state.student.otpValidTime,
		user_id: state.auth.user_id,
		user_name: state.auth.fname + " " + state.auth.lname,
		questionNo: state.questionNo.currentQestionNo,
		scholasticQuestionList: state.branch.scholasticQuestionList,
		showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
		classStandardList: state.standard.classStandardList,
		showLoading: state.board.showLoading,
		boardList: state.board.boardList,
		studentShowLoading: state.student.showLoading,
		schoolList: state.board.schoolList,
		tabN: state.auth.tabName,
		schoolNameShowingLoader: state.board.schoolNameShowingLoader
	};
};
export default connect(mapStateToProps)(RegistrationNew);