import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch, } from 'react-redux';
import { useHistory } from "react-router-dom"

import ReactApexChart from "react-apexcharts";

import {
	Row,
	Col,
} from "react-bootstrap";


const ChartCard3 = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const functionalCallFirstTimeRef = useRef(true);
	const [chartSeries, setChartSeries] = useState();

	useEffect(() => {

	}, []);

	const barChartDetails = {
		series: [{
			name: props.MockData ? props.MockData.name : '',
			data: props.MockData ? props.MockData.data : '',
		}, {
			name: props.ModuleData ? props.ModuleData.name : '',
			data: props.ModuleData ? props.ModuleData.data : '',
		}, {
			name: props.SetData ? props.SetData.name : '',
			data: props.SetData ? props.SetData.data : '',
		}],
		/* series: [{
			name: "set",
			data: [10, 35, 24, 55, 10, 60, 70, 90],
		},
		{
			name: "module",
			data: [90, 5, 28, 15, 66, 35, 45, 55],
		},
		{
			name: "mock",
			data: [55, 44, 32, 60, 44, 78, 10, 19],
		}
		], */
		options: {
			chart: {
				type: "bar",
				height: 100,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "100%",
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: false,
			},

			legend: {
				show: true,
				fontSize: "12px",
				fontWeight: 300,

				labels: {
					colors: "black",
				},
				position: "bottom",
				horizontalAlign: "center",
				markers: {
					width: 19,
					height: 19,
					strokeWidth: 0,
					radius: 19,
					strokeColor: "#fff",
					fillColors: ["#4472c4", "#ed7d31", "#a5a5a5"],
					offsetX: 0,
					offsetY: 0,
				},
				onItemClick: {
					toggleDataSeries: false
				},
			},
			yaxis: {
				labels: {
					style: {
						colors: "#3e4954",
						fontSize: "14px",
						fontFamily: "Poppins",
						fontWeight: 100,
					},
				},
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			xaxis: {
				categories: props.scholasticLabel,
				// categories: ["Math", "Phy", "Chem", "Bio", "Geo", "His", "Civ", "Eco"],

			},
			fill: {
				colors: ["#4472c4", "#ed7d31", "#a5a5a5"],
				opacity: 1,
			},
			/* tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands";
					},
				},
			}, */
		},
	};

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						<div className="chart_card_heading">{props.pageHeading}</div>
						{props.MockData != '' && props.ModuleData != '' && props.SetData != '' && props.scholasticLabel ?
							<>
								<div className="chart_body">
									<ReactApexChart
										options={barChartDetails.options}
										series={barChartDetails.series}
										type="bar"
										height={300}
									/>
								</div>
							</> : 
							<div>
								No Data Available
							</div>
						}
					</div>
				</Col>
			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		scholasticOverAllPerformance: state.performance.scholasticOverAllPerformance,
	};
};
export default connect(mapStateToProps)(ChartCard3);