import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"
import {
	Row,
	Col,
	Modal,
} from "react-bootstrap";

//Import Components
import ChartCard16Module from './performanceComponent/ChartCard16Module';
import ChartCard17 from './performanceComponent/ChartCard17';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'

import { getScholasticChapterwiseAnalysisDataDetails, performanceRequestAction, getScholasticChapterwiseAnalysisDataAction } from '../../../store/actions/PerformanceScoreAction';

const ChapterwiseAnalysisOnModule = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [showTableModal, setShowTableModal] = useState(false)
	const [previousValue, setPrevousValue] = useState(location.state)
	const [sets, setSets] = useState([])
	const [chapterName, setChapterName] = useState()
	const [chapterNo, setChapterNo] = useState()

	useEffect(() => {
		// console.log("****---111", previousValue)
		dispatch(getScholasticChapterwiseAnalysisDataAction([]));
		dispatch(performanceRequestAction(true))
		// dispatch(getScholasticChapterwiseAnalysisDataDetails(previousValue.chapter_id, previousValue.subject_id, 2, props.history))
		dispatch(getScholasticChapterwiseAnalysisDataDetails(location.state.chapter_id, location.state.subject_id, 2, location.state.group_subject_id, props.history))

		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
			
		};
	}, []);

	useEffect(() => {

		setSets([])

		if (props.scholasticChaptersAnalysisDataPiechart != '' && props.scholasticChaptersAnalysisDataPiechart != undefined) {
			let chapterSet = [];
			let dataValue = props.scholasticChaptersAnalysisDataPiechart
			Object.keys(dataValue).forEach(function (key) {
				// Object.keys(dd).forEach(function (key) {
				let labels = [];
				let vals = [];
				Object.keys(dataValue[key]).forEach(function (key2) {
					labels.push(key2);
					vals.push(dataValue[key][key2]);
				});
				setChapterName(dataValue[key]['chapter_heading'])
				setChapterNo(dataValue[key]['chapter_name'])
				let obj = {
					labelName: labels,
					value: vals,
				}
				chapterSet.push({
					[key]: obj
				})
			});

			// console.log("chapterSet-------", chapterSet);

			setSets(chapterSet)
		}

	}, [props.scholasticChaptersAnalysisDataPiechart]);

	const showTableData = () => {
		// console.log("Show Table Data");
		setShowTableModal(true)
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	const goPrevious = () => {
		// console.log("chapterwise-analysis-on-module------->", history)
		history.goBack();
	}


	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Online Exam Performance Score <i className="bi bi-chevron-right"></i> Scholastic Performance Score({location.state && location.state.group_name}) <i className="bi bi-chevron-right"></i> Overall Scholastic Performance Score <i className="bi bi-chevron-right"></i> Subjectwise Scholastic Score <i className="bi bi-chevron-right"></i> Subjectwise Analysis MOL <i className="bi bi-chevron-right"></i>Chapterwise Analysis MOL</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<Row>
				{props.scholasticChaptersAnalysisDataPiechart != "" && props.scholasticChaptersAnalysisDataPiechart != undefined && sets != "" ?
					sets.map((item, index) => {
						console.log("----------------------item", Object.keys(item))
						{/* console.log("----------------------item", Object.values(item)) */ }
						{/* console.log("----------------------item", item) */ }
						return (
							<React.Fragment key={index}>
								<Col xl={6} >
									<ChartCard16Module
										pageHeading={Object.keys(item)[0]}
										// pageHeading={`Name Of The Chapter Set` + Object.keys(item)[0]}
										dataValue={item[Object.keys(item)].value}
										showTableData={() => showTableData()}
										tableData={props.scholasticChaptersAnalysisDataTabledata}
										// subjectName={previousValue.subject}
										subjectName={location.state.subject}
										chapter_heading={chapterName}
										ChartCountData={props.scholasticChaptersAnalysisDataPiechart}
										// moudleIndex={previousValue.moudleIndex}
										moudleIndex={location.state.moudleIndex}
									/>
								</Col>
								<Col xl={6}>
									<ChartCard17
										testNo={Object.keys(item)[0]}
										pageHeading="Name Of The Chapter"
										tableData={props.scholasticChaptersAnalysisDataTabledata}
										chapter_heading={chapterName}
										type_of="Module"
										subjectName={location.state.subject}
										// subjectName={previousValue.subject}
									/>
								</Col>
							</React.Fragment>
						)
					})
					: null}
				{/* {props.scholasticChaptersAnalysisDataTabledata != "" && props.scholasticChaptersAnalysisDataTabledata != undefined && sets != '' ?
					<Col xl={6}>
						<ChartCard17
							pageHeading="Name Of The Chapter"
							tableData={props.scholasticChaptersAnalysisDataTabledata}
							chapter_heading={chapterName}
							type_of="Module"
							subjectName={previousValue.subject}
						/>
					</Col>
					: null} */}
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								<div className='performer_table_heading'>Chapter Test Wise Score Comparison</div>
								<p>Scholastic <i className="fa fa-angle-right assessment_icon_color"></i> ICSE 10 <i className="fa fa-angle-right assessment_icon_color"></i> Chapter Test <i className="fa fa-angle-right assessment_icon_color"></i> Math <i className="fa fa-angle-right assessment_icon_color"></i> Polynomial</p>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="5px" className="performance_analysis_table_data">
								<thead>
									<tr>
										<th className="text-center"></th>
										<th className="text-center light_gray_3" colSpan={2}>SWA</th>
										<th className="text-center light_gray_3" colSpan={2}>HOT</th>
										<th className="text-center light_gray_3" colSpan={2}>DES</th>
										<th className="text-center light_gray_3" colSpan={2}>TOTAL</th>
									</tr>
									<tr>
										<th className="text-center"></th>
										<th className="text-center light_blue">CORRECT</th>
										<th className="text-center light_sky2">MARKS</th>
										<th className="text-center light_green2">CORRECT</th>
										<th className="text-center light_sky2">MARKS</th>
										<th className="text-center light_yellow1">CORRECT</th>
										<th className="text-center light_sky2">MARKS</th>
										<th className="text-center light_red3">CORRECT</th>
										<th className="text-center light_sky2">MARKS</th>
									</tr>

								</thead>
								<tbody>
									<tr>
										<td className="text-center light_red2" rowSpan={2}>POLYNOMIAL</td>
										<td className="text-center light_gray_2">6</td>
										<td className="text-center light_gray_1">6</td>
										<td className="text-center light_gray_2">2</td>
										<td className="text-center light_gray_1">4</td>
										<td className="text-center light_gray_2">2</td>
										<td className="text-center light_gray_1">4</td>
										<td className="text-center light_gray_2">10</td>
										<td className="text-center light_gray_1">14</td>
									</tr>
									<tr>
										<td className="text-center light_gray_2">6</td>
										<td className="text-center light_gray_1">6</td>
										<td className="text-center light_gray_2">2</td>
										<td className="text-center light_gray_1">4</td>
										<td className="text-center light_gray_2">2</td>
										<td className="text-center light_gray_1">4</td>
										<td className="text-center light_gray_2">10</td>
										<td className="text-center light_gray_1">14</td>
									</tr>

								</tbody>
							</table>

						</div>

					</div>
				</>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	// console.log("%%%^^^%%%%----", state.performance.scholasticChaptersAnalysisDataPiechart)
	return {
		performanceLoader: state.performance.loading,
		scholasticChaptersAnalysisDataPiechart: state.performance.scholasticChaptersAnalysisDataPiechart,
		scholasticChaptersAnalysisDataTabledata: state.performance.scholasticChaptersAnalysisDataTabledata
	};
};
export default connect(mapStateToProps)(ChapterwiseAnalysisOnModule);