import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';
import { Link } from "react-router-dom";

import CategoryCard from '../eLibrary/CategoryCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getNtseExamTypeData } from '../../../store/actions/ExamTypeAction';
import { getOnlineCompetitiveSubscriptionDetailsData } from '../../../store/actions/OnlineExamAction';

const CompetitiveNtseType = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const [previousValue, setPrevousValue] = useState(location.state)

	useEffect(() => {
		dispatch(getNtseExamTypeData(2, 1, props.history));
	}, []);

	const selectType = (item) => {
		// dispatch(getOnlineCompetitiveSubscriptionDetailsData(0, previousValue.type_name, props.history))
		// history.push({ pathname: "/competitive-assessment-Select", state: { categoryId: item.type_name == 'NTSE' ? 2 : 3, exam_type: item.type_name, id: item.id, categoryId: 2 } })
		history.push({ pathname: "/competitive-assessment-Select", state: { categoryId: item.exam_type == 'NTSE' ? 2 : 3, exam_type: item.exam_type, subtype: item.id, categoryId: 2, } }) //id: item.id,
		
	}

	return (
		<>

			<div className="row">
				<div className="demo-elibrary" >
					
					{props.ntseType != '' ?
						props.ntseType.map((item, index) => (
							<Link to="#" onClick={() => selectType(item)} key={index}>
								<CategoryCard
									// key={index}
									// path={item.id == 1 ? '' : ''}
									icon={item.id == 1 ? ntse : ntse}
									title={item.subtype_name}
									description={item.sub_heading}
									bgColor={item.id == 1 ? '#4ba5c0' : '#4ba5c0'}
									leftColor={'#4ba5c0'}
									iconClass={'left_image'}
									iconClassRgt={'right_image'}
								/>
							</Link>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		ntseType: state.examtype.ntseType
	};
};

export default connect(mapStateToProps)(CompetitiveNtseType);