import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { connect, useDispatch, useSelector } from 'react-redux';

import ChartistGraph from "react-chartist";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Loader from "../../components/Loader"

import { getPerformanceScoreListData, performanceRequestAction } from '../../../store/actions/PerformanceScoreAction';


const SubjectScorecardSetWise = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [labels, setLabels] = useState(['ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9', 'ch2', 'ch4'])
    const [chartData, setChartData] = useState([60, 60, 60, 60, 60, 60, 70, 70, 70, 80, 80, 80, 80, 90, 90, 90, 90])
    const [backgroundColor, setBackgroundColor] = useState(['#00B0F0', '#00B0F0', '#00B0F0', '#00B0F0', '#00B0F0', '#00B0F0', '#C00000', '#C00000', '#C00000', '#FFC000', '#FFC000',
        '#FFC000', '#FFC000', '#00B050', '#00B050', '#00B050', '#00B050'])



    const performanceList = useSelector(state => state.performance.performanceList);
    const performanceOverallavg = useSelector(state => state.performance.performanceOverallavg);
    const [overallAvg, setOverallAvg] = useState(0)
    const filterRef = useRef(true);
    const functionalCallFirstTimeRef = useRef(true);
    const [showData, setShowData] = useState(false)
    const chartRef = useRef();

    ChartJS.register(ArcElement, Tooltip, Legend);
    // var labels = [ 'ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9', 'ch2', 'ch4'];
    const data1 = {
        // labels: ["Below 70%", "70% to 79%", "80% to 89%", "90% and above"],
        labels: labels,
        datasets: [{
            label: 'My First Dataset',
            // data: [60,60, 70, 80, 90],
            // data: [60, 60, 60, 60, 60, 60, 70, 70, 70, 80, 80, 80, 80, 90, 90, 90, 90],
            data: chartData,
            // data: ['ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9','ch2', 'ch4'],
            backgroundColor: backgroundColor,
            /* backgroundColor: [
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#00B0F0',
                '#C00000',
                '#C00000',
                '#C00000',
                '#FFC000',
                '#FFC000',
                '#FFC000',
                '#FFC000',
                '#00B050',
                '#00B050',
                '#00B050',
                '#00B050'
            ], */
            hoverBackgroundColor: [
                '#FF5733',
                '#FF5733',
                '#FF5733',
                '#FF5733'
            ],
            borderWidth: 2,
            hoverOffset: 2,
            borderJoinStyle: 'round',

        }],
    };
    const options1 = {
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Math PCS set",
                padding: {
                    bottom: 10
                },
                weight: "bold",
                color: "#00325c",
                font: {
                    size: 13
                },
                align: "center"
            },

            datalabels: {
                labels: {
                    name: {
                        align: 'center',
                        font: { size: 10 },
                        color: "#000",
                        formatter: function (value, ctx) {
                            return ctx.chart.data.labels[ctx.dataIndex];
                        },
                        anchor: "center", //start, center, end
                        rotation: function (ctx) {
                            const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
                            const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                            const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
                            return rotation < 180 ? rotation - 90 : rotation + 90;
                        },
                    },
                }
            },
        }
    };
    const plugins1 = [ChartDataLabels, {
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = overallAvg + "%",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = (height / 2);
            ctx.fillText(text, textX, textY);
            ctx.save();
        },

    }];


    const showChartData = (event, item) => {

        // console.log(getElementAtEvent(chartRef.current, event,));
        const chartIndex = getElementAtEvent(chartRef.current, event);

        const keyValue = [];
        let valueData = [];
        
        const chapterName = Object.fromEntries(Object.entries(props.performanceList).filter(([key, value]) => {
            keyValue.push(key)
            value.filter(item => {
                valueData.push(item);
            })
        }))

        const chName = valueData.filter(i => i.chapter == keyValue[chartIndex[0].index]);
        let subject_id = chName[0].subject_id;
        let chapter_id = chName[0].chapter_id;
        
        //from set
        history.push({ pathname: "/online-comparative-study-set-exams", state: { subject_id: subject_id, chapter_id: chapter_id, type: 1, type_name: 'Set', subject_name: previousValue.subject_name } })
    }

    const testData = {
        "SCARCH2": [
            {
                "id": 4,
                "student_id": 381,
                "exam_unique_id": "1_1672659143474_215",
                "chapter": "SCARCH2",
                "chapter_id": 2,
                "question_type_swa": 3,
                "question_type_hot": 2,
                "question_type_des": 2,
                "subject_id": 1,
                "set_no": 2,
                "created_at": "2023-01-02T17:02:23.000Z",
                "question_type_swa_marks": 6,
                "question_type_hot_marks": 4,
                "question_type_des_marks": 4,
                "question_type_total": 7,
                "question_type_total_marks": 14,
                "set_average": 50,
                "total_avg": 50
            }
        ],
        "SCARCH1": [
            {
                "id": 5,
                "student_id": 381,
                "exam_unique_id": "1_1672659788966_215",
                "chapter": "SCARCH1",
                "chapter_id": 1,
                "question_type_swa": 2,
                "question_type_hot": 1,
                "question_type_des": 1,
                "subject_id": 1,
                "set_no": 2,
                "created_at": "2023-01-02T17:13:08.000Z",
                "question_type_swa_marks": 4,
                "question_type_hot_marks": 2,
                "question_type_des_marks": 2,
                "question_type_total": 4,
                "question_type_total_marks": 8,
                "set_average": 29,
                "total_avg": 44
            },
            {
                "id": 6,
                "student_id": 381,
                "exam_unique_id": "1_1672725070056_215",
                "chapter": "SCARCH1",
                "chapter_id": 1,
                "question_type_swa": 3,
                "question_type_hot": 3,
                "question_type_des": 2,
                "subject_id": 1,
                "set_no": 1,
                "created_at": "2023-01-03T11:21:10.000Z",
                "question_type_swa_marks": 6,
                "question_type_hot_marks": 6,
                "question_type_des_marks": 4,
                "question_type_total": 8,
                "question_type_total_marks": 16,
                "set_average": 58
            },
            {
                "id": 7,
                "student_id": 381,
                "exam_unique_id": "1_1672734191888_215",
                "chapter": "SCARCH1",
                "chapter_id": 1,
                "question_type_swa": 2,
                "question_type_hot": 3,
                "question_type_des": 1,
                "subject_id": 1,
                "set_no": 1,
                "created_at": "2023-01-03T13:53:11.000Z",
                "question_type_swa_marks": 4,
                "question_type_hot_marks": 6,
                "question_type_des_marks": 2,
                "question_type_total": 6,
                "question_type_total_marks": 12,
                "set_average": 43
            }
        ],
        "SCARCH112": [
            {
                "id": 15,
                "student_id": 381,
                "exam_unique_id": "1_1672659788966_215",
                "chapter": "SCARCH12",
                "chapter_id": 1,
                "question_type_swa": 2,
                "question_type_hot": 1,
                "question_type_des": 1,
                "subject_id": 1,
                "set_no": 2,
                "created_at": "2023-01-02T17:13:08.000Z",
                "question_type_swa_marks": 4,
                "question_type_hot_marks": 2,
                "question_type_des_marks": 2,
                "question_type_total": 4,
                "question_type_total_marks": 8,
                "set_average": 29,
                "total_avg": 75.6
            },
        ],
        "SCARCH13": [
            {
                "id": 25,
                "student_id": 381,
                "exam_unique_id": "1_1672659788966_215",
                "chapter": "SCARCH13",
                "chapter_id": 1,
                "question_type_swa": 2,
                "question_type_hot": 1,
                "question_type_des": 1,
                "subject_id": 1,
                "set_no": 2,
                "created_at": "2023-01-02T17:13:08.000Z",
                "question_type_swa_marks": 4,
                "question_type_hot_marks": 2,
                "question_type_des_marks": 2,
                "question_type_total": 4,
                "question_type_total_marks": 8,
                "set_average": 29,
                "total_avg": 95
            },
        ],
        "SCARCH7": [
            {
                "id": 5,
                "student_id": 381,
                "exam_unique_id": "1_1672659788966_215",
                "chapter": "SCARCH7",
                "chapter_id": 1,
                "question_type_swa": 2,
                "question_type_hot": 1,
                "question_type_des": 1,
                "subject_id": 1,
                "set_no": 2,
                "created_at": "2023-01-02T17:13:08.000Z",
                "question_type_swa_marks": 4,
                "question_type_hot_marks": 2,
                "question_type_des_marks": 2,
                "question_type_total": 4,
                "question_type_total_marks": 8,
                "set_average": 29,
                "total_avg": 85
            },
            {
                "id": 6,
                "student_id": 381,
                "exam_unique_id": "1_1672725070056_215",
                "chapter": "SCARCH1",
                "chapter_id": 1,
                "question_type_swa": 3,
                "question_type_hot": 3,
                "question_type_des": 2,
                "subject_id": 1,
                "set_no": 1,
                "created_at": "2023-01-03T11:21:10.000Z",
                "question_type_swa_marks": 6,
                "question_type_hot_marks": 6,
                "question_type_des_marks": 4,
                "question_type_total": 8,
                "question_type_total_marks": 16,
                "set_average": 58
            },
            {
                "id": 7,
                "student_id": 381,
                "exam_unique_id": "1_1672734191888_215",
                "chapter": "SCARCH1",
                "chapter_id": 1,
                "question_type_swa": 2,
                "question_type_hot": 3,
                "question_type_des": 1,
                "subject_id": 1,
                "set_no": 1,
                "created_at": "2023-01-03T13:53:11.000Z",
                "question_type_swa_marks": 4,
                "question_type_hot_marks": 6,
                "question_type_des_marks": 2,
                "question_type_total": 6,
                "question_type_total_marks": 12,
                "set_average": 43
            }
        ],
    }

    useEffect(() => {
        dispatch(performanceRequestAction(true))
        dispatch(getPerformanceScoreListData(previousValue.subject_id, props.history));
        filterData();

        return () => {
            setShowData(true)
        }

    }, [performanceOverallavg]);
    // }, [performanceList, performanceOverallavg]);

    const filterData = () => {
        const keyValue = [];
        let valueData = [];
        // const chapterName = Object.fromEntries(Object.entries(testData).filter(([key, value]) => {
        const chapterName = Object.fromEntries(Object.entries(props.performanceList).filter(([key, value]) => {
            keyValue.push(key)
            value.filter(item => {
                valueData.push(item);
            })
        }))

        const filterDataBelow70 = valueData.filter(i => i.total_avg < 70);
        const filterDataBelow7079 = valueData.filter(i => i.total_avg >= 70 && i.total_avg <= 79);
        const filterDataBelow8089 = valueData.filter(i => i.total_avg >= 80 && i.total_avg <= 89);
        const filterDataBelow90100 = valueData.filter(i => i.total_avg >= 90 && i.total_avg <= 100);

        let labels = [];
        let chartData = [];
        let chartColorBackground = [];
        filterDataBelow70.forEach((elm) => {
            labels.push(elm.chapter);
            // chartData.push(elm.total_avg);
            chartData.push(60);
            chartColorBackground.push('#00B0F0')
        })
        filterDataBelow7079.forEach((elm) => {
            labels.push(elm.chapter);
            // chartData.push(elm.total_avg);
            chartData.push(70);
            chartColorBackground.push('#C00000')
        })
        filterDataBelow8089.forEach((elm) => {
            labels.push(elm.chapter);
            // chartData.push(elm.total_avg);
            chartData.push(80);
            chartColorBackground.push('#FFC000')
        })
        filterDataBelow90100.forEach((elm) => {
            labels.push(elm.chapter);
            // chartData.push(elm.total_avg);
            chartData.push(90);
            chartColorBackground.push('#00B050')
        })

        setChartData(chartData)
        setLabels(labels)
        setBackgroundColor(chartColorBackground);
        setOverallAvg(performanceOverallavg);

    }

    const ShowChapterDetails = (item) => {
        //from set
        history.push({ pathname: "/online-comparative-study-set-exams", state: { subject_id: item.subject_id, chapter_id: item.chapter_id, type: 1, type_name: 'Set', subject_name: previousValue.subject_name  } })
    }

    return (
        <>
            {showData ?
                <div className='performance_container'>
                    <div className="text-center"><h3>{previousValue.subject_name} PSC Set</h3></div>
                    <div className='performance_parent_container'>
                        <div className='score_container'>

                            <div className='score_card_parent_container'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="text-center" rowSpan="2"></th>
                                            <th className="text-center" colSpan="2" >SWA</th>
                                            <th className="text-center" colSpan="2" >HOTS</th>
                                            <th className="text-center" colSpan="2" >DES</th>
                                            <th className="text-center" colSpan="2" >TOT</th>
                                            <th></th>
                                        </tr>

                                        <tr>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Percent</th>
                                            <th className="text-center">Avg%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.performanceList != "" ?
                                            Object.keys(props.performanceList).map((keyName, i) => (

                                                props.performanceList[keyName].map((item, index) => (
                                                    (index == 0) ?
                                                        <tr className={`${i % 2 == 0 ? `tr-background` : null} cursorPointer text-center`} onClick={() => ShowChapterDetails(item)}>
                                                            <td rowSpan={props.performanceList[keyName].length}>{keyName}</td>
                                                            <td className="text-center">{item.question_type_swa}</td>
                                                            <td className="text-center">{item.question_type_swa_marks}</td>
                                                            <td className="text-center">{item.question_type_hot}</td>
                                                            <td className="text-center">{item.question_type_hot_marks}</td>
                                                            <td className="text-center">{item.question_type_des}</td>
                                                            <td className="text-center">{item.question_type_des_marks}</td>
                                                            <td className="text-center">{item.question_type_total}</td>
                                                            <td className="text-center">{item.question_type_total_marks}</td>
                                                            <td className="text-center">{item.set_average}</td>
                                                            <td className="text-center" rowspan={props.performanceList[keyName].length}>{item.total_avg}%</td>
                                                        </tr>
                                                        :
                                                        <tr className={`${i % 2 == 0 ? `tr-background` : null} cursorPointer`}>
                                                            <td className="text-center">{item.question_type_swa}</td>
                                                            <td className="text-center">{item.question_type_swa_marks}</td>
                                                            <td className="text-center">{item.question_type_hot}</td>
                                                            <td className="text-center">{item.question_type_hot_marks}</td>
                                                            <td className="text-center">{item.question_type_des}</td>
                                                            <td className="text-center">{item.question_type_des_marks}</td>
                                                            <td className="text-center">{item.question_type_total}</td>
                                                            <td className="text-center">{item.question_type_total_marks}</td>
                                                            <td className="text-center">{item.set_average}</td>
                                                        </tr>
                                                ))

                                            ))
                                            : <tr>
                                                <td className="text-center" colSpan={10}>No data Available</td>
                                            </tr>}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th className="text-center">Avg%</th>
                                            <th className="text-center">{performanceOverallavg}%</th>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>

                        </div>

                        <div className='chart_container flex-1 jcc aic wh400 flex_d_col'>
                            <div className='chart_container wh400'>
                                {props.performanceList != '' ?
                                    <Doughnut
                                        ref={chartRef}
                                        data={data1}
                                        options={options1}
                                        plugins={plugins1}
                                        onClick={showChartData}
                                    />  //onClick={() => CheckData()} //redraw={true}
                                    : null}
                            </div>
                            <div className='graph_marking_container'>
                                <div className="marking_parent_container">
                                    <div className='marking_box below70'></div>
                                    <div>Below 70%</div>
                                </div>
                                <div className="marking_parent_container">
                                    <div className='marking_box between7079'></div>
                                    <div>70% to 79%</div>
                                </div>
                                <div className="marking_parent_container">
                                    <div className='marking_box between8089'></div>
                                    <div>80% to 89%</div>
                                </div>
                                <div className="marking_parent_container">
                                    <div className='marking_box between90100'></div>
                                    <div>90% and above</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='chart_container flex-1 wh400 jcc text-center '>
                        <ChartistGraph data={data} options={options} type={type} />
                    </div> */}
                    </div>
                </div>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        performanceList: state.performance.performanceList,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(SubjectScorecardSetWise);
