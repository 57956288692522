import React, { useEffect, useState, } from 'react';

import { useDispatch, } from 'react-redux';
import { useHistory } from "react-router-dom"


import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ChapterBox = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [infoBox, setInfoBox] = useState(false)

    useEffect(() => {

    }, []);

    const handleMouseOver = () => {
        setInfoBox(true)
    };

    const handleMouseOut = () => {
        setInfoBox(false)
    };

    return (
        <div className='ch_box chapter_panel'
            style={{ backgroundColor: props.baseColor <= 70 ? '#982626' : props.baseColor >= 71 && props.baseColor <= 80   ? '#f1c431' : props.baseColor >= 81 && props.baseColor <= 90  ? '#5bba47' : props.baseColor >= 91 && props.baseColor >= 100  ? '#31c5f4' :'#000' }}
            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
        >
            <div className='ch_name' style={{ color: props.baseColor <= 70 ? '#ffffff' : '#000000' }}>Ch {props.name}</div>
            {infoBox ?
                <div className={props.dataCount <= 6 ? 'toolTipsLessThenSix' : 'toolTips'}>
                    <div className="top_container">
                        {props.chapter_name}{}
                    </div>
                    {/* <div className="bottom_container">
                    {props.chapter_name}
                    </div> */}
                </div>
                : null}
        </div>
    )
}


export default ChapterBox;