import React from 'react';

import IconTint from 'react-icon-tint';


export default function CategoryCard(props) {

	return (
		// <div className="row">
		<div className="exam_inside_container" >
			<div className={`col-md-6 contain_container ${props.backgroundColor}`}>
				<div className="left_container">
					<IconTint
						// src={`${props.id == 1 ? scholastic_img_01 : scholastic_img_11}`} 
						src={props.leftIcon}
						alt=""
						color={props.leftIconColor}
						className={`${props.id == 1 ? "left_image" : "blue_left_image"}`}
					/>
				</div>
				<div className="middle_container">
					<div className="middle_inside_container">
						<h3 className="font_white">{props.category}</h3>
						<p className="font_white">{props.sub_heading}</p>
					</div>
				</div>
				<div className="right_container">
					<IconTint
						src={props.rightIcon}
						// src={`${props.id == 1 ? scholastic_img_01 : scholastic_img_11}`}
						alt=""
						color="#fff"
						className="right_image"
					/>
				</div>
			</div>
		</div>
		// </div>
	)
}
