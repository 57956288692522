import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"
import {
    Row,
    Col,
    Modal,
    Button,
    Spinner
} from "react-bootstrap";
import math from '../../../images/subject_icon/math.png';

import { addToCartData, subscribeLoading, getScSubjectData, showRightNavAction } from '../../../store/actions/SubscribeAction';


const PurchaseSubcribeScholasticCard = (props) => {

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [sets, setSets] = useState(2);
    const [isModules, setIsModules] = useState(true);
    const [isMock, setIsMock] = useState(true);
    const [isBundle, setIsBundle] = useState(true);
    const [total, setTotal] = useState(props.amountperSet);
    const [mock, setMock] = useState(0);
    const [module, setModule] = useState(0);
    const [bundle, setBundle] = useState(0);
    const [setAmount, setSetAmount] = useState(0);
    const [finalTotal, setFinalTotal] = useState(0);
    const [isLibrary, setIsLibrary] = useState(false);
    const [library, setLibrary] = useState(0);

    const history = useHistory();

    useEffect(() => {
        setSetAmount(props.amountperSet.split(',')[0]);
    }, []);

    const onModulesHandler = () => {
        setIsModules(!isModules);
        setTotal(isModules ? total - props.moduleAmount : total + props.moduleAmount);
        setModule(isModules ? 0 : props.moduleAmount);
    };
    const onMockHandler = () => {
        setIsMock(!isMock);
        setTotal(isMock ? total - props.mockAmount : total + props.mockAmount);
        setMock(isMock ? 0 : props.mockAmount);
    };
    const onBundleHandler = () => {
        setIsBundle(!isBundle);
        setTotal(isBundle ? total - props.caseStudyAmount : total + props.caseStudyAmount);
        setBundle(isBundle ? 0 : props.caseStudyAmount);
    };
    const onChangeRadio = (event) => {
        setSetAmount(props.amountperSet.split(',')[event.target.value - 1]);
        setSets(event.target.value);
        setTotal(Number(setAmount) + module + mock + bundle);
    }

    const onProgress = () => {
        dispatch(subscribeLoading(true));
        dispatch(addToCartData(props.category_id, props.id, sets, isModules ? props.totalModules : 0, isMock ? props.totalMocks : 0, props.board, props.class_no ? props.class_no : 0, finalTotal, isBundle ? 1 : 0, 0, isLibrary ? 1 : 0, history));
        dispatch(showRightNavAction(true))
        setModal(false);
    }

    const onArray = (num) => {
        let array = [];
        for (let x = 1; num >= x; x++) {
            array.push(
                <div className="form-check" key={x}>
                    <input
                        onChange={onChangeRadio}
                        className="form-check-input"
                        type="radio"
                        name={"set" + props.id + x}
                        value={x}
                        checked={sets == x}
                        id={"set" + props.id + x}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={"set" + props.id + x}
                    >
                        {x}
                    </label>
                </div>
            );
        }
        return array;
    }
    const finalSubscriptionSubmit = () => {
        setModal(true);
        let totalValue = Number(setAmount) + module + mock + bundle + library;
        setFinalTotal(totalValue);
    }

    const onLibraryHandler = (e) => {
        setIsLibrary(e.target.checked);
        if (e.target.checked == true) {
            setLibrary(props.library);
        } else {
            setLibrary(0);
        }
    }

    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <div className="subcribe-scholastic-card subscription-disable">
                        <div className="question-mark-circle">
                            <i className="fa-sharp fa-solid fa-question"></i>
                        </div>

                        <div className="subcribe-scholastic-details">
                            <img src={math} alt="" />
                            <h4>{props.subjectName}</h4>
                        </div>

                        <div className="subcribe-scholastic-configuration">

                            <div className="set-scholastic-item">
                                <div className="set-scholastic-title">
                                    <h4>sets{props.setCount}</h4>
                                </div>
                                <div className="set-scholastic-content">

                                    <div className="set-scholastic-content-lft">

                                        {onArray(props.setCount)}

                                    </div>

                                    <div className="set-scholastic-content-rght">
                                        <p>Rs.200/-</p>
                                    </div>

                                </div>
                            </div>

                            <div className="set-scholastic-item">
                                <div className="set-scholastic-title">
                                    <h4>module tests</h4>
                                </div>
                                <div className="set-scholastic-content">

                                    <div className="set-scholastic-content-lft">


                                        <div className="form-check custom-checkbox checkbox-success">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={"modules" + props.id}
                                                name={"modules" + props.id}
                                                value={props.totalModules}
                                                checked={isModules}
                                                onChange={onModulesHandler}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={"modules" + props.id}
                                            >
                                                {props.totalModules} Modules
                                            </label>
                                        </div>


                                    </div>

                                    <div className="set-scholastic-content-rght">
                                        {isModules ?
                                            <p>Rs.{props.moduleAmount}/-</p>
                                            : null}
                                    </div>

                                </div>
                            </div>

                            <div className="set-scholastic-item">
                                <div className="set-scholastic-title">
                                    <h4>mock tests</h4>
                                </div>
                                <div className="set-scholastic-content">

                                    <div className="set-scholastic-content-lft">

                                        <div className="form-check custom-checkbox checkbox-success">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={"mocks" + props.id}
                                                name={"mocks" + props.id}
                                                value={props.totalMocks}
                                                checked={isMock}
                                                onChange={onMockHandler}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={"mocks" + props.id}
                                            >
                                                {props.totalMocks} Mocks
                                            </label>
                                        </div>

                                    </div>

                                    <div className="set-scholastic-content-rght">
                                        {isMock ?
                                            <p>Rs.{props.mockAmount}/-</p>
                                            : null}
                                    </div>

                                </div>
                            </div>

                            <div className="set-scholastic-item">
                                <div className="set-scholastic-title">
                                    <h4>mock tests</h4>
                                </div>
                                <div className="set-scholastic-content">

                                    <div className="set-scholastic-content-lft">

                                        <div className="form-check custom-checkbox checkbox-success">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={"eLibrary" + props.id}
                                                name={"eLibrary" + props.id}
                                                checked={isLibrary}
                                                onChange={onLibraryHandler}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={"eLibrary" + props.id}
                                            >
                                                E-library
                                            </label>
                                        </div>

                                    </div>

                                    <div className="set-scholastic-content-rght">
                                        {isLibrary ?
                                            <p>Rs.{props.library}/-</p>
                                            : null}
                                    </div>

                                </div>
                            </div>

                            {props.board == 2 ?
                                <div className="set-scholastic-item">
                                    <div className="set-scholastic-title">
                                        <h4>Case studies</h4>
                                    </div>
                                    <div className="set-scholastic-content">

                                        <div className="set-scholastic-content-lft">

                                            <div className="form-check custom-checkbox checkbox-success">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={"bundle" + props.id}
                                                    name={"bundle" + props.id}
                                                    value="2"
                                                    checked={isBundle}
                                                    onChange={onBundleHandler}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={"bundle" + props.id}
                                                >
                                                    Bundle
                                                </label>
                                            </div>

                                        </div>

                                        <div className="set-scholastic-content-rght">
                                            {isBundle ?
                                                <p>Rs.{props.caseStudyAmount}/-</p>
                                                : null}
                                        </div>

                                    </div>
                                </div>
                                : null}
                        </div>

                        <div className="subcribe-scholastic-price-details">
                            <h4>Rs.{Number(setAmount) + module + mock + bundle + library}/-</h4>
                            {props.isPurchased == 1 ?
                                null :
                                <>
                                    {!props.cartList.find(element => element.subscription_id == props.id && element.exam_category_id == 1) ?
                                        <Button
                                            onClick={() => finalSubscriptionSubmit()}
                                        >Add to Cart</Button>
                                        : <Button disabled>Add to Cart</Button>}
                                </>
                            }

                        </div>

                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-sm subscribe-modal"
                show={modal}
            >
                <Modal.Header>
                    <Modal.Title>Cart Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</Modal.Body>
                <Modal.Footer>
                    <div className="subscribe-modal-footer">
                        <Button
                            className="cancelBtn"
                            onClick={() => setModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="proceedBtn"
                            onClick={onProgress}
                        >Proceed
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.subscribe.showLoading,
        isNav: state.subscribe.isNav,
        cartList: state.subscribe.cartList
    };
};
export default connect(mapStateToProps)(PurchaseSubcribeScholasticCard);