import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { connect, useDispatch, useSelector } from 'react-redux';
import { Pie, Bar } from "react-chartjs-2";
import Loader from "../../components/Loader"
import ChartDataLabels from 'chartjs-plugin-datalabels';


import { performanceRequestAction, getCompetitiveSetwizeScoreNtseDataDetails } from '../../../store/actions/PerformanceScoreAction';


const ComparativeStudySetNtse = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [satDetails, setSatDetails] = useState()
    const [matDetails, setMatDetails] = useState()
    const [setData, setSetData] = useState();


    const [barChartsData, setBarChartsData] = useState();
    const [matBarChartsData, setMatBarChartsData] = useState();

    const comparativeSetwiseScoreNtse = useSelector(state => state.performance.comparativeSetwiseScoreNtse);

    const satChartColor = ['#4472C4', '#ED7D31', '#A5A5A5', '#FFC000', '#5B9BD5', '#70AD47', '#264478', '#138535', '#7A297B', '#B21016', '#807900', '#B84A5B', '#232B99', '#94D8F6', '#DAAADB'];

    const tempMatValue = [
        {
            "branch_name": "AL",
            "set_no": 1,
            "total_correct": 0,
            "total_question": 5,
            "total_attended": 0,
            "total_notattended": 5,
            "total_incorrect": 0,
            "score": 0
        },
        {
            "branch_name": "AR",
            "set_no": 1,
            "total_correct": 2,
            "total_question": 3,
            "total_attended": 2,
            "total_notattended": 1,
            "total_incorrect": 0,
            "score": 2
        },

    ]
    /* chart data */
    const data = {
        datasets: [
            {
                // data: [88, 92, 10, 5, 2, 3],
                data: setData,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#00C400",
                    "#7F7F7F",
                    "#A5A5A5",
                    "#C00000",
                    "#F9301B",
                ],
                /* hoverBackgroundColor: [
                    "#00B050",
                    "#F9301B",
                    "#C00000",
                    "#00C400",
                    "#A5A5A5",
                    "#7F7F7F",
                ], */
            },
        ],
    };


    const options = {
        plugins: {
            legend: false,
            responsive: true,
            datalabels: {
                color: '#fff',
                display: true,
                anchor: "end",
                offset: -20,
                align: 'end',
                backgroundColor: '#000',
                borderRadius: 3,
                formatter: Math.round,
                font: {
                    size: 12,
                },

            },

        },
        maintainAspectRatio: false,
    };

    const satDataBar = {
        defaultFontFamily: "Poppins",
        labels: ["SAT"],
        datasets: barChartsData,
    };
    const matDdataBar = {
        defaultFontFamily: "Poppins",
        labels: ["MAT"],
        datasets: matBarChartsData,
    };
    const optionsBar = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltips: {
                mode: "index",
                intersect: false,
            },
            responsive: true,
        },
        scales: {
            x:
            {
                stacked: true,
            },
            y:
            {
                stacked: true,
            },
        },
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "center",
                offset: -20,
                align: "center"
            }
        },
    };

    useEffect(() => {
        dispatch(performanceRequestAction(true))
        dispatch(getCompetitiveSetwizeScoreNtseDataDetails(previousValue.set_no, props.history))

        if (comparativeSetwiseScoreNtse != '') {
            showDetails()
        }

    }, [comparativeSetwiseScoreNtse]);

    /* create data details */
    const showDetails = () => {
        const keyValue = [];
        let valueData = [];
        var satSeriesData = null;
        const chapterName = Object.fromEntries(Object.entries(comparativeSetwiseScoreNtse.secondary).filter(([key, value]) => {
            keyValue.push(key)
            let satSeriesData = value;
            valueData.push(satSeriesData)
        }))
        setSatDetails(valueData)

        let pieData = [];
        const satCorrect = props.comparativeSetwiseScoreNtse.firstary.total_correct_sat;
        const matCorrect = props.comparativeSetwiseScoreNtse.firstary.total_correct_mat;
        const satnotAttemped = props.comparativeSetwiseScoreNtse.firstary.total_notattended_sat;
        const matnotAttemped = props.comparativeSetwiseScoreNtse.firstary.total_notattended_mat;
        const satincorrect = props.comparativeSetwiseScoreNtse.firstary.total_incorrect_sat;
        const matincorrect = props.comparativeSetwiseScoreNtse.firstary.total_incorrect_mat;

        pieData.push(satCorrect, matCorrect, satnotAttemped, matnotAttemped, satincorrect, matincorrect);
        setSetData(pieData)

        let satBarChartData = [];
        let seriesData = null;
        valueData.forEach((elm, i) => {
            let seriesData = {}
            seriesData['label'] = elm.branch_name;
            seriesData['backgroundColor'] = satChartColor[i];
            seriesData['data'] = [elm.score]
            satBarChartData.push(seriesData);
        })
        setBarChartsData(satBarChartData);

        const matKeyValue = [];
        let matValueData = [];
        var matSeriesData = null;

        const matDetails = Object.fromEntries(Object.entries(comparativeSetwiseScoreNtse.thirdary).filter(([key, value]) => {
            // matKeyValue.push(key)
            let matSeriesData = value;
            if (matSeriesData != '') {
                matValueData.push(matSeriesData)
            }
        }))
        setMatDetails(matValueData)
        // setMatDetails(tempMatValue)

        let matBarChartData = [];
        let matseriesData = null;
        matValueData.forEach((elm, i) => { //matValueData
            let matseriesData = {}
            matseriesData['label'] = elm.branch_name;
            matseriesData['backgroundColor'] = satChartColor[i];
            matseriesData['data'] = [elm.score]
            matBarChartData.push(matseriesData);
        })
        setMatBarChartsData(matBarChartData);
    }


    return (
        <>
            {props.comparativeSetwiseScoreNtse != '' ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    
                    <div className='performance_container'>

                        <div className="text-center"><h3>Comparative study of Set Exams(Maths)</h3></div>
                        <div className='performance_parent_container'>
                            <div className='score_container w450'>
                                <div className='score_card_parent_container w450 flex-1 jcc'>
                                    <table>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th className="text-center">Tot Qs</th>
                                            <th className="text-center">Attemped</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Not Attemped</th>
                                            <th className="text-center">Incorrect</th>
                                        </tr>
                                        {props.comparativeSetwiseScoreNtse != '' ?
                                            <tbody>
                                                <tr>
                                                    <td className="text-center" rowSpan={2}>Set {previousValue.set_no}</td>
                                                    <td className="text-center">SAT</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_question_sat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_attended_sat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_correct_sat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_notattended_sat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_incorrect_sat}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">MAT</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_question_mat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_attended_mat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_correct_mat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_notattended_mat}</td>
                                                    <td className="text-center">{props.comparativeSetwiseScoreNtse.firstary.total_incorrect_mat}</td>
                                                </tr>

                                            </tbody>
                                            : null}
                                    </table>
                                </div>
                            </div>
                            <div className='chart_container w660 jcc text-center flex_d_col'>
                                <div className="pie_parent_container">
                                    <Pie data={data} height={210} options={options} plugins={[ChartDataLabels]} />
                                </div>

                                <div className='graph_marking_container'>
                                    <div className="marking_parent_container flex-1">
                                        <div className='marking_box total_correct_sat'></div>
                                        <div className='font9'>Sat Correct</div>
                                    </div>
                                    <div className="marking_parent_container flex-1">
                                        <div className='marking_box total_correct_mat'></div>
                                        <div className='font9'>Mat Correct</div>
                                    </div>
                                    <div className="marking_parent_container flex-1">
                                        <div className='marking_box total_notattended_sat'></div>
                                        <div className='font9'>Sat Incorrect</div>
                                    </div>
                                    <div className="marking_parent_container flex-1">
                                        <div className='marking_box total_notattended_mat'></div>
                                        <div className='font9'>Mat Incorrect</div>
                                    </div>
                                    <div className="marking_parent_container flex-1">
                                        <div className='marking_box total_incorrect_sat'></div>
                                        <div className='font9'>Sat Not Attempted</div>
                                    </div>
                                    <div className="marking_parent_container flex-1">
                                        <div className='marking_box total_incorrect_mat'></div>
                                        <div className='font9'>Mat Not Attempted</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {props.comparativeSetwiseScoreNtse != '' ?
                            <div className='performance_parent_container'>
                                <div className='score_container'>
                                    <div className='score_card_parent_container'>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th className="text-center">Tot Qs</th>
                                                <th className="text-center">Attemped</th>
                                                <th className="text-center">Correct</th>
                                                <th className="text-center">Not Attemped</th>
                                                <th className="text-center">Incorrect</th>
                                                <th className="text-center">Score</th>
                                            </tr>

                                            <tbody>
                                                {satDetails != '' && satDetails != undefined ?
                                                    satDetails.map((item, i) => (
                                                        (i == 0) ?
                                                            <tr key={i} >
                                                                <td rowspan={satDetails.length}>SAT</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `} >{item.branch_name}</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_question}</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_attended}</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_correct}</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_notattended}</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_incorrect}</td>
                                                                <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.score}</td>
                                                            </tr>
                                                            :
                                                            <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                                <td className="text-center">{item.branch_name}</td>
                                                                <td className="text-center">{item.total_question}</td>
                                                                <td className="text-center">{item.total_attended}</td>
                                                                <td className="text-center">{item.total_correct}</td>
                                                                <td className="text-center">{item.total_notattended}</td>
                                                                <td className="text-center">{item.total_incorrect}</td>
                                                                <td className="text-center">{item.score}</td>
                                                            </tr>
                                                    ))
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='chart_container flex-1 jcc text-center wh400 flex_d_col'>
                                    <div className='graph_marking_container'>
                                        <div className="marking_parent_container">
                                            <h4>Comparative study set1 vs set2</h4>
                                        </div>
                                    </div>
                                    {barChartsData != '' && barChartsData != undefined ?
                                        <div className="pie_parent_container">
                                            <Bar data={satDataBar} height={150} options={optionsBar} plugins={[ChartDataLabels]} />
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}

                        {matDetails != '' ?
                            <div className='performance_parent_container'>
                                <div className='score_container'>
                                    <div className='score_card_parent_container'>
                                        {props.comparativeSetwiseScoreNtse != '' ?
                                            <table>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th className="text-center">Tot Qs</th>
                                                    <th className="text-center">Attemped</th>
                                                    <th className="text-center">Correct</th>
                                                    <th className="text-center">Not Attemped</th>
                                                    <th className="text-center">Incorrect</th>
                                                    <th className="text-center">Score</th>
                                                </tr>

                                                <tbody>
                                                    {matDetails ?
                                                        matDetails.map((item, i) => (
                                                            (i == 0) ?
                                                                <tr key={i} >
                                                                    <td rowspan={matDetails.length}>MAT</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `} >{item.branch_name}</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_question}</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_attended}</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_correct}</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_notattended}</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.total_incorrect}</td>
                                                                    <td className={`${i % 2 == 0 ? `tr-background text-center` : null} `}>{item.score}</td>
                                                                </tr>
                                                                :
                                                                <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                                    <td className="text-center">{item.branch_name}</td>
                                                                    <td className="text-center">{item.total_question}</td>
                                                                    <td className="text-center">{item.total_attended}</td>
                                                                    <td className="text-center">{item.total_correct}</td>
                                                                    <td className="text-center">{item.total_notattended}</td>
                                                                    <td className="text-center">{item.total_incorrect}</td>
                                                                    <td className="text-center">{item.score}</td>
                                                                </tr>
                                                        ))
                                                        : null}
                                                </tbody>
                                            </table>
                                            : null}
                                    </div>
                                </div>
                                <div className='chart_container flex-1 jcc text-center wh400 flex_d_col'>
                                    <div className='graph_marking_container'>
                                        <div className="marking_parent_container">
                                            <h4>Comparative study set1 vs set2</h4>
                                        </div>
                                    </div>
                                    {matBarChartsData != '' && matBarChartsData != undefined ?
                                        <div className="pie_parent_container">
                                            <Bar data={matDdataBar} height={150} options={optionsBar} plugins={[ChartDataLabels]} />
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}

                    </div>
                </>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        comparativeSetwiseScoreNtse: state.performance.comparativeSetwiseScoreNtse,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(ComparativeStudySetNtse);
