import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import { getBoardData, loadingBoardAction } from '../../store/actions/BoardActions';
import { getClassStandardData, loadingClassStandardAction } from '../../store/actions/ClassStandardAction';
import { getBranchScholasticData, loadingBranchScholasticAction } from '../../store/actions/ScholasticAction';
import { getChapterData, loadingChapterAction } from '../../store/actions/ChapterAction';

const ExamDetails = (props) => {

    const history = useHistory();
    const categoryId = useSelector(state => state.category.selectCategory);

    const [board, setBoard] = useState('');
    const [standard, setStandard] = useState('');
    const [branch, setBranch] = useState('');
    const [chapter, setChapters] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadingBoardAction(true));
        dispatch(getBoardData(props.history));
        dispatch(loadingClassStandardAction(true));
        dispatch(getClassStandardData(props.history));
        if (categoryId != null) {
            dispatch(loadingBranchScholasticAction(true));
            dispatch(getBranchScholasticData(categoryId, props.history));
        }

    }, [categoryId]);

    const showChapterlist = (branch_code, id) => {
        setBranch(branch_code)
        dispatch(loadingChapterAction(true));
        dispatch(getChapterData(id, props.history));
    }


    return (
        <>
            <div className="row">

                <div className="col-md-1">

                    <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-xxl-4">
                    <div className="row">
                        <div className="col-xl-12 col-sm-6">
                            <div className="card green_container padding_15 box_height_547">

                                <div className="form-group exam">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control form-control select_option"
                                            value={board}
                                            onChange={(e) => setBoard(e.target.value)}
                                        >
                                            <option value="">Select Board</option>
                                            {
                                                props.boardList.map((item, index) => {
                                                    return <option key={index} value={item.short_code}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                        {/* {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>} */}
                                    </div>
                                </div>
                                {board === '' ?
                                    <div className="form-group exam">
                                        <div className="basic-dropdown form-control select_option unselect_text">
                                            <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                            Select Standard
                                        </div>
                                    </div>
                                    :
                                    <div className="form-group exam">
                                        <div className="basic-dropdown">
                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control form-control select_option"
                                                value={standard}
                                                onChange={(e) => setStandard(e.target.value)}
                                            >
                                                <option value="">Select Standard</option>
                                                {
                                                    props.classStandardList.map((item, index) => {
                                                        return <option key={index} value={item.class_no}>{item.short_code}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                }

                                <div className="select_branch_list unselect_text">
                                    Select Branch
                                </div>
                                {standard !== "" ?
                                    <div className="select_branch_list_name">
                                        {
                                            props.branchScholasticList.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {branch === item.branch_code ?
                                                            <div className="font_open_sans branch_list_container branch_selected" key={index} value={item.branch_code} >
                                                                {item.branch_name}
                                                            </div>
                                                            :
                                                            <Link to="#">
                                                                <div className="font_open_sans exam_branch_list_container" key={index} value={item.branch_code} onClick={() => showChapterlist(item.branch_code, item.id)}>
                                                                    {item.branch_name}
                                                                </div>
                                                            </Link>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-9 col-xxl-8">
                    <div className="card padding_15">
                        <div className="padding_15 green_background round_8">
                            <div className="top-heading font_16 font_white">
                                Select Chapters ({branch != '' ? `${props.chaptercList.length}` : 0})
                            </div>
                        </div>

                        <div className="botton_continer round_8 padding_15">
                            {
                                props.chaptercList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {/* {chapter == item.id ?
                                                <div className="chapters_listing margin_buttom_20">
                                                    <div clsss="chapter_heading">
                                                        <div className="font_open_sans color_blue_1 font_13 font_weight_600">
                                                            {item.heading}
                                                        </div>
                                                    </div>
                                                    <div clsss="chapter_description">
                                                        <div className="font_open_sans color_gery_1 font_13">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                                : */}
                                            {/* <Link to="/page-exam-start"> */}
                                            {branch != '' ?
                                                <>

                                                    {index < 2 ?
                                                        <Link to={{ pathname: "/page-demo-scholastic-exam", state: { board, standard, branch, chapter: item.short_code } }}>
                                                            <div className="chapters_listing margin_buttom_20">
                                                                <div clsss="chapter_heading">
                                                                    <div className="font_open_sans color_gery_1 font_13 font_weight_600">
                                                                        {item.chapter_name}
                                                                    </div>
                                                                </div>
                                                                <div clsss="chapter_description">
                                                                    <div className="font_open_sans color_gery_11 font_13">
                                                                        {item.short_code}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        :
                                                        props.user_id == 0 ?
                                                            <>
                                                                <div className="chapters_listing margin_buttom_20 unselect_text">
                                                                    <div clsss="chapter_heading">
                                                                        <div className="font_open_sans color_gery_1 font_13 font_weight_600">
                                                                            {item.chapter_name}
                                                                        </div>
                                                                    </div>
                                                                    <div clsss="chapter_description">
                                                                        <div className="font_open_sans color_gery_11 font_13">
                                                                            {item.short_code}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <Link to={{ pathname: "/page-demo-scholastic-exam", state: { board, standard, branch, chapter: item.short_code } }}>
                                                                <div className="chapters_listing margin_buttom_20">
                                                                    <div clsss="chapter_heading">
                                                                        <div className="font_open_sans color_gery_1 font_13 font_weight_600">
                                                                            {item.chapter_name}
                                                                        </div>
                                                                    </div>
                                                                    <div clsss="chapter_description">
                                                                        <div className="font_open_sans color_gery_11 font_13">
                                                                            {item.short_code}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                    }
                                                </>
                                                : null}
                                            {/* } */}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user_id: state.auth.user_id,
        showLoading: state.board.showLoading,
        boardList: state.board.boardList,
        classStandardList: state.standard.classStandardList,
        showLoadingclassStandard: state.standard.showLoadingclassStandard,
        branchScholasticList: state.branch.branchScholasticList,
        showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
        chaptercList: state.chapter.chapterList,
        showLoadingChapter: state.chapter.showLoadingChapter,
    };
};
export default connect(mapStateToProps)(ExamDetails);