import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import Loader from "../../components/Loader";
import { getPurchasedLibrarySubjectDetails } from '../../../store/actions/SubjectAction';
import { branchListAgainstSubjectid } from '../../../store/actions/ScholasticAction';
import { getChapterData, getBranchIdByChapterData, getBranchIdByChapterDataAction, loadingChapterAction } from '../../../store/actions/ChapterAction';
import { getOnlineConceptMapDetails, elibraryLoading, eliraryShowCallIcon, eliraryCategoryAction, eliraryScholasticCategoryAction } from '../../../store/actions/LibraryAction';

import Spinner from 'react-bootstrap/Spinner';

const LibraryCategoryWiseDetails = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [subject, setSubject] = useState('');
    const [branch, setBranch] = useState('');
    const [defaultValue, setDefaultVAlue] = useState("")
    const [subjectName, setSubjectName] = useState("")


    useEffect(() => {
        dispatch(getPurchasedLibrarySubjectDetails(props.history));

		return () => {

		};

    }, []);

    const onSubjectHandler = (id) => {
        // console.log("$-----", id)
        setSubject(id);
        // setSubject(id.split(',')[0]);
        setSubjectName(id.split(',')[1]);
        dispatch(loadingChapterAction(true));
        dispatch(getChapterData(id.split(',')[0], props.history));
        // setBranch('');
        // dispatch(getBranchIdByChapterDataAction([]));
        // dispatch(branchListAgainstSubjectid(val, props.history));
    }

    const onBranchHandler = (val) => {
        setBranch(val);
        dispatch(loadingChapterAction(true));
        dispatch(getBranchIdByChapterData(val, props.history));
    }

    const onChapterHandler = (item) => {
        // console.log( "%%%%%%---", item )
        dispatch(elibraryLoading(true));
        dispatch(getOnlineConceptMapDetails(1, 0, item.id, props.history));
        dispatch(eliraryScholasticCategoryAction([item, "Scholastic", subjectName, subject.split(',')[0]]));
    }


    return (
        <>
            <div className="row">
                <div className="col-md-1">
                    <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-xxl-4">
                    <div className="row">
                        <div className="col-xl-12 col-sm-6">
                            <div className="card green_container padding_15 box_height_547">
                                <div className="form-group exam mb-3">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            // defaultValue={"option"}
                                            className="form-control form-control select_option green_background color_white"
                                            value={subject}
                                            onChange={(e) => onSubjectHandler(e.target.value)}
                                        >
                                            <option value={defaultValue}>Select Subject</option>
                                            {
                                                props.librarySubjectList.map((item, index) => {
                                                    return <option key={index} value={[item.subject_id, item.subject_name]}>{item.subject_name}</option>
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>

                                {/* <div className="form-group exam mb-3">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control form-control select_option"
                                            value={branch}
                                            onChange={(e) => onBranchHandler(e.target.value)}
                                        >
                                            <option value="">Select Branch</option>
                                            {
                                                props.branchScholasticList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.branch_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div> */}

                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-9 col-xxl-8">
                    <div className="card padding_15">
                        <div className="padding_15 green_background round_8">
                            <div className="top-heading font_16 font_white">
                                Select Chapters ({subject ? `${props.chaptercList.length}` : 0})
                            </div>
                        </div>

                        <div className="botton_continer round_8 padding_15 green_background_border">
                            {

                                props.chaptercList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>

                                            {subject ?
                                                <Link to="#" onClick={() => onChapterHandler(item)}>
                                                    <div className="chapters_listing margin_buttom_20">
                                                        <div clsss="chapter_heading">
                                                            <div className="font_open_sans color_gery_1 font_13 font_weight_900">
                                                                {/* {item.chapter_name} */}
                                                                Chapter {index+1}
                                                            </div>
                                                        </div>
                                                        <div clsss="chapter_description">
                                                            <div className="font_open_sans color_gery_1 font_13">
                                                                {item.sub_heading}
                                                            </div>
                                                        </div>
                                                        <div clsss="chapter_description">
                                                            <div className="font_open_sans color_gery_1 font_13">
                                                                {item.short_code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                null}
                                        </React.Fragment>
                                    )
                                })

                            }
                            {props.showLoadingChapter ?
                                <div className="exam_list_container" >
                                    <div className="spinner-container">
                                        <div className="spinner-view">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                //size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        librarySubjectList: state.subject.purchaseLibrarySubjectList,
        branchScholasticList: state.branch.branchScholasticListAgainstSubjectId,
        chaptercList: state.chapter.chapterList,
        showLoading: state.elibrary.loading,
        showLoadingChapter: state.chapter.showLoadingChapter,
    };
};
export default connect(mapStateToProps)(LibraryCategoryWiseDetails);