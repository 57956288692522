import React, { useState, } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
// image
import logofull from '../../images/clv_logo.png';
import * as utility from '../../utility/Utility';
import Loader from "../components/Loader"

import { forgetPasswordEmail, loadingToggleAction } from "../../store/actions/AuthActions"

// const ForgotPassword = ({ props }) => {
function ForgotPassword(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  let errorsObj = { email: '', };
  const [errors, setErrors] = useState(errorsObj);
  const [email, setEmail] = useState()


  const onSubmit = (e) => {
    e.preventDefault();
    let error = false;

    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    } else if (utility.validateEmail(email)) {
      errorObj.email = 'Not a Valid Email';
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(forgetPasswordEmail(email, props.history));
  };

  return (
    <>
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/page-login">
                          <img src={logofull} alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center">Password Reset</h4>
                      <h6 className="text-center mb-4 ">Relax, we are here to help you.</h6>
                      {/* <form onSubmit={(e) => onSubmit(e)}> */}
                      <div>
                        <div className="form-group mb-4">
                          <label className="">
                            <strong>Email <span className="mandatory_color">*</span></strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            // defaultValue="hello@example.com"
                            placeholder=" Enter your Registered Email ID"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            onKeyDown={utility.whiteSpacenotAllow}
                          />
                          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                        </div>
                        <div className="text-center dflex">
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-warning btn-block text-center me-3"
                          >
                            Cancel
                          </button>
                          <button
                            // type="submit"
                            className="btn btn-primary btn-block text-center"
                            onClick={(e) => onSubmit(e)}
                          >
                            SUBMIT
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {props.showLoading ?
          <Loader />
          : null}
      </div>
      <div><ToastContainer /></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(ForgotPassword);
// export default ForgotPassword;
