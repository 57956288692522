import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Line } from "react-chartjs-2";

import {
	Row,
	Col,
} from "react-bootstrap";


const ChartCard2 = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const functionalCallFirstTimeRef = useRef(true);
	const [chartSeries, setChartSeries] = useState();
	const [chartData, setChartData] = useState([]);



	useEffect(() => {
		createNewDatasets()

	}, []);

	const createNewDatasets = () => {
		const createDatasets = [];
		const label = {};
		const data = {};
		const borderColor = {};
		const borderWidth = {};
		const backgroundColor = {};
		const tension = {};

		if (props.dataSets != '' && props.dataSets != undefined) {
			let dataSet = [];
			let dataValue = props.dataSets
			Object.keys(dataValue).forEach(function (key) {
				// console.log("chapterSet----key---", dataValue[key].label);
				let obj = {
					label: dataValue[key].label,
					data: dataValue[key].data,
					borderColor: dataValue[key].borderColor,
					borderWidth: "2",
					backgroundColor: dataValue[key].backgroundColor,
					tension: 0.4,
				};
				dataSet.push(
					obj
				)
				setChartData(dataSet)
			});
		}
	}


	const data = {
		defaultFontFamily: "Poppins",
		// labels: ["Math", "Phy", "Chem", "Bio", "Geo", "His", "Civ", "Eco"],
		labels: props.label,
		datasets: chartData,
		/* datasets: [
			{
				// label: "Scholastic",
				label: props.dataSets[0].label,
				data: props.dataSets[0].data,
				// data: [25, 20, 60, 41, 66, 45, 80, 90],
				borderColor: "#48CDF4",
				borderWidth: "2",
				backgroundColor: "#48CDF4",
				// pointBackgroundColor: "#48CDF4",
				tension: 0.4
			},
			{
				// label: "Competitive",
				// data: [5, 20, 15, 41, 35, 65, 80, 70],
				label: props.dataSets[1].label,
				data: props.dataSets[1].data,
				borderColor: "#59C378",
				borderWidth: "2",
				// backgroundColor: "transparent",
				backgroundColor: "#59C378",
				pointBackgroundColor: "#59C378",
				tension: 0.4
			},

		], */
	};

	const options = {
		maintainAspectRatio: false,
		plugins: {
			// legend: true,
			legend: {
				display: true,
				onHover: function (e) {
					e.native.target.style.cursor = "pointer";
				  },
				  onLeave: function (e) {
					e.native.target.style.cursor = "default";
				  },
			},
			tooltips: {
				intersect: false,
			},
			hover: {
				mode: "nearest",
				// intersect: true,
			}
		},
		scales: {
			/* y:
			{
				ticks: {
					beginAtZero: true,
					max: 100,
					min: 0,
					stepSize: 20,
					padding: 10,
				},
			}, */

			x:
			{
				ticks: {
					padding: 5,
				},
			},
			yaxis: {
				min: 0,
				max: 100,
				tickAmount: 8,
			},

		},

	};

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						<div className="chart_card_heading">{props.pageHeading}</div>
						<div className="chart_body">
							{/* <ChartistGraph data={data} options={options} type={type} /> */}
							<Line data={data} options={options} height={200} />
						</div>

					</div>
				</Col>
			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		scholasticOverAllPerformance: state.performance.scholasticOverAllPerformance,
	};
};
export default connect(mapStateToProps)(ChartCard2);