import React, {useEffect} from "react";
// import './App.css';

import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";

import {
  Button,
  Position,
  Tooltip,
  Viewer,
  Worker,
  PrimaryButton,
  RenderPageProps
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import GlobalConfigs from "../../../configs/GlobalConfigs";
import { useSelector } from 'react-redux';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useState } from "react";

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';


// const pdfUrl = "https://decentraland.org/whitepaper.pdf";
// const pdfUrl = "https://www.africau.edu/images/default/sample.pdf";

export default function PdfView() {
  const demoExamPdf = useSelector(state => state.questionNo.demoExamPdf);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // const pdfUrl = GlobalConfigs.PDF_URL+demoExamPdf;
  // const pdfUrl = "https://crestest-dam-bucket.s3.us-east-2.amazonaws.com/dam/demoexam_1665100800_130.pdf";
  const pdfUrl = "https://crestest-dam-bucket.s3.us-east-2.amazonaws.com/dam/NTGCH5CM1_2021.1F-2.pdf";

  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])


  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  let noteId = notes.length;

  const renderHighlightTarget = (props) => {
    const highlightContent = () => {
      const note = {
        id: ++noteId,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText
      };
      setNotes([...notes, note]);
      props.toggle();
    };
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: "translate(0, 8px)",
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <Button onClick={highlightContent}>
              <MessageIcon />
            </Button>
          }
          content={() => <div style={{ width: "100px" }}>Highlight</div>}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  const renderHighlights = (props) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights
  });

  const renderPage = (props) => {
    return (
        <>
            {props.canvasLayer.children}
            <div style={{ userSelect: 'none' }}>
                {props.textLayer.children}
            </div>
            {props.annotationLayer.children}
        </>
    );
};

  return (
    <div className="App">
      <h1>Demo Exam Details</h1>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            padding: '4px',
          }}
        >
          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
        </div>
        <div
          style={{ height: "100%", border: "1px solid rgba(250, 0, 250, 1)" }}
        >
          <Viewer
            initialPage={3}
            fileUrl={pdfUrl}
            renderPage={renderPage}
            // plugins={[highlightPluginInstance, zoomPluginInstance]}
            plugins={[ zoomPluginInstance]}
            /* plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
          ]} */
          />
        </div>
      </Worker>
    </div>
  );
}
