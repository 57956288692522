import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';
import geo_genious from '../../../images/exam-type/geo_genious.png';
import nso from '../../../images/exam-type/nso.png';
import imo from '../../../images/exam-type/imo.png';

import ScholasticCatagoryCard from '../../components/eLibrary/ScholasticCatagoryCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getExamTypeData } from '../../../store/actions/ExamTypeAction';
import { getPurchasedGroupListData, } from '../../../store/actions/SubjectAction';

const ScholasticPerformanceScore = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getExamTypeData(2, props.history)); /* get exam data details */
		dispatch(getPurchasedGroupListData(props.history)) /* get purchase group subject list details */
	}, []);

	const selectType = (item) => {
		// console.log("Item--11----", item)
		const typeName = item.type_name.toUpperCase()
		// history.push({ pathname: `/competitive-performance-score/${typeName}-perfomance-score`, state: { exam_type: item.type_name } })
	}

	/* got to item select details page */
	const selectGroupItem = (group_subject_id, group_name, subject_image) => {
		// console.log("groupd_id---", groupd_id)
		history.push({ pathname: "/overall-scholastic-performance-score" , state: { group_subject_id: group_subject_id, group_name: group_name, subject_image:subject_image } } )

	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Online Exam Performance Score <i className="bi bi-chevron-right"></i> Scholastic Performance Score</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={() => history.goBack()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<div className="row">
				<div className="demo-elibrary" >
					{props.getpurchasedGrouplist != '' ?

						props.getpurchasedGrouplist.map((item, index) => (
							<div className={`margin_buttom_40 ${item.is_exam == 0 ? null : "cursorPointer"}`} onClick={() => item.is_exam == 1 ?  selectGroupItem(item.subejct_id, item.subject_name, item.subject_image ) : null} key={index}>
								<ScholasticCatagoryCard
									icon={item.subject_image} 
									title={item.subject_name}
									description={item.sub_heading}
									bgColor={item.id == 1 ? '#94AC4B' : '#94AC4B'}
									leftColor={'#94AC4B'}
									iconClass={'new_icon_left_image_scholastic'}
									iconClassRgt={'new_icon_right_image_scholastic'}
									subscribe={item.subscribe}
									is_exam={item.is_exam}
								/>
							</div>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList,
		getpurchasedGrouplist: state.subject.getpurchasedGrouplist,
	};
};

export default connect(mapStateToProps)(ScholasticPerformanceScore);