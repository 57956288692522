import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';
import {
    demoExamSubmitAction
} from '../store/actions/ScholasticAction';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

import { useHistory } from "react-router-dom"
import * as utility from '../utility/Utility'

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.LOGIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function demoLoginService() {
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_DEMO_STUDENT_LOGIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
    });
}

export function checkToken() {
    let getData = localStorage.getItem('userDetails');
    let login_token;
    let userid;
    if (getData == null || "") {
        login_token = "";
        userid = "";
    } else {
        login_token = JSON.parse(getData).token;
        userid = JSON.parse(getData).id;
    }

    const postData = {
        login_token,
        userid,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_VALIDATE_TOKEN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function formatError(errorResponse) {
    switch (errorResponse) {
        case 'Invalid username or password.':
            //return 'Invalid Password';
            swal("Oops", "Invalid username or password", "error", { button: "Try Again!", });
            break;
        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history, path) {
    checkToken()
        .then((response) => {
            if (response.data.status === 200) {
                const tokenDetailsString = localStorage.getItem('userDetails');
                let tokenDetails = '';
                tokenDetails = JSON.parse(tokenDetailsString);
                dispatch(loginConfirmedAction(tokenDetails));
                dispatch(demoExamSubmitAction(JSON.parse(tokenDetailsString).demo_exam_status));
                /* if (path != "demo-e-library-pdf" && path != 'online-payment-success' && path != 'online-payment-aborted' && path != 'online-payment-failure' && path != 'online-payment-illegal-access') {
                    history.replace('/dashboard');
                } */
                // history.replace('/dashboard');
            } else {
                dispatch(logout(history));
                return;
            }
        })
        .catch((error) => {
            // console.log('hello', error.data);
            // const errorMessage = formatError(error.response.data);
            // utility.showError(errorMessage);

        });
    /* const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    } *//////

    // tokenDetails = JSON.parse(tokenDetailsString);///
    // let expireDate = new Date(tokenDetails.expireDate);
    // let todaysDate = new Date();

    // if (todaysDate > expireDate) {
    //     dispatch(logout(history));
    //     return;
    // }
    // dispatch(loginConfirmedAction(tokenDetails));///

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, history);
}


export function forgetPassword(email) {

    const postData = {
        email
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_FORGET_PASSWORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function newPasswordGenerate(password, message) {

    const postData = {
        message,
        password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GENERATE_NEW_PASSWORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function getTermsCondition() {

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_TERMS_CONDITION_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
    });
}

export function getprivacyPolicy() {

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_PRIVACY_POLICY_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
    });
}


export function unlockScreen(password) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_UNLOCK_SCREEN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function feedbackGivenData() {
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let student_id = JSON.parse(getData).id;

    const postData = {
        student_id: student_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_FEEDBACK_GIVEN_API,

        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function sendOtpBeforeLoginScreen() {

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_SEND_OTP_BEFORE_LOGIN_SCREEN_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
    });
}

/*API  call user details when visit dashboard when user login  */
export function dashboardLogindataData() {
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_DASHBOARD_LOGINDATA_API,

        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });
}

export function checkPasswordLinkExistData(message) {
    // let getData = localStorage.getItem('userDetails');
    // let token = JSON.parse(getData).token;

    const postData = {
        message
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_CHECK_PASSWORD_LINK_EXIST_API,

        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}
