import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import GaugeChart from 'react-gauge-chart'
import Loader from "../../components/Loader"

import ReactApexChart from "react-apexcharts";

import { performanceRequestAction, getScholasticOverAllPerformanceData } from '../../../store/actions/PerformanceScoreAction';

import clv from "../../../images/clv.png"


const OverallScholasticPerformanceScore = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [chartSeries, setChartSeries] = useState();
    const totalRef = useRef(true);
    const functionalCallFirstTimeRef = useRef(true);

    const scholasticOverAllPerformanceTotal = useSelector((state) => state.performance.scholasticOverAllPerformance);

    const overAllDetails = {
        // series: [props.scholasticOverAllPerformance[1].total],
        series: [chartSeries],
        options: {
            chart: {
                height: 300,
                type: "radialBar",
                offsetY: -10,
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "16px",
                            color: undefined,
                            offsetY: 120,
                        },
                        value: {
                            offsetY: 0,
                            fontSize: "34px",
                            color: "#ff6a59",
                            formatter: function (val) {
                                return val + "%";
                            },
                        },
                    },
                },
            },
            fill: {
                type: "gradient",
                colors: "#ff6a59",
                gradient: {
                    shade: "dark",
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91],
                },
            },
            stroke: {
                dashArray: 4,
                colors: "#ff6a59",
            },
            labels: [""],
        },
    };

    useEffect(() => {

        if (functionalCallFirstTimeRef.current) {
            dispatch(performanceRequestAction(true))
            dispatch(getScholasticOverAllPerformanceData(props.history))
        }
        functionalCallFirstTimeRef.current = false;

        if (scholasticOverAllPerformanceTotal != '') {
            if (totalRef.current) {
                setChartSeries(props.scholasticOverAllPerformance[1].total)
            }
            totalRef.current = false;
        }
        /* return () => {
            console.log('Prev value', scholasticOverAllPerformanceTotal) 

         } */
    }, [scholasticOverAllPerformanceTotal]);//scholasticOverAllPerformanceTotal

    const detailsPage = () => {
        history.push({ pathname: "/online-subject-wise-comparison-on" })
    }
    const chartStyle = {
        height: 125,
        width: 290,
    }
    const goPrevious = () => {
        // console.log("online-overall-scholastic-performance-score-->", history)
        history.goBack()
    }

    return (

        <>
            {props.scholasticOverAllPerformance != "" ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={goPrevious}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className="text-center"><h3>Overall Scholastic Performance Score(Weightage)</h3></div>
                        <div className='performance_parent_container'>

                            <div className='score_container flex-1'>
                                <div className='score_card_parent_container jcc'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className="text-center">Set</th>
                                                <th className="text-center">Module</th>
                                                <th className="text-center">Mock</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.scholasticOverAllPerformance != "" ?
                                                props.scholasticOverAllPerformance.map((item, i) => {
                                                    return [
                                                        <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                            <td className="text-center">{item.label}</td>
                                                            <td className="text-center">{item.set}</td>
                                                            <td className="text-center">{item.module}</td>
                                                            <td className="text-center">{item.mock}</td>
                                                            <td className="text-center">{item.total == '' ? '' : item.total + "%"}</td>
                                                        </tr>
                                                    ];
                                                })
                                                : <tr>
                                                    <td colSpan={4}>No data Available</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            {chartSeries !== '' && chartSeries !== undefined ?
                                <div className='chart_container flex-1 wh400 jcc aic text-center flex_column relative_container'>
                                    <div id="chart speed_chart">
                                        <GaugeChart id="gauge-chart1"
                                            nrOfLevels={400}
                                            arcWidth={0.15}
                                            arcsLength={[0.9, 0.15, 0.15, 0.15]}
                                            colors={['#C00000', '#FFC000', '#00B050', '#00B0F0']}
                                            percent={chartSeries / 100}
                                            arcPadding={0.00}
                                            textColor="#000"
                                            needleColor="#275D79"
                                            needleBaseColor="#275D79"
                                            cornerRadius={0}
                                            style={chartStyle}
                                        />
                                    </div>
                                    <div className='needle_base_image_container'>
                                        <img src={clv} alt="" className='needle_base_image' />
                                    </div>
                                    <div className="text-center details_text" onClick={detailsPage}>
                                        <div className="btn btn-danger">Details</div>
                                    </div>
                                </div>
                                : null}

                        </div>

                    </div>
                </>
                : <Loader />}

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        scholasticOverAllPerformance: state.performance.scholasticOverAllPerformance,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(OverallScholasticPerformanceScore);
