import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"

import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";

//Import Components
import ChartCard25 from './performanceComponent/ChartCard25';
import ChartCard26 from './performanceComponent/ChartCard26';
import ChartCard27 from './performanceComponent/ChartCard27';

import setBodyImage from '../../../setBodyImage';

import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_3background.png'

import { performanceRequestAction, getelibrarySessionTimeDataDetails, elibraryMostVisitedSubjectsDataDetails, elibraryMostSearchQuestionsDataDetails } from '../../../store/actions/PerformanceScoreAction';


const ElibraryPerformanceScoreScholasticExams = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [previousValue, setPrevousValue] = useState(location.state)

	useEffect(() => {
		dispatch(performanceRequestAction(true))
		dispatch(getelibrarySessionTimeDataDetails(location.state.id, 0, props.history)) /* call Session Time data */
		dispatch(elibraryMostVisitedSubjectsDataDetails(location.state.id, 0, props.history)) /* call Most Visited Subject data */
		dispatch(elibraryMostSearchQuestionsDataDetails(location.state.id, 0, props.history)) /* call Most Search Questions data */

		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
		};


	}, []);

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> e-Library Performance Score <i className="bi bi-chevron-right"></i> e-Library Scholastic Performance Score  </div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={() => history.goBack()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>

			<Row>

				<Col xl={12}>
					{props.elibrarySessionTime != '' && props.elibrarySessionTime != undefined
						?
						<ChartCard25
							pageHeading="Session Time"
							series={props.elibrarySessionTime.series[0]}
							categories={props.elibrarySessionTime.categories}
						/>
						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</Col>

				<Col xl={12}>
					{props.elibraryMostVisitedSubjects != '' && props.elibraryMostVisitedSubjects != undefined
						?
						<ChartCard26
							pageHeading="Most Visited Subject"
							series={props.elibraryMostVisitedSubjects.series[0]}
							categories={props.elibraryMostVisitedSubjects.categories}
						/>
						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</Col>
				<Col xl={12}>
					{props.elibraryMostSearchQuestions != '' && props.elibraryMostSearchQuestions != undefined
						?
						<ChartCard27
							pageHeading="Most Search Questions"
							series={props.elibraryMostSearchQuestions.series[0]}
							categories={props.elibraryMostSearchQuestions.categories}
						/>
						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</Col>

			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		loading: state.performance.loading,
		elibrarySessionTime: state.performance.elibrarySessionTime,
		elibraryMostVisitedSubjects: state.performance.elibraryMostVisitedSubjects,
		elibraryMostSearchQuestions: state.performance.elibraryMostSearchQuestions,
	};
};
export default connect(mapStateToProps)(ElibraryPerformanceScoreScholasticExams);