import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardActions';
import { getClassStandardData, loadingClassStandardAction } from '../../../store/actions/ClassStandardAction';
import { getBranchScholasticData, loadingBranchScholasticAction } from '../../../store/actions/ScholasticAction';
import { getChapterData, loadingChapterAction } from '../../../store/actions/ChapterAction';
import { eliraryShowCallIcon } from '../../../store/actions/LibraryAction';

const BranchLibrary = (props) => {

	const history = useHistory();

	const [board, setBoard] = useState('');
	const [standard, setStandard] = useState('');
	const [branch, setBranch] = useState('');
	const [chapter, setChapters] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadingBoardAction(true)); /* board loader */
		dispatch(getBoardData(props.history)); /* get board data */
		dispatch(loadingClassStandardAction(true)); /* class loader */
		dispatch(getClassStandardData(props.history));/* get class data */
		dispatch(loadingBranchScholasticAction(true)); /* subject loader */
		dispatch(getBranchScholasticData(1, props.history)); /* get subject data */

		dispatch(eliraryShowCallIcon(1))
		return () => {
			dispatch(eliraryShowCallIcon(0))
		};

	}, []);

	/* select chapter list */
	const showChapterlist = (branch_code, id) => {
		setBranch(branch_code)
		dispatch(loadingChapterAction(true));
		dispatch(getChapterData(id, props.history));
	}


	return (
		<>
			<div className="row">

				<div className="col-md-1">

					<div className="top_back_container" onClick={() => history.goBack()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>

				</div>
			</div>
			<div className="row">
				<div className="col-xl-3 col-xxl-4">
					<div className="card elibrary-branchlist-section box_height_547">
						<div className="elibrary-branchlist">
							<div className="elibrary-branchlist-title">
								<h4>Select Branch</h4>
							</div>
							<div className="select_branch_list_name">
								{
									props.branchScholasticList.map((item, index) => {
										return (
											<React.Fragment key={index}>
												{branch === item.branch_code ?
													<div className="font_open_sans branch_list_container branch_selected" key={index} value={item.branch_code} >
														{item.branch_name}
													</div>
													:
													<Link to="#">
														<div className="font_open_sans exam_branch_list_container" key={index} value={item.branch_code} onClick={() => showChapterlist(item.branch_code, item.id)}>
															{item.branch_name}
														</div>
													</Link>
												}
											</React.Fragment>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-9 col-xxl-8">
					<div className="card box_height_547">

						<div className="elibrary-branchlist-map-section">

							<div className="elibrary-branchlist-map-header">
								<h4>select concept map</h4>
							</div>

							{/* <div className="elibrary-branchlist-map-content">
								<ul>
									<li>
										<Link to="/page-e-library-exam">
											Introduction to Python Data Types
											<span>Learn the basics of data types in Python 3.</span>
										</Link>
									</li>
								</ul>
							</div> */}
							<div className="botton_continer_blue round_8 padding_15">
								{
									props.chaptercList.map((item, index) => {
										return (
											<React.Fragment key={index}>

												{branch != '' ?
													<>
														<Link to={{ pathname: "/page-e-library-exam", state: { board, standard, branch, chapter: item.short_code } }}>
															<div className="chapters_listing margin_buttom_20">
																<div className="chapter_heading">
																	<div className="font_open_sans color_gery_1 font_13 font_weight_600">
																		{item.chapter_name}
																	</div>
																</div>
																<div className="chapter_description">
																	<div className="font_open_sans color_gery_11 font_13">
																		{item.short_code}
																	</div>
																</div>
															</div>
														</Link>
													</>
													: null
												}
											</React.Fragment>
										)
									})
								}
							</div>

						</div>

					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
		boardList: state.board.boardList,
		classStandardList: state.standard.classStandardList,
		showLoadingclassStandard: state.standard.showLoadingclassStandard,
		branchScholasticList: state.branch.branchScholasticList,
		showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
		chaptercList: state.chapter.chapterList,
		showLoadingChapter: state.chapter.showLoadingChapter,
	};
};
export default connect(mapStateToProps)(BranchLibrary);