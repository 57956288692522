import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { connect, useDispatch, useSelector } from 'react-redux';
import { Pie, Bar } from "react-chartjs-2";
// import {  } from "react-chartjs-2";
import Loader from "../../components/Loader"
import ChartDataLabels from 'chartjs-plugin-datalabels';


import { getscholasticComparativeStudyChapterWiseDetailsData, performanceRequestAction, getScholasticComparativeStudyModuleDetailsData, getScholasticComparativeStudyMockDetailsData } from '../../../store/actions/PerformanceScoreAction';


const ComparativeStudySetExams = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [setData0, setSetData0] = useState();
    const [setData1, setSetData1] = useState();
    const [setData2, setSetData2] = useState();
    const [setData3, setSetData3] = useState();
    const [setData4, setSetData4] = useState();
    const [setData5, setSetData5] = useState();

    const [barChartsData, setBarChartsData] = useState();
    const [barChartsLabel, setBarChartsLabel] = useState();
    const [showData, setShowData] = useState(false)

    const performanceOverallavg = useSelector(state => state.performance.performanceOverallavg);


    /* chart data */
    const data0 = {
        datasets: [
            {
                // data: [13, 0, 0],
                data: setData0,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#C00000",
                    "#A6A6A6",
                ],
                /* hoverBackgroundColor: [
                    "#000000",
                    "#C00000",
                    "#A6A6A6",
                ], */
            },
        ],
    };
    const data1 = {
        datasets: [
            {
                data: setData1,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#C00000",
                    "#A6A6A6",
                ],
            },
        ],
    };
    const data2 = {
        datasets: [
            {
                data: setData2,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#C00000",
                    "#A6A6A6",
                ],
            },
        ],
    };
    const data3 = {
        datasets: [
            {
                data: setData3,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#C00000",
                    "#A6A6A6",
                ],
            },
        ],
    };
    const data4 = {
        datasets: [
            {
                data: setData4,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#C00000",
                    "#A6A6A6",
                ],
            },
        ],
    };
    const data5 = {
        datasets: [
            {
                data: setData5,
                borderWidth: 0,
                backgroundColor: [
                    "#00B050",
                    "#C00000",
                    "#A6A6A6",
                ],
            },
        ],
    };

    const options = {
        plugins: {
            legend: false,
            responsive: true,
            datalabels: {
                color: '#fff',
                display: true,
                align: 'center',
                backgroundColor: '#000',
                borderRadius: 3,
                font: {
                    size: 12,
                },

            },

        },
        maintainAspectRatio: false,
    };

    const dataBar = {
        defaultFontFamily: "Poppins",
        // labels: ["Set1", "Set2", "Set3",],
        labels: barChartsLabel,
        datasets: barChartsData,
    };
    const optionsBar = {
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
            tooltips: {
                mode: "index",
                intersect: false,
            },
            responsive: true,
        },
        scales: {
            x:
            {
                stacked: true,
            },
            y:
            {
                stacked: true,
            },
        },
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "center",
                offset: -20,
                align: "center"
            }
        },
    };

    useEffect(() => {
        if (previousValue.type == 1) {
            //for set
            dispatch(performanceRequestAction(true))
            dispatch(getscholasticComparativeStudyChapterWiseDetailsData(previousValue.subject_id, previousValue.chapter_id, props.history))
        } else if (previousValue.type == 2) {
            //for module
            dispatch(performanceRequestAction(true))
            dispatch(getScholasticComparativeStudyModuleDetailsData(previousValue.subject_id, props.history))
        } else if (previousValue.type == 3) {
            //for Mock
            dispatch(performanceRequestAction(true))
            dispatch(getScholasticComparativeStudyMockDetailsData(previousValue.subject_id, props.history))
        }


        for (let i = 0; i < props.scholasticchapterWiseDetails.length; i++) {
            eval('setSetData' + i)([props.scholasticchapterWiseDetails[i].total_correct, props.scholasticchapterWiseDetails[i].total_incorrect, props.scholasticchapterWiseDetails[i].total_not_attempt])
        }

        /* for (let i = 0; i < props.scholasticchapterWiseDetails.length; i++) {
            if (props.scholasticchapterWiseDetails.length == 1) {
                setSetData([props.scholasticchapterWiseDetails[0].total_correct, props.scholasticchapterWiseDetails[0].total_incorrect, props.scholasticchapterWiseDetails[0].total_not_attempt])
            } 
        } */

        const barGraphDetailsData = [{
            label: "SWA",
            backgroundColor: "#4472C4",
            data: [],
        },
        {
            label: "HOTS",
            backgroundColor: "#ED7D31",
            data: [],
        },
        {
            label: "DES",
            backgroundColor: "#A5A5A5",
            data: [],
        }
        ];

        for (let i = 0; i < props.scholasticchapterWiseDetails.length; i++) {
            barGraphDetailsData[0].data.push(props.scholasticchapterWiseDetails[i].question_type_swa_marks);
            barGraphDetailsData[1].data.push(props.scholasticchapterWiseDetails[i].question_type_hot_marks);
            barGraphDetailsData[2].data.push(props.scholasticchapterWiseDetails[i].question_type_des_marks);
        }
        setBarChartsData(barGraphDetailsData);

        const setTotalLabel = [];
        var setName = null;
        for (let i = 0; i < props.scholasticchapterWiseDetails.length; i++) {
            let setName = {};
            setName = "Set " + (i + 1);
            setTotalLabel.push(setName);
        }
        setBarChartsLabel(setTotalLabel)

        return () => {
            setBarChartsLabel('');
            setBarChartsData('')
            setSetData0('');
            setSetData1('');
            setSetData2('');
            setSetData3('');
            setSetData4('');
            setShowData(true)

        }

    }, [props.scholasticchapterWiseDetails]);


    return (
        <>
            {/* {props.scholasticchapterWiseDetails != '' && barChartsData != '' && barChartsData != undefined && barChartsLabel != '' && barChartsLabel != undefined ? */}
            {showData ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>

                        <div className="text-center"><h3>Comparative study of {previousValue.type_name} Exams({previousValue.subject_name})</h3></div>
                        <div className='performance_parent_container'>
                            <div className='score_container w450'>
                                <div className='score_card_parent_container w450 flex-1 jcc'>
                                    <table>
                                        <tr>
                                            {previousValue.type == 1 ? (<th></th>) : previousValue.type == 2 || previousValue.type == 3 ? null : null}
                                            <th></th>
                                            <th className="text-center">Tot Qs</th>
                                            <th className="text-center">Attemped</th>
                                            <th className="text-center">Not Attemped</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Incorrect</th>
                                        </tr>
                                        <tbody>
                                            {props.scholasticchapterWiseDetails.map((item, i) => (
                                                (i == 0) ?
                                                    <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                        {previousValue.type == 1 ?
                                                            <td rowSpan={props.scholasticchapterWiseDetails.length}>{previousValue.type == 1 ? (item.chapter) : previousValue.type == 2 || previousValue.type == 3 ? null : null}</td>
                                                            : null}
                                                        <td className="text-center">{previousValue.type == 1 ? ("Set " + item.set_no) : previousValue.type == 2 ? ("Module " + item.module_no) : previousValue.type == 3 ? ("Mock " + item.mock_no) : null}</td>
                                                        <td className="text-center">{item.total_question}</td>
                                                        <td className="text-center">{item.total_attempt}</td>
                                                        <td className="text-center">{item.total_not_attempt}</td>
                                                        <td className="text-center">{item.total_correct}</td>
                                                        <td className="text-center">{item.total_incorrect}</td>

                                                    </tr>
                                                    :
                                                    <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                        <td className="text-center">{previousValue.type == 1 ? ("Set " + item.set_no) : previousValue.type == 2 ? ("Module " + item.module_no) : previousValue.type == 3 ? ("Mock " + item.mock_no) : null}</td>
                                                        <td className="text-center">{item.total_question}</td>
                                                        <td className="text-center">{item.total_attempt}</td>
                                                        <td className="text-center">{item.total_not_attempt}</td>
                                                        <td className="text-center">{item.total_correct}</td>
                                                        <td className="text-center">{item.total_incorrect}</td>
                                                    </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='chart_container w660 jcc text-center flex_d_col'>
                                <div className="pie_parent_container">

                                    {props.scholasticchapterWiseDetails.map((item, i) => (
                                        <div className="" key={i}>
                                            <div>Set {i + 1}</div>
                                            <div className="pie_container">
                                                <Pie data={eval('data' + i)} height={110} options={options} plugins={[ChartDataLabels]} />
                                            </div>
                                        </div>
                                    ))
                                    }

                                </div>
                                <div className='graph_marking_container'>
                                    <div className="marking_parent_container">
                                        <div className='marking_box correct_color'></div>
                                        <div>Correct</div>
                                    </div>
                                    <div className="marking_parent_container">
                                        <div className='marking_box incorrect_color'></div>
                                        <div>Incorrect</div>
                                    </div>
                                    <div className="marking_parent_container">
                                        <div className='marking_box not_attempted__color'></div>
                                        <div>Not Attempted</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='performance_parent_container'>
                            <div className='score_container'>
                                <div className='score_card_parent_container'>
                                    <table>
                                        <tr>
                                            <th></th>
                                            <th colSpan="2" className='text-center'>SWA</th>
                                            <th colSpan="2" className='text-center'>HOTS</th>
                                            <th colSpan="2" className='text-center'>DES</th>
                                            <th colSpan="2" className='text-center'>TOT</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            {/* {previousValue.type == 1 ? (<th></th>) : previousValue.type == 2 || previousValue.type == 3 ? null : null} */}
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                            <th className="text-center">Correct</th>
                                            <th className="text-center">Marks</th>
                                        </tr>
                                        <tbody>
                                            {props.scholasticchapterWiseDetails.map((item, i) => (
                                                (i == 0) ?
                                                    <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                        {previousValue.type == 1 ? <td rowspan={props.scholasticchapterWiseDetails.length}>{item.chapter}</td> :
                                                            <td>{previousValue.type == 2 ? ("Module " + item.module_no) : previousValue.type == 3 ? ("Mock " + item.mock_no) : null}</td>}
                                                        {/* <td rowspan={props.scholasticchapterWiseDetails.length}>{item.chapter}</td> */}

                                                        <td className="text-center">{item.question_type_swa}</td>
                                                        <td className="text-center">{item.question_type_swa_marks}</td>
                                                        <td className="text-center">{item.question_type_hot}</td>
                                                        <td className="text-center">{item.question_type_hot_marks}</td>
                                                        <td className="text-center">{item.question_type_des}</td>
                                                        <td className="text-center">{item.question_type_des_marks}</td>
                                                        <td className="text-center">{item.question_type_total}</td>
                                                        <td className="text-center">{item.question_type_total_marks}</td>

                                                    </tr>
                                                    :
                                                    <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                        {previousValue.type == 1 ? null :
                                                            <td>{previousValue.type == 2 ? ("Module " + item.module_no) : previousValue.type == 3 ? ("Mock " + item.mock_no) : null}</td>}
                                                        <td className="text-center">{item.question_type_swa}</td>
                                                        <td className="text-center">{item.question_type_swa_marks}</td>
                                                        <td className="text-center">{item.question_type_hot}</td>
                                                        <td className="text-center">{item.question_type_hot_marks}</td>
                                                        <td className="text-center">{item.question_type_des}</td>
                                                        <td className="text-center">{item.question_type_des_marks}</td>
                                                        <td className="text-center">{item.question_type_total}</td>
                                                        <td className="text-center">{item.question_type_total_marks}</td>
                                                    </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='chart_container flex-1 jcc text-center wh400 flex_d_col'>
                                <div className='graph_marking_container'>
                                    <div className="marking_parent_container">
                                        <h4>Comparative study set1 vs set2</h4>
                                    </div>
                                </div>

                                <div className="pie_parent_container">
                                    <Bar data={dataBar} height={150} options={optionsBar} plugins={[ChartDataLabels]} />
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        scholasticchapterWiseDetails: state.performance.scholasticchapterWiseDetails,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(ComparativeStudySetExams);
