import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';
// import ntse from '../../../images/exam-type/ntse.png';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';
import geo_genious from '../../../images/exam-type/geo_genious.png';
import nso from '../../../images/exam-type/nso.png';
import imo from '../../../images/exam-type/imo.png';


import CompetitiveElibrarySubCard from '../../components/eLibrary/CompetitiveElibrarySubCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getExamTypeData } from '../../../store/actions/ExamTypeAction';
import { elibraryPathAction } from '../../../store/actions/LibraryAction';

const CompetitiveTypeList = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getExamTypeData(2, props.history));
	}, []);

	/* select type */
	const selectType = (item) => {
		// console.log("item------===================-", item)
		// history.push({ pathname: '/e-library-competitive-list', state: { item: item } })
		localStorage.setItem('elibraryPathItemValue', JSON.stringify({item:item}));
		localStorage.setItem('elibraryPath', JSON.stringify(`e-library-competitive-${item.type_name}-subject-list`));
		dispatch(elibraryPathAction(`e-library-competitive-${item.type_name}-subject-list`));
		history.push({ pathname: `/e-library-competitive-${item.type_name}-subject-list`, state: { item: item } })
		

		const competitiveListData = {
			item: item
		}
		localStorage.setItem('localstrageCompetitiveLibraryListData', JSON.stringify(competitiveListData));
	}

	return (
		<>

			<div className="row">
				<div className="col-md-12">
					<div className='text_blue'>e-library <i className="bi bi-chevron-right"></i> Competitive List <i className="bi bi-chevron-right"></i> e-Library Competitive Segments</div>
				</div>
				<div className='mt-3'></div>
			</div>

			<div className="row">
				<div className="demo-elibrary" >
					{props.typelist != '' ?

						props.typelist.map((item, index) => (
							// <div className='cursorPointer' onClick={() => selectType(item)} key={index}>
							<div className={`margin_buttom_40 ${item.e_subscribe == 1 ? "cursorPointer" : null}`} onClick={() => item.e_subscribe == 1 ? selectType(item) : null} key={index}>
								<CompetitiveElibrarySubCard
									// key={index}
									// path={item.id == 1 ? '' : ''}
									// icon={item.id == 1 ? ntse : ntse} //nstse
									// icon={item.id == 1 ? ntse : item.id == 2 ? nstse : item.id == 3 ? imo : item.id == 4 ? nso : item.id == 5 ? geo_genious :  null} //nstse
									icon={item.image_path} //nstse
									title={item.type_name}
									description={item.sub_heading}
									bgColor={item.id == 1 ? '#02879B' : '#02879B'}
									leftColor={'#02879B'}
									iconClass={'new_icon_left_image'}
									iconClassRgt={'new_icon_right_image'}
									e_subscribe={item.e_subscribe}
									subject_image={item.subject_image}
								/>
							</div>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList
	};
};

export default connect(mapStateToProps)(CompetitiveTypeList);