import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"
import { Link } from "react-router-dom";
import {
	Row,
	Col,
	Modal,
	Button,
	Spinner
} from "react-bootstrap";
import Loader from "../../components/Loader"
import { Doughnut, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';

//Import Components
import ChartCard8Module from './performanceComponent/ChartCard8Module';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'

import { getScholasticGetsubjectwiseChaptersDataDetails, performanceRequestAction, getscholasticGetsubjectwiseChaptersAction } from '../../../store/actions/PerformanceScoreAction';
import * as utility from '../../../utility/Utility';

const SubjectwiseAnalysisOnModule = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const chartRef = useRef();

	const [previousValue, setPrevousValue] = useState(location.state)
	const [showTableModal, setShowTableModal] = useState(false)

	const [satDetails, setSatDetails] = useState()
	const [subjects, setSubjects] = useState([])
	const [averagePercentage, seAaveragePercentage] = useState()


	useEffect(() => {

		dispatch(performanceRequestAction(true))
		dispatch(getscholasticGetsubjectwiseChaptersAction([])); /* previous data remove */
		// dispatch(getScholasticGetsubjectwiseChaptersDataDetails(previousValue.exam_type, props.history))
		dispatch(getScholasticGetsubjectwiseChaptersDataDetails(location.state.exam_type, location.state.group_subject_id, props.history)) /* call module data */
		if (location.state.exam_type == 'undefined' || location.state.exam_type == undefined) {
			history.push({ pathname: "/overall-scholastic-performance-score" })
		}
		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
			dispatch(getscholasticGetsubjectwiseChaptersAction([]));
		};

	}, []);

	useEffect(() => {
		/* hoverBackgroundColor
		< 70% : '#982626', 70% - 80% > '#f1c431', 80% - 90% > '#5bba47', 90% - 100% > #31c5f4
		*/

		if (props.scholasticGetsubjectwiseChaptersPiechart != '' && props.scholasticGetsubjectwiseChaptersPiechart != undefined) {
			let subjects = [];
			let dataVAlue = props.scholasticGetsubjectwiseChaptersPiechart
			Object.keys(dataVAlue).forEach(function (key) {
				// Object.keys(dd).forEach(function (key) {
				let labels = [];
				let vals = [];
				let chapters = [];
				let subject = []
				let percentage = [];
				let color = []
				let hoverBackground = []
				Object.keys(dataVAlue[key]).forEach(function (key2) {
					labels.push(key2);
					vals.push(dataVAlue[key][key2][0]);
					chapters.push(dataVAlue[key][key2][1]);
					subject.push(dataVAlue[key][key2][2]);

					if (dataVAlue[key][key2][0] < 70) {
						hoverBackground.push('#982626')
					} else if (dataVAlue[key][key2][0] >= 70 && dataVAlue[key][key2][0] < 80) {
						hoverBackground.push('#f1c431')
					} else if (dataVAlue[key][key2][0] >= 80 && dataVAlue[key][key2][0] < 90) {
						hoverBackground.push('#5bba47')
					} else if (dataVAlue[key][key2][0] >= 90) {
						hoverBackground.push('#31c5f4')
					}

				});

				percentage.push(dataVAlue[key]['MOD1'][3])
				color.push((dataVAlue[key]['MOD1'][4]))

				let obj = {
					labelName: labels,
					value: vals,
					chapters: chapters,
					subject: subject,
					percentage: percentage,
					color: color,
					hoverBackground: hoverBackground,
				}
				subjects.push({
					[key]: obj
				})
			});

			// console.log("subjects-------", subjects);

			setSubjects(subjects)
			dispatch(performanceRequestAction(false))

		} else if (props.scholasticGetsubjectwiseChaptersPiechart == undefined && props.scholasticGetsubjectwiseChaptersPiechart == '') {
			if (Object.keys(props.scholasticGetsubjectwiseChaptersPiechart).length === 0) {

				utility.showError("No Data Available");
				history.goBack();
			}

		}
	}, [props.scholasticGetsubjectwiseChaptersPiechart, props.scholasticGetsubjectwiseChaptersTabledata,]);

	const showTableData = () => {
		// console.log("Show Table Data");
		setShowTableModal(true)
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	const goPrevious = () => {
		history.goBack();
	}



	return (
		<>

			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Online Exam Performance Score <i className="bi bi-chevron-right"></i> Scholastic Performance Score({location.state && location.state.group_name}) <i className="bi bi-chevron-right"></i> Overall Scholastic Performance Score <i className="bi bi-chevron-right"></i>  Subjectwise Scholastic Score <i className="bi bi-chevron-right"></i> Subjectwise Analysis MOL</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>

			</div>
			<Row>
				{/* {props.scholasticGetsubjectwiseChaptersPiechart != '' ? */}

				{subjects && subjects.map((item, index) => {
					// console.log("----------------------item", Object.keys(item))
					// console.log("----------------------item", Object.values(item))
					// console.log("----------------------item", item)

					return (
						<Col xl={6} key={index}>
							<ChartCard8Module
								pageHeading={Object.keys(item)[0]}
								// highlight={previousValue.subName}
								highlight={location.state.subName}
								showTableData={() => showTableData()}
								labelName={item[Object.keys(item)].labelName}
								value={item[Object.keys(item)].value}
								chapters={item[Object.keys(item)].chapters}
								subject={item[Object.keys(item)].subject}
								totalMarks={item[Object.keys(item)].percentage[0]}
								tableData={props.scholasticGetsubjectwiseChaptersTabledata}
								chartColor={item[Object.keys(item)].color[0]}
								hoverBackground={item[Object.keys(item)].hoverBackground}
								group_subject_id={location.state.group_subject_id}
								group_name={location.state.group_name}
							// showChartData={showChartData}
							/>
						</Col>
					)
				})}

			</Row>

			<div>
				{props.performanceLoader  ?
					<Loader />
					: null}
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		performanceLoader: state.performance.loading,
		scholasticGetsubjectwiseChaptersPiechart: state.performance.scholasticGetsubjectwiseChaptersPiechart,
		scholasticGetsubjectwiseChaptersTabledata: state.performance.scholasticGetsubjectwiseChaptersTabledata,
	};
};
export default connect(mapStateToProps)(SubjectwiseAnalysisOnModule);