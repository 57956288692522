import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import AssessmentDetailCard from "./components/AssessmentDetailCard";
import Spinner from "react-bootstrap/Spinner";
import Loader from "../../components/Loader";
import { Button, Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { RatingComponent } from "react-rating-emoji";
import "react-rating-emoji/dist/index.css";

import { Virtuoso } from "react-virtuoso";

import {
  onlineAssessmentListRequestAction,
  getOnlineScholasticAssessmentDetailsExamIDWise,
  getOnlineCompetitiveAssessmentDetailsExamIDWise,
  onlineScholasticAssessmentListFailureAction,
} from "../../../store/actions/ScholasticAction";
import {
  previousExamTypeAction,
  getFeedbackDetails,
  storeFeedbackDetails,
} from "../../../store/actions/OnlineExamAction";
import {
  feedbackGivenAction,
  feedbackGivenDataDetails,
} from "../../../store/actions/AuthActions";
import { backFromAssessmentDetailsAction } from "../../../store/actions/SubscribeAction";

let localStorageExamId;
let localStorageExam;
let localStorageFromExam;
let currentScrollTop = 0;

const OnlineAssessmentDetails = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const newStudentid = useSelector((state) => state.student.newStudentid);
  const examid = useSelector((state) => state.onlineexam.examId);

  const [previousValue, setPreviousValue] = useState(location.state);
  const [count, setCount] = useState(0);
  const [previousExam, setPreviousExam] = useState([""]);
  const [modal, setModal] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [refreshExamId, setRefreshExamId] = useState("");
  const [listData, setListData] = useState([]);
  const [allListData, setAllListData] = useState(
    props.onlineScholasticExamAessmentDetailsList
  );
  const [startIndex, setStartIndex] = useState(0);
  // const [rating, setRating] = useState(0);
  const [newRating, setNewRating] = useState(0);
  const [pressSubmit, setPressSubmit] = useState(1);

  const [rating, setRating] = useState([
    {
      id: 4,
      rating_value: 2,
    },
    {
      id: 1,
      rating_value: 2,
    },
    {
      id: 3,
      rating_value: 5,
    },
    {
      id: 1,
      rating_value: 1,
    },
  ]);

  const board_name = useSelector((state) => state.auth.board_name);
  const standard = useSelector((state) => state.auth.standard);
  const previousExamType = useSelector(
    (state) => state.onlineexam.previousExamType
  );
  const feedback_status = useSelector((state) => state.auth.feedback_status);

  useEffect(() => {
    // console.log("==examid=====", "+", examid)
    // console.log("==location=====", location)
    // console.log("==history=====", history)
    // console.log("==previousValue=====", previousValue)
    // console.log("==feedback_status=====", feedback_status)
    // console.log("==previousExamType=====", previousExamType)

    let refreshExamDetails = localStorage.getItem("refreshExamId");
    // let ExamIdFromLocalStorage = JSON.parse(getExamId);
    localStorageExamId = JSON.parse(refreshExamDetails).examId;
    localStorageExam = JSON.parse(refreshExamDetails).exam;
    localStorageFromExam = JSON.parse(refreshExamDetails).fromExam;
    // console.log("----------", refreshExamDetails)
    /* console.log("props.onlineScholasticExamAessmentDetailsList[0].exam_feedback----", !!props.onlineScholasticExamAessmentDetailsList.length && props.onlineScholasticExamAessmentDetailsList[0].exam_feedback ) */

    /* if (feedback_status == 0 && feedback_status != null) {
      setModal(true)
    } */

    if (location.state === undefined) {
      if (previousExamType == 1) {
        // console.log("*---11----Q222")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineScholasticAssessmentDetailsExamIDWise(examid, props.history)
        );
      } else if (previousExamType == 2) {
        // console.log("*--22-----Q222")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineCompetitiveAssessmentDetailsExamIDWise(examid, props.history)
        );
      } else if (examid == 0 && localStorageExam == 1) {
        // console.log("*---33----Q222")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineScholasticAssessmentDetailsExamIDWise(
            localStorageExamId,
            props.history
          )
        );
      } else if (examid == 0 && localStorageExam == 2) {
        // console.log("*---444----Q222")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineCompetitiveAssessmentDetailsExamIDWise(
            localStorageExamId,
            props.history
          )
        );
      }
    } else {
      if (examid != 0 && previousValue.exam == 1) {
        // console.log("*-------Q1")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineScholasticAssessmentDetailsExamIDWise(examid, props.history)
        );
        dispatch(previousExamTypeAction(previousValue.exam));
      } else if (examid != 0 && previousValue.exam == 2) {
        // console.log("*-------Q222")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineCompetitiveAssessmentDetailsExamIDWise(examid, props.history)
        );
        dispatch(previousExamTypeAction(previousValue.exam));
      } else if (examid == 0 && previousValue.exam == 1) {
        // console.log("*-------Q333")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineScholasticAssessmentDetailsExamIDWise(
            localStorageExamId,
            props.history
          )
        );
        dispatch(previousExamTypeAction(previousValue.exam));
      } else if (examid == 0 && previousValue.exam == 2) {
        // console.log("*-------Q444")
        dispatch(onlineAssessmentListRequestAction(true));
        dispatch(
          getOnlineCompetitiveAssessmentDetailsExamIDWise(
            localStorageExamId,
            props.history
          )
        );
        dispatch(previousExamTypeAction(previousValue.exam));
      }
    }

    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
      window.MathJax.typesetPromise();
    }

    /* document.addEventListener('keydown', (e) => {
      e = e || window.event;
      if (e.keyCode == 116) {
        e.preventDefault();
      }
    }); */

    //browser back button
    /* if (location.state !== undefined) {
      if (previousValue.fromExam == 1) {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
          history.go(1);
        };
      }
    } */
    //End ---- browser back button

    return () => {
      dispatch(onlineScholasticAssessmentListFailureAction([]));
      // setModal(false)
      /* window.onpopstate = function () {
        // history.reload();
        history.push();
      }; */
      // localStorage.removeItem('refreshExamId');
    };
  }, [
    newStudentid,
    examid,
    board_name,
    standard,
    previousValue,
    previousExam,
    feedback_status,
  ]);

  useEffect(() => {
    dispatch(getFeedbackDetails(props.history));

    return () => {
      setModal(false);
      setPressSubmit(1);
    };
  }, []);

  useEffect(() => {
    if (
      !!props.onlineScholasticExamAessmentDetailsList.length &&
      props.onlineScholasticExamAessmentDetailsList[0].exam_feedback == 0 &&
      pressSubmit == 1
    ) {
      // console.log("setModal(true)----------------")

      setModal(true);
    }
  }, [props.onlineScholasticExamAessmentDetailsList, pressSubmit]);

  useEffect(() => {
    // currentScrollTop
    let lazyArrayData = [];
    window.onload = function () {
      currentScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
    };
    window.onscroll = function () {
      /* if ((window.innerHeight) >= document.body.offsetHeight) {
        console.log('At the bottom!');
      } */
      // console.log("allListData----", allListData)
      if (
        currentScrollTop + window.innerHeight <=
        document.documentElement.scrollTop
      ) {
        /* for (i = 0; i < 4; i++) {

        } */
        // props.onlineScholasticExamAessmentDetailsList
        // setListData()
        // lazyArrayData = props.onlineScholasticExamAessmentDetailsList.slice(startIndex, 4);//return 13
        // //let ss = allListData.splice(1);//for inserting to temp array
        // setStartIndex(startIndex + 4);
        // console.log("sssaaaass", ss);
        // console.log("ddddddd", props.onlineScholasticExamAessmentDetailsList);
        // console.log('At the bottom!');
        currentScrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      }
    };
  }, []);

  const ratingChanged = (newValue, id) => {
    let tempFeedback = [...feedback];
    const feedbackIndex = tempFeedback.findIndex((item) => item.id === id);

    if (feedbackIndex !== -1) {
      tempFeedback[feedbackIndex].rating_value = newValue;
    } else {
      const newFeedback = { id, rating_value: newValue };
      tempFeedback.push(newFeedback);
    }

    setFeedback(tempFeedback);
  };

  /* const ratingChanged = (newValue, id) => {
    // console.log('.....newRating......', newValue, id);
    let tempFeedback = [...feedback];

    var id_feedbackdata = null;
    id_feedbackdata = {}
    id_feedbackdata['id'] = id;
    id_feedbackdata['rating_value'] = newValue;
    tempFeedback.push(id_feedbackdata)
    setFeedback(tempFeedback)
  }; */

  const submitFeedBack = () => {
    // console.log("submitFeedBack..", feedback);
    // return;
    setModal(false);
    setPressSubmit(0);
    dispatch(
      storeFeedbackDetails(
        feedback,
        examid == 0 ? localStorageExamId : examid,
        props.history
      )
    );
    dispatch(feedbackGivenAction(1));
  };

  const goBackAssessmentList = () => {
    dispatch(backFromAssessmentDetailsAction(1));
    history.goBack();
  };

  const handleRating = (newRating, id) => {
    // console.log('.....newRating......', newRating, id);
    // setRating(newRating);
  };

  const checkRatingValue = (itemId) => {
    let result = [];
    if (feedback.length > 0) {
      result = feedback.filter((o) => o.id == itemId);

      // console.log("****result**02*,", result);
      // return result[0].rating_value
    }

    // let result = feedback.find(o => o.id === id)
    // console.log("$$$$$$$$$========", !!result.length ? result[0].rating_value : "0"  )
    // return result[0].rating_value
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="text_blue">
            Online Exam <i className="bi bi-chevron-right"></i> Online
            Assessment List <i className="bi bi-chevron-right"></i> Online
            Assessment Details
          </div>
        </div>
        <div className="mt-3"></div>
      </div>
      {props.onlineScholasticExamAessmentDetailsList != "" ? (
        <div className="row">
          {location.state !== undefined ? (
            previousValue.fromExam != 1 ? (
              <div className="col-md-1">
                {/* <div className="top_back_container" onClick={() => history.goBack()}> */}
                <div
                  className="top_back_container"
                  onClick={() => goBackAssessmentList()}
                >
                  <span className="back_arrow">
                    <i className="bi bi-chevron-left"></i>
                  </span>
                  Back
                </div>
              </div>
            ) : null
          ) : null}

          <div className="col-md-12">
            <div className="assessment_container_parent ">
              <>
                {props.onlineScholasticExamAessmentDetailsList != "" ? (
                  <>
                    <div className="card-header">
                      <div>
                        <h4>Online Assessment Details</h4>
                        <h5>
                          Class: {standard}, Board: {board_name}
                        </h5>
                      </div>
                      {/* {console.log("----@111", props.onlineScholasticExamAessmentDetailsList[0].question_type)} */}
                      {/* {location.state !== undefined ? previousValue.exam == 1 : previousExamType == 1 ? */}
                      {(location.state !== undefined &&
                        previousValue.exam == 1) ||
                      previousExamType == 1 ||
                      (examid == 0 && localStorageExam == 1) ? (
                        <>
                          <h5>
                            {
                              props.onlineScholasticExamAessmentDetailsList[0]
                                .subject_group_name
                            }{" "}
                            <>
                              <i
                                className="fa fa-angle-right assessment_icon_color"
                                aria-hidden="true"
                              ></i>
                            </>{" "}
                            {
                              props.onlineScholasticExamAessmentDetailsList[0]
                                .subject_name
                            }{" "}
                            {props.onlineScholasticExamAessmentDetailsList[0]
                              .subject_name ==
                            props.onlineScholasticExamAessmentDetailsList[0]
                              .branch_name ? null : props
                                .onlineScholasticExamAessmentDetailsList[0]
                                .branch_name ? (
                              <>
                                <i
                                  className="fa fa-angle-right assessment_icon_color"
                                  aria-hidden="true"
                                ></i>
                                {" " +
                                  props
                                    .onlineScholasticExamAessmentDetailsList[0]
                                    .branch_name}
                              </>
                            ) : null}{" "}
                            {props.onlineScholasticExamAessmentDetailsList[0]
                              .exam_type_name ? (
                              <>
                                <i
                                  className="fa fa-angle-right assessment_icon_color"
                                  aria-hidden="true"
                                ></i>
                                {
                                  " " +
                                    props
                                      .onlineScholasticExamAessmentDetailsList[0]
                                      .exam_type_name /* + "-" + props.onlineScholasticExamAessmentDetailsList[0].exam_set_counter */
                                }{" "}
                              </>
                            ) : null}{" "}
                            {props.onlineScholasticExamAessmentDetailsList[0]
                              .chapter_name ? (
                              <>
                                {props
                                  .onlineScholasticExamAessmentDetailsList[0]
                                  .exam_type > 1 ? null : (
                                  <i
                                    className="fa fa-angle-right assessment_icon_color"
                                    aria-hidden="true"
                                  ></i>
                                )}
                                {props
                                  .onlineScholasticExamAessmentDetailsList[0]
                                  .exam_type > 1
                                  ? null
                                  : " " +
                                    props
                                      .onlineScholasticExamAessmentDetailsList[0]
                                      .chapter_name}
                              </>
                            ) : null}{" "}
                          </h5>
                        </>
                      ) : //<i class="fa fa-angle-right" aria-hidden="true"></i> //exam_subtype
                      null}
                      {(location.state !== undefined &&
                        previousValue.exam == 2) ||
                      previousExamType == 2 ||
                      (examid == 0 && localStorageExam == 2) ? (
                        <h5>
                          {props.onlineScholasticExamAessmentDetailsList[0]
                            .exam_type
                            ? props.onlineScholasticExamAessmentDetailsList[0]
                                .exam_type
                            : null}{" "}
                          {props.onlineScholasticExamAessmentDetailsList[0]
                            .exam_type ? (
                            <>
                              <i
                                className="fa fa-angle-right assessment_icon_color"
                                aria-hidden="true"
                              ></i>{" "}
                            </>
                          ) : null}
                          {props.onlineScholasticExamAessmentDetailsList[0]
                            .exam_subtype
                            ? props.onlineScholasticExamAessmentDetailsList[0]
                                .exam_subtype
                            : null}{" "}
                          {props.onlineScholasticExamAessmentDetailsList[0]
                            .exam_subtype ? (
                            <>
                              <i
                                className="fa fa-angle-right assessment_icon_color"
                                aria-hidden="true"
                              ></i>{" "}
                            </>
                          ) : null}
                          {"Set: " +
                            props.onlineScholasticExamAessmentDetailsList[0]
                              .exam_set_counter}{" "}
                        </h5>
                      ) : null}

                      <h6 className="text_right right_box_width">
                        Total questions:{" "}
                        {props.onlineScholasticExamAessmentDetailsList.length}
                        <br /> Correct:{" "}
                        {
                          props.onlineScholasticExamAessmentDetailsList.filter(
                            (i) => i.guest_post_ans_status === 1
                          ).length
                        }
                        <br /> Incorrect:{" "}
                        {
                          props.onlineScholasticExamAessmentDetailsList.filter(
                            (i) =>
                              i.guest_post_ans_status === 0 &&
                              i.guest_post_ans !== "undefined"
                          ).length
                        }
                        <br /> Not attempted:{" "}
                        {
                          props.onlineScholasticExamAessmentDetailsList.filter(
                            (i) =>
                              i.guest_post_ans_status === 0 &&
                              i.guest_post_ans === "undefined"
                          ).length
                        }
                      </h6>
                      {/* <h4>Total: correct: incorrect: not attempted: </h4> */}
                    </div>
                    <div className="card-header jcc aic">
                      <h5>
                        Marks obtained :{" "}
                        {props.onlineScholasticExamAessmentDetailsList[0].marks}{" "}
                        / Total marks :{" "}
                        {
                          props.onlineScholasticExamAessmentDetailsList[0]
                            .total_marks
                        }
                      </h5>
                    </div>
                  </>
                ) : null}

                {props.onlineScholasticExamAessmentDetailsList != "" ? (
                  <Virtuoso
                    style={{ height: "100vh" }}
                    totalCount={
                      props.onlineScholasticExamAessmentDetailsList.length
                    }
                    itemContent={(index) => (
                      <AssessmentDetailCard
                        statusColor={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .guest_post_ans_status
                        }
                        guest_post_ans={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .guest_post_ans
                        }
                        // questionNo={index + 1}
                        questionNo={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .question_counter
                        }
                        question={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .question
                        }
                        options={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .options
                        }
                        studentAnswer={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .guest_post_ans === "undefined"
                            ? ""
                            : props.onlineScholasticExamAessmentDetailsList[
                                index
                              ].guest_post_ans
                        }
                        correctAnswer={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .answer
                        }
                        reason={
                          props.onlineScholasticExamAessmentDetailsList[index]
                            .reason
                        }
                      />
                    )}
                  />
                ) : (
                  <div className="no_data_text">
                    <h4>No list available for online assessment</h4>
                  </div>
                )}

                {/* {props.onlineScholasticExamAessmentDetailsList != "" ?
                  props.onlineScholasticExamAessmentDetailsList.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <AssessmentDetailCard
                          statusColor={item.guest_post_ans_status}
                          guest_post_ans={item.guest_post_ans}
                          questionNo={index + 1}
                          question={item.question}
                          options={item.options}
                          studentAnswer={item.guest_post_ans === "undefined" ? "" : item.guest_post_ans}
                          correctAnswer={item.answer}
                          reason={item.reason}
                        />
                      </React.Fragment>
                    )
                  })
                  :
                  <div className="no_data_text">
                    <h4>No list available for online assessment</h4>
                  </div>
                } */}
              </>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Modal className="fade" size="lg" show={modal}>
        <Modal.Header>
          <Modal.Title className="modal_title">
            We would love to hear your feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="feedback_modal_container">
            {props.feedbackDetails != "" &&
            props.feedbackDetails != undefined ? (
              props.feedbackDetails.map((item, index) => {
                return (
                  <div className="feedback_item" key={index}>
                    <div className="feedback_text_container">
                      {item.questions}
                    </div>
                    <div className="feedback_rating_container">
                      <RatingComponent
                        rating={
                          !!feedback.length &&
                          feedback.findIndex((o) => o.id === item.id) != -1
                            ? feedback[
                                feedback.findIndex((o) => o.id === item.id)
                              ].rating_value
                            : newRating
                        }
                        onClick={(newValue) => {
                          ratingChanged(newValue, item.id);
                        }}
                      />
                      {/* <ReactStars
                        count={5}
                        // onChange={ratingChanged}
                        onChange={newValue => { ratingChanged(newValue, item.id); }}
                        size={28}
                        isHalf={true}
                        emptyIcon={<i className="fa fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-o"></i>}
                        fullIcon={<i className="fa fa-star-o"></i>}
                        activeColor="#1177ff"
                        color="#d9d5d5"
                      /> */}
                    </div>
                  </div>
                );
              })
            ) : props.feedbackDetails == "" ? (
              <div>No data</div>
            ) : (
              <div className="spinner_parent_container">
                <div className="spinner-container-footer">
                  <div className="spinner-view">
                    <Spinner
                      as="span"
                      animation="border"
                      //size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="feedback_btn_group">
            <button
              className="feedback_cancel_btn"
              onClick={() => setModal(false)}
            >
              {/* Quit Exam */}
              Close
            </button>
            <button
              // disabled={pressSubmit == 1 ? false : true}
              // className={`feedback_submit_btn ${pressSubmit == 1 ? null : 'subscription-disable'} `}
              disabled={
                feedback.length == props.feedbackDetails.length ? false : true
              }
              className={`feedback_submit_btn ${
                feedback.length == props.feedbackDetails.length ? null : "subscription-disable"
              } `}
              onClick={submitFeedBack}
            >
              {/* Start Exam */}
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer_container"></div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log("&&&&&&&&&&&", state.onlineexam.onlineScholasticExamAessmentDetailsList)
  return {
    onlineScholasticExamAessmentDetailsList:
      state.onlineexam.onlineScholasticExamAessmentDetailsList,
    feedbackDetails: state.onlineexam.feedbackDetails,
  };
};
export default connect(mapStateToProps)(OnlineAssessmentDetails);
