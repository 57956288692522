import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import {
	Row,
	Col,
	Card,
	Tab,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Nav,
	Button
} from "react-bootstrap";

const TabClasses = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();


	const [board, setBoard] = useState('');




	useEffect(() => {

	}, []);


	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					<div className="basic-dropdown text_center_in_page">
						<h4>Online classes coming soon</h4>
						{/* <h4>This is online Classes</h4> */}
						{/* <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.</p> */}
						{/* <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.</p> */}
					</div>
				</Col>
			</Row>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
	};
};
export default connect(mapStateToProps)(TabClasses);