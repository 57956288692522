import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"

import {
	Row,
	Col,
} from "react-bootstrap";


//Import Components
import ChartCard from './performanceComponent/ChartCard';
import ChartCard1 from './performanceComponent/ChartCard1';
import ChartCard2 from './performanceComponent/ChartCard2';
import ChartCard3 from './performanceComponent/ChartCard3';
import setBodyImage from '../../../setBodyImage';

import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'
import logofull from '../../../images/dashboard.png';

import { getgetScholasticSetModuleMockSubjectWiseDataDetails, performanceRequestAction, getCompareScholasticCompetitiveDataDataDetails, getCompareScholasticCompetitiveDataAction } from '../../../store/actions/PerformanceScoreAction';


const OverallScholasticScore = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [previousValue, setPrevousValue] = useState(location.state)

	useEffect(() => {
		// console.log("overall--location.state", location.state)
		dispatch(getCompareScholasticCompetitiveDataAction([])); /* previous data blank */
		dispatch(performanceRequestAction(true))
		dispatch(getgetScholasticSetModuleMockSubjectWiseDataDetails(previousValue.group_subject_id, props.history)) /* get subject wise mock, module chapter data */
		dispatch(getCompareScholasticCompetitiveDataDataDetails('Competitive', previousValue.group_subject_id, props.history)) /* get Scholastic Competitive compare data  */

		setBodyImage(performerBackground) /* set change new background image */
		return () => {
			setBodyImage(dashboardBackground) /* quit page and restoring old background image */
		};

	}, []);

	const goPrevious = () => {
		// console.log("overall-scholastic-performance-score-->", history)
		history.goBack()
	}

	return (
		<>

			<div className="row ">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Online Exam Performance Score <i className="bi bi-chevron-right"></i> Scholastic Performance Score({location.state && location.state.group_name}) <i className="bi bi-chevron-right"></i> Overall Scholastic Performance Score</div>
				</div>
			</div>
			<div className="d-flex flex-row justify-content-between mb-auto p-2 align-items-center">
				{/* <div className="col-md-1"> */}
				<div className="col-md-1">
					<div className="top_back_container_overall" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
				<div className='d-flex flex-row align-items-center'>
					<div className="overall_right_heading mr-auto p-1">
					{location.state && location.state.group_name}
					</div>
					<img
						src={location.state && location.state.subject_image != '' ? location.state.subject_image : logofull}
						alt="creastest"
						// color={props.leftColor}
						className="overall_sub_image"
					/>
				</div>
			</div>

			<Row>
				<Col xl={6}>
					<ChartCard
						pageHeading="Score Spectrum"
						group_subject_id={previousValue && previousValue.group_subject_id}
					/>
				</Col>
				<Col xl={6}>
					{props.MockData != '' && props.ModuleData != '' && props.SetData != '' && props.scholasticLabel ?
						<ChartCard1
							pageHeading="Strength Analysis"
							scholasticLabel={props.scholasticLabel}
							MockData={props.MockData}
							ModuleData={props.ModuleData}
							SetData={props.SetData}
							group_subject_id={location.state.group_subject_id}
							group_name={location.state.group_name}
						/>
						: null}
				</Col>
				<Col xl={6}>
					{props.compareScholasticCompetitiveLabels != '' && props.compareScholasticCompetitiveDatasets != '' && !!props.compareScholasticCompetitiveDatasets ?
						<ChartCard2
							pageHeading="Scholastic/Competitive Index (%)"
							label={props.compareScholasticCompetitiveLabels}
							dataSets={props.compareScholasticCompetitiveDatasets}
						/>
						: null
					}
				</Col>
				<Col xl={6}>
					{/* {props.MockData != '' && props.ModuleData != '' && props.SetData != '' && props.scholasticLabel ? */}
					<ChartCard3
						pageHeading="Performance Analysis"
						scholasticLabel={props.scholasticLabel}
						MockData={props.MockData}
						ModuleData={props.ModuleData}
						SetData={props.SetData}
					/>
					{/* : null} */}
				</Col>

			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		MockData: state.performance.MockData,
		ModuleData: state.performance.ModuleData,
		SetData: state.performance.SetData,
		scholasticLabel: state.performance.scholasticLabel,
		compareScholasticCompetitiveDatasets: state.performance.compareScholasticCompetitiveDatasets,
		compareScholasticCompetitiveLabels: state.performance.compareScholasticCompetitiveLabels
		// compareScholasticCompetitive: state.performance.compareScholasticCompetitive,
	};
};
export default connect(mapStateToProps)(OverallScholasticScore);