import React, { useEffect, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { getCategoryData } from '../../../store/actions/ExamCategoryAction';
import { onlineScholasticAssessmentListSuccessAction } from '../../../store/actions/ScholasticAction';

import {
	Spinner
} from "react-bootstrap";
import IconTint from 'react-icon-tint';


const PerformanceCategoryOnlineExam = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const callFirstTimeRef = useRef(true);

	useEffect(() => {
		if (callFirstTimeRef.current) {
			dispatch(getCategoryData(props.history)); /* get Category details */
		}
		callFirstTimeRef.current = false

	}, [props.categorylist]);


	const removePreviousAssessmentListData = () => {
		dispatch(onlineScholasticAssessmentListSuccessAction(''))
	}

	const goPerformance = (id) => {
		if (id == 1) {
			history.push({ pathname: "/scholastic-performance-score" })
		} else if (id == 2) {
			history.push({ pathname: "/competitive-performance-score" })
		} /* else if (id == 3) {
			history.push({ pathname: "/e-library-performance-score" })
		} */
	}

	const testPage = () => {
		history.push({ pathname: "/competitive-performance-score" })
	}


	return (
		<>

			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score {/* <i className="bi bi-chevron-right"></i> Online Exam Performance Score  */}<i className="bi bi-chevron-right"></i> Online Exam Performance Score  </div>
				</div>
			</div>
			{
				props.categorylist == "" || props.categorylist == undefined ?
					<div className="exam_list_container" >
						<div className="spinner-container">
							<div className="spinner-view">
								<Spinner
									as="span"
									animation="border"
									//size="sm"
									role="status"
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>
					:
					<>
						<div className="row">
							<div className="exam_inside_container_performance" >

								<div className={`col-md-6 contain_container mb-40 cursorPointer ${props.categorylist[0].is_exam == 0 ? "subscription-disable" : null}`} style={{ backgroundColor: "#94AC4B" }} onClick={() => props.categorylist[0].is_exam != 0 ? goPerformance(1) : null} >
									<div className="left_container_performance">
										<div className='graph_img_container_performance'>
											<img src={props.categorylist[0].performance} alt="" className='graph_img' />
										</div>
										{/* <IconTint
												src={graph_score}
												alt=""
												color={"#94AC4B"}
												className={'left_image'}
											/> */}
									</div>
									<div className="middle_container">
										<div className="middle_inside_container">
											<h3 className="font_white">{props.categorylist[0].category}</h3>
											<p className="font_white">{props.categorylist[0].performance_subheading}</p>
										</div>
									</div>
									<div className="right_container_performance">
										<IconTint
											src={props.categorylist[0].performance}
											alt=""
											color="#fff"
											className={'right_image_performance'} />
									</div>
								</div>

								<div className={`col-md-6 contain_container mb-40 cursorPointer ${props.categorylist[1].is_exam == 0 ? "subscription-disable" : null}`} style={{ backgroundColor: "#71BFD8" }} onClick={() => props.categorylist[1].is_exam != 0 ? goPerformance(2) : null}>
									<div className="left_container_performance">
										<div className='graph_img_container_performance'>
											<img src={props.categorylist[1].performance} alt="" className='graph_img' />

										</div>
										{/* <IconTint
												src={graph_score}
												alt=""
												color={"#94AC4B"}
												className={'left_image'}
											/> */}
									</div>
									<div className="middle_container">
										<div className="middle_inside_container">
											<h3 className="font_white">{props.categorylist[1].category}</h3>
											<p className="font_white">{props.categorylist[1].performance_subheading}</p>
										</div>
									</div>
									<div className="right_container_performance">
										<IconTint
											src={props.categorylist[1].performance}
											alt=""
											color="#fff"
											className={'right_image_performance'} />
									</div>
								</div>
							</div>
						</div>
					</>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		categorylist: state.category.examcategoryList,
	};
};
export default connect(mapStateToProps)(PerformanceCategoryOnlineExam);