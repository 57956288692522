import React, { useEffect, } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import {
	Row,
	Col,
} from "react-bootstrap";
import PerformanceLoader from '../../../jsx/components/PerformanceLoader'

//Import Components
import ChartCardC from './performanceComponent/ChartCardC';
import ChartCard18 from './performanceComponent/ChartCard18';
import ChartCard2 from './performanceComponent/ChartCard2';
import ChartCard19 from './performanceComponent/ChartCard19';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_2background.png'

import { getgetScholasticSetModuleMockSubjectWiseDataDetails, performanceRequestAction, getCompareScholasticCompetitiveDataDataDetails, getCompetitiveSetwiseScoreDataDetails, getcompetitiveSubjectAvgscoreDataDetails } from '../../../store/actions/PerformanceScoreAction';

const OverallCompetitiveScore = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();


	useEffect(() => {
		dispatch(performanceRequestAction(true))
		dispatch(getgetScholasticSetModuleMockSubjectWiseDataDetails(props.history))
		dispatch(getCompareScholasticCompetitiveDataDataDetails(props.history))

		dispatch(getCompetitiveSetwiseScoreDataDetails(props.history))
		dispatch(getcompetitiveSubjectAvgscoreDataDetails(props.history))


		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
		};
	}, []);




	return (
		<>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={() => history.goBack()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<Row>
				<Col xl={6}>
					<ChartCardC
						pageHeading="Performance Weightage"
					/>
				</Col>

				<Col xl={6}>
					{props.getcompetitiveSubjectAvgscore != '' && props.getcompetitiveSubjectAvgscore != undefined ?
						<>
							<ChartCard18
								pageHeading="Subject Average Score vs Market Trend (%)"
								dataSet={props.getcompetitiveSubjectAvgscore}
							/>
						</>
						:
						<PerformanceLoader />
					}
				</Col>

				<Col xl={6}>
					{props.compareScholasticCompetitiveLabels != '' && props.compareScholasticCompetitiveDatasets != '' ?
						<ChartCard2
							pageHeading="Scholastic vs Competitive (%)"
							label={props.compareScholasticCompetitiveLabels}
							dataSets={props.compareScholasticCompetitiveDatasets}
						/>
						:
						<PerformanceLoader />
					}
				</Col>

				<Col xl={6}>
					{props.compititiveSetwiseScore != '' && props.compititiveSetwiseScore != undefined ?
						<ChartCard19
							pageHeading="Set Wise Score Comparison (%)"
							dataSet={props.compititiveSetwiseScore}
						/>
						: <PerformanceLoader />
					}
				</Col>

			</Row>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		compareScholasticCompetitiveDatasets: state.performance.compareScholasticCompetitiveDatasets,
		compareScholasticCompetitiveLabels: state.performance.compareScholasticCompetitiveLabels,
		compititiveSetwiseScore: state.performance.compititiveSetwiseScore,
		loading: state.performance.loading,
		getcompetitiveSubjectAvgscore: state.performance.getcompetitiveSubjectAvgscore,
	};
};
export default connect(mapStateToProps)(OverallCompetitiveScore);