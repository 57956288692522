import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import Loader from "../../components/Loader";
import { getsubscribedListDetails, subscribeLoading } from '../../../store/actions/SubscribeAction';

import testIcon from '../../../images/TestIcon.png'; // 

const studentTableBlog = [
    { image: testIcon, title: 'Mathematic', set: 1, module: 3, mock: 3, elibrary: 'Yes', caseStudies: "no" },
    { image: testIcon, title: 'Biology', set: 2, module: 3, mock: 3, elibrary: 'No', caseStudies: "Yes" },
];


const SubscribeList = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectId, setSelectId] = useState('')


    useEffect(() => {
        dispatch(subscribeLoading(true))
        dispatch(getsubscribedListDetails(props.history));

        // dispatch(eliraryShowCallIcon(1))
        return () => {
            // dispatch(eliraryShowCallIcon(0))
        };

    }, []);


    const selectSubject = (id) => {
        // console.log("Check", id)
        setSelectId(id)
    }

    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    {/* <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i>
                    </div> */}
                    <div className='text_blue'>Subscribe <i className="bi bi-chevron-right"></i> Subscribe List</div>
                </div>
                <div className='mt-3'></div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card students-list">
                        <div className="card-header border-0 flex-wrap pb-0">
                            <h4>Subscribe List</h4>
                        </div>
                        {/* <div className='mt-5'></div> */}

                        {props.getsubscribedList != '' ?
                            <div className="card-body py-0">
                                <div className="table-responsive" >
                                    <div id="student_wrapper" className="dataTables_wrapper no-footer">
                                        <table className="table display mb-4 dataTablesCard order-table card-table text-black application " id="application-tbl11_next">
                                            <thead>
                                                <tr>
                                                    <th>Subject</th>
                                                    <th>Set</th>
                                                    <th>Module</th>
                                                    <th>Mock</th>
                                                    <th>E-library</th>
                                                    <th>Case Studies</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.getsubscribedList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {/* <div className="d-flex align-items-center">
                                                                <img src={item.image} alt="" />
                                                                <h4 className="mb-0 fs-16 font-w500">{item.title}</h4>

                                                            </div> */}
                                                            <div className='dflex aic'>
                                                                <div className="d-flex align-items-center e_library_image_container jcc aic" style={{ backgroundColor: item.subject_color_code != '' && item.subject_color_code != null ? item.subject_color_code : "#e3e3e3" }}>
                                                                    {/* <img src={testIcon} alt="" /> */}
                                                                    {item.subject_image != null && item.subject_image != '' && item.subject_image != "undefined" && item.subject_image != undefined ?
                                                                        <img src={item.subject_image} className='e_library_image' alt="" />
                                                                        :
                                                                        <div style={{ textAlign: "center" }}>No <br /> Image</div>
                                                                    }

                                                                </div>
                                                                <div><h4 className="mb-0 ms-2 fs-16 font-w500">{item.subject_name}</h4></div>
                                                            </div>
                                                        </td>
                                                        <td>{item.no_set}</td>
                                                        <td>{item.module_count}</td>
                                                        <td>{item.mock_count}</td>
                                                        <td>{item.elibrary}</td>
                                                        <td>{item.case_study}</td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        <div className='mt-5'></div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>

            </div>

            <div>
                {props.loading ?
                    <Loader />
                    : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.subscribe.showLoading,
        getsubscribedList: state.subscribe.getsubscribedList,
    };
};
export default connect(mapStateToProps)(SubscribeList);