import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard26 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [showTableModal, setShowTableModal] = useState(false)
    const [board_name, setBoard_name] = useState('')
    const [standard, setStandard] = useState('')

    var subjectChartDetails = {

        /* series: [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, */ /* {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        } */
        series: [props.series],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false,
                },
            },

            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                // categories: ['Sub1', 'Sub2', 'Sub3', 'Sub4', 'Sub5', 'Sub6', 'Sub7', 'Sub8', 'Sub9'],
                categories: props.categories,
            },
            yaxis: {
                show: true,
                /* title: {
                    text: '$ (thousands)'
                } */

                min: 0,
                max: 100,
                tickAmount: 8,

            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        },


    };


    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);

    }, []);


    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }



    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        {subjectChartDetails.options != '' && subjectChartDetails.series != '' ?
                            <>
                                <div className="chart_body " >
                                    <ReactApexChart
                                        options={subjectChartDetails.options}
                                        series={subjectChartDetails.series}
                                        type="bar"
                                        height={300}
                                    />

                                </div>
                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData} >Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex-1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                <div className='performer_table_heading'>Most Visited Subject</div>
                                <p>e-library <i className="fa fa-angle-right assessment_icon_color"></i> {/* {board_name}: {standard}  <i className="fa fa-angle-right assessment_icon_color"></i> */} Most Visited Subject</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Subject</th>
                                        <th className="text-center light_sky">Most visited subject</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {props.categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center light_gray_1">{item}</td>
                                                <td className="text-center light_gray_2">{props.series.data[index]}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {

    return {

    };
};
export default connect(mapStateToProps)(ChartCard26);