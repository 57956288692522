import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import ReactApexChart from "react-apexcharts";


import {
	Row,
	Col,
	Modal
} from "react-bootstrap";



const ChartCard5 = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const [showTableModal, setShowTableModal] = useState(false)
	const [board_name, setBoard_name] = useState('')
	const [standard, setStandard] = useState('')



	useEffect(() => {
		let getData = localStorage.getItem('userDetails');
		let token = JSON.parse(getData).token;
		let board_name = JSON.parse(getData).board_name;
		let standard = JSON.parse(getData).standard;
		setBoard_name(board_name);
		setStandard(standard);
	}, []);


	const averageChartData = {
		// series: [71, 63, 90, 71, 63, 90, 71, 63],
		// series: [21],
		series: props.SetData.data,
		// series: graphicDetails,
		options: {
			chart: {
				type: "radialBar",
				width:320,
				// height: 370,
				offsetY: 0,
				offsetX: 0,
			},
			plotOptions: {
				radialBar: {
					size: undefined,
					inverseOrder: false,
					hollow: {
						margin: 0,
						size: "20%",
						background: "transparent",
					},

					track: {
						show: true,
						background: "#F5F6F6",
						strokeWidth: "100%",
						opacity: 1,
						margin: 6, // margin is in pixels
					},
				},
			},
			responsive: [
				{
					breakpoint: 830,
					options: {
						chart: {
							offsetY: 0,
							offsetX: 0,
						},
						legend: {
							position: "bottom",
							offsetX: 0,
							offsetY: 0,
						},
						plotOptions: {
							radialBar: {
								hollow: {
									size: "20%",
								},
							},
						},
					},
				},
				{
					breakpoint: 800,
					options: {
						chart: {
							offsetY: 0,
							offsetX: 0,
						},
						legend: {
							position: "bottom",
							offsetX: 0,
							offsetY: 0,
						},
						plotOptions: {
							radialBar: {
								hollow: {
									size: "10%",
								},
							},
						},
					},
				},
				{
					breakpoint: 768,
					options: {
						chart: {
							offsetY: 0,
							offsetX: 0,
						},
						legend: {
							position: "bottom",
							offsetX: 0,
							offsetY: 0,
						},
						plotOptions: {
							radialBar: {
								hollow: {
									size: "30%",
								},
							},
						},
					},
				},
				{
					breakpoint: 330,
					options: {
						chart: {
							offsetY: 0,
							offsetX: 0,
						},
						legend: {
							position: "bottom",
							offsetX: 0,
							offsetY: 0,
						},
						plotOptions: {
							radialBar: {
								hollow: {
									size: "20%",
								},
							},
						},
					},
				},
			],

			fill: {
				opacity: 1,
			},
			dataLabels: {
				name: {
					show: false,
				},
				value: {
					show: false,
				}
			},

			colors: ['#3851AB', '#8592CB', '#3851AB', '#8592CB', '#3851AB', '#8592CB', '#3851AB', '#8592CB', '#3851AB', '#8592CB', '#3851AB', '#8592CB',],
			// labels: ["Math", "Phy", "Chem", "Bio", "Geo", "His", "Civ", "Eco",],
			labels: props.scholasticLabel,
			legend: {
				fontSize: "12px",
				show: true,
				position: "left",
				labels: {
					useSeriesColors: true,
				},
				markers: {
					size: 10
				},
				itemMargin: {
					vertical: 2
				},
				formatter: function (seriesName, opts) {
					return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
				},
			},
		},
	};

	/* go to subject wise details page */
	const showDetails = (item) => {
		// history.push({ pathname: "/subjectwise-analysis-on-set", state: { subName: item, exam_type: 1 } })
		// history.push({ pathname: "/subjectwise-analysis-on-chapter-test", state: { subName: item, exam_type: 1, group_subject_id: props.group_subject_id, group_name: props.group_name } })
		history.push({ pathname: "/subjectwise-analysis-ctl", state: { subName: item, exam_type: 1, group_subject_id: props.group_subject_id, group_name: props.group_name } })
	}

	const showSetTableData = () => {
		setShowTableModal(true)
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className={`chart_card_parent ${props.highlight == 3 ? `hightlight_box` : null}`}>
						<div className="chart_card_heading">{props.pageHeading}</div>

						{props.SetData.data != "" ?
							<>
								<div className="chart_body">
									<ReactApexChart
										options={averageChartData.options}
										series={averageChartData.series}
										type="radialBar"
										height={props.height ? props.height : 240}
									/>
								</div>
								<div className="bottom_container">
									<div className="data_label_parent_container">  
										<div className={`data_label ${props.scholasticLabel.length > 6 ? "justify-content-start" : "justify-content-center" }`}>
											{props.scholasticLabel.map((item, index) => {
												return (
													<div className='item_box cursorPointer' style={{ background: `${index % 2 == 0 ? '#3851AB' : '#8592CB'}` }} onClick={() => showDetails(item)} key={index}>{item}</div>
												)
											})}
										</div>
									</div>
									<div className="bottom_details">
										{/* <div className="btn_details back_btn_color text_black">Back </div> */}
										<div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
									</div>
								</div>
							</>
							:
							<div className='dflex flex1 jcc aic'>
								No Data Available
							</div>
						}
					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								<div className='performer_table_heading'>Score Grid - CTL</div>
								<p>Scholastic <i className="fa fa-angle-right assessment_icon_color"></i> {board_name}: {standard}  <i className="fa fa-angle-right assessment_icon_color"></i>Score grid CTL</p>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="10px" className="performance_table_data">
								<thead>
									<tr>
										<th className="text-center light_sky">Subject</th>
										<th className="text-center light_sky">Ch Test</th>
									</tr>

								</thead>
								<tbody>
									{props.scholasticLabel.map((item, index) => {
										return (
											<React.Fragment  key={index}>
												<tr>
													<td className="text-center light_gray_1">{item}</td>
													<td className="text-center light_gray_2">{props.SetData.data[index]}</td>
												</tr>
											</React.Fragment>
										)
									})}
									<tr>
										<td className="text-center light_gray_1">Overall</td>
										<td className="text-center light_gray_2">{props.SetData.overall}</td>
									</tr>

								</tbody>

							</table>

						</div>

					</div>
				</>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	return {

	};
};
export default connect(mapStateToProps)(ChartCard5);