import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom"
import { connect, useDispatch, useSelector } from 'react-redux';
import CounterClockComponent from "../../jsx/components/exam/components/CounterClockComponent"

import co_logo from "../../images/clv_logo.png";
import demo_user from "../../images/demo_user.png";
import dashboard from "../../images/dashboard.png";
import instruction from "../../images/Instruction.png";
import { ToastContainer } from 'react-toastify';
import Loader from "../components/Loader"
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import Spinner from 'react-bootstrap/Spinner';

import DatePicker from "react-datepicker";

import { getCompetitiveExamQuestionsData, loadingBranchScholasticAction, submitScholasticExam, getScholasticQuestionListAction } from '../../store/actions/ScholasticAction';
import { selectDemoQuestionNumber, selectPrevousDemoQuestion, selectNextDemoQuestion, timeUpAction } from '../../store/actions/demoExamAction';
import { newStudentRegistration, showingLoaderStudentAction, demoUserRecordExistsOrNot, sendVerificationOtp, verificationCodeAction, recordExistsSuccess, registrationStatusDetails, postDemoStudentRegistrationAction } from '../../store/actions/StudentAction';
import { getBoardData, loadingBoardAction, getSchoolListData, getSchoolListFailureAction, selectSchoolNameListAction, schoolNameShowingLoaderAction } from '../../store/actions/BoardActions';
import { getClassStandardData, loadingClassStandardAction } from '../../store/actions/ClassStandardAction';

import * as utility from '../../utility/Utility';
import swal from "sweetalert";
import TermsCondition from './TermsCondition';
import CounterClockSoundComponent from "../components/exam/components/CounterClockSoundComponent"

import CreatableSelect from 'react-select/creatable';
import ExamInstruction from '../components/ExamInstruction'

const DemoCompetitiveExamStart = (props) => {
    const status = useSelector(state => state.student.status);
    const newStudentid = useSelector(state => state.student.newStudentid);
    const demoExamSubmit = useSelector(state => state.questionNo.demoExamDoneOrNot);
    // const status = useSelector(state => state.student.registrationStatus);
    const schoolList = useSelector(state => state.board.schoolList);
    const warningSound = useSelector((state) => state.questionNo.warningSound); //-----
    const timeUpWarning = useSelector((state) => state.questionNo.timeUpWarning); //-----

    const [isChecked, setIsChecked] = useState(false)
    const [instructionExamModal, setInstructionExamModal] = useState(false);
    const [informationModal, setInformationModal] = useState(false);
    const [timeUpModal, setTimeUpModal] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [alertSound, setAlertSound] = useState(0);

    /* fullScreen  off and on */
    const fullScreeTtoggleChange = () => {
        setIsFullScreen(!isFullScreen)
        if (isFullScreen == true) {
            launchFullscreen(document.documentElement)
        } else {
            exitFullscreen();
        }
    }

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [is_visited_count, setIs_visited_count] = useState(0)
    const [is_answered_count, setIs_answered_count] = useState(0)

    const [previousValue, setPrevousValue] = useState(location.state)
    const [selected, setSelected] = useState([]);
    const [showQuestion, setShowQuestion] = useState(false)

    const [examTime, setExamTime] = useState(0)
    const [registrationModal, setRegistrationModal] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [otpVerificationModal, setOtpVerificationModal] = useState(false)
    const [selectQuestion, setSelectQuestion] = useState()
    const [isPlaying, setIsPlaying] = useState(true)
    const [showResendOtp, setShowResendOtp] = useState(false)
    const [verifyButtonShow, setVerifyButtonShow] = useState(true)
    const [showOtpValidationTimer, setShowOtpValidationTimer] = useState(false)
    const [viewPassword, setViewPassword] = useState("password");
    const [viewCPassword, setViewCPassword] = useState("password");

    const [schoolNameList, setSchoolNameList] = useState([]);
    const [onlySchoolName, setOnlySchoolName] = useState([]);
    const [schoolAddress, setSchoolAddress] = useState([]);
    const [blankSchoolNameList, setBlankSchoolNameList] = useState(0);
    const [defalutValue, setDefalutValue] = useState('option');
    const [defalutValueBlank, setDefalutValueBlank] = useState(null);
    const isShowSchoolList = useSelector(state => state.board.isShowSchoolList);
    const [termsAndConditionModal, SetTermsAndConditionModal] = useState(false)

    const [isSubmit, setIsSubmit] = useState(0)

    let errorsObj = {
        fname: '', lname: '', dob: '', gender: '', address: '', pincode: '', mobile: '', email: '', password: '', cpassword: '', std: '', board: '', schoolname: '', schooladd: '', userdob: '', motp: '', eotp: '',
    };
    const [errors, setErrors] = useState(errorsObj);
    const [userdob, setUserdob] = useState();
    // const [dateDob, setDateDob] = useState();
    const [userDetails, setUserDetails] = useState({
        fname: '',
        lname: '',
        dob: '',
        gender: '',
        address: '',
        pincode: '',
        mobile: '',
        email: '',
        password: '',
        cpassword: '',
        std: '',
        board: '',
        schoolname: '',
        schooladd: '',
        // motp: props.mOtp == '' ? '' : props.mOtp,
        // eotp: props.eOtp == '' ? '' : props.eOtp,
        motp: '',
        eotp: '',
    })
    const fnameRef = useRef();
    const lnameRef = useRef();
    const addressRef = useRef();
    const pincodeRef = useRef();
    const mobileRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const cpasswordRef = useRef();
    const stdRef = useRef();
    const boardRef = useRef();
    const schoolnameRef = useRef();
    const schooladdRef = useRef();
    const mobileOtpRef = useRef();
    const emailOtpRef = useRef();
    const InstructionExamModalRef = useRef(true);
    const otpModalRef = useRef(true);

    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState(null);
    const [infoBox, setInfoBox] = useState(false)
    const [userImage, setUserImage] = useState(demo_user);

    const [isDemo, setIsDemo] = useState('')

    useEffect(() => {

        let getData = localStorage.getItem('userDetails');
        let profile_pic = JSON.parse(getData).profile_pic;
        let userId = JSON.parse(getData).id;
        setUserImage(profile_pic != '' && profile_pic != undefined && profile_pic != null ? profile_pic : demo_user)
        if (userId > 0) {
            dispatch(postDemoStudentRegistrationAction(userId)); /* create userid when guest user registration completed */
        }

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)

        if (InstructionExamModalRef.current) {
            dispatch(selectDemoQuestionNumber(0)) /* when exam start set question no strat from 0 */
            setInstructionExamModal(true); /* show instrction modal */
            dispatch(loadingBranchScholasticAction(true)); /* question loader showing when qustion loading */

            dispatch(getCompetitiveExamQuestionsData(props.history)); /* get Competitive Exam question details */
        }
        InstructionExamModalRef.current = false
        dispatch(loadingBoardAction(true)); /* board loader showing when board data loading */
        dispatch(getBoardData(props.history)); /* get board data */
        dispatch(loadingClassStandardAction(true)); /* class loader showing when class data loading */
        dispatch(getClassStandardData(props.history)); /* get class data */

        if (status == 0 && status != null) {
            setOtpVerificationModal(true); /* verification modal show */
            setInstructionExamModal(false); /* exam instruction modal close */
            setRegistrationModal(false);/* regostration modal close */
            dispatch(showingLoaderStudentAction(true)); /* showing loader for demo user send OTP  */
            dispatch(sendVerificationOtp(userDetails.mobile, userDetails.email, props.history)) /* Send verification OTP to user  */
        }
        if (newStudentid != null && newStudentid != undefined && demoExamSubmit == 0 && !!props.scholasticQuestionList.length && isSubmit == 1) {
            submitDemoExam() /* After registration submit demo exam  */
            exitFullscreen(); /* submit demo exam and exit from full screen  */
        }

        const interval = setInterval(() => {
            showEquition()
        }, 100);

        showEquition() /* show equition in question and answer */

        if (isShowSchoolList == 1) {
            setSchoolNameList(props.schoolList)
        }

        if (isShowSchoolList == 1) {
            /* to be Deleted */
            setOnlySchoolName([])
            let schoolName = [];
            schoolNameList.forEach((elm) => {
                schoolName.push(elm.school_name);
            })
            setOnlySchoolName(schoolName)
            /* to be Deleted */

            let selectSchoolNameBoardWise = [];
            let selectSchoolName = {}
            schoolNameList.forEach((elm) => {
                selectSchoolName = {}
                selectSchoolName['value'] = elm.school_name
                selectSchoolName['label'] = elm.school_name
                selectSchoolNameBoardWise.push(selectSchoolName);
            })
            setOptions(selectSchoolNameBoardWise)
            dispatch(schoolNameShowingLoaderAction(false)) /* showing loader off when school list load completed */
        }

        if (blankSchoolNameList == 1) {
            // dispatch(getSchoolListFailureAction(''));
            setUserDetails(userDetails => ({
                ...userDetails,
                fname: '',
                lname: '',
                dob: '',
                gender: '',
                address: '',
                pincode: '',
                mobile: '',
                email: '',
                password: '',
                cpassword: '',
                std: '',
                board: '',
                schoolname: '',
                schooladd: '',
                motp: '',
                eotp: '',
            }))
            setUserdob('')
            setOnlySchoolName([]);
            dispatch(getSchoolListFailureAction([])); /* School List Blank */
        }
        setBlankSchoolNameList(0);

        return () => {
            document.removeEventListener('contextmenu', handleContextmenu)
            clearInterval(interval)
        };
    }, [status, newStudentid, demoExamSubmit, schoolNameList, schoolList, isShowSchoolList, isPlaying]);

    useEffect(() => {
        dispatch(selectSchoolNameListAction(0));
        const preventUnload = (event) => {
            // NOTE: This message isn't used in modern browsers, but is required
            const message = 'Sure you want to leave?';
            event.preventDefault();
            return event.returnValue = message;
        };

        window.addEventListener('beforeunload', preventUnload);

        return () => {
            window.removeEventListener('beforeunload', preventUnload);
            // console.log("beforeunload close")
        };
    }, []);

    //-----
    useEffect(() => {
        // document.addEventListener('keydown', detectKeyDown, true)
        // console.log(">>>warningSound>>>>", warningSound)
        if (timeUpWarning == 1) {
            setTimeUpModal(true)
        }
    }, [warningSound, timeUpWarning,]);
    //-----
    useEffect(() => {
        if (fnameRef.current) {
            fnameRef.current.focus();
        }
    }, []);

    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        let user_id = JSON.parse(getData).id;
        setIsDemo(user_id);
    }, []);

    useEffect(() => {
        return () => {
            setIsSubmit(0)
        };
    }, []);

    useEffect(() => {
        return () => {
            dispatch(getScholasticQuestionListAction([])); /* when quit from this page Scholastic Exam question upload as blank */
        };
    }, []);

    useEffect(() => {

        if (!!props.scholasticQuestionList.length) {

            var handler = document.querySelector('.handler');
            var wrapper = document.querySelector('.answer_top_container');
            var boxA = wrapper.querySelector('.question_heading');
            var boxB = wrapper.querySelector('.checkbox_from_container');
            var isHandlerDragging = false;

            document.addEventListener('mousedown', function (e) {
                // If mousedown event is fired from .handler, toggle flag to true
                if (e.target === handler) {
                    isHandlerDragging = true;
                }
            });

            document.addEventListener('mousemove', function (e) {
                // Don't do anything if dragging flag is false
                if (!isHandlerDragging) {
                    return false;
                }

                e.preventDefault();

                // Get offset
                var containerOffsetTop = wrapper.offsetTop;
                var containerOffsetBottom = wrapper.offsetBottom;


                // Get x-coordinate of pointer relative to container
                var pointerRelativeXpos = e.clientY - containerOffsetTop;
                var pointerRelativeXpos2 = e.clientY - e.offsetTop + e.offsetHeight;


                var boxAminWidth = 30;


                boxA.style.height = (Math.max(boxAminWidth, pointerRelativeXpos - 2)) + 'px';
                boxB.style.height = (Math.max(boxAminWidth, pointerRelativeXpos2 - 8)) + 'px';
            });
            document.addEventListener('mouseup', function (e) {
                // Turn off dragging flag when user mouse is up
                isHandlerDragging = false;
            });
        }
        // }, 5000)
    }, [props.scholasticQuestionList]);

    useEffect(() => {
        if (!!props.scholasticQuestionList.length) {
            var wrapper = document.querySelector('.answer_top_container');
            var boxA = wrapper.querySelector('.question_heading');
            boxA.style.height = '250px';
        }
    }, [props.questionNo]);

    /* show Math equition */
    const showEquition = () => {
        if (typeof window?.MathJax !== undefined) {
            window.MathJax.typesetClear();
            window.MathJax.typeset();
            window.MathJax.typesetPromise();
        }
    }

    /* show Full Screen */
    const launchFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }

    /* show exit Full Screen */
    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    /* instruction off and start Exam */
    const startExam = () => {
        setInstructionExamModal(false)
        setShowQuestion(true)
        launchFullscreen(document.documentElement);
        props.scholasticQuestionList[props.questionNo].is_visited = 1;

        visited_count();
        if (isPlaying) {
            // startTimer();
        }
    }

    let counterTimerobj;
    /* start Exam time */
    const startTimer = () => {
        var examTime = 60 * props.scholasticQuestionList[0].exam_duration;
        // var examTime = 60 * 0.1;
        var timer = examTime, minutes, seconds;

        counterTimerobj = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            setExamTime(minutes + ":" + seconds)

            if (timer <= 300 && timer >= 295) {
                setAlertSound(1);
            } else if (timer <= 294) {
                setAlertSound(0);
            }

            if (--timer == 0) {
                minutes = "00";
                seconds = "00"
                setExamTime(minutes + ":" + seconds)
                if (isPlaying) {
                    // console.log("startTimer---")
                    setTimeUpModal(true);
                }
                clearInterval(counterTimerobj);
            }
        }, 1000);
    }

    /* updated select question number */
    const questNumber = (id) => {
        dispatch(selectDemoQuestionNumber(id)) /* current question store into action */
        if (props.scholasticQuestionList[id].is_visited == 1) {
            if (props.scholasticQuestionList[id].is_answered == 1) {
                props.scholasticQuestionList[id].is_visited = 0;
            } else {
                //do nothing
            }
        } else {
            if (props.scholasticQuestionList[id].is_answered && props.scholasticQuestionList[id].is_answered == 1) {
                props.scholasticQuestionList[id].is_visited = 0;

            } else {
                props.scholasticQuestionList[id].is_visited = 1;
            }
            answer_count();
            visited_count();
        }
    }

    /* quit from exam and back to dashboard */
    const backToDashboard = () => {
        dispatch(getSchoolListFailureAction([])); /* School list blank */
        setRegistrationModal(false)
        history.push({ pathname: "/dashboard" })
        exitFullscreen()
    }

    /* Select previous Question */
    const prevousQuestion = (currentionNumver) => {
        dispatch(selectPrevousDemoQuestion(currentionNumver - 1)); /* previous current question store into action */
        props.scholasticQuestionList[currentionNumver - 1].is_visited = 1;
        visited_count();
        if (props.scholasticQuestionList[currentionNumver - 1].is_answered == 1) {
            props.scholasticQuestionList[currentionNumver - 1].is_visited = 0;
        } else {
            //do nothing
        }
    }

    /* Select next Question */
    const nextQuestion = (currentionNumver) => {
        dispatch(selectNextDemoQuestion(currentionNumver + 1)) /* next current question store into action */
        props.scholasticQuestionList[currentionNumver + 1].is_visited = 1;
        visited_count();
        if (props.scholasticQuestionList[currentionNumver + 1].is_answered == 1) {
            props.scholasticQuestionList[currentionNumver + 1].is_visited = 0;
        } else {
            //do nothing
        }
    }

    /* Select answer option Question */
    const answerSetSelected = (data, index) => {
        let temp_data = [...selected]
        temp_data[props.questionNo] = data

        setSelected(temp_data)
        props.scholasticQuestionList[props.questionNo].is_answered = 1;
        props.scholasticQuestionList[props.questionNo].is_answered_data = data;
        props.scholasticQuestionList[props.questionNo].is_visited = 0;
        answer_count();
        visited_count();
        if (utility.decryptAES(props.scholasticQuestionList[props.questionNo].answer) == data) {
            props.scholasticQuestionList[props.questionNo].is_corrected = 1;
        } else {
            props.scholasticQuestionList[props.questionNo].is_corrected = 0;
        }

    }

    /* how many qustion visit this exam */
    const visited_count = () => {
        var rez = 0;
        props.scholasticQuestionList.forEach(function (item) {
            if (item.is_visited >= 0) {
                rez++;
            }
        });
        setIs_visited_count(rez)
    }

    /* how many questions give answer this exam */
    const answer_count = () => {
        var rez = 0;
        props.scholasticQuestionList.forEach(function (item) {
            if (item.is_answered >= 0) {
                rez++;
            }
        });
        setIs_answered_count(rez);
    }

    /* updated of state of registration form */
    const setdata = (e) => {
        const { name, value } = e.target
        setUserDetails((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        })

        if (e.target.name === 'board') {
            let borad_id = e.target.value.split(",")[0]

            if (e.target.value != '') {

                setUserDetails(userDetails => ({
                    ...userDetails,
                    schoolname: '',
                    schooladd: '',
                }))
                dispatch(selectSchoolNameListAction(0));

                if (e.target.value == "Select Board") {
                    setUserDetails(userDetails => ({
                        ...userDetails,
                        schoolname: '',
                        schooladd: '',
                    }))
                    dispatch(selectSchoolNameListAction(0));
                } else {
                    dispatch(loadingBoardAction(true));
                    dispatch(schoolNameShowingLoaderAction(true))/* showing loader show when school list load */
                    setSchoolNameList([])
                    setOnlySchoolName([])
                    dispatch(getSchoolListData(e.target.value == "Select Board" ? '' : borad_id, history)); /* get school list data as per board */
                }
            } else {
                dispatch(getSchoolListFailureAction([])); /* school list data update as blank */
                setUserDetails(userDetails => ({
                    ...userDetails,
                    schooladd: '',
                }))
            }
        }
    }

    /* validation registration form */
    const demouserRegister = (e) => {
        e.preventDefault();

        const re = /^[A-Za-z\s]+$/;
        const onlyNumber = /^[0-9]+$/;
        const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        const { fname, lname, gender, dob, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd } = userDetails;

        let error = false;

        const errorObj = { ...errorsObj };
        if (fname === '') {
            fnameRef.current.focus();
            errorObj.fname = 'First name is Required';
            error = true;
        } else if (fname.length < 3) {
            fnameRef.current.focus();
            errorObj.fname = 'Minimum 3 Character is Required';
            error = true;
        } else if (!re.test(fname)) {
            fnameRef.current.focus();
            errorObj.fname = 'Allow only alphabets';
            error = true;
        }
        if (lname === '') {
            errorObj.lname = 'Last name is Required';
            lnameRef.current.focus();
            error = true;
        } else if (lname.length < 3) {
            errorObj.lname = 'Minimum 3 Character is Required';
            lnameRef.current.focus();
            error = true;
        } else {
            if (!re.test(lname)) {
                errorObj.lname = 'Allow only alphabets';
                lnameRef.current.focus();
                error = true;
            }
        }

        if (userdob === '' || userdob === undefined) {
            errorObj.userdob = 'DOB is Required';
            error = true;
        }

        if (gender === '') {
            errorObj.gender = 'Gender is Required';
            error = true;
        }
        if (address === '') {
            errorObj.address = 'Address is Required';
            addressRef.current.focus();
            error = true;
        } else if (address.length < 3) {
            errorObj.address = 'Minimum 3 Character is Required';
            addressRef.current.focus();
            error = true;
        } else if (!isNaN(address)) {
            errorObj.address = 'Please enter a valid Address';
            addressRef.current.focus();
            error = true;
        }
        if (pincode === '') {
            errorObj.pincode = 'Pin is Required';
            pincodeRef.current.focus();
            error = true;
        } else if (!onlyNumber.test(pincode)) {
            errorObj.pincode = 'Allow only numeric';
            pincodeRef.current.focus();
            error = true;
        } else if (pincode.length < 6) {
            errorObj.pincode = 'Not a valid pin';
            pincodeRef.current.focus();
            error = true;
        } else if (pincode == '000000') {
            errorObj.pincode = 'Not a Valid Pin';
            pincodeRef.current.focus();
            error = true;
        }
        if (mobile === '') {
            errorObj.mobile = 'Mobile is Required';
            mobileRef.current.focus();
            error = true;
        } else if (mobile.length < 10) {
            errorObj.mobile = 'Check Mobile No.';
            mobileRef.current.focus();
            error = true;
        } else if (!onlyNumber.test(mobile)) {
            errorObj.mobile = 'Allow only numeric';
            mobileRef.current.focus();
            error = true;
        } else if (mobile == '0000000000') {
            errorObj.mobile = 'Not a valid number';
            mobileRef.current.focus();
            error = true;
        }
        if (email === '') {
            errorObj.email = 'Email is Required';
            emailRef.current.focus();
            error = true;
        } else if (utility.validateEmail(email)) {
            errorObj.email = 'Not a Valid Email';
            emailRef.current.focus();
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            passwordRef.current.focus();
            error = true;
        } else if (password.length < 6 || password.length > 15) {
            errorObj.password = 'Password minimum 6 and maximum 15 characters Required';
            passwordRef.current.focus();
            error = true;
        } /* else if (!regularExpression.test(password)) {
            errorObj.password = 'Password not in pattern';
            passwordRef.current.focus();
            error = true;
        } */

        if (cpassword === '') {
            errorObj.cpassword = 'Confirm password is Required';
            cpasswordRef.current.focus();
            error = true;
        } else if (cpassword !== password) {
            errorObj.cpassword = 'Mismatched with password';
            cpasswordRef.current.focus();
            error = true;
        }
        if (std === '') {
            errorObj.std = 'Standard is Required';
            stdRef.current.focus();
            error = true;
        }
        if (board === '') {
            errorObj.board = 'Board is Required';
            boardRef.current.focus();
            error = true;
        }
        if (schoolname === '') {
            errorObj.schoolname = 'School name is Required';
            // schoolnameRef.current.focus();
            error = true;
        }
        if (schooladd === '') {
            errorObj.schooladd = 'School address is Required';
            // schooladdRef.current.focus();
            error = true;
        }


        setErrors(errorObj);
        if (error) {
            return;
        } else {
            SetTermsAndConditionModal(true)
            studentDetailsBeforeOtpVerify()
            //dispatch(showingLoaderStudentAction(true));
            //dispatch(demoUserRecordExistsOrNot(mobile, email, props.history))
            // setOtpVerificationModal(true);
        }

    }

    /* check user details exists or not */
    const checkUserRecord = () => {
        SetTermsAndConditionModal(false)
        dispatch(showingLoaderStudentAction(true));
        dispatch(demoUserRecordExistsOrNot(userDetails.mobile, userDetails.email, history)) /* send guest user mobile and emai for duplication check */
        // setOtpVerificationModal(true);
    }

    /* validation registration OTP form */
    const verifyOtp = (e) => {
        e.preventDefault();
        const { fname, lname, gender, dob, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd } = userDetails;

        const re = /^[A-Za-z]+$/;
        const onlyNumber = /^[0-9]+$/;

        const { motp, eotp } = userDetails;
        let error = false;
        const errorObj = { ...errorsObj };

        if (motp === '') {
            mobileOtpRef.current.focus();
            errorObj.motp = 'Mobile OTP is required';
            error = true;
        } else if (!onlyNumber.test(motp)) {
            mobileOtpRef.current.focus();
            errorObj.motp = 'Allow only numeric';
            error = true;
        } else if (motp.length < 6) {
            mobileOtpRef.current.focus();
            errorObj.motp = 'Verify your OTP';
            error = true;
        }

        if (eotp === '') {
            emailOtpRef.current.focus();
            errorObj.eotp = 'Email OTP is required';
            error = true;
        } else if (!onlyNumber.test(eotp)) {
            emailOtpRef.current.focus();
            errorObj.eotp = 'Allow only numeric';
            error = true;
        } else if (eotp.length < 6) {
            emailOtpRef.current.focus();
            errorObj.eotp = 'Verify your OTP';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        } else {

            if ((userDetails.eotp == utility.decryptAES(props.eOtp)) && (userDetails.motp == utility.decryptAES(props.mOtp))) {

                dispatch(showingLoaderStudentAction(true));
                /* guest user data send for registration */
                dispatch(newStudentRegistration(fname.trim(), lname.trim(), dob, gender, address.trim(), pincode.trim(), mobile.trim(), email, password.trim(), cpassword.trim(), std, board, schoolname.trim(), schooladd.trim(), userdob, props.history));
                const removeOtp = {
                    email_otp: '',
                    mobile_otp: '',
                }
                dispatch(verificationCodeAction(removeOtp)) /* after registration remove otp from action or reducer */
                exitFullscreen();
                setOtpVerificationModal(false);
                setRegistrationModal(false);

            } else {
                /* if OTP mismatch */
                dispatch(registrationStatusDetails(mobile.trim(), email, `${fname.trim()} ${lname.trim()}`, userDetails.motp == utility.decryptAES(props.mOtp) ? 1 : 0, userDetails.eotp == utility.decryptAES(props.eOtp) ? 1 : 0, 1, userDetails.std, userDetails.board, userDetails.pincode, userDetails.schoolname));

                alert("Verify your OTP")
            }
        }
    }

    const studentDetailsBeforeOtpVerify = () => {
		dispatch(registrationStatusDetails(userDetails.mobile.trim(), userDetails.email, `${userDetails.fname.trim()} ${userDetails.lname.trim()}`, userDetails.motp == utility.decryptAES(props.mOtp) ? 1 : 0, userDetails.eotp == utility.decryptAES(props.eOtp) ? 1 : 0, 1, userDetails.std, userDetails.board, userDetails.pincode, userDetails.schoolname));
	}

    /* Demo Exam Submit */
    const submitDemoExam = () => {
        // console.log("Submit Fnal Answer", newStudentid)
        const exam_category_id = 2;
        var examdata = [];
        var id_wise_value = null;

        for (var i = 0; i < props.scholasticQuestionList.length; i++) {
            id_wise_value = {};
            id_wise_value['question_id'] = props.scholasticQuestionList[i].id;
            id_wise_value['question_no'] = props.scholasticQuestionList[i].question_no;
            id_wise_value['guest_post_ans'] = props.scholasticQuestionList[i].is_answered_data;
            id_wise_value['guest_post_ans_status'] = props.scholasticQuestionList[i].is_corrected;
            examdata.push(id_wise_value);
        }
        if (examdata != "" && props.user_id == 0 && demoExamSubmit == 0 || demoExamSubmit == undefined) {
            dispatch(submitScholasticExam(examdata, newStudentid, exam_category_id, props.history)); /* exam submit when guest user registered */
        } else {
            dispatch(submitScholasticExam(examdata, props.user_id, exam_category_id, props.history)); /* exam submit when user is registered */
        }
    }

    /* Submit exam and check user demo or not */
    const submitFinalAnswer = () => {
        // if (props.user_id == 0 && demoExamSubmit == 0) {
        setIsPlaying(false)
        if (props.user_id == 0) {
            setIsPlaying(false)
            setRegistrationModal(true);
        } else {
            if (demoExamSubmit == 1) {
                exitFullscreen();
                submitDemoExam();
            }
        }
    }

    /* Submit exam if time up */
    const timeUpSubmit = () => {
        // console.log("timeUpSubmit")
        dispatch(timeUpAction(0)) //-----
        setIsPlaying(false)
        // setTimeUpModal(false);///////////
        submitFinalAnswer();
    }

    const onChange = (date) => {
        // Day.js object
        // console.log(date);

        // to normal Date object
        // console.log(date.toDate());
        setUserDetails(userDetails.email = date)
    }
    const selectDate = () => {
        setShowDatePicker(true)
    }

    /* OTP verify time count */
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            // return <div className="timer">Too lale...</div>;
            const removeOtp = {
                email_otp: '',
                mobile_otp: '',
            }

            setUserDetails(userDetails => ({
                ...userDetails,
                motp: '',
                eotp: '',
            }))

            dispatch(verificationCodeAction(removeOtp)) /* remove OTP from action or reducer when time is expired */
            setShowResendOtp(true) /* show resend button when time is expired */
            setShowOtpValidationTimer(true)
            setVerifyButtonShow(false) /* hide verification button when time is expired */
            dispatch(registrationStatusDetails(userDetails.mobile, userDetails.email, `${userDetails.fname} ${userDetails.lname}`, 0, 0, 2, userDetails.std, userDetails.board, userDetails.pincode, userDetails.schoolname)); /* send OTP verfication details */
        }

        return (
            <div className="timer">
                {/* <div className="small_text">Remaining</div> */}
                <div className="remainingTime">{remainingTime}</div>
                {/* <div className="small_text">seconds</div> */}
            </div>
        );
    };

    /* resend registration otp */
    const resendOtp = () => {
        dispatch(showingLoaderStudentAction(true));
        dispatch(sendVerificationOtp(userDetails.mobile, userDetails.email, props.history)) /* resend verfication code to user mobile and email */
        setShowResendOtp(false) /* hide resend button when received new OTP */
        setVerifyButtonShow(true) /* show verification button when received new OTP  */
    }

    /* show password */
    const showPassword = () => {
        // console.log("viewPassword--", viewPassword)
        if (viewPassword == "password") {
            setViewPassword("text")
        } else {
            setViewPassword("password")
        }
    }

    /* show confirm password */
    const showCPassword = () => {
        if (viewCPassword == "password") {
            setViewCPassword("text")
        } else {
            setViewCPassword("password")
        }
    }

    /* update school name and school address */
    const getSelectedValue = (item) => {
        if (item != null) {
            setUserDetails(userDetails => ({
                ...userDetails,
                schoolname: item.value,
            }))
        }

        if (item != null) {
            let selectSchoolAddress = schoolNameList.filter(i => i.school_name === item.value)

            if (selectSchoolAddress.length <= 0) {
                setUserDetails(userDetails => ({
                    ...userDetails,
                    schooladd: '',
                }))
            } else {
                setUserDetails(userDetails => ({
                    ...userDetails,
                    schooladd: selectSchoolAddress[0].school_address,
                }))
            }
        } else {
            setUserDetails(userDetails => ({
                ...userDetails,
                schooladd: '',
            }))
        }
    }

    /* state update School name  */
    const schoolNameChange = (item) => {
        setUserDetails(userDetails => ({
            ...userDetails,
            schoolname: item,
        }))
    }

    /* otp verification modal close  */
    const closeOtpVerificationModal = () => {
        dispatch(recordExistsSuccess(null));
        setOtpVerificationModal(false)
        setUserDetails(userDetails => ({
            ...userDetails,
            // fname: '',
            // lname: '',
            // dob: '',
            // gender: '',
            // address: '',
            // pincode: '',
            // mobile: '',
            // email: '',
            password: '',
            cpassword: '',
            // std: '',
            // board: '',
            schoolname: '',
            schooladd: '',
            motp: '',
            eotp: '',
        }));
        // setUserdob('')
        // setOnlySchoolName([]);
        // dispatch(getSchoolListFailureAction([]));

        setShowResendOtp(false)
        setVerifyButtonShow(true)

        exitFullscreen();
        history.push({ pathname: "/dashboard" })

    }

    /* confirmation before Exam submit  */
    const submitFinalAnswerWithAlert = () => {
        swal({
            title: "Do you want to submit?",
            text:
                "",
            icon: "warning",
            buttons: true,
            dangerMode: false,
        }).then((willDelete) => {
            if (willDelete) {
                setIsSubmit(1)
                submitFinalAnswer();
            }
        })
    }

    /* confirmation before back to Dashboard  */
    const gobackDashboardWithAlert = () => {
        swal({
            title: "Do you want to go back to dashboard?",
            text:
                "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                backToDashboard();
            }
        })
    }

    /* Dob change  */
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const handleMouseOver = () => {
        setInfoBox(true)

    };

    const handleMouseOut = () => {
        setInfoBox(false)
    };


    return (
        <>

            <div className="body_container ">
                <div className="row">
                    <div className="exam_start_top_container background_blue">
                        <div className="col-md-2">
                            <img
                                src={co_logo}
                                className="exam_start_logo"
                                alt="profile"
                            />
                        </div>

                        <div className="col-md-4 subject_name_container">
                            <div>
                                <h5>Competitive {`>`} {isDemo == 0 ? `Guest` : `Registered`} Demo Exam</h5>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="right_side_top_container background_blue">
                                <div className="right_indidual_contaoiner">

                                    <div className="timer_ounter">
                                        {showQuestion ?
                                            <>
                                                <CounterClockComponent examTime={props.scholasticQuestionList[0].exam_duration} isPlaying={isPlaying} />

                                            </>
                                            :
                                            <div className="clock_icon">
                                                <i className="mdi mdi-clock"></i>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className="right_indidual_contaoiner">
                                    <Link to="#" className="link_container">
                                        <div className="fullScreen" onClick={() => fullScreeTtoggleChange()}>
                                            {isFullScreen ? <i className="bi bi-arrows-fullscreen text_blue"></i> : <i className="bi bi-fullscreen-exit text_blue"></i>}

                                        </div>
                                        <div className="icon_description text_blue">
                                            Full Screen
                                        </div>
                                    </Link>
                                </div>

                                <div className="right_indidual_contaoiner">
                                    <Link to="#" className="link_container">
                                        <div className="image-container" onClick={() => setInformationModal(true)}>
                                            <img
                                                src={instruction}
                                                className="right_side_icon_size"
                                                alt="CreaTest"
                                            />
                                        </div>
                                        <div className="icon_description text_blue">
                                            Instruction
                                        </div>
                                    </Link>
                                </div>

                                <Link to="#" className="link_container" onClick={() => gobackDashboardWithAlert()}>
                                    <div className="right_indidual_contaoiner">
                                        <div className="image-container">
                                            <img
                                                src={dashboard}
                                                className="right_side_icon_size"
                                                alt="CreaTest"
                                            />
                                        </div>
                                        <div className="icon_description text_blue">
                                            Dashboard
                                        </div>
                                    </div>
                                </Link>
                                <div className="right_indidual_contaoiner">
                                    <Link to="#" className="link_container">
                                        <div className="image-container">
                                            <img
                                                src={userImage}
                                                className="right_side_icon_size border_radious_50 userImageSize"
                                                alt="CreaTest"
                                            />
                                        </div>
                                        <div className="icon_description text_blue">
                                            {props.user_name.split(' ')[0]}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="answer_container_parent">
                    <div className="answer_container background_blue">
                        <div className="answer_top_container parent_container_new">
                            <div className="question_heading unselect_text precent_text">

                                {showQuestion ?
                                    (props.scholasticQuestionList.length <= 0 && props.scholasticQuestionList == "") ? null : <div dangerouslySetInnerHTML={{ __html: props.scholasticQuestionList[props.questionNo].question }}></div>
                                    : null}


                            </div>
                            <div class="handler"></div>

                            {showQuestion ?

                                props.scholasticQuestionList.length <= 0 ? null :
                                    <form className="checkbox_from_container" onSubmit={(e) => e.preventDefault()}>
                                        <div className="checkbox_container">
                                            <div className="container">
                                                <div className="checkbox_inside_container unselect_text">
                                                    <div className="row option_container">
                                                        {
                                                            Object.keys(props.scholasticQuestionList[props.questionNo].options[0]).map(([key, value]) => {

                                                                return (
                                                                    <React.Fragment key={key}>

                                                                        <div className="form-group col-md-12">
                                                                            <div className="form-check mb-2 modern-radio-container"
                                                                                onClick={() => { answerSetSelected(key) }}
                                                                            >
                                                                                {selected[props.questionNo] == key ?
                                                                                    <>
                                                                                        <div className="key_value">{key}{":"}</div>
                                                                                        <div className="radio-outer-circle unselected" >
                                                                                            <i className="mdi mdi-check check_mark"></i>
                                                                                        </div>

                                                                                        <div className="helper-text"><div dangerouslySetInnerHTML={{ __html: props.scholasticQuestionList[props.questionNo].options[0][key] }}></div>
                                                                                        </div>

                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="key_value">{key}{":"}</div>
                                                                                        <div className="radio-outer-circle unselected" >

                                                                                        </div>

                                                                                        <div className="helper-text"><div dangerouslySetInnerHTML={{ __html: props.scholasticQuestionList[props.questionNo].options[0][key] }}></div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                : null}
                            {/* </div> */}
                        </div>
                        <div className="display_question_number blue_background">

                            {(props.scholasticQuestionList.length <= 0 && props.scholasticQuestionList == "") ? null :
                                <>
                                    {showQuestion ?
                                        <div className="view_question_number_container">
                                            <div className="left_arrow_container">
                                                {props.questionNo === 0 ?
                                                    <div className="icon_container">
                                                        <div className="arrow_text text-muted">Pre</div>
                                                        <div><i className="bi bi-chevron-left text-warning text-muted"></i></div>
                                                    </div>
                                                    :
                                                    <Link to="#">

                                                        <div className="icon_container" onClick={() => prevousQuestion(props.questionNo)}>
                                                            <div className="arrow_text">Pre</div>
                                                            <div><i className="bi bi-chevron-left text-warning"></i></div>
                                                        </div>
                                                    </Link>
                                                }
                                            </div>
                                            <div className="question_display">
                                                Displaying <span className="text-warning count_number">{props.questionNo + 1}</span> of <span className="text-warning count_number">{props.scholasticQuestionList.length}</span> Questions
                                            </div>

                                            <div className="right_arrow_container">
                                                {props.questionNo === props.scholasticQuestionList.length - 1 ?
                                                    <div className="icon_container">
                                                        <div><i className="bi bi-chevron-right text-warning text-muted" ></i></div>
                                                        <div className="arrow_text text-muted">Next</div>
                                                    </div>
                                                    :
                                                    <Link to="#">
                                                        <div className="icon_container" onClick={() => nextQuestion(props.questionNo)}>
                                                            <div><i className="bi bi-chevron-right text-warning" ></i></div>
                                                            <div className="arrow_text">Next</div>
                                                        </div>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                        : null}
                                </>
                            }
                        </div>
                    </div>
                    <div className="question_no_container background_blue">
                        <div className="select_question_number">
                            <div className="inside_select_question_number">
                                {showQuestion ?
                                    props.scholasticQuestionList.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Link to="#">
                                                    <div className={`question_number ${item.is_visited == 1 ? `blue_background` : item.is_answered == 1 ? `green_background_one ` : `orange_background`} ${index == props.questionNo ? `selectQuestionBox` : ''}`} onClick={() => questNumber(index)}>
                                                        <div className="que_number" >{index + 1}</div>
                                                    </div>
                                                </Link>
                                            </React.Fragment>
                                        )
                                    })

                                    : null
                                }
                            </div>

                        </div>
                        <div className="count_status_question">
                            <div className="count_container green_background_one">
                                <div className="count_number">{showQuestion ? is_answered_count : 0}</div>
                                <div className="count_status">Answered</div>
                            </div>
                            <div className="count_container blue_background">
                                <div className="count_number">{showQuestion ? is_visited_count : 0}</div>
                                <div className="count_status">Visited</div>
                            </div>
                            <div className="count_container orange_background">
                                <div className="count_number">{showQuestion ? (props.scholasticQuestionList.length - is_visited_count) : 0}</div>
                                <div className="count_status">Not Visited</div>
                            </div>
                        </div>
                        <div className="submit_container">
                            <Link to="#">

                                <div className="btn btn-warning blue_background text-center" onClick={submitFinalAnswerWithAlert}>Submit</div>
                            </Link>
                        </div>
                    </div>
                </div>

                <Modal className="fade" show={instructionExamModal} scrollable={true}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Basic instructions for online examinations:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ExamInstruction />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">
                                <Button
                                    variant="danger text-center modal_footer_button"
                                    onClick={() => history.goBack()}
                                >
                                    Quit exam
                                </Button>
                                <Button
                                    variant="success text-center modal_footer_button"
                                    onClick={startExam}
                                >
                                    Start exam
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal className="fade" show={informationModal} scrollable={true}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Basic instructions for online examinations:</Modal.Title>
                        <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setInformationModal(false)}
                        ></Button>
                    </Modal.Header>
                    <Modal.Body>
                        <ExamInstruction />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">
                                <Button
                                    variant="danger text-center modal_footer_button"
                                    onClick={() => setInformationModal(false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

                <Modal className="fade" show={isPlaying ? timeUpModal : false}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Timeup</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        Click on submit button to view your assessment
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">
                                <Button
                                    variant="warning text-center modal_footer_button"

                                    onClick={timeUpSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

                <Modal className="fade" show={registrationModal}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Register yourself to view your assessment report<br /><span className='font-w500 register_text'>(<span className='mandatory_color'>*</span>) Marked fields are mandatory to fill</span></Modal.Title>

                    </Modal.Header>
                    <Modal.Body>

                        <div className="col-lg-12">

                            <div className="basic-form registration">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <div className="row">
                                        <div className="col-sm-6 mb-2">
                                            <label>First name <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={fnameRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Enter first name"
                                                onChange={setdata}
                                                value={userDetails.fname}
                                                name="fname"
                                                autoComplete="off"
                                                // onKeyPress={utility.inputAlphabetOnly}
                                                onKeyPress={utility.inputAlphabetOnlyWithSpace}
                                            />
                                            {errors.fname && <div className="text-danger fs-12">{errors.fname}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Last name <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={lnameRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Enter last name"
                                                onChange={setdata}
                                                value={userDetails.lname}
                                                name="lname"
                                                autoComplete="off"
                                                // onKeyPress={utility.inputAlphabetOnly}
                                                onKeyPress={utility.inputAlphabetOnlyWithSpace}
                                            />
                                            {errors.lname && <div className="text-danger fs-12">{errors.lname}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Date of birth <span className="mandatory_color">*</span></label>
                                            <DatePicker
                                                className="form-control input_value"
                                                name="dob"
                                                selected={userdob}
                                                // onChange={(date) => setDobFormat(date)}
                                                // onChange={(date) => setUserdob(Date.parse(date))}
                                                onChange={(date) => setUserdob(date)}
                                                // minDate={new Date()} 
                                                maxDate={new Date()}
                                                placeholderText="DOB"
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown
                                                /* onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} */
                                                onChangeRaw={handleDateChangeRaw}
                                                preventOpenOnFocus={true}
                                            />
                                            {errors.userdob && <div className="text-danger fs-12">{errors.userdob}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Gender <span className="mandatory_color">*</span></label>
                                            <div className="form-control select_div_gender">
                                                <select
                                                    defaultValue={"option"}
                                                    className="select_option_gender input_value"
                                                    value={userDetails.gender}
                                                    onChange={setdata}
                                                    name="gender"
                                                >
                                                    <option color={'#ff0000'} value="">Select gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            {errors.gender && <div className="text-danger fs-12">{errors.gender}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Address <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={addressRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Enter your address"
                                                onChange={setdata}
                                                value={userDetails.address}
                                                name="address"
                                                autoComplete="off"
                                                onKeyPress={utility.addressSpecialCharacter}
                                            />
                                            {errors.address && <div className="text-danger fs-12">{errors.address}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Pin <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={pincodeRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Enter pin"
                                                onChange={setdata}
                                                value={userDetails.pincode}
                                                name="pincode"
                                                autoComplete="off"
                                                maxLength={6}
                                                onKeyPress={utility.inputNumberOnly}

                                            />
                                            {errors.pincode && <div className="text-danger fs-12">{errors.pincode}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Mobile <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={mobileRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Enter mobile no"
                                                onChange={setdata}
                                                value={userDetails.mobile}
                                                name="mobile"
                                                autoComplete="off"
                                                maxLength={10}
                                                onKeyPress={utility.inputNumberOnly}
                                            />
                                            {errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Email <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={emailRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Enter email ID"
                                                onChange={setdata}
                                                value={userDetails.email}
                                                name="email"
                                                autoComplete="off"
                                            />
                                            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2 eye_parent_container">

                                            <div className='d-flex justify-content-between align-items-center flex-row'>
                                                {infoBox ?
                                                    <div className='info_box'>
                                                        Minimum length of 6 characters and maximum length of at least 15 characters
                                                    </div>
                                                    : null}
                                                <div>
                                                    <label>Password <span className="mandatory_color">*</span></label>
                                                </div>
                                                <div
                                                    onMouseOver={handleMouseOver}
                                                    onMouseOut={handleMouseOut}
                                                >
                                                    <i className="bi bi-info-circle-fill blue_color"></i>
                                                </div>


                                            </div>
                                            <div className="view_password" onClick={() => showPassword()}><i className={viewPassword == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></div>
                                            <input
                                                ref={passwordRef}
                                                type={viewPassword}
                                                className="form-control input_value"
                                                placeholder="Password"
                                                onChange={setdata}
                                                value={userDetails.password}
                                                name="password"
                                                autoComplete="off"
                                                minLength={6}
                                                maxLength={15}
                                                onKeyDown={utility.whiteSpacenotAllow}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }} onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                            />
                                            {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2 eye_parent_container">
                                            <label>Confirm password <span className="mandatory_color">*</span></label>

                                            <input
                                                ref={cpasswordRef}
                                                type={viewCPassword}
                                                className="form-control input_value"
                                                placeholder="Confirm password"
                                                onChange={setdata}
                                                value={userDetails.cpassword}
                                                name="cpassword"
                                                autoComplete="off"
                                                minLength={6}
                                                maxLength={15}
                                                onKeyDown={utility.whiteSpacenotAllow}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }} onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                            />
                                            {errors.cpassword && <div className="text-danger fs-12">{errors.cpassword}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Standard <span className="mandatory_color">*</span></label>
                                            <div className="form-control select_div_standard">

                                                <select
                                                    ref={stdRef}
                                                    defaultValue={"option"}
                                                    className="select_option_gender input_value"
                                                    value={userDetails.std}
                                                    onChange={setdata}
                                                    name="std"
                                                >
                                                    <option value="">Select standard</option>
                                                    {
                                                        props.classStandardList.map((item, index) => {
                                                            return <option key={index} value={item.class_no}>{item.short_code}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.std && <div className="text-danger fs-12">{errors.std}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>Board <span className="mandatory_color">*</span></label>
                                            <div className="form-control select_div_board">

                                                <select
                                                    ref={boardRef}
                                                    defaultValue={"option"}
                                                    className="select_option_gender input_value"
                                                    value={userDetails.board}
                                                    onChange={setdata}
                                                    name="board"
                                                >
                                                    <option value={defalutValueBlank}>Select board</option>
                                                    {
                                                        props.boardList.map((item, index) => {
                                                            return <option key={index} value={[item.id, item.short_code, item.name]}>{item.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                        </div>

                                        <div className="col-sm-6 mb-2">
                                            <label>School Name <span className="mandatory_color">*</span></label>
                                            {/* {schoolList != '' ? */}
                                            {isShowSchoolList == 1 ?
                                                <>

                                                    <CreatableSelect
                                                        isClearable
                                                        onChange={getSelectedValue}
                                                        options={options} />

                                                </>
                                                :
                                                <>
                                                    {props.schoolNameShowingLoader ?
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        : null}
                                                    <input
                                                        ref={schoolnameRef}
                                                        type="text"
                                                        className="form-control input_value school_name"
                                                        placeholder="Enter school name"
                                                        onChange={setdata}
                                                        value={userDetails.schoolname}
                                                        name="schoolname"
                                                        autoComplete="off"
                                                        disabled
                                                    /></>
                                            }
                                            {errors.schoolname && <div className="text-danger fs-12">{errors.schoolname}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label>School Address <span className="mandatory_color">*</span></label>
                                            <input
                                                ref={schooladdRef}
                                                type="text"
                                                className="form-control input_value school_name"
                                                placeholder="Enter school address"
                                                onChange={setdata}
                                                value={userDetails.schooladd}
                                                name="schooladd"
                                                autoComplete="off"
                                                disabled={isShowSchoolList == 1 ? false : true}
                                            />
                                            {errors.schooladd && <div className="text-danger fs-12">{errors.schooladd}</div>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">

                                <Button
                                    variant="danger text-center modal_footer_button"
                                    // onClick={() => setRegistrationModal(false)}
                                    onClick={() => backToDashboard()}
                                >
                                    Close
                                </Button>
                                {/* </Link> */}
                                <Button
                                    variant="warning text-center modal_footer_button"

                                    // onClick={() => setRegistrationModal(false)}
                                    onClick={demouserRegister}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

                <Modal className="fade" show={otpVerificationModal}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Verify Your Contact Information</Modal.Title> {/* (M:{props.mOtp}, E: {props.eOtp}) */}
                        <div className="optLoader">
                            {/* {console.log("=======@@111=====", `M: ${utility.decryptAES(props.mOtp)}, E: ${utility.decryptAES(props.eOtp)}`) } */}
                            {props.mOtp != '' && props.eOtp != '' ?
                                <>
                                    <div className="timer-wrapper">
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={props.otpValidTime * 60}
                                            colors={["#004777", "#F7B801", "#ff0000", "#ff0000"]}
                                            // colorsTime={[10, 6, 3, 0]}
                                            colorsTime={[50, 50, 50, 0]}
                                            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                                            size={30}
                                            strokeWidth={3}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                    <div className="small_text">Seconds Remaining </div>
                                </>
                                : null}
                        </div>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-lg-12">

                            <div className="basic-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <div className="row">
                                        <div className="col-sm-6 mb-2">
                                            <label className="col-sm-6 col-form-label col-form-label-md">
                                                Mobile OTP
                                            </label>
                                            <input
                                                ref={mobileOtpRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Mobile OTP"
                                                onChange={setdata}
                                                value={userDetails.motp}
                                                name="motp"
                                                autocomplete="off"
                                                maxLength={6}
                                            />
                                            {errors.motp && <div className="text-danger fs-12">{errors.motp}</div>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label className="col-sm-6 col-form-label col-form-label-md">
                                                Email OTP
                                            </label>
                                            <input
                                                ref={emailOtpRef}
                                                type="text"
                                                className="form-control input_value"
                                                placeholder="Email OTP"
                                                onChange={setdata}
                                                value={userDetails.eotp}
                                                name="eotp"
                                                autocomplete="off"
                                                maxLength={6}
                                            />
                                            {errors.eotp && <div className="text-danger fs-12">{errors.eotp}</div>}
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">

                                <Button
                                    variant="danger text-center modal_footer_button"
                                    // onClick={() => setOtpVerificationModal(false)}
                                    onClick={() => closeOtpVerificationModal()}
                                >
                                    Close
                                </Button>
                                {verifyButtonShow ?
                                    <Button
                                        variant="warning text-center modal_footer_button"
                                        onClick={verifyOtp}
                                    >
                                        Verify
                                    </Button>
                                    : null}

                                {showResendOtp ?
                                    <Button
                                        variant="warning text-center modal_footer_button"
                                        onClick={resendOtp}
                                    >
                                        Resend OTP
                                    </Button>
                                    : null}
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <TermsCondition
                    termsAndConditionModal={termsAndConditionModal}
                    termsConditionSubmit={() => checkUserRecord()}
                    closeTermsConsition={() => SetTermsAndConditionModal(false)}
                />
            </div>
            <div>
                {props.showLoadingBranchScholastic ?
                    <Loader />
                    : null}
            </div>
            <div>
                {/* {alertSound == 1 ? */}
                {warningSound == 1 ?
                    <>
                        <CounterClockSoundComponent isPlaying={warningSound == 1 ? true : false} />
                    </>

                    : null}
            </div>
            <div><ToastContainer /></div>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        newuserId: state.student.newStudentid,
        eOtp: state.student.email_otp,
        mOtp: state.student.mobile_otp,
        otpValidTime: state.student.otpValidTime,
        user_id: state.auth.user_id,
        user_name: state.auth.fname,
        questionNo: state.questionNo.currentQestionNo,
        scholasticQuestionList: state.branch.scholasticQuestionList,
        showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
        classStandardList: state.standard.classStandardList,
        showLoading: state.board.showLoading,
        boardList: state.board.boardList,
        schoolList: state.board.schoolList,
        schoolNameShowingLoader: state.board.schoolNameShowingLoader
    };
};
export default connect(mapStateToProps)(DemoCompetitiveExamStart);