import React, { useEffect } from 'react'
import { connect, } from 'react-redux';

import "react-toastify/dist/ReactToastify.css";

//
import loginbg from '../../images/background-login.png'

import logofull from '../../images/clv_logo.png';


function ComingSoon(props) {


	useEffect(() => {

	}, []);



	return (
		<>
			<div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")", backgroundSize: 'cover' }}>
				<div className="login-wrapper">
					<div className="container h-100">
						<div className="row h-100 align-items-center justify-contain-center">
							<div className="col-xl-12 mt-3">
								<div className='d-flex jcc'>
									<img src={logofull} className="comming_soon_logo"></img>
								</div>
								<div className='mt-5'></div>
								<div className='mt-5'></div>
								<div className='mt-5'></div>
								<div className='d-flex jcc'>
									<h1>Coming Soon</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</>

	)
}

const mapStateToProps = (state) => {
	return {

	};
};
export default connect(mapStateToProps)(ComingSoon);