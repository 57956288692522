import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';
import { Link } from "react-router-dom";

import CategoryCard from '../../components/eLibrary/CategoryCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getExamTypeData } from '../../../store/actions/ExamTypeAction';

const CompetiTypeAssessment = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getExamTypeData(2, props.history));
	}, []);

	const selectType = (item) => {

		if (item.id == 1) {
			history.push({pathname:'/online-assessment-ntse-type', state:item})
		} else {
			history.push({ pathname: "/competitive-assessment-Select", state: { categoryId: item.type_name == 'NTSE' ? 2 : 3, exam_type: item.type_name, id: item.id, categoryId: 2 } })
		} 
	}

	return (
		<>

			<div className="row">
				<div className="demo-elibrary" >
					
					{props.typelist != '' ?

						props.typelist.map((item, index) => (
							<Link to="#" onClick={() => selectType(item)}>
								<CategoryCard
									key={index}
									// path={item.id == 1 ? '' : ''}
									icon={item.id == 1 ? ntse : nstse}
									title={item.type_name}
									description={item.sub_heading}
									bgColor={item.id == 1 ? '#4ba5c0' : '#5fc3e1'}
									leftColor={'#4ba5c0'}
									iconClass={'left_image'}
									iconClassRgt={'right_image'}
								/>
							</Link>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList
	};
};

export default connect(mapStateToProps)(CompetiTypeAssessment);