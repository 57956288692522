import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import { getTermsConditionDetails } from '../../store/actions/AuthActions';

const TermsCondition = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getTermsConditionDetails(props.history)); /* call terms & condition data */

    }, []);




    return (
        <>
            <Modal className="fade =" show={props.termsAndConditionModal} size='lg'>
                <Modal.Header>
                    <Modal.Title className="modal_title">User Agreement Review and Accept Our Terms and Conditions </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12 modal_body_height">
                        {props.termsCondition != '' ?
                            <>
                                <div dangerouslySetInnerHTML={{ __html: props.termsCondition }}></div>

                                <div className="modal_footer_right_container jcc aic mt-3">

                                    <Button
                                        variant="danger text-center modal_footer_button"
                                        onClick={() => props.closeTermsConsition()}
                                    >
                                        Reject
                                    </Button>

                                    <Button
                                        variant="warning text-center modal_footer_button"
                                        onClick={() => props.termsConditionSubmit()}
                                    // disabled={checked.length == 2 ? false : true}
                                    // disabled={checked.length == (isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock) ? false : true}
                                    >
                                        Accept
                                    </Button>

                                </div>
                            </>
                            :
                            <div className="spinner-view">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    //size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <div className="modal_footer_container_info">
                        <div className="modal_footer_left_container">
                            <p className="footer_text">Good Luck</p>
                            <p className="footer_text">Team Crestest</p>
                        </div>
                        <div className="modal_footer_right_container">

                            <Button
                                variant="danger text-center modal_footer_button"
                            // onClick={() => [setModuleMockChapterModal(false), setChecked('')]}
                            >
                                Close
                            </Button>

                            <Button
                                variant="warning text-center modal_footer_button"
                            // onClick={() => moduleMockExamSubmit()}
                            // disabled={checked.length == 2 ? false : true}
                            // disabled={checked.length == (isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock) ? false : true}
                            >
                                Submit
                            </Button>

                        </div>
                    </div> */}

                </Modal.Footer>
            </Modal>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        termsCondition: state.auth.termsCondition
    };
};
export default connect(mapStateToProps)(TermsCondition);