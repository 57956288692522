import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import {
	loadingToggleAction, loginAction, demoLogin,
} from '../../store/actions/AuthActions';
import Spinner from 'react-bootstrap/Spinner';
import * as utility from '../../utility/Utility';

import { ToastContainer, } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

//
import loginbg from '../../images/background-login.png'
import logo from '../../images/log2.png'
import logofull from '../../images/clv_logo.png';
import footerIcon from '../../images/policy-icon.png';
import loginLeftImage from '../../images/login_left_image.png';
import Moment from 'react-moment';
import PrivacyPolicy from './PrivacyPolicy';

// import ReactGA from 'react-ga'; // Add Google Analytics

function Login(props) {

	const [email, setEmail] = useState(''); 
	let errorsObj = { email: '', password: '', };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState(''); 
	const dispatch = useDispatch();
	const [guestshowLoading, setGuestshowLoading] = useState(false)
	const [rememberChecked, setRememberChecked] = useState(false)
	const [viewPassword, setViewPassword] = useState("password");
	const [viewCPassword, setViewCPassword] = useState("password");
	const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)

	var d = new Date();
	var CryptoJS = require("crypto-js");

	const [preLogin, setPreLogin] = useState(process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/api/" ? 1 : 1)
	let otperrorsObj = { otpError: '', };
	const [otpErrors, setOtpErrors] = useState(otperrorsObj);
	const [Otp, setOtp] = useState('')

	const OtpBeforeLoginScreen = useSelector(state => state.auth.OtpBeforeLoginScreen);


	useEffect(() => {

		if (process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/api/") {
			// dispatch(sendOtpBeforeLoginScreenDetails(props.history));
		}

		let getData = (localStorage.getItem('userDetails') != null);

		if (getData) {
			let token = JSON.parse(getData).token;
		} else {

		}

		let userId, userPassword;
		let getLoginData = localStorage.getItem('loginDetails');

		if (getLoginData == null) {
			setEmail('');
			setPassword('');
		} else {
			setRememberChecked(true)
			userId = JSON.parse(getLoginData).userEmail
			userPassword = JSON.parse(getLoginData).userPassword
			
			setEmail(utility.decryptAES(userId));
			setPassword(utility.decryptAES(userPassword));
		}
	}, []);

	// Add Google Analytics
	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	/* OTP submit with validation */
	const onSubmitOtp = (e) => {
		e.preventDefault();
		let error = false;

		const errorObj = { ...otperrorsObj };

		if (Otp == '' || Otp == undefined) {
			errorObj.otpError = 'Please Enter Otp';
			error = true;
		}

		setOtpErrors(errorObj);
		if (error) {
			return;
		}
		
		let decryptOtp = utility.decryptAES(OtpBeforeLoginScreen)

		if (Otp == decryptOtp) {
			setPreLogin(1)
			utility.showSuccess("Successfully Entered OTP");
		} else {
			utility.showError("Verify your OTP");
			// alert("Verify your OTP")
		}
		// setPreLogin(1)
	};

	/* on submit login data */
	function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(email, password, props.history));


		if (rememberChecked) {
			let userId, userPassword;
			let getLoginData = localStorage.getItem('loginDetails');
			if (getLoginData == null) {
				setEmail('');
				setPassword('');
			} else {
				setRememberChecked(true)
				userId = JSON.parse(getLoginData).userEmail
				userPassword = JSON.parse(getLoginData).userPassword
				if (userId == email) {
					setEmail(utility.decryptAES(userId));
					setPassword(utility.decryptAES(userPassword));
				} else {
					let userLoginDetails = {
						// userEmail: email,
						// userPassword: password
						userEmail: CryptoJS.AES.encrypt(email.toString(), "crestestexam987654321").toString(),
						userPassword: CryptoJS.AES.encrypt(password.toString(), "crestestexam987654321").toString(),
					};
					localStorage.setItem('loginDetails', JSON.stringify(userLoginDetails));
					setEmail(email);
					setPassword(password);
				}
			}
		} else {
			localStorage.removeItem('loginDetails');
		}
	}

	/* for demo login */
	const loginDemo = () => {
		setGuestshowLoading(true)
		dispatch(demoLogin(props.history));
	}

	/* check box login details remember */
	const rememberMe = (e) => {
		let userId, userPassword;
		if (e.target.checked) {
			setRememberChecked(true)
			let userLoginDetails = {
				// userEmail: email,
				// userPassword: password
				userEmail: CryptoJS.AES.encrypt(email.toString(), "crestestexam987654321").toString(),
				userPassword: CryptoJS.AES.encrypt(password.toString(), "crestestexam987654321").toString(),
			};
			// localStorage.setItem('loginDetails', JSON.stringify(userLoginDetails));
			localStorage.setItem('loginDetails', JSON.stringify(userLoginDetails));

		} else {
			setRememberChecked(false)
			localStorage.removeItem('loginDetails');
		}
	}

	/* show password */
	const showPassword = () => {
		if (viewPassword == "password") {
			setViewPassword("text")
		} else {
			setViewPassword("password")
		}
	}

	return (
		<>
			{preLogin == 0 ?
				<>
					<div className="authincation h-100 p-meddle">
						<div className="container h-100">

							<div className="row justify-content-center h-100 align-items-center">
								<div className="col-md-6">
									<div className="authincation-content">
										<div className="row no-gutters">
											<div className="col-xl-12">
												<div className="auth-form">
													<div className="text-center mb-3">
														<img src={logofull} alt="" />
													</div>
													{/* {console.log(">>>111>>", utility.decryptAES(OtpBeforeLoginScreen))} */}
													<h4 className="text-center">OTP Verification</h4>
													{/* <h6 className="text-center mb-4 ">we'll send you OTP.</h6> */}
													<form onSubmit={(e) => e.preventDefault()}>
														<div>
															<div className="form-group mb-4">
																<label className="">
																	<strong>OTP <span className="mandatory_color">*</span></strong>
																</label>
																<input
																	type="text"
																	className="form-control"
																	// defaultValue="hello@example.com"
																	placeholder="Enter OTP"
																	onChange={(e) => setOtp(e.target.value)}
																	value={Otp}
																	onKeyDown={utility.whiteSpacenotAllow}
																	name="otp"
																	maxLength={6}
																	onKeyPress={utility.inputNumberOnly}
																/>
																{otpErrors.otpError && <div className="text-danger fs-12">{otpErrors.otpError}</div>}
															</div>
															<div className="text-center dflex">

																<button
																	className="btn btn-primary btn-block text-center"
																	onClick={(e) => onSubmitOtp(e)}
																>
																	SUBMIT
																</button>

															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div><ToastContainer /></div>
				</>
				:
				<>
					<div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")", backgroundSize: 'cover' }}>
						<div className="login-wrapper">
							<div className="container h-100">
								<div className="row h-100 align-items-center justify-contain-center">
									<div className="col-xl-12 mt-3">
										<div className="card">
											<div className="card-body p-0">
												<div className="row m-0">
													<div className="col-xl-6 col-md-6 sign">
														{/* <div> */}
														<img src={loginLeftImage} className="education-img"></img>
														{/* </div> */}
														{/* <div>
													<div className="my-5 ms-4">
														<img width="180" src={logofull} alt="" />
													</div>
													<img src={logo} className="education-img"></img>
												</div> */}
													</div>
													<div className="col-xl-6 col-md-6">
														<div className="sign-in-your">
															<h4 className="fs-22 font-w600 text-pimary-c">Sign in your account</h4>
															<span className='font-w500 text-secondary-c'>Welcome back! Login with your data that you entered<br /> during registration.</span>

															{/* {props.errorMessage && (
																<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
																	{props.errorMessage}
																</div>
															)}
															{props.successMessage && (
																<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
																	{props.successMessage}
																</div>
															)} */}
															<form onSubmit={onLogin}>
																<div className="mb-3 mt-3">
																	<label htmlFor="email" name='Email' className="mb-1 text-secondary-c font-w500">Email</label>
																	<input name='login_id' type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
																	{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
																</div>
																<div className="mb-3 eye_parent_container">
																	<label htmlFor="password" name='password' className="mb-1 text-secondary-c font-w500">Password</label>
																	<div name="login_pwd_viewBtn" className="view_password_login" onClick={() => showPassword()}><i className={viewPassword == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></div>
																	<input type={viewPassword}
																		className="form-control"
																		name='login_pwd'
																		value={password}
																		onChange={(e) => setPassword(e.target.value)}
																		onKeyDown={utility.whiteSpacenotAllow}
																	/>
																	{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
																</div>
																<div className="row d-flex justify-content-between mt-3">
																	<div className="mb-2">
																		<div className="form-check custom-checkbox ms-1">
																			<input name="login_remember" type="checkbox" className="form-check-input" id="basic_checkbox_1" checked={rememberChecked} onChange={(e) => rememberMe(e)} />
																			<label htmlFor="RememberMyPreference" name='RememberMyPreference' className="form-check-label text-secondary-c font-w500 fs-14">Remember my preference</label>
																		</div>
																	</div>
																	<div className="mb-2">
																		<p className="fs-13"><span className='font-w500'>CRESTEST</span> web platform has a <span className='font-w500'>free demo version</span>, you can access the same without opening an account to get a feel of the best <span className="font-w500">online education</span> platform in India. To access <span className="font-w500">CRESTEST</span> guest login or <span className="font-w500">CRESTEST</span> demo platform – <span className="font-w700 text-secondary-c">CRESTEST guest login</span>.</p>
																	</div>
																	<div className={`mb-3 text-center ${process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/apiv2/" ? "subscription-disable" : null}`}> {/* subscription-disable */}
																	{/* <div className={`mb-3 text-center subscription-disable`}> */}
																		<span name="Signup_page" className="text-secondary-c font-w600 fs-15">Don't have an account? <Link to="./page-register" className="text-secondary-c cursorPointer select_text">Sign up</Link></span>
																	</div>

																</div>
																<div className="login_button_container">
																	<div className="singin_container">
																		{/* <button name="login_btn" className="btn btn-primary-c btn-block" autoFocus={true} onClick={onLogin}>Sign Me In<span className="ml-3"> */}
																		<button type="submit" name="login_btn" className="btn btn-primary-c btn-block" /* autoFocus={true} onClick={onLogin} */>Sign me in<span className="ml-3">
																			{props.showLoading ?
																				<Spinner
																					as="span"
																					animation="border"
																					size="sm"
																					role="status"
																					aria-hidden="true"
																				/>
																				: null}
																		</span>
																		</button>
																	</div>
																	<div className={`guest_login_container ${process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/apiv2/" ? "subscription-disable" : null}`}> 
																	{/* <div className={`guest_login_container subscription-disable`}>  */}
																		<div name="login_guest" className="btn btn-info btn_text" onClick={loginDemo} >CRESTEST guest login<span className="ml5 demo_spinner_position">
																			{guestshowLoading ?
																				<Spinner
																					as="span"
																					animation="border"
																					size="sm"
																					role="status"
																					aria-hidden="true"
																				/>
																				: null}
																		</span>
																		</div>
																	</div>

																</div>
																{/* <div className="text-center col-md-12">
															<button type="submit" className="btn btn-primary-c btn-block">Sign Me In <span className="ml-3">
																{props.showLoading ?
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>
																	: null}
															</span>
															</button>
														</div> */}
																{/* <div className={`mt-3 text-center ${process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/apiv2/" ? "subscription-disable" : null}`}>  */}
																<div className={`mt-3 text-center`}> 
																	<span name="login_forget_pwdpage" className="text-secondary-c font-w600 fs-15"><Link to="/page-forgot-password" className="text-secondary-c select_text">Forgot password</Link></span>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="text-center">
											{/* <p className="text-white mb-0 fs-12">©2022 Crestest Learning Ventures LLP. All Rights Reserved.</p> */}
											<p className="text-white mb-0 fs-12">{" "}{<Moment format="YYYY" withTitle>{d}</Moment>} Crestest Learning Ventures LLP. All rights reserved.</p>
											<p className="font-w500 mb-0 fs-13"><Link to="#" className="privacy-text select_text" onClick={() => setPrivacyPolicyModal(true)}>Privacy policy</Link> <img src={footerIcon} /></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<PrivacyPolicy
							privacyPolicyModal={privacyPolicyModal}
							closePrivacyPolicyModal={() => setPrivacyPolicyModal(false)}
						/>
					</div>

					<div><ToastContainer /></div>
				</>
			}
		</>

	)
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);