import React from 'react';
import { Link } from "react-router-dom";
import IconTint from 'react-icon-tint';
import logo from '../../../images/clv_logo.png';
import bashboard from '../../../images/dashboard.png';
import { useLocation, useHistory } from "react-router-dom"


export default function LibraryHeader(props) {
	const history = useHistory();
	return (
		<>
			<div className="lbray-header">
				<div className="lbray-lft-section">
					<Link onClick={() => history.goBack()}>
						<i className="fa-solid fa-angle-left"></i>
						<span>Back</span>
					</Link>
				</div>
				<div className="lbray-cntr-section">
					<img src={logo}></img>
				</div>
				<div className="lbray-rght-section">

					<div className="image-thum-item">
						<Link to="/">
						<img src={bashboard}></img>
						<span>Dashboard</span>
						</Link>
					</div>

				</div>
			</div>
		</>
	)
}