import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	Card,
	Tab,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Nav,
	Button
} from "react-bootstrap";

import { addToCartData, subscribeLoading, showRightNavAction, compititiveSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';

const SubcribeCompetitiveCard = (props) => {

	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const [isLibrary, setIsLibrary] = useState(props.data.has_library == 0 ? false : true);
	const [totalAmount, setTotalAmount] = useState(props.amount);

	const competitiveSubscriptionSourceData = useSelector(state => state.subscribe.competitiveSubscriptionSourceData);
	const competitiveSubscriptionSourceDataColl = useSelector(state => state.subscribe.competitiveSubscriptionSourceDataColl);

	useEffect(() => {
		// console.log("competitiveSubscriptionSourceDataColl----", competitiveSubscriptionSourceDataColl)

	}, [competitiveSubscriptionSourceDataColl]);

	useEffect(() => {

		if (props.cartList != '') {
			props.cartList.find(function (e) {
				if (e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.has_library == 1 && e.exam_type_id == props.type && e.only_elibrary == 0) {
					setIsLibrary(true)
					// setTotalAmount(totalAmount + props.library);
				} else {
					setIsLibrary(false)
					// 
				}
			})
		} else {
			setIsLibrary(false)
			setTotalAmount(props.amount);
		}
	}, [props.cartList]);

	/*  */

	const onProgress = () => {
		// console.log("isLibrary----", isLibrary)
		dispatch(subscribeLoading(true));
		dispatch(addToCartData(props.category_id, props.id, props.setCount, 0, 0, props.type, props.class_no ? props.class_no : 0, totalAmount, 0, 0, isLibrary ? 1 : 0, props.history));
		dispatch(showRightNavAction(true))
		setModal(false);
		if (!isLibrary) {
			dispatch(compititiveSubscriptionSourceAction([]))
		}
	}

	const onLibraryHandler = (e) => {
		// console.log("click-- onLibraryHandler")
		setIsLibrary(e.target.checked);
		if (e.target.checked == true) {
			setTotalAmount(totalAmount + props.library);
			props.competitiv_e_library_select_handaler(e.target.checked, props.id, props.type)
		} else {
			setTotalAmount(totalAmount - props.library);
			props.competitiv_e_library_unselect_handaler(e.target.checked, props.id, props.type)
		}
	}

	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					{/* exam_category_id, exam_type_id */}
					{/* <div className={`subcribe-competitive-card  ${props.cartList.length == 0 ? (props.isPurchased == 1 ? "subscription-disable" : null) : (props.isPurchased == 1 || props.cartList[0].subscription_id != props.id && props.cartList[0].exam_type_id == props.data.exam_type_id ? "subscription-disable" : null) }`}>  */}
					{/* <div className={`subcribe-competitive-card  ${props.cartList.length == 0 ? (props.isPurchased == 1 ? "subscription-disable" : null) : (props.isPurchased == 1 || props.cartList.find(x => x.exam_type_id === props.data.exam_type_id && x.exam_category_id === props.data.exam_category_id && x.subscription_id != props.id) ? "subscription-disable" : null)}`}> */}
					<div className={`subcribe-competitive-card  ${props.cartList.length == 0 ? (props.isPurchased == 1 ? "subscription-disable" : null) : (props.isPurchased == 1 || props.cartList.find(x => x.exam_type_id === props.data.exam_type_id && x.exam_category_id === props.data.exam_category_id && x.subscription_id != props.id && x.only_elibrary != 1) ? "subscription-disable" : null)}`}>


						{/* <div className="question-mark-circle">
							<i className="fa-sharp fa-solid fa-question"></i>
						</div> */}
						<div className="subcribe-competitive-details">
							{props.sticker_text != '' ?
								<div className='sticker_base'>
									<div className='sticker_text'>{props.sticker_text}</div>
								</div>
								: null}
							<h4>{props.setCount} Sets </h4> {/* <h6>(p.id:-{props.id})(eti:-{props.data.exam_type_id})</h6> */}
							<h4>{props.questionPerset * props.setCount} Questions</h4>
							<p>( {props.questionPerset} Questions per set )</p>

						{/* {console.log("competitiveSubscriptionSourceDataColl-------", competitiveSubscriptionSourceDataColl)} */}
							<div className={`set-elibrary-content ${props.data.disabled_library == 1 ? "subscription-disable" : null}`} >

								<div className="set-scholastic-content-lft">
									{/* {console.log("props.cartList---", props.cartList, "props.id--", props.id, "props.data.exam_type_id--", props.data.exam_type_id, "props.type--", props.type, "isLibrary---", isLibrary, "props--", props)} */}

									{/* {console.log("====111====", props.cartList.find(e => e.exam_type_id == props.data.exam_type_id && e.has_library == 1 && e.exam_type_id == props.type && e.exam_category_id == 2 ))} */}
									<div className="form-check custom-checkbox checkbox-success">
										<input
											type="checkbox"
											className="form-check-input"
											id={"e-library" + props.id}
											name={"e-library" + props.id}
											//value={props.library} isLibrary

											/* old Check--------------------- */
											/* checked={props.cartList != '' ? (props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.has_library == 1 && e.exam_type_id == props.type) ? true : competitiveSubscriptionSourceDataColl.exam_type == props.type && competitiveSubscriptionSourceDataColl.id != props.id ? false : true) : isLibrary} */
											/* old Check--------------------- */

											/* New Check--------------- */
											checked={props.cartList != '' ? (props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.has_library == 1 && e.exam_type_id == props.type && e.exam_category_id == 2 && e.only_elibrary == 0) ? true : props.cartList.find(e => e.only_elibrary == 1 && e.exam_type_id == props.type && e.exam_category_id == 2) && isLibrary ? false : isLibrary ? true : false) : isLibrary}
											/* New Check--------------- */

											onChange={onLibraryHandler}

											// disabled={props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id ? true : false)}
											// disabled={props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id  ? true : false)}

											/* old disable-------- */
											/* disabled={props.cartList != '' ? (props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.exam_type_id == props.type && e.exam_category_id == 2 ) ? true : props.cartList.find(e => e.only_elibrary == 1 ? true : false)) : props.selectSubId &&  props.selectSubId != props.id  && competitiveSubscriptionSourceDataColl.exam_type == props.type  ? true : false} */
											/* old disable-------- */

											/* new disable-------- */
											disabled={props.cartList != '' ? (props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.exam_type_id == props.type && e.exam_category_id == 2) ? true : props.cartList.find(e => e.only_elibrary == 1 && e.exam_category_id == 2 && e.exam_type_id == props.type ? true : props.selectSubId && props.selectSubId != props.id && competitiveSubscriptionSourceDataColl.exam_type == props.type ? true : false)) : props.selectSubId && props.selectSubId != props.id && competitiveSubscriptionSourceDataColl.exam_type == props.type ? true : false}
										/* new disable-------- */ // 

										// disabled={competitiveSubscriptionSourceData && props.selectSubId != props.id ? true : false} // competitiveSubscriptionSourceData &&
										/>
										<label
											className="form-check-label"
											htmlFor={"e-library" + props.id}
										>
											e-Library {/* {isLibrary ? "true" : "false"} */} {props.data.disabled_library == 1 ? <span className='small_text_left' >(Already purchased)</span> : null}
										</label>
									</div>

								</div>

								<div className="set-elibrary-content-rght">
									{isLibrary ?
										<p className=""><strike>Rs.{props.data.only_elibrary_price}/-</strike></p>
										: null}
								</div>

							</div>


						</div>



						<div className="subcribe-competitive-price-details">
							<div>

								<div>
									{/* {props.data.cart_amount == 0 ? */}
									{props.cartList != '' ? (props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.has_library == 1 && e.exam_type_id == props.type) ? <h4>Rs.{props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id && e.has_library == 1 && e.exam_type_id == props.type).cart_amount}/- </h4> : <h4>Rs.{totalAmount}/- </h4>) : <h4>Rs.{isLibrary ? totalAmount : props.amount}/- </h4>}
									{/* <h4>Rs.{totalAmount}/- </h4> */}
									{/* :
								<h4>Rs.{props.data.cart_amount}/-</h4>
							} */}
								</div>
								<div className='small_text_left '>(Including GST)</div>
							</div>

							<div className="displayNone">{props.exam_type_id}</div>
							{props.isPurchased == 1 ?
								null :
								<>
									{!props.cartList.find(element => element.subscription_id === props.id && element.exam_category_id == 2 && element.exam_type_id == props.exam_type_id && element.only_elibrary != 1) ?
										<Button
											onClick={() => setModal(true)}
										>Add to cart</Button>
										: <Button disabled>Add to cart</Button>
									}
								</>
							}
						</div>

					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-sm subscribe-modal"
				//size="sm"
				show={modal}
			>
				<Modal.Header>
					<Modal.Title>Cart Items</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!!props.data && props.data != undefined ?
						<p>Details : Set {props.setCount} {!!isLibrary ? " + " : null} {!!isLibrary ? "e-Library" : null}</p>
						: null}
					{/* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. */}

				</Modal.Body>
				<Modal.Footer>
					<div className="subscribe-modal-footer">
						<Button
							className="cancelBtn"
							onClick={() => setModal(false)}
						>
							Cancel
						</Button>
						<Button
							className="proceedBtn"
							onClick={onProgress}
						>Proceed</Button>
					</div>

				</Modal.Footer>
			</Modal>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	// console.log("cartList----", state.subscribe.cartList)
	return {
		showLoading: state.subscribe.showLoading,
		cartList: state.subscribe.cartList
	};
};
export default connect(mapStateToProps)(SubcribeCompetitiveCard);