import React from 'react';
import loginbg from '../../../images/background-login.png';
import LibraryHeader from '../../components/eLibrary/LibraryHeader'
import PdfView from "../../components/exam/PdfView"
import Moment from 'react-moment';

const ExamDemoLibrary = () => {

    var d = new Date();
    return (
        <>
            <div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")", backgroundSize: 'cover', minHeight: '100%'}}>
                <div className="login-wrapper">
                    <div className="exam-container">

                        <div className="exam-library-header">
                            <LibraryHeader />
                        </div>

                        <div className="exam-library-content">
                            <PdfView />
                        </div>

                        <div className="exam-library-footer">
                            <p>Copyright © Designed & Developed by Crestest Learning Ventures LLP {<Moment format="YYYY" withTitle>{d}</Moment>}</p>
                            {/* <p>Copyright © Designed & Developed by Crestest Learning Ventures LLP 2022</p> */}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ExamDemoLibrary;