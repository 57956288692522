
import {
    getEventHistoryList,
    likeEventHistory
} from '../../services/DashboardService';

import {
    logout,
} from '../actions/AuthActions';

import {
    DASHBOARD_HISTORY_LIST_SUCCESS,
    DASHBOARD_HISTORY_LIST_FAILURE,
    DASHBOARD_HISTORY_LIST_REQUEST,
    DASHBOARD_EVENT_HISTORY_LIKE
} from '../constants';

import * as utility from '../../utility/Utility';


/* get history Data */
export function getEventHistoryData(history) {
    return (dispatch) => {
        getEventHistoryList()
            .then((response) => {
                //console.log('response event history', response.data);
                if (response.data.status == 200) {
                    dispatch(getEventHistoryLikeAction(response.data.already_liked));
                    dispatch(getEventHistorySuccessAction(response.data.data));
                } else if (response.data.status == 410) {
                    dispatch(getEventHistoryFailureAction(response.data.data));
                    utility.showError(response.data.msg);
                    return;
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                } else {

                }

            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

/* Event history Like option */
export function likeEventHistoryData(event_id, history) {
    return (dispatch) => {
        likeEventHistory(event_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEventHistoryLikeAction(response.data.is_liked));
                    utility.showSuccess(response.data.msg);
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

/* store event history data in reducer */
export function getEventHistorySuccessAction(data) {
    return {
        type: DASHBOARD_HISTORY_LIST_SUCCESS,
        payload: data,
    };
}

/* store blank event history data in reducer */
export function getEventHistoryFailureAction(data) {
    return {
        type: DASHBOARD_HISTORY_LIST_FAILURE,
        payload: data,
    };
}

/* Store Event History Like Data */
export function getEventHistoryLikeAction(data) {
    return {
        type: DASHBOARD_EVENT_HISTORY_LIKE,
        payload: data,
    };
}
/* show event history loader */
export function getEventHistoryRequestAction(status) {
    return {
        type: DASHBOARD_HISTORY_LIST_REQUEST,
        payload: status,
    };
}