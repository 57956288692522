import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function registerNewStudent(fname, lname, dob, gender, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd, userdob, history) {

    const postData = {
        email: email,
        password: password,
        fname: fname,
        lname: lname,
        dob: userdob,
        mobile: mobile,
        profile_pic: '',
        gender: gender,
        address: address,
        pincode: pincode,
        standard: std,
        board: board,
        school_name: schoolname,
        school_address: schooladd,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REGISTER_NEW_STUDENT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

/* API call for verification OTP to user  */
export function otpSendForRegistration(mobile, email, history) {
    const postData = {
        mobile,
        email,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_SEND_VERIFICATION_OTP_TO_MOBILE_AND_EMAIL_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function checkStudentRecordExistInTheSystemOrNot(mobile, email, history) {
    const postData = {
        mobile,
        email,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_CHECK_STUDENT_RECORD_EXIST_IN_THE_SYSTEM_OR_NOT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function askQuestionData(search_text, subject_id) {
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        search_text,
        subject_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_SEARCH_TEXT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function registrationStatus(mobile, email, student_name, mobile_otp_status, email_otp_status, otp_timeout, standard, board, pincode, school_name) {
    const postData = {
        mobile,
        student_name,
        email,
        mobile_otp_status,
        email_otp_status,
        otp_timeout,
        standard, 
        board, 
        pincode, 
        school_name,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_STORE_OTP_VERIFCATION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}



