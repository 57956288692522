import React from 'react';

export default function ExamInstruction() {

    return (
        <>
            <p className="modal_body">The examination does not require using any paper, pen, pencil and calculator.</p>
            <p className="modal_body">Every student will take the examination on a Laptop/Desktop/Smart Phone.</p>
            <p className="modal_body">The Subjects or topics covered in the exam will be as per the Syllabus.</p>
            <p className="modal_body">In the online exam interface, the timer indicating the remaining time will be displayed on the right side for the test taker's convenience.</p>
            <p className="modal_body">After three attempts, the exam will conclude.</p>
            <p className="modal_body">In this exam, marks will be distributed as follows: one mark for Short Answer (SWA) questions, two marks for Higher Order Thinking Skills (HOTS) questions, and two marks for Descriptive questions.</p>
            <p className="modal_body">Visited questions will be marked in bluish cyan color, not visited questions in sky blue, and attempted questions in a green color for easy tracking during the exam.</p>
            <p className="modal_body">Please be aware that once the exam time is over, it will be automatically submitted.</p>
            <p className="modal_body">The participants are reminded to review their answers before concluding the exam. Best wishes for their performance.</p>
            <p className="modal_body">Please note that a reminder bell will ring five minutes before the end of the exam for review.</p>
            <p className="modal_body">Once the exam is submitted, the assessment will be available immediately.</p>
        </>
    )
}
