import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard25 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [showTableModal, setShowTableModal] = useState(false)
    const [board_name, setBoard_name] = useState('')
    const [standard, setStandard] = useState('')

    const lineChartDetails = {

        /* series: [{
            name: "Session Duration",
            data: [45, 52, 38, 24, 33, 26, 21,]
        },
        {
            name: "Page Views",
            data: [35, 41, 62, 42, 13, 18, 29,]
        },
        {
            name: 'Total Visits',
            data: [87, 57, 74, 99, 75, 38, 62,]
        }
        ], */
        series: [props.series],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [2, 2, 2],
                // curve: 'straight',
                curve: 'smooth',
                lineCap: 'butt',
                // dashArray: [0, 8, 5]
                dashArray: [0, 0, 0]
            },
            /* title: {
              text: 'Page Statistics',
              align: 'left'
            }, */
            legend: {
                show: false,
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                // categories: ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7'],
                categories: props.categories,
            },
            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val) {
                                return val + " (mins)"
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val + " per session"
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                ]
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        },


    };


    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);

    }, []);

    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }



    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        {lineChartDetails.options != '' && lineChartDetails.series != "" ?
                            <>
                                <div className="chart_body" >
                                    <ReactApexChart
                                        options={lineChartDetails.options}
                                        series={lineChartDetails.series}
                                        type="line"
                                        height={300}
                                    />
                                </div>
                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData} >Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex-1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                <div className='performer_table_heading'>{props.pageHeading}</div>
                                <p>e-library <i className="fa fa-angle-right assessment_icon_color"></i> {/* {board_name}: {standard}  <i className="fa fa-angle-right assessment_icon_color"></i> */} Session Time</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Subject</th>
                                        <th className="text-center light_sky">Session time <br /> (in mins.)</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {props.categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center light_gray_1">{item}</td>
                                                <td className="text-center light_gray_2">{props.series.data[index]}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard25);