import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";
import Loader from "../../components/Loader"

import LibraryCompetetiveCard from '../../components/ElibraryCard/LibraryCompetetiveCard';
import LibraryScholasticCard from '../../components/ElibraryCard/LibraryScholasticCard';
import { getCategoryData } from '../../../store/actions/ExamCategoryAction';
import { getExamTypeData } from '../../../store/actions/ExamTypeAction';
import { getClassStandardData } from '../../../store/actions/ClassStandardAction';
import { getBoardData } from '../../../store/actions/BoardActions';
import { getOnlyLibraryCmData, getOnlyLibraryScData, getScSubjectData, getCmSubjectData, subscribeLoading, removeSubjectListAction, removeLibraryListAction, eLibrarySelectSubjectsListAction, scholasticSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';



const TabLibrary = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [categoty, setCategory] = useState('');
	const [type, setType] = useState('');
	const [classname, setClassName] = useState('');
	const [board, setBoard] = useState('');
	const [isClass, setIsClass] = useState(true);
	const [defalutSelectValue, setDefalutSelectValue] = useState();

	const SubStatus = useSelector(state => state.subscribe.scSubscriptionStatus);
	const eLibrarySelectSubjectsList = useSelector(state => state.subscribe.eLibrarySelectSubjectsList);
	const subscriptionCheckBoxStatus = useSelector(state => state.subscribe.subscriptionCheckBoxStatus);
	const scholasticSubscriptionSourceData = useSelector(state => state.subscribe.scholasticSubscriptionSourceData);
	const competitiveSubscriptionSourceData = useSelector(state => state.subscribe.competitiveSubscriptionSourceData);

	const [elibrarySelectFromSubscription, setElibrarySelectFromSubscription] = useState([]);

	useEffect(() => {
		dispatch(getCategoryData(history));
		dispatch(getExamTypeData(2, history));
		dispatch(getClassStandardData(history));
		dispatch(getBoardData(history));

		/* if (SubStatus === 1) {
			// dispatch(subscribeLoading(true));
			if (type != '') {
				dispatch(getOnlyLibraryCmData(type, type == '1' ? 0 : classname, props.history));
			} else {
				dispatch(getOnlyLibraryScData(board, classname, props.history));
				setType('');
			}
		} */
	}, []); /* SubStatus */

	useEffect(() => {
		if (SubStatus === 1) {
			if (type != '') {
				dispatch(getOnlyLibraryCmData(type, type == '1' ? 0 : classname, history));
			} else {
				dispatch(getOnlyLibraryScData(board, classname, history));
				setType('');
			}
		}
	}, [SubStatus]);

	/* useEffect(() => {
		// console.log("useEffect--elibrarySelectFromSubscription---", elibrarySelectFromSubscription)
		// console.log("useEffect--eLibrarySelectSubjectsList---", eLibrarySelectSubjectsList)
		setElibrarySelectFromSubscription(eLibrarySelectSubjectsList)
	}, [eLibrarySelectSubjectsList]); */

	/* useEffect(() => {
		// console.log("subscriptionCheckBoxStatus----", subscriptionCheckBoxStatus)
		let newData = [];
		let obj = {};
		// console.log("@11-Taplibray===============", props.cartList)
		let onlyElibraryOrNot = props.cartList.filter(i => i.only_elibrary === 1);
		// console.log("onlyElibraryOrNot---", onlyElibraryOrNot)
		
		// console.log("scholasticSubscriptionSourceData--Lib--", scholasticSubscriptionSourceData)
		if (onlyElibraryOrNot != "" && onlyElibraryOrNot.length > 0) {
			console.log("@2")
			// console.log("subscriptionCheckBoxStatus----", props.cartList[0].only_elibrary)
			onlyElibraryOrNot.forEach(item => {
				item.combo_subject_ids.forEach(item2 => {
					// console.log("item2---", item2)
					obj = {}
					obj['subject_id'] = item2;
					obj['source'] = "elibrary";
					newData.push(obj)
				})
			});
			// let temp_data = scholasticSubscriptionSourceData.concat(newData);
			// dispatch(scholasticSubscriptionSourceAction(temp_data))
			
			let difference = scholasticSubscriptionSourceData.filter(x => x.source == 'elibrary');
			// console.log("difference----", difference)
			if (difference.length > 0) {
				console.log("@2--1")
				let filtered = scholasticSubscriptionSourceData.filter(i => i.source !== 'elibrary');
				// console.log("filtered----", filtered)
				let temp_data = filtered.concat(newData);
				// dispatch(scholasticSubscriptionSourceAction(temp_data))
			} else {
				console.log("@2--2")
				let temp_data = scholasticSubscriptionSourceData.concat(newData);
				// setSubjectSelectWithSource(temp_data)
				// dispatch(scholasticSubscriptionSourceAction(temp_data))
			}
		} else {
			console.log("@1")
			let difference = scholasticSubscriptionSourceData.filter(x => x.source == 'elibrary');
			// console.log("difference----", difference)
			if (difference.length > 0) {
				let filtered = scholasticSubscriptionSourceData.filter(i => i.source !== 'elibrary');
				// console.log("filtered----", filtered)
				let temp_data = filtered.concat(newData);
				dispatch(scholasticSubscriptionSourceAction(temp_data))
			} else {
				let temp_data = scholasticSubscriptionSourceData.concat(newData);
				// setSubjectSelectWithSource(temp_data)
				dispatch(scholasticSubscriptionSourceAction(temp_data))
			}
		}
		// } else {
		// 	console.log("e_library----", newData)
		// }
	}, [props.cartList]); */



	/* useEffect(() => {
		// console.log("subscriptionCheckBoxStatus----", subscriptionCheckBoxStatus)
		if (props.cartList != "") {
			let newData = [];
			let obj = {};
			props.cartList.forEach(item => {
				item.combo_subject_ids.forEach(item2 => {
					newData.push(item2)
				})
			});
			// console.log("T-library---", newData, eLibrarySelectSubjectsList)
			// let difference = eLibrarySelectSubjectsList.filter(x => !newData.includes(x));
			// console.log("difference----", difference)
			// setElibrarySelectFromSubscription(newData)	
			dispatch(eLibrarySelectSubjectsListAction(newData))
			dispatch(scholasticSubscriptionSourceAction(newData))

		} else {
			if (eLibrarySelectSubjectsList == '') {
				setElibrarySelectFromSubscription([])
			} else {
				dispatch(eLibrarySelectSubjectsListAction(eLibrarySelectSubjectsList))
			}
			if (props.cartList == '') {
				dispatch(eLibrarySelectSubjectsListAction([]))
			}
			// console.log("selectElibrarySubjectId----", eLibrarySelectSubjectsList)

		}
	}, [props.cartList]); */


	const onExamCategoryHandler = (event) => {
		// console.log("+++++++", event.target.value )
		setCategory(event.target.value);
		setBoard('');
		setType('');
		setClassName('');
		setIsClass(true);
		// dispatch(removeSubjectListAction());
		if (event.target.value == "Select Exam Category") {
			dispatch(removeLibraryListAction());
		} else if ((event.target.value == 2 || event.target.value == 1)) {
			dispatch(removeLibraryListAction());
		}
	}

	const onExamtypedHandler = (event) => {

		if (event.target.value != "Select Exam Type") {
			let is_class_value = props.typelist.filter(i => i.id == event.target.value)
			// setIsClass(event.target.value == '1' ? true : false);
			setIsClass(is_class_value[0].is_class == 0 ? true : false);
		}

		setType(event.target.value);
		setClassName('');

		if (event.target.value !== '2' && event.target.value !== '5' && event.target.value !== "Select Exam Type") {

			dispatch(subscribeLoading(true));
			dispatch(getOnlyLibraryCmData(event.target.value, 0, history));
		} else {

			dispatch(removeLibraryListAction());
		}
	}

	const onBoardHandler = (event) => {

		setBoard(event.target.value);
		setIsClass(event.target.value && event.target.value != "Select Board" ? false : true);
		setClassName('');
		// dispatch(removeSubjectListAction());
		if (event.target.value == "Select Board") {
			dispatch(removeLibraryListAction());
		}
	}

	const onClassHandler = (event) => {

		setClassName(event.target.value);
		if (categoty === '1' && event.target.value && event.target.value != "Select Class") {

			dispatch(subscribeLoading(true));
			dispatch(getOnlyLibraryScData(board, event.target.value != "Select Class" ? event.target.value : '', history));
		} else if (categoty === '2' && event.target.value && event.target.value != "Select Class") {

			dispatch(subscribeLoading(true));
			dispatch(getOnlyLibraryCmData(type, event.target.value != "Select Class" ? event.target.value : '', history));
		} else if (event.target.value == "Select Class") {
			dispatch(removeLibraryListAction());
		}
	}


	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					<div className="subscribe-dropdown-section">
						<div className="subscribe-basic-dropdown">
							<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
							{props.categorylist == "" || props.categorylist == undefined ?

								<select
									className="form-control form-control"
								>
									<option value={defalutSelectValue}>Select Exam Category</option>
								</select>

								:
								<select
									// defaultValue={"option"}
									className="form-control form-control"
									value={categoty}
									onChange={onExamCategoryHandler}
								>
									<option value={defalutSelectValue}>Select Exam Category</option>
									{
										props.categorylist.map((item, index) => {
											return <option key={index} value={item.id}>{item.category}</option>
										})
									}
								</select>
							}
						</div>
						{categoty === "2" ?
							<div className="subscribe-basic-dropdown">
								<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
								<select
									// defaultValue={"option"}
									className="form-control form-control"
									value={type}
									disabled={categoty ? false : true}
									onChange={onExamtypedHandler}
								>
									<option value={defalutSelectValue}>Select Exam Type</option>
									{
										props.typelist.map((item, index) => {
											return <option key={index} value={item.id}>{item.type_name}</option>
										})
									}

								</select>
							</div>
							:
							<div className="subscribe-basic-dropdown">

								<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
								<select
									// defaultValue={"option"}
									className="form-control form-control"
									value={board}
									disabled={categoty && categoty != "Select Exam Category" ? false : true}
									onChange={onBoardHandler}
								>
									<option value={defalutSelectValue}>Select Board</option>
									{
										<option value={props.board}>{props.board_name}</option>
									}

								</select>
							</div>
						}
						<div className="subscribe-basic-dropdown">
							<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
							<select
								// defaultValue={"option"}
								className="form-control form-control"
								value={classname}
								disabled={isClass}
								onChange={onClassHandler}
							>
								<option value={defalutSelectValue}>Select Class</option>
								{
									<option value={props.standard}>Class {props.standard}</option>
								}
							</select>
						</div>

					</div>
				</Col>
			</Row>
			{props.showLoading ?
				<>
					<Loader />
				</>
				: null}

			{
				categoty === "1" ?
					<Row>
						{/* {console.log("^^^^Library^^^^^^", scholasticSubscriptionSourceData)} */}
						{
							props.libraryScList.map((item, index) => {
								return <Col xl={4} key={index}>
									< LibraryScholasticCard
										key={item.id}
										id={item.id}
										subjectName={item.subject_name}
										// card_id={item.combo_elibrary_subject_id}
										card_id={item.combo_elibrary_subject_id}
										/* subject_id={elibrarySelectFromSubscription.filter(function (obj) {      
											return item.combo_elibrary_subject_id.indexOf(obj) !== -1;
										})} */
										/* subject_id={eLibrarySelectSubjectsList.map(obj => {
											//if (item.combo_elibrary_subject_id.includes(obj)) {
												// return obj;
											//}
											return obj;
										})} */
										// subject_id={scholasticSubscriptionSourceData.map(obj => {
										// 	//if (item.combo_elibrary_subject_id.includes(obj)) {
										// 	// return obj;
										// 	//}
										// 	return obj.subject_id;
										// })}
										
										subject_cards={scholasticSubscriptionSourceData}
										combo_subject_ids={item.combo_elibrary_subject_id}

										category_id={categoty}
										board={board}
										class_no={classname}
										library={item.library_price}
										isPurchased={item.is_purchased}
										sticker_text={item.sticker_text}
										subject_image={item.subject_image}
										// subscriptionSelectElibrary={eLibrarySelectSubjectsList}

									/>
								</Col>
							})
						}
					</Row>
					: categoty === "2" ?
						<Row>
							{
								props.libraryCmList.map((item, index) => {
									return <Col xl={4} key={index}>
										<LibraryCompetetiveCard
											key={item.id}
											id={item.id}
											category_id={categoty}
											type={type}
											typeName={item.type_name}
											class_no={classname}
											exam_type_id={item.exam_type_id}
											library={item.library_price}
											isPurchased={item.is_purchased}
											sticker_text={item.sticker_text}
											is_select_elibrary_from_subscription={competitiveSubscriptionSourceData}
										/>
									</Col>
								})
							}
						</Row>
						: null
			}


		</Fragment>
	)
}

const mapStateToProps = (state) => {
	// console.log(">>>>>>>>>---11--", state.subscribe.eLibraryCmList)
	// console.log("libraryScList-11----", state.subscribe.eLibraryScList,) 
	// console.log("state.subscribe.cartList----", state.subscribe.cartList,)
	return {
		standard: state.auth.standard,
		board: state.auth.board,
		board_name: state.auth.board_name,
		categorylist: state.category.examcategoryList,
		typelist: state.examtype.examTypeList,
		classlist: state.standard.classStandardList,
		boardlist: state.board.boardList,
		scsubjectlist: state.subscribe.scSubjectList,
		cmSubjectList: state.subscribe.cmSubjectList,
		libraryCmList: state.subscribe.eLibraryCmList,
		libraryScList: state.subscribe.eLibraryScList,
		showLoading: state.subscribe.showLoading,
		cartList: state.subscribe.cartList,
	};
};
export default connect(mapStateToProps)(TabLibrary);