import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	Tab,
	Nav,
	Button
} from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { getSubscriptionTextData, subscribeLoading, scholasticSubscriptionSourceAction, compititiveSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';


import TabExam from './TabExam';
import TabLibrary from './TabLibrary';
import TabClasses from './TabClasses';

const Subscribe = (props) => {

	const tabData = [
		{
			name: "Online Exam",
			icon: "digital-tachograph",
		},
		{
			name: "e-Library",
			icon: "icons",
		},
		{
			name: "Online Classes",
			icon: "chalkboard-teacher",
		},
	];


	const history = useHistory();
	const dispatch = useDispatch();


	const [board, setBoard] = useState('');
	const [standard, setStandard] = useState('');
	const [branch, setBranch] = useState('');
	const [chapter, setChapters] = useState('');

	const scholasticSubscriptionSourceData = useSelector(state => state.subscribe.scholasticSubscriptionSourceData);

	useEffect(() => {
		dispatch(subscribeLoading(true));
		dispatch(getSubscriptionTextData(props.history));
	}, []);

	useEffect(() => {

	}, [ props.cartList]);

	return (
		<Fragment>
			<div className="row">
                <div className="col-md-12 mb-3">
                    <div className='text_blue'>Subscription</div> 
                </div>
            </div>

			{props.scrollingText != '' ?
				<Row>
					
					<Col xl={12}>
						<div>
							<Marquee
								speed={100}
								gradient={false}
								style={{ fontSize: 16, color: '#fff', backgroundColor: '#2e637b' }}
								pauseOnHover={true}
							>
								{props.scrollingText}
							</Marquee>
						</div>
					</Col>
				</Row>
				: null}
			<Row>
				<Col xl={12}>
					<div className="subscribe-tab">
						<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
							<Nav as="ul" className="nav-tabs">
								{tabData.map((data, i) => (
									<Nav.Item as="li" key={i}>
										<Nav.Link eventKey={data.name.toLowerCase()}>
											<i className={`la la-${data.icon} me-2`} />

											{data.name}
										</Nav.Link>
									</Nav.Item>
								))}
							</Nav>

							<Tab.Content className="pt-4">

								<Tab.Pane eventKey="online exam">

									<TabExam />

								</Tab.Pane>

								<Tab.Pane eventKey="e-library">
									<TabLibrary />
								</Tab.Pane>

								<Tab.Pane eventKey="online classes">
									<TabClasses />
								</Tab.Pane>

							</Tab.Content>
						</Tab.Container>
					</div>
				</Col>
			</Row>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.subscribe.showLoading,
		scrollingText: state.subscribe.scrollingText,
		cartList: state.subscribe.cartList,
	};
};
export default connect(mapStateToProps)(Subscribe);