import React, { useState, useEffect, } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import Loader from "../../components/Loader";
import { elibraryGetsubjectListDetails, getElibraryContentRequest, elibraryGetLastsubjectListAction, getStoreElibraryVisitData } from '../../../store/actions/LibraryAction';
import { getChapterData, loadingChapterAction, getChapterAction } from '../../../store/actions/ChapterAction';
import { getOnlineConceptMapDetails, elibraryLoading, eliraryScholasticCategoryAction, elibraryListActivePageAction } from '../../../store/actions/LibraryAction';

import ReactPaginate from 'react-paginate';



const LibraryScholasticList = (props) => {


    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const isLastSubject = useSelector((state) => state.elibrary.isLastSubject);
    const eLitraryListActivePage = useSelector((state) => state.elibrary.eLitraryListActivePage);

    const [selectId, setSelectId] = useState('')
    const [subjectName, setSubjectName] = useState("")

    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(10)
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(10)
    // const [active, setActive] = useState(1);
    const [active, setActive] = useState(eLitraryListActivePage);

    const [setNo, setSetNo] = useState(0);
    const [groupSubjectId, setGroupSubjectId] = useState(0);
    const [resetFirstPage, setresetFirstPage] = useState({
        selected: 0,
    })


    useEffect(() => {
        dispatch(getElibraryContentRequest(true));
        dispatch(elibraryGetsubjectListDetails(1, '', props.history));
        // dispatch(eliraryShowCallIcon(1))
        setTotalPage(props.chaptercList.length / pageRangeDisplayed)
        setStart(0);
        // setEnd(props.chaptercList.length < 10 ? props.chaptercList.length : 10)
        setEnd(10)

        return () => {
            // dispatch(eliraryShowCallIcon(0))
            setSelectId('')
            dispatch(getChapterAction([]));
            // localStorage.removeItem('localstrageElibraryDetails');
            dispatch(elibraryGetLastsubjectListAction(0));
        };

    }, []);

    useEffect(() => {
        if (eLitraryListActivePage > 1) {
            setStart((((eLitraryListActivePage) - 1) * pageRangeDisplayed + 1) - 1)
            setEnd((eLitraryListActivePage) * pageRangeDisplayed)
        } else {
            setActive(1)
            setStart(0);
            setEnd(10)
        }
    }, [eLitraryListActivePage]);

    useEffect(() => {

        if (isLastSubject == 1) {
            let goPageDetails = localStorage.getItem('localstrageElibraryDetails');

            let selectId = JSON.parse(goPageDetails).selectId
            let subjectName = JSON.parse(goPageDetails).subjectName

            setSelectId(selectId)
            setSubjectName(subjectName)
            dispatch(loadingChapterAction(true));
            dispatch(getChapterData(selectId, '', 0, props.history));
        }

        return () => {

        };

    }, []);

/* select subject */
    const selectSubject = (id, subject_name) => {
        // setStart(0);
        // setEnd(10)
        dispatch(elibraryListActivePageAction(1))
        setSelectId(id)
        setSubjectName(subject_name)
        dispatch(loadingChapterAction(true));
        dispatch(getChapterData(id, '', 0, props.history));

    }
    /* on chapter select */
    const onChapterHandler = (item) => {
        // dispatch(getStoreElibraryVisitData(item.branch_id, item.short_code))
        dispatch(getStoreElibraryVisitData(item.subject_id, item.short_code))

        dispatch(elibraryLoading(true));
        dispatch(getOnlineConceptMapDetails(1, 0, item.id, item, props.history));
        dispatch(eliraryScholasticCategoryAction([item, "Scholastic", subjectName, selectId]));
        const elibraryDetails = {
            title: "",
            category: "Scholastic",
            item: item,
            subjectName: subjectName,
            selectId: selectId
        }
        localStorage.setItem('localstrageElibraryDetails', JSON.stringify(elibraryDetails));
    }

    /* pagination */
    const handlePageClick = (data) => {
        // console.log("data---121-", data, active)
        dispatch(elibraryListActivePageAction(data.selected + 1))
        setStart((((data.selected + 1) - 1) * pageRangeDisplayed + 1) - 1)
        setEnd((data.selected + 1) * pageRangeDisplayed)
        if (((data.selected + 1) * pageRangeDisplayed) > props.chaptercList.length) {
            setEnd(props.chaptercList.length);
        }
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className='text_blue'>e-library <i className="bi bi-chevron-right"></i> Scholastic List <i className="bi bi-chevron-right"></i> e-Library Scholastic List</div>
                </div>
                <div className='mt-3'></div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card students-list">
                        <div className="card-header border-0 flex-wrap pb-0">
                            <h4>Scholastic e-Library list</h4>
                        </div>
                        <div className='mt-5'></div>
                        <div className='flex-1 jcc aic fdr flex-wrap'>
                            {props.elibraryGetsubjectList.map((item, index) => (
                                item.library_exist == 1 ?
                                    <div className={`subject_button dflex jcc aic fww font-w500 fs-16 me-3 cursor-pointer ${item.subject_id == selectId ? `sub_button_select` : null}`} style={{ backgroundColor: item.subject_color_code }} onClick={() => selectSubject(item.subject_id, item.name)} key={index}> {/* sub_button_select */}
                                        {item.name}
                                    </div>
                                    :
                                    <div className='subject_button dflex jcc aic font-w500 fs-16 me-3 no_subscription' key={index}>
                                        {item.name}
                                    </div>
                            ))}

                        </div>
                        <div className='mt-5'></div>
                        {props.chaptercList != '' ?
                            <div className="card-body py-0">
                                <div className="table-responsive" >
                                    <div id="student_wrapper" className="dataTables_wrapper no-footer">
                                        <table className="table display mb-4 dataTablesCard order-table card-table text-black application " id="application-tbl1_next">
                                            <thead>
                                                <tr>
                                                    <th>Subject</th>
                                                    <th>Chapter no.</th>
                                                    <th>Chapter name</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.chaptercList.slice(start, end).map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="d-flex align-items-center e_library_image_container jcc aic" style={{ backgroundColor: item.subject_color_code }}>
                                                                {/* <img src={testIcon} alt="" /> */}
                                                                {item.elibrary_image != null && item.elibrary_image != '' && item.elibrary_image != "undefined" && item.elibrary_image != undefined ?
                                                                    <img src={item.elibrary_image} className='e_library_image' alt="" />
                                                                    :
                                                                    <div style={{ textAlign: "center" }}>No <br /> Image</div>
                                                                }

                                                                {/* <h4 className="mb-0 fs-16 font-w500">{item.title}</h4> */}

                                                            </div>
                                                        </td>
                                                        {/* <td>Chapter {index + 1}</td> */}
                                                        <td>Chapter {(start + index) + 1}</td>
                                                        <td>{item.sub_heading}</td>
                                                        <td>
                                                            <div className="d-flex">

                                                                <Link to="#" className="action_btn view_action_btn_color d-flex jcc aic"
                                                                    onClick={() => onChapterHandler(item)}
                                                                >
                                                                    <i className="fas fa-eye font_white"></i>
                                                                </Link>


                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>

                                        </table>

                                        <div className='mt-5'></div>
                                        <div className="pagination-container d-flex justify-content-between flex-row">
                                            <div className="page-result">
                                                {props.chaptercList.length < 11 ?
                                                    <p>{(start + 1) + '-' + props.chaptercList.length + ' of ' + props.chaptercList.length + ' chapters'}</p>
                                                    :
                                                    <p>{(start + 1) + '-' + end + ' of ' + props.chaptercList.length + ' chapters'}</p>
                                                }
                                                {/* <p>{(start + 1) + '-' + end + ' of ' + props.chaptercList.length + ' chapters'}</p> */}
                                            </div>

                                            {props.chaptercList.length > 10 ?
                                                < div >
                                                    <ReactPaginate
                                                        containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
                                                        pageClassName='page-item'
                                                        pageLinkClassName='page-link'
                                                        breakLabel="..."
                                                        nextLabel={<i className="la la-angle-right"></i>}
                                                        nextClassName='page-item'
                                                        nextLinkClassName='page-link'
                                                        previousLabel={<i className="la la-angle-left"></i>}
                                                        previousClassName='page-item'
                                                        previousLinkClassName='page-link'
                                                        breakClassName='page-item'
                                                        breakLinkClassName='page-link'
                                                        renderOnZeroPageCount={null}
                                                        activeClassName="active"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={3}
                                                        pageCount={Math.ceil(props.chaptercList.length / pageRangeDisplayed)}
                                                        // initialPage={active - 1}
                                                        forcePage={active - 1}
                                                    />
                                                </div>
                                                : null}
                                        </div>

                                        <div className='mt-5'></div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

            </div>

            <div>
                {props.showLoading || props.showLoadingChapter ?
                    <Loader />
                    : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        elibraryGetsubjectList: state.elibrary.elibraryGetsubjectList,
        showLoading: state.elibrary.loading,
        chaptercList: state.chapter.chapterList,
        showLoadingChapter: state.chapter.showLoadingChapter,
    };
};
export default connect(mapStateToProps)(LibraryScholasticList);