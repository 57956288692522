import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"
import { Link } from "react-router-dom";
import {
	Row,
	Col,
	Modal,
	Button,
	Spinner
} from "react-bootstrap";
import Loader from "../../components/Loader"


//Import Components
import ChartCard8 from './performanceComponent/ChartCard8';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'

import { getScholasticGetsubjectwiseChaptersDataDetails, performanceRequestAction, getscholasticGetsubjectwiseChaptersAction, scholasticGetsubjectwiseChaptersTableDataDetails, scholasticGetsubjectwiseChaptersTableDataAction } from '../../../store/actions/PerformanceScoreAction';

const SubjectwiseAnalysisOnSet = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const chartRef = useRef();

	const [previousValue, setPrevousValue] = useState(location.state)
	const [showTableModal, setShowTableModal] = useState(false)

	const [satDetails, setSatDetails] = useState()
	const [subjects, setSubjects] = useState([])
	const [averagePercentage, seAaveragePercentage] = useState()
	const scrollRef = useRef();
	const [listCompleted, setListCompleted] = useState(0)


	useLayoutEffect(() => {
		// console.log("location.state-------", location.state)
		// console.log("history-->", history)

		// console.log("previousValue-------", previousValue)
		dispatch(getscholasticGetsubjectwiseChaptersAction([]))
		dispatch(performanceRequestAction(true))
		// dispatch(getScholasticGetsubjectwiseChaptersDataDetails(previousValue.exam_type, props.history))
		dispatch(getScholasticGetsubjectwiseChaptersDataDetails(previousValue.exam_type, previousValue.group_subject_id, props.history))
		if (location.state.exam_type == 'undefined' || location.state.exam_type == undefined) {
			history.push({ pathname: "/overall-scholastic-performance-score" })
		}
		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
			dispatch(getscholasticGetsubjectwiseChaptersAction([]));
		};

	}, []);

	useEffect(() => {

		// console.log("###1---------", listCompleted, scrollRef.current)
		if (scrollRef.current != undefined && scrollRef.current != null) {
			scrollRef.current.scrollIntoView({ behavior: "smooth" });
		}

		return () => {
		};

	}, [subjects]);



	useEffect(() => {
		/* hoverBackgroundColor
		< 70% : '#982626', 70% - 80% > '#f1c431', 80% - 90% > '#5bba47', 90% - 100% > #31c5f4
		*/
		setSubjects([])
		// {console.log("props.scholasticGetsubjectwiseChaptersPiechart-----", props.scholasticGetsubjectwiseChaptersPiechart)}
		// {console.log("subjects-----", subjects)}
		if (props.scholasticGetsubjectwiseChaptersPiechart != '' && props.scholasticGetsubjectwiseChaptersPiechart != undefined) {

			let subjects = [];
			let dataVAlue = props.scholasticGetsubjectwiseChaptersPiechart
			Object.keys(dataVAlue).forEach(function (key) {
				// Object.keys(dd).forEach(function (key) {
				let labels = [];
				let vals = [];
				let chapters = [];
				let subject = []
				let percentage = [];
				let color = []
				let hoverBackground = []
				let firstKey = ''
				Object.keys(dataVAlue[key]).forEach(function (key2) {
					// console.log("key2----", key2)
					if (firstKey == '') {
						firstKey = key2
					}

					labels.push(key2);
					vals.push(dataVAlue[key][key2][0]);
					chapters.push(dataVAlue[key][key2][1]);
					subject.push(dataVAlue[key][key2][2]);

					if (dataVAlue[key][key2][0] < 70) {
						hoverBackground.push('#982626')
					} else if (dataVAlue[key][key2][0] >= 70 && dataVAlue[key][key2][0] < 80) {
						hoverBackground.push('#f1c431')
					} else if (dataVAlue[key][key2][0] >= 80 && dataVAlue[key][key2][0] < 90) {
						hoverBackground.push('#5bba47')
					} else if (dataVAlue[key][key2][0] >= 90) {
						hoverBackground.push('#31c5f4')
					}

				});

				// percentage.push(dataVAlue[key]['CH1'][3])
				// color.push((dataVAlue[key]['CH1'][4]))
				percentage.push(dataVAlue[key][firstKey][3])
				color.push((dataVAlue[key][firstKey][4]))

				let obj = {
					labelName: labels,
					value: vals,
					chapters: chapters,
					subject: subject,
					percentage: percentage,
					color: color,
					hoverBackground: hoverBackground,
				}
				subjects.push({
					[key]: obj
				})
			});

			// console.log("subjects-----=--", subjects);

			setSubjects(subjects)
			dispatch(performanceRequestAction(false))
			// scrollRef.current.scrollIntoView({ behavior: "smooth" });
		}


	}, [props.scholasticGetsubjectwiseChaptersPiechart, props.scholasticGetsubjectwiseChaptersTabledata,]);

	const showTableData = () => {
		// console.log("Show Table Data");
		setShowTableModal(true)
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}
	const scrollPosition = () => {
		scrollRef.current.scrollIntoView({ behavior: "smooth" });
	}

	const goPrevious = () => {
		// console.log("subjectwise-analysis-on-set-->", history)
		dispatch(getscholasticGetsubjectwiseChaptersAction([]));
		history.goBack()
	}
	const parentShowDetails = (subject, chapters, pageHeading, group_subject_id, group_name) => {
		// console.log("e-----------", subject, chapters, pageHeading, group_subject_id, group_name)
		/* history.push({
			pathname: "/chapterwise-analysis-on-chapter-test",
			state: { subject_id: subject, chapter_id: chapters, subject: pageHeading, group_subject_id: group_subject_id, group_name: group_name }
		}) */
		history.push({
			pathname: "/chapterwise-analysis-ctl",
			state: { subject_id: subject, chapter_id: chapters, subject: pageHeading, group_subject_id: group_subject_id, group_name: group_name }
		})

	}

	const parentScholasticChaptersTableDetails = (subjectName) => {
		// console.log("parentScholasticChaptersTableDetails---------------", subjectName)
		dispatch(scholasticGetsubjectwiseChaptersTableDataAction([]));
		dispatch(scholasticGetsubjectwiseChaptersTableDataDetails(subjectName, previousValue.exam_type, previousValue.group_subject_id, props.history))
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Online Exam Performance Score <i className="bi bi-chevron-right"></i> Scholastic Performance Score({previousValue && previousValue.group_name}) <i className="bi bi-chevron-right"></i> Overall Scholastic Performance Score <i className="bi bi-chevron-right"></i> Subjectwise Scholastic Score <i className="bi bi-chevron-right"></i> Subjectwise Analysis CTL</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>

			<Row>
				{/* {props.scholasticGetsubjectwiseChaptersPiechart != '' ? */}
				{
					subjects.map((item, index, subjects) => {
						// console.log("----------------------item", Object.keys(item))
						// console.log("----------------------item", Object.values(item))
						// console.log("----------------------item", item)
						return (
							<Col xl={6} key={index}>
								<ChartCard8
									pageHeading={Object.keys(item)[0]}
									highlight={previousValue.subName}
									// highlight={previousValue.subName}
									showTableData={() => showTableData()}
									labelName={item[Object.keys(item)].labelName}
									value={item[Object.keys(item)].value}
									chapters={item[Object.keys(item)].chapters}
									subject={item[Object.keys(item)].subject}
									totalMarks={item[Object.keys(item)].percentage[0]}
									tableData={props.scholasticGetsubjectwiseChaptersTabledata}
									chartColor={item[Object.keys(item)].color[0]}
									hoverBackground={item[Object.keys(item)].hoverBackground}
									// showChartData={showChartData}
									// reference={previousValue.subName == Object.keys(item)[0] ? scrollRef : null}
									reference={previousValue.subName == Object.keys(item)[0] ? scrollRef : null}
									group_subject_id={previousValue.group_subject_id}
									group_name={previousValue.group_name}
									show_details={parentShowDetails}
									table_details={parentScholasticChaptersTableDetails}
								/>
							</Col>
						)
					})
				}
			</Row>

			<div>
				{props.performanceLoader ?
					<Loader />
					: null}
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	// console.log("Check----", state.performance.scholasticGetsubjectwiseChaptersPiechart,)
	return {
		performanceLoader: state.performance.loading,
		scholasticGetsubjectwiseChaptersPiechart: state.performance.scholasticGetsubjectwiseChaptersPiechart,
		scholasticGetsubjectwiseChaptersTabledata: state.performance.scholasticGetsubjectwiseChaptersTabledata,
	};
};
export default connect(mapStateToProps)(SubjectwiseAnalysisOnSet);