import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ChapterBox from './ChapterBox'

const ChartCard24 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [showToolTips, setShowToolTips] = useState()
    const [height, setHeight] = useState(0)
    const [maxArrayLength, setMaxArrayLength] = useState([])
    const [subjectLength, setSubjectLength] = useState([])
    const [showTableModal, setShowTableModal] = useState(false)

    const messagesEndRef = useRef(null);
    const ref = useRef(null)
    /* const [chapterPosition, setChapterPosition] = useState([{
        "Math": [{
            "name": "Math",
            "data": [
                {
                    "name": "1",
                    "value": 7,
                },
                {
                    "name": "2",
                    "value": 8,
                }
            ]
        }],
        "Science": [{
            "name": "Phy",
            "data": [
                    {
                        "name": "1",
                        "value": 9,
                    },
                    {
                        "name": "2",
                        "value": 10,
                    }
                ]
            }, {
                "name": "Chem",
                "data": [
                    {
                        "name": "1",
                        "value": 8,
                    },
                    {
                        "name": "2",
                        "value": 9,
                    },
                    {
                        "name": "2",
                        "value": 10,
                    }
                ]
            },
        ],
        "History": [{
            "name": "Hist",
            "data": [
                {
                    "name": "1",
                    "value": 7,
                },
                {
                    "name": "2",
                    "value": 8,
                }
            ]
        }]
    }
    ]) */
    const [chapterPosition, setChapterPosition] = useState(props.dataSet)
    const [grapgDiv, setGrapgDiv] = useState("");

    ChartJS.register(ArcElement, Tooltip, Legend);

    useEffect(() => {
        // console.log("$$>>>>>>----", props.dataSet)
        // console.log("$$>>chapterPosition>>>>----", chapterPosition)
        createGraph();
    }, [showToolTips, maxArrayLength, props.dataSet]);

    useEffect(() => {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
    }, [grapgDiv, props.dataSet]);


    let dataLength = []
    let dataCount = []

    const createGraph = () => {
        Object.keys(props.dataSet).map((keyName, i) => {
            let blocks = props.dataSet[keyName];
            let graphDiv = Object.keys(blocks).map((keyName2, i) => {

                // console.log("+++++++++", blocks[keyName2].length);

                let _dataCount = {};
                _dataCount = blocks[keyName2].length
                dataCount.push(_dataCount)
                setSubjectLength(dataCount)

                let subBlock = [];
                for (let i = 0; i < blocks[keyName2].length; i++) {

                    // console.log(">>>>>>>>", blocks[keyName2][i].length);
                    let internalBlocks = [];

                    for (let j = 0; j < blocks[keyName2][i].data.length; j++) {
                        let mlength = {};
                        mlength = blocks[keyName2][i].data.length
                        dataLength.push(mlength)

                        internalBlocks.push(<ChapterBox baseColor={blocks[keyName2][i].data[j].value} name={blocks[keyName2][i].data[j].name} chapter_name={blocks[keyName2][i].data[j].chapter_name} key={j} dataCount={subjectLength.reduce((partialSum, a) => partialSum + a, 0)} />);
                    }

                    setMaxArrayLength(Math.max(...dataLength))
                    subBlock.push(<div className='col_child_data' key={i} >
                        <div>
                            {internalBlocks}
                        </div>

                        <div className='subject_name'>
                            {blocks[keyName2][i].name}
                        </div>
                    </div>)

                }
                return (<div className='col_data' ref={ref} key={i} style={{ height: (25 * parseInt(maxArrayLength) + 40) > 320 ? (25 * parseInt(maxArrayLength) + 40) : 320 }}>
                    {subBlock}
                </div>
                )
            });
            setGrapgDiv(graphDiv);
        })

    }

    const showSetTableData = () => {
        // console.log("Show Table Data ----123");
        setShowTableModal(true)
    }

    const closeTableModal = () => {
        setShowTableModal(false)
    }

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>

                        <div className="chart_body chart_parent_container" ref={messagesEndRef} >
                            <>
                                {grapgDiv != '' ?
                                    grapgDiv
                                    :
                                    <div className='dflex flex1 jcc aic'>
                                        No Data Available
                                    </div>
                                }

                                {/* <div className='col_data' >
                                        <div className='col_child_data'>
                                            <div ref={messagesEndRef}>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Math
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='col_data'>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                            </div>
                                            <div className='subject_name'>
                                                Phy
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Chem
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Bio
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col_data'>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Geo
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                His
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                            </div>
                                            <div className='subject_name'>
                                                Civ
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                His
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col_data'>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Geo
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                His
                                            </div>
                                        </div>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                            </div>
                                            <div className='subject_name'>
                                                Civ
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col_data'>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Math
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col_data'>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Math
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col_data'>
                                        <div className='col_child_data'>
                                            <div>
                                                <ChapterBox baseColor={10} />
                                                <ChapterBox baseColor={9} />
                                                <ChapterBox baseColor={8} />
                                                <ChapterBox baseColor={7} />
                                            </div>
                                            <div className='subject_name'>
                                                Math
                                            </div>
                                        </div>
                                    </div> */}

                            </>

                        </div>


                        <div className="bottom_details">
                            {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                            {/* <div className="btn_details details_btn_color text_white mt-2 cursorPointer" onClick={showSetTableData}>Details</div> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                <div className='performer_table_heading'>{props.pageHeading}</div>
                                <p>Competitive  <i className="fa fa-angle-right assessment_icon_color"></i> {props.subType.subType}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Subject</th>
                                        <th className="text-center light_sky">Chapter</th>
                                        <th className="text-center light_sky">Chapter Name</th>
                                        <th className="text-center light_sky">Score</th>

                                    </tr>

                                </thead>
                                <tbody>
                                    {
                                        props.dataSet != '' ?
                                            Object.keys(props.dataSet).map((keyName, i) => {
                                                let blocks = props.dataSet[keyName];
                                                let div = [];
                                                Object.keys(blocks).map((keyName2, k) => {
                                                    let blocks_2 = blocks[keyName2];
                                                    Object.keys(blocks_2).map((keyName3, j) => {
                                                        let block_3 = blocks_2[keyName3];
                                                        Object.keys(block_3.data).map((keyName4, o) => {
                                                            div.push(
                                                                <React.Fragment key={o}>
                                                                    <tr>
                                                                        <td className="text-center light_gray_1">{blocks_2[keyName3].name}</td>
                                                                        <td className="text-center light_gray_1">Chapter-{blocks_2[keyName3].data[o].name}</td>
                                                                        <td className="text-center light_gray_1">{blocks_2[keyName3].data[o].chapter_name}</td>
                                                                        <td className="text-center light_gray_1">{blocks_2[keyName3].data[o].value}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })

                                                    })
                                                })
                                                return div;
                                            })
                                            : null}
                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>


        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard24);