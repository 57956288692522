class GlobalConfigs 
{
  get VERSION()
  {
    return "1";
  }
  get IMAGE_URL()
  {
    // return "http://api.schemaphic.co.in:3000/api/";//DEV
    return "https://api.schemaphic.co.in";//DEV
  }
  get API_URL()
  {
    return process.env.REACT_APP_APIHOSTURL
    // return "http://localhost:4000/api/";//DEV
  }
  get PDF_URL()
  {
    return process.env.REACT_APP_PDFURL
  }

  get PAYMENT_URL()
  {
    return process.env.REACT_APP_LMS_PAYMENT_GATEWAY_URL
  }
  get TINY_API_KEY()
  {
    // return "ekszj85znvdkwzkbkyomgno4xolufwzrgeevmljyyslabsug"
    return "nuefiqw42n5kbrb4b3i6sodvu6uujdip0uzoursaq0fnkmkd"
  }
}

const globalConfigs = new GlobalConfigs();

export default globalConfigs;
