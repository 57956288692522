import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard17SetCSS = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    // const [labels, setLabels] = useState(['Ch1', 'Ch2', 'Ch3', 'Ch4', 'Ch5', 'Ch6', 'Ch7', 'Ch8', 'Ch9', 'Ch10', 'Ch11', 'Ch12', 'Ch13', 'Ch14', 'Ch15', 'Ch16', 'Ch17'])
    // const [chartData, setChartData] = useState([60, 60, 70, 70, 80, 80, 80, 90,])
    const [backgroundColor, setBackgroundColor] = useState(['#CBF1FA',])

    const [barGraphDetailsData, setBarChartsData] = useState([])
    const [categories, setCategories] = useState([])
    const [tableData, setTableData] = useState([props.tableData])
    const [typeOf, setTypeOf] = useState(props.type_of)

    const [overallAvg, setOverallAvg] = useState(0)
    const chartRef = useRef();

    const [dataSets, setDataSets] = useState(props.allDdataValue)
    const [seriesData, setSeriesData] = useState()

    // const [tableData, setTableData] = useState(props.tableData)
    const [filterTableData, setFilterTableData] = useState([])
    const [board_name, setBoard_name] = useState('')
    const [standard, setStandard] = useState('')
    const [showTableModal, setShowTableModal] = useState(false)

    ChartJS.register(ArcElement, Tooltip, Legend);

    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        // let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);
        // setFilterTableData([tableData[0][parseInt(props.testNo)]])
    }, []);
    // }, [tableData, filterTableData,]);

    useEffect(() => {

        const barGraphDetailsData = [
            /* {
                name: "Correct SWA",
                data: [],
            },
            {
                name: "Correct HOTS",
                data: [],
            },
            {
                name: "Correct DES",
                data: [],
            }, */
            {
                name: "Correct CSS",
                data: [],
            }
        ];


        let _categories = [];
        // Object.keys(tableData[0]).forEach(function (key) {
        //tifOptions.push(<option value={key}>{tifs[key]}</option>);
        //console.log(">>>>", tableData[0][key])
        // _categories.push(props.type_of + props.testNo);
        _categories.push(props.chapter_heading);

        // barGraphDetailsData[0].data.push(tableData[0][parseInt(props.testNo)]['SWA']['total_correct']);
        // barGraphDetailsData[1].data.push(tableData[0][parseInt(props.testNo)]['HOT']['total_correct']);
        // barGraphDetailsData[2].data.push(tableData[0][parseInt(props.testNo)]['DES']['total_correct']);
        barGraphDetailsData[0].data.push(props.tableData[0]['CSS']['total_correct']);
        // });

        /* Object.keys(tableData[0]).forEach(function (key) {
            //tifOptions.push(<option value={key}>{tifs[key]}</option>);
            //console.log(">>>>", tableData[0][key])
            _categories.push(props.type_of + key);
            barGraphDetailsData[0].data.push(tableData[0][key]['SWA']['total_correct']);
            barGraphDetailsData[1].data.push(tableData[0][key]['HOT']['total_correct']);
            barGraphDetailsData[2].data.push(tableData[0][key]['DES']['total_correct']);
        }); */

        /* Object.keys(props.tableData).forEach(function (key) {

            //tifOptions.push(<option value={key}>{tifs[key]}</option>);
            //console.log(">>>>", tableData[0][key])
            _categories.push(props.type_of + key);
            barGraphDetailsData[0].data.push(props.tableData[1]['SWA']['total_correct']);
            barGraphDetailsData[1].data.push(props.tableData[1]['HOT']['total_correct']);
            barGraphDetailsData[2].data.push(props.tableData[1]['DES']['total_correct']);
        }); */

        // console.log("=====================", barGraphDetailsData)

        setBarChartsData(barGraphDetailsData);
        setCategories(_categories);

    }, [tableData, props.tableData]);
    // }, [tableData, props.tableData]);


    const barData = {
        series: barGraphDetailsData,
        /* series: [
            {
                name: 'Correct SWA',
                data: [4,]
                // data: [44, 55, 41]
            }, 
             {
                name: 'Correct HOTS',
                data: [13]
                // data: [13, 23, 20]
            }, {
                name: 'Correct DES',
                data: [11]
                // data: [11, 17, 15]
            }
        ], */

        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: false,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    },
                    columnWidth: "30%",
                },
            },
            xaxis: {
                type: 'text',
                categories: categories,
                // categories: ["Set 1", "Set 2", "Set 3", "Set 4"]
            },
            /* yaxis: {
                min: 0,
                max: 100,
                tickAmount: 8,
            }, */
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'top',
                offsetY: 10,
                onItemClick: {
                    toggleDataSeries: false
                },
                /* labels: {
                    colors: ["#DD33DD"],
                    useSeriesColors: true
                }, */
            },
            fill: {
                type: ['solid'],
                // colors: [ "#FFCCFF"],
                // colors: ["#DD33DD"],
                opacity: 1
            },
            colors: ["#FFCCFF"]

        },

    };

    const showTableData = () => {
        // console.log("Show Table Data");
        // console.log("Show Table Data", [tableData[0][parseInt(props.testNo)]]);

        setShowTableModal(true)
        // setFilterTableData(tableData)
        // setFilterTableData([props.tableData])
    }

    const closeTableModal = () => {
        setShowTableModal(false)
    }

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading mb-3">
                            {props.type_of == 'Module' ? <><div className='chart_heading'>Activity Check - MOL</div></> : props.type_of == 'Mock' ? <><div className='chart_heading'>Activity Check - MCL</div></> : <><div className='chart_heading'>Activity Check - CTL</div></>}
                            {props.chapter_heading}</div>
                        <div className="chart_body" >
                            <ReactApexChart options={barData.options} series={barData.series} type="bar" height={280} />
                        </div>
                        <div className="bottom_details">
                            {/* <div className="btn_details back_btn_color text_black">Back</div> */}

                            {/* <div className="btn_details details_btn_color text_white">Details</div> */}

                            <div className="btn_details details_btn_color text_white cursorPointer" onClick={showTableData}>Details</div>

                        </div>
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                {/* <div className='performer_table_heading'>{props.type_of} Test Wise Score Comparison</div> */}
                                <div className='performer_table_heading'>{props.type_of == 'Module' ? <>Skill Scan MOL</> : props.type_of == 'Mock' ? <>Skill Scan MCL</> : <>Skill Scan CTL</>} </div>
                                <p>Scholastic <i className="fa fa-angle-right assessment_icon_color"></i> {board_name}: {standard} <i className="fa fa-angle-right assessment_icon_color"></i> {`Case Study `} <i className="fa fa-angle-right assessment_icon_color"></i> {props.table_heading} </p>
                            </div>
                            {/* <i className="fa fa-angle-right assessment_icon_color"></i> Polynomial</p> */}
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>

                        <div className='modal_body_container'>
                            <table cellPadding="5px" className="performance_analysis_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center"></th>
                                        {/* <th className="text-center light_gray_3" colSpan={2}>SWA</th> */}
                                        {/* <th className="text-center light_gray_3" colSpan={2}>HOT</th> */}
                                        {/* <th className="text-center light_gray_3" colSpan={2}>DES</th> */}
                                        <th className="text-center light_gray_3" colSpan={2}>CSS</th>
                                        <th className="text-center light_gray_3" colSpan={2}>Total</th>
                                    </tr>
                                    <tr>
                                        <th className="text-center"></th>
                                        {/* <th className="text-center light_blue">CORRECT</th> */}
                                        {/* <th className="text-center light_sky2">MARKS</th> */}
                                        {/* <th className="text-center light_green2">CORRECT</th> */}
                                        {/* <th className="text-center light_sky2">MARKS</th> */}
                                        {/* <th className="text-center light_yellow1">CORRECT</th> */}
                                        {/* <th className="text-center light_sky2">MARKS</th> */}
                                        <th className="text-center light_yellow1">Correct</th>
                                        <th className="text-center light_sky2">Marks</th>
                                        <th className="text-center light_red3">Correct</th>
                                        <th className="text-center light_sky2">Marks</th>
                                    </tr>

                                </thead>
                                <tbody>

                                    <tr>
                                        <td className="text-center light_red2">{`Case Study  ${props.table_heading}`} </td>

                                        <td className="text-center light_gray_2">{props.tableData[0]['CSS']['total_correct']}</td>
                                        <td className="text-center light_gray_1">{props.tableData[0]['CSS']['total_marks']}</td>
                                        <td className="text-center light_gray_2">{props.tableData[0]['total']['total_correct']}</td>
                                        <td className="text-center light_gray_1">{props.tableData[0]['total']['total_marks']}</td>
                                    </tr>

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}


const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard17SetCSS);