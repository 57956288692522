import React from 'react';
import {
    Spinner
} from "react-bootstrap";

export default function PerformanceLoader() {

    return (
        <>
            <div className="spinner_performance_container">
                <div className="spinner-view">
                    <Spinner
                        as="span"
                        animation="border"
                        //size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            </div>
        </>
    )
}
