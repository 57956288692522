import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';
import geo_genious from '../../../images/exam-type/geo_genious.png';
import nso from '../../../images/exam-type/nso.png';
import imo from '../../../images/exam-type/imo.png';

import CompetitivePerSubCard from '../../components/eLibrary/CompetitivePerSubCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getExamTypeData } from '../../../store/actions/ExamTypeAction';

const CompetitivePerformanceTypeLibrary = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getExamTypeData(2, props.history));
	}, []);

	const selectType = (item) => {
		const typeName = item.type_name.toUpperCase()
		history.push({ pathname: `/competitive-performance-score/${typeName}-perfomance-score`, state: { exam_type: item.type_name, id: item.id, image:item.image_path} })
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Competitive Performance Score <i className="bi bi-chevron-right"></i> Competitive Performance Score</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={() => history.goBack()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<div className="row">
				<div className="demo-elibrary" >
					{props.typelist != '' ?

						props.typelist.map((item, index) => (
							<div className={`margin_buttom_40 ${item.is_exam == 1 ? "cursorPointer" : null}`} onClick={() => item.is_exam == 1 ? selectType(item) : null} key={index}>
								<CompetitivePerSubCard
									// key={index}
									// path={item.id == 1 ? '' : ''}
									// icon={item.id == 1 ? ntse : ntse} //nstse
									// icon={item.id == 1 ? ntse : item.id == 2 ? nstse : item.id == 3 ? imo : item.id == 4 ? nso : item.id == 5 ? geo_genious :  null} //nstse
									icon={item.image_path} //nstse
									title={item.type_name}
									description={item.sub_heading}
									bgColor={item.id == 1 ? '#02879B' : '#02879B'}
									leftColor={'#02879B'}
									iconClass={'new_icon_left_image'}
									iconClassRgt={'new_icon_right_image'}
									subscribe={item.subscribe}
									is_exam={item.is_exam}
								/>
							</div>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList
	};
};

export default connect(mapStateToProps)(CompetitivePerformanceTypeLibrary);