import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { getOnlineCompetitiveSubscriptionDetailsData, getOnlineCompetitiveQuestionListData } from '../../store/actions/OnlineExamAction';
import CategorySetCardOne from '../components/exam/components/CategorySetCardOne';
import ntse from '../../images/exam-type/ntse.png';

const CompetitiveExamSelect = (props) => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [setlectSet, setSetlectSet] = useState('')
    const [setlectIndividualSet, setSetlectIndividualSet] = useState('')
    const [purchsedSet, setPurchsedSet] = useState('');

    useEffect(() => {

        dispatch(getOnlineCompetitiveSubscriptionDetailsData(previousValue.id, previousValue.exam_type, props.history))
    }, []);

    /* useEffect(() => {
        const setTotalData = [];
        var setText = null;
        for (let i = 0; i < props.satCount.no_set; i++) {
            let setText = {};
            setText['name'] = "ComSet " + (i + 1);
            setText['com_set_no'] = i + 1;
            setTotalData.push(setText);
        }
        setPurchsedSet(setTotalData)

        const setMatTotalData = [];
        var setText = null;
        for (let i = 0; i < props.matCount.no_set; i++) {
            let setText = {};
            setText['name'] = "ComSet " + (i + 1);
            setText['com_set_no'] = i + 1;
            setMatTotalData.push(setText);
        }
        setMatPurchsedSet(setMatTotalData)

    }, []); */

    const setSetlectSetData = (selectSet) => {

        setSetlectSet(selectSet)
        const setTotalData = [];
        var setText = null;
        for (let i = 0; i < selectSet.split(',')[1]; i++) {
            let setText = {};
            setText['name'] = "ComSet " + (i + 1);
            setText['com_set_no'] = i + 1;
            setTotalData.push(setText);
        }
        setPurchsedSet(setTotalData)

    }
    const setlectIndividualSetData = (select_data) => {
        // console.log("------**-setlectIndividualSetData--", )
        setSetlectIndividualSet(select_data)
        // dispatch(getOnlineCompetitiveQuestionListData(previousValue.exam_type, setlectSet.split(',')[0], select_data, previousValue.exam_type == "NSTSE" ? 0 : previousValue.id, props.history))
        // dispatch(getOnlineCompetitiveQuestionListData(previousValue.exam_type, setlectSet.split(',')[0], select_data, previousValue.exam_type == "NSTSE" ? 0 : previousValue.id, props.history))
        // dispatch(getOnlineCompetitiveQuestionListData(previousValue.exam_type, setlectSet.split(',')[0], props.competitiveSubscriptionDetails[0].current_exam_set, previousValue.exam_type != "NTSE" ? 0 : previousValue.id, props.history))
        history.push({ pathname: '/page-competitive-exam', state: { exam_type: previousValue.exam_type, subscription_id: setlectSet.split(',')[0], set_no: props.competitiveSubscriptionDetails[0].current_exam_set, subtype: previousValue.exam_type != "NTSE" ? 0 : previousValue.id, } });
    }
    /* exam_type, subscription_id, set_no, subtype */

    return (
        <>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Competitive Exams  <i className="bi bi-chevron-right"></i> Competitive Exam Select {location.state.id == 2 ? "(NSTSE)" : location.state.id == 3 ? "(IMO)" : location.state.id == 4 ? "(NSO)" : location.state.id == 4 ? "(GEO GENIOUS)" : null}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1">
                    <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="card_row" >
                    {props.competitiveSubscriptionDetails != '' && props.competitiveSubscriptionDetails != undefined ?

                        // props.ntseType.map((item, index) => (
                        <>
                            <CategorySetCardOne
                                // icon={ntse}
                                icon={location.state.image}
                                title={previousValue.exam_type}
                                description={previousValue.exam_type}
                                bgColor={'#02879B'}
                                leftColor={'#02879B'}
                                iconClass={'new_icon_left_image'}
                                iconClassRgt={'new_icon_right_image'}
                                satCount={props.competitiveSubscriptionDetails[0]}
                                goToExam={setlectIndividualSetData}

                            // matCount={props.competitiveSubscriptionDetailsMat[0]}
                            // id={1}
                            />
                        </>
                        // ))
                        :
                        <div className="exam_list_container" >
                            <div className="spinner-container">
                                <div className="spinner-view">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        //size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            
        </>
    )
}

const mapStateToProps = (state) => {
    
    return {
        competitiveSubscriptionDetails: state.onlineexam.competitiveSubscriptionDetails,
        user_id: state.auth.user_id,
        ntseType: state.examtype.ntseType
    };
};
export default connect(mapStateToProps)(CompetitiveExamSelect);