import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { connect, useDispatch, useSelector } from 'react-redux';
import logo from "../../images/clv_logo.png";
import {
  unlockScreenData, loadingToggleAction, loginAction
} from '../../store/actions/AuthActions';
import { ToastContainer } from 'react-toastify';


const LockScreen = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  let errorsObj = { password: '' };
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(errorsObj);
  const [userName, setUserName] = useState();

  useEffect(() => {

    let getData = localStorage.getItem('userDetails');
    // console.log("getData----------------", getData)
    let userNameFromLocalSTorage = JSON.parse(getData).email;

    setUserName(userNameFromLocalSTorage)
    localStorage.removeItem('userDetails');
    localStorage.removeItem('refreshExamId');
    localStorage.removeItem('localstrageElibraryDetails');

  }, []);

  const submitHandler = (e) => {

    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    // dispatch(unlockScreenData(password, props.history));
    dispatch(loginAction(userName, password, props.history));
    setPassword('');
  };


  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      {/* <Link to="/dashboard"> */}
                        <img src={logo} alt="" />
                      {/* </Link> */}
                    </div>
                    <h4 className="text-center mb-4 ">Account Security Alert: Access Denied : Password Locked</h4>
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group mb-3">
                        <label className="">
                          <strong>Password</strong>
                        </label>
                        <input className="form-control" type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn unlock-btn btn-block"
                        >
                          Unlock
                          {props.loader ?
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            : null}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div><ToastContainer /></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.auth.showLoading
  };
};
export default connect(mapStateToProps)(LockScreen);