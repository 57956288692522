import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Button, Modal } from "react-bootstrap";

import { getBoardData, loadingBoardAction } from '../../store/actions/BoardActions';
import { getClassStandardData, loadingClassStandardAction } from '../../store/actions/ClassStandardAction';
import { getBranchScholasticData, loadingBranchScholasticAction, } from '../../store/actions/ScholasticAction';
import { getChapterData, loadingChapterAction, getExamCompletedListData, getChapterAction } from '../../store/actions/ChapterAction';
import { getPurchasedSubjectData, purchasedSubjectsListRequestAction, getPurchasedGroupListData, getPurchasedSubjectslistScholasticData } from '../../store/actions/SubjectAction';
import { onlineRequestAction, getOnlineScholasticModuleQuestionListData, getOnlineScholasticMockQuestionListData, getscholasticexamsdetailsCasestudytData } from '../../store/actions/OnlineExamAction';

const ScholasticExam = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [board, setBoard] = useState('');
    const [standard, setStandard] = useState('');
    const [branch, setBranch] = useState('');
    const [branchSortCode, setBranchSortCode] = useState('');
    const [branchId, setBranchId] = useState('');

    const [subject, setSubject] = useState('');
    const [subscribSet, setSubscribSet] = useState('');
    const [chapter, setChapters] = useState('');
    const [purchsedSet, setPurchsedSet] = useState('');
    const [setlectSet, setSetlectSet] = useState('')
    const [module, setModule] = useState('')
    const [moduleDetails, setModuleDetails] = useState('')
    const [mock, setMock] = useState('')
    const [mockDetails, setMockDetails] = useState('')
    const [exam_type, setExam_type] = useState(0)
    const [set_no, setSet_no] = useState()
    const [module_current_no, setModule_current_no] = useState()
    const [mock_current_no, setMock_current_no] = useState()
    const [defalutSelectValue, setDefalutSelectValue] = useState('');
    const [defalutValue, setDefalutValue] = useState('option');
    const [moduleMockChapterModal, setModuleMockChapterModal] = useState(false);
    const [checked, setChecked] = useState([]);
    const [maxChapterSelect, setMaxChapterSelect] = useState(false)
    const [maxChapterSelectMock, setMaxChapterSelectMock] = useState(false)
    const checkedCount = Object.keys(checked).filter(key => checked[key]).length;
    const disabled = checkedCount > 1;
    const [chapterIdSelected, setChapterIdSelected] = useState([]);
    const [moduleMockChaterList, setModuleMockChaterList] = useState([]);
    const [isModuleOrMock, setIsModuleOrMock] = useState();

    const [caseStudy, setCaseStudy] = useState('');
    const [casestudy_current_no, setCasestudy_current_no] = useState('')

    const examCompletedChapterList = useSelector((state) => state.chapter.examCompletedChapterList);
    const callFirstTimeRef = useRef(true);

    const [moduleInterm, setModuleInterm] = useState();
    const [mockInterm, setMockInterm] = useState();

    const [groupSubject, setGroupSubject] = useState('');

    useEffect(() => {
        // let isMounted = true;
        if (callFirstTimeRef.current) {
            dispatch(getPurchasedGroupListData(props.history)) /* get purchased subject list data */
            dispatch(loadingBoardAction(true)); /* board loader */
            dispatch(getBoardData(props.history)); /* get board list data */
            dispatch(loadingClassStandardAction(true)); /* class loader */
            dispatch(getClassStandardData(props.history));/* get class list data */
            dispatch(loadingBranchScholasticAction(true)); /* get subject loader */
            dispatch(getBranchScholasticData(1, props.history)); /* get subject data */
            // dispatch(getPurchasedSubjectData(props.history))

        }
        callFirstTimeRef.current = false
        // return () => { isMounted = false };
        if (examCompletedChapterList != '') {
            setModuleMockChaterList(examCompletedChapterList) /* get Module / mock chapter list */
        }

    }, [examCompletedChapterList, maxChapterSelect, maxChapterSelectMock]);  //exam_type

    /* get chapter list */
    useEffect(() => {
        if (exam_type == 1 && set_no != '') { 
            setModuleMockChaterList([])
            dispatch(getExamCompletedListData(set_no, subject.split(',')[0], 1, exam_type, '', parseInt((groupSubject.split(',')[0])), props.history))
        } else if (exam_type == 2) {
            setModuleMockChaterList([])
            dispatch(getExamCompletedListData(0, subject.split(',')[0], 1, exam_type, '', parseInt(groupSubject.split(',')[0]), props.history))
        } else if (exam_type == 3) {
            setModuleMockChaterList([])
            dispatch(getExamCompletedListData(0, subject.split(',')[0], 1, exam_type, '', parseInt(groupSubject.split(',')[0]), props.history))
        } else if (exam_type == 4) {
            setModuleMockChaterList([])
            dispatch(getExamCompletedListData(0, subject.split(',')[0], 1, exam_type, '', parseInt(groupSubject.split(',')[0]), props.history))
        }

    }, [exam_type, set_no]);

    const showChapterlist = (branch_id) => {
        let splitBranchValue = branch_id.split(',');
        setBranch(branch_id);
        setBranchSortCode(splitBranchValue[1]);
        setBranchId(splitBranchValue[0])
        // dispatch(loadingChapterAction(true));
        // dispatch(getChapterData(splitBranchValue[0], props.history));
    }

    /* set data select */
    const setSetlectSetData = (set_data) => {

        // console.log("**>>>", set_data)
        setModule("")
        setMock("")
        // setExam_type(0)
        setExam_type(1)
        // setExam_type(set_data)----------to be check--------------
        let splitSetValue = set_data.split(',');
        setSetlectSet(splitSetValue);
        // setExam_type(1)
        setSet_no(set_data)

        if (subject != '') {
            dispatch(loadingChapterAction(true));
            dispatch(getChapterData(subject.split(',')[0], splitSetValue[0], parseInt(groupSubject.split(',')[0]), props.history)); /* get chapter data */
        }
    }

    /* select module data */
    const setSetlectModuleData = async (module_data) => {

        let subjectData = props.getpurchasedSubjectListAsPerGroupId.filter(i => i.subject_id === parseInt(subject.split(",")[0]))
        setSetlectSet("");
        setMock("")
        if (module_data == '') {
            setExam_type(0)
            setModuleMockChapterModal(false)
        } else {
            setExam_type(2)
            if (subjectData[0].module_interm == 0) {
                
                setModuleMockChapterModal(true)
                setModuleMockChaterList(props.examCompletedChapterList)
            } else {
                const selectChapterId = [];
                
                history.push({ pathname: '/page-scholastic-exam-moudle-mock', state: { isModuleOrMock: 1, exam_type: 2, branchSortCode: branchSortCode, chapter: 'CH0', set_no: module_data, subject_id: subject.split(',')[0], selectChapterId, group_subject_id: parseInt(groupSubject.split(',')[0]) } });
                const examDetails = {
                    isModuleOrMock: 1,
                    exam_type: 2,
                    branchSortCode: branchSortCode,
                    chapter: 'CH0',
                    set_no: module_data,
                    subject_id: subject.split(',')[0],
                    selectChapterId: selectChapterId,
                    group_subject_id: parseInt(groupSubject.split(',')[0])
                }
                localStorage.setItem('examConditionData', JSON.stringify(examDetails));
            }

        }
        setModule(module_data)
        setIsModuleOrMock(1)
    }

    /* select mock data */
    const setSetlectMockData = (mock_data) => {

        let subjectData = props.getpurchasedSubjectListAsPerGroupId.filter(i => i.subject_id === parseInt(subject.split(",")[0]))

        setModule("")
        setSetlectSet("");
        if (mock_data == '') {
            setExam_type(0)
            setModuleMockChapterModal(false)
        } else {
            setExam_type(3)
            if (subjectData[0].mock_interm == 0) {
                setModuleMockChapterModal(true)
                setModuleMockChaterList(props.examCompletedChapterList)
            } else {
                const selectChapterId = [];
                
                history.push({ pathname: '/page-scholastic-exam-moudle-mock', state: { isModuleOrMock: 2, exam_type: 3, branchSortCode: branchSortCode, chapter: 'CH0', set_no: mock_data, subject_id: subject.split(',')[0], selectChapterId, group_subject_id: parseInt(groupSubject.split(',')[0]) } });

                const examDetails = {
                    isModuleOrMock: 2,
                    exam_type: 3,
                    branchSortCode: branchSortCode,
                    chapter: 'CH0',
                    set_no: mock_data,
                    subject_id: subject.split(',')[0],
                    selectChapterId: selectChapterId,
                    group_subject_id: parseInt(groupSubject.split(',')[0])
                }
                localStorage.setItem('examConditionData', JSON.stringify(examDetails));

            }

        }
        setMock(mock_data)
        setIsModuleOrMock(2)
    }

    /* select case study  */
    const setCaseStudySelect = (caseStudy_data) => {
        
        history.push({ pathname: '/page-scholastic-exam-moudle-mock', state: { isModuleOrMock: 3, exam_type: 4, branchSortCode: branchSortCode, chapter: 'CH0', set_no: caseStudy_data, subject_id: subject.split(',')[0], group_subject_id: parseInt(groupSubject.split(',')[0]) } });

        const examDetails = {
            isModuleOrMock: 3,
            exam_type: 4,
            branchSortCode:
                branchSortCode,
            chapter: 'CH0',
            set_no: caseStudy_data,
            subject_id: subject.split(',')[0],
            group_subject_id: parseInt(groupSubject.split(',')[0])
        }
        localStorage.setItem('examConditionData', JSON.stringify(examDetails));

    }

    /* show module or mock data  */
    const moduleMockExamSubmit = () => {
        const selectChapterId = [];
        var objSelectChapterId = null;
        var objChapterId = null;

        checked.forEach((elm, i) => {
            let objSelectChapterId = {};
            let objChapterId = {};
            objSelectChapterId = moduleMockChaterList[elm]
            objChapterId = objSelectChapterId.chapter_id
            selectChapterId.push(objChapterId)
        })
        setChecked('')
        if (isModuleOrMock == 1) {
            history.push({ pathname: '/page-scholastic-exam-moudle-mock', state: { isModuleOrMock: 1, exam_type: 2, branchSortCode: branchSortCode, chapter: 'CH0', set_no: module, subject_id: subject.split(',')[0], selectChapterId, group_subject_id: parseInt(groupSubject.split(',')[0]) } });

            const examDetails = {
                isModuleOrMock: 1,
                exam_type: 2,
                branchSortCode: branchSortCode,
                chapter: 'CH0',
                set_no: module,
                subject_id: subject.split(',')[0],
                selectChapterId: selectChapterId,
                group_subject_id: parseInt(groupSubject.split(',')[0])
            }
            localStorage.setItem('examConditionData', JSON.stringify(examDetails));

        } else if (isModuleOrMock == 2) {
            history.push({ pathname: '/page-scholastic-exam-moudle-mock', state: { isModuleOrMock: 2, exam_type: 3, branchSortCode: branchSortCode, chapter: 'CH0', set_no: mock, subject_id: subject.split(',')[0], selectChapterId, group_subject_id: parseInt(groupSubject.split(',')[0]) } });

            const examDetails = {
                isModuleOrMock: 2,
                exam_type: 3,
                branchSortCode: branchSortCode,
                chapter: 'CH0',
                set_no: mock,
                subject_id: subject.split(',')[0],
                selectChapterId: selectChapterId,
                group_subject_id: parseInt(groupSubject.split(',')[0])
            }
            localStorage.setItem('examConditionData', JSON.stringify(examDetails));
            
        }
        
        setModuleMockChapterModal(false)
    }

    /* show subject list */
    const showSubjectListAsSubjectid = (value) => {
        setSubject('')
        dispatch(getChapterAction([]));
        setBranch('');
        setPurchsedSet('');
        setModuleDetails('');
        setMockDetails('');
        setSetlectSet('');
        setCaseStudy('')
        setSet_no('');

        const splitSubjectId = value.split(',');
        setGroupSubject(value);

        dispatch(purchasedSubjectsListRequestAction(true));
        dispatch(getPurchasedSubjectslistScholasticData(splitSubjectId[0], props.history))
        
    }

    /* create dropdown list */
    const showBranchListAgainstSubjectid = (subjectId) => {
        dispatch(getChapterAction([]));
        setBranch('');
        setPurchsedSet('');
        setModuleDetails('');
        setMockDetails('');
        setSetlectSet('');
        setCaseStudy('')
        setSet_no('');

        const splitSubjectId = subjectId.split(',');
        setSubject(subjectId);

        if (subjectId != '') {
            setMaxChapterSelect(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].max_chapter_select)
            setMaxChapterSelectMock(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].max_chapter_select_mock)

            const setTotalData = [];
            var setText = null;
            
            var testValue = props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].set_count

            testValue.forEach(el => {
                let setText = {};
                setText['name'] = "Test " + JSON.parse(el);
                setText['set_no'] = el;
                setTotalData.push(setText);
            })

            if (props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].casestudy_count > 0) {
                let caseStudyText = null;
                var caseStudyValue = [props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].casestudy_count]

                caseStudyValue.forEach(el => {
                    let caseStudyText = {};
                    
                    caseStudyText['name'] = "Case Studies ";
                    caseStudyText['set_no'] = 'cs1';
                    setTotalData.push(caseStudyText);
                })
            }

            
            setPurchsedSet(setTotalData)

            //case Study
            const caseStudyTotalData = [];
            var caseText = null;
            
            for (let i = 0; i < props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].casestudy_count; i++) {
                let caseText = {};
                caseText['name'] = "Case Study " + (i + 1);
                caseText['casestudy_no'] = i + 1;
                caseStudyTotalData.push(caseText);
            }
            setCaseStudy(caseStudyTotalData)
            
            setCasestudy_current_no(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].casestudy_current_no)
            setModuleInterm(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].module_interm)

            //moduleDetails
            const moduleData = [];
            let moduleText = null;
            // for (let i = 0; i < props.purchasedSubjectList[splitSubjectId[1]].module_count; i++) {
            for (let i = 0; i < props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].module_count; i++) {
                let moduleText = {};
                moduleText['name'] = "Module " + (i + 1);
                moduleText['module_no'] = i + 1;
                moduleData.push(moduleText);
            }
            setModuleDetails(moduleData)
            /* setModule_current_no(props.purchasedSubjectList[splitSubjectId[1]].module_current_no)
            setMockInterm(props.purchasedSubjectList[splitSubjectId[1]].mock_interm) */
            setModule_current_no(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].module_current_no)
            setMockInterm(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].mock_interm)
            //mockDetails
            const mockData = [];
            let mockText = null;
            // for (let i = 0; i < props.purchasedSubjectList[splitSubjectId[1]].mock_count; i++) {
            for (let i = 0; i < props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].mock_count; i++) {
                let mockText = {};
                mockText['name'] = "Mock " + (i + 1);
                mockText['mock_no'] = i + 1;
                mockData.push(mockText);
            }
            setMockDetails(mockData)
            // setMock_current_no(props.purchasedSubjectList[splitSubjectId[1]].mock_current_no)
            setMock_current_no(props.getpurchasedSubjectListAsPerGroupId[splitSubjectId[1]].mock_current_no)
        }
    }

    /* change mockmodule check data */
    const checkChange = (value, event) => {
        if (checked.indexOf(value) !== -1) {
            setChecked(checked.filter((checkBox) => checkBox !== value));
        } else {
            setChecked([...checked, value]);
        }
    };

    const callExamData = (branchSortCode, chapter, exam_type, setlectSet, subject_id, set_no,) => {
        // branchSortCode: item.branch, chapter: item.short_code, exam_type, setlectSet, subject_id: subject.split(',')[0], set_no: set_no
        const examDetails = {
            branchSortCode: branchSortCode,
            chapter: chapter,
            exam_type: exam_type,
            setlectSet: setlectSet,
            subject_id: subject_id,
            set_no: set_no,
        }
        localStorage.setItem('examConditionData', JSON.stringify(examDetails));
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Scholastic Exams</div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-1">
                    <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back
                    </div>

                </div>
            </div> */}
            <div className="row">
                <div className="col-xl-3 col-xxl-4">
                    <div className="row">
                        <div className="col-xl-12 col-sm-6">
                            <div className="card green_container padding_15 box_height_547">

                                {/* ----Group Subject------- */}
                                <div className="form-group exam">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow color_white "><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            // defaultValue={defalutValue}
                                            className="form-control form-control select_option green_background color_white"
                                            value={groupSubject}
                                            // onChange={(e) => setSubject(e.target.value)}
                                            onChange={(e) => showSubjectListAsSubjectid(e.target.value)}
                                        >
                                            <option value={defalutSelectValue}>Select Group</option>
                                            {
                                                props.getpurchasedGrouplist.map((item, index) => {
                                                    return <option key={index} value={[item.subejct_id, index]}>{item.subject_name}</option>
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>
                                {/* ----Group Subject------- */}
                                {groupSubject != '' ?
                                    <div className="form-group exam">
                                        <div className="basic-dropdown">
                                            <span className="drop_down_arrow color_white "><i className="bi bi-caret-down-fill"></i></span>
                                            <select
                                                // defaultValue={defalutValue}
                                                className="form-control form-control select_option green_background color_white"
                                                value={subject}
                                                // onChange={(e) => setSubject(e.target.value)}
                                                onChange={(e) => showBranchListAgainstSubjectid(e.target.value)}
                                            >
                                                <option value={defalutSelectValue}>Select Subject</option>
                                                {
                                                    props.getpurchasedSubjectListAsPerGroupId.map((item, index) => {
                                                        return <option key={index} value={[item.subject_id, index]}>{item.subject_name}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    : null}
                                {/*  } */}

                                {/* Branch--- */}
                                {/* {subject !== '' ?
                                        props.branchScholasticListAgainstSubjectId == null || props.branchScholasticListAgainstSubjectId == undefined ?
                                            <div className="form-group exam">
                                                <div className="basic-dropdown form-control select_option unselect_text">
                                                    <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                                    Select Branch
                                                </div>
                                            </div>
                                            :
                                            <div className="form-group exam">
                                                <div className="basic-dropdown">
                                                    <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                    <select
                                                        defaultValue={"option"}
                                                        className="form-control form-control select_option"
                                                        value={branch}
                                                        // onChange={(e) => setBranch(e.target.value)}
                                                        onChange={(e) => showChapterlist(e.target.value)}
                                                    >
                                                        <option value="">Select Branch</option>
                                                        {
                                                            props.branchScholasticListAgainstSubjectId.map((item, index) => {
                                                                return <option key={index} value={[item.id, item.branch_code]}>{item.branch_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        : <div className="form-group exam">
                                            <div className="basic-dropdown form-control select_option unselect_text">
                                                <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                                Select Branch
                                            </div>
                                        </div>
                                    } */}

                                {/* Set--- */}
                                {/* setlectSet == '' ?
                                        <div className="form-group exam">
                                            <div className="basic-dropdown form-control select_option unselect_text">
                                                <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                                Select Set
                                            </div>
                                        </div>
                                        : */}

                                {subject == '' ?
                                    null
                                    :
                                    <div className="form-group exam">
                                        <div className="basic-dropdown">
                                            <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                            <select
                                                // defaultValue={defalutValue}
                                                className="form-control form-control select_option green_background color_white"
                                                value={setlectSet}
                                                // onChange={(e) => setSetlectSet(e.target.value)}
                                                onChange={(e) => setSetlectSetData(e.target.value)}
                                            >
                                                <option value={defalutSelectValue}>Select Chapter Test</option>
                                                {
                                                    purchsedSet.map((item, index) => {
                                                        return <option key={index} value={item.set_no}>{item.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }

                                {/* Case Studies--- */}
                                {/* {subject === '' ?
                                    null
                                    :
                                    caseStudy != "" ?
                                        <div className="form-group exam">
                                            <div className="basic-dropdown">
                                                <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                                <select
                                                    // defaultValue={defalutValue}
                                                    className="form-control form-control select_option green_background color_white"
                                                    value={mock}
                                                    // onChange={(e) => setMock(e.target.value)}
                                                    onChange={(e) => setCaseStudySelect(e.target.value)}
                                                >
                                                    <option value={defalutSelectValue}>Select Chapter Test Case Studies</option>
                                                    {
                                                        caseStudy.map((item, index) => {
                                                            return <option key={index} value={item.casestudy_no} disabled={item.casestudy_no == casestudy_current_no ? false : true}>{item.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                } */}

                                {/* module--- */}

                                {subject === '' ?
                                    null
                                    :
                                    moduleDetails != '' ?
                                        <div className="form-group exam">
                                            <div className="basic-dropdown">
                                                <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                                <select
                                                    // defaultValue={defalutValue}
                                                    className="form-control form-control select_option green_background color_white"
                                                    value={module}
                                                    // onChange={(e) => setModule(e.target.value)}
                                                    onChange={(e) => setSetlectModuleData(e.target.value)}
                                                >
                                                    <option value={defalutSelectValue}>Select Module Test</option>
                                                    {
                                                        moduleDetails.map((item, index) => {
                                                            return (
                                                                item.module_no < module_current_no ?
                                                                    <option className="fa" key={index} value={item.module_no} disabled={item.module_no == module_current_no ? false : true} style={{ 'color': '#1A9525', 'fontFamily': 'inherit' }}> {item.name}</option>
                                                                    : item.module_no == module_current_no ?
                                                                        <option className="fa " key={index} value={item.module_no} disabled={item.module_no == module_current_no ? false : true} style={{ 'color': '#ffffff', 'fontFamily': 'inherit' }}>{item.name} {moduleInterm == 0 ? '' : `(${moduleInterm}/3)`} </option>
                                                                        : item.module_no > module_current_no ?
                                                                            <option className="fa" key={index} value={item.module_no} disabled={item.module_no == module_current_no ? false : true} style={{ 'color': '#484848', 'fontFamily': 'inherit' }}> {item.name}</option> : null

                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                }


                                {/* mock--- */}

                                {subject === '' ?
                                    null
                                    :
                                    mockDetails != "" ?
                                        <div className="form-group exam">
                                            <div className="basic-dropdown">
                                                <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                                <select
                                                    // defaultValue={defalutValue}
                                                    className="form-control form-control select_option green_background color_white"
                                                    value={mock}
                                                    // onChange={(e) => setMock(e.target.value)} &#xf00c; &#xf040;]
                                                    onChange={(e) => setSetlectMockData(e.target.value)}
                                                >
                                                    <option value={defalutSelectValue}>Select Mock Test</option>
                                                    {
                                                        mockDetails.map((item, index) => {
                                                            return (
                                                                item.mock_no < mock_current_no ?
                                                                    <option key={index} value={item.mock_no} disabled={item.mock_no == mock_current_no ? false : true} style={{ 'color': '#1A9525', 'fontFamily': 'inherit' }}>{item.name}</option>
                                                                    : item.mock_no == mock_current_no ?
                                                                        <option className="fa" key={index} value={item.mock_no} disabled={item.mock_no == mock_current_no ? false : true} style={{ 'color': '#ffffff', 'fontFamily': 'inherit' }} >{item.name} {mockInterm == 0 ? '' : `(${mockInterm}/3)`}</option>
                                                                        : item.mock_no > mock_current_no ?
                                                                            <option className="fa" key={index} value={item.mock_no} disabled={item.mock_no == mock_current_no ? false : true} style={{ 'color': '#484848', 'fontFamily': 'inherit' }}>{item.name} </option> : null
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                }


                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-9 col-xxl-8">
                    <div className="card padding_15">
                        <div className="padding_15 green_background round_8">
                            <div className="top-heading font_16 font_white">
                                Select Chapter/s ({purchsedSet != '' ? `${props.chaptercList.length}` : 0})
                            </div>
                        </div>

                        <div className="botton_continer round_8 padding_15 green_background_border">
                            {

                                props.chaptercList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {setlectSet != '' ?
                                                props.examCompletedChapterList == undefined ?
                                                    <Link to={{ pathname: "/page-scholastic-exam", state: { branchSortCode: item.branch, chapter: item.short_code, exam_type, setlectSet, set_no: set_no, subject_id: subject.split(',')[0], group_subject_id: parseInt(groupSubject.split(',')[0]) } }}>
                                                        <div className="chapters_listing margin_buttom_20">
                                                            <div clsss="chapter_heading">
                                                                <div className="font_open_sans color_gery_1 font_13 font_weight_900">
                                                                    {item.chapter_name}
                                                                    Chapter: {index + 1}
                                                                </div>
                                                            </div>
                                                            <div clsss="chapter_description">
                                                                <div className="font_open_sans color_gery_1 font_13">
                                                                    {item.exam_unique_id}
                                                                </div>
                                                            </div>
                                                            <div clsss="chapter_description">
                                                                <div className="font_open_sans color_gery_1 font_13">
                                                                    {/* {item.short_code} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link> :
                                                    !props.examCompletedChapterList.find(element => element.chapter_id == item.id) ?
                                                        <>
                                                            <Link to={{
                                                                pathname: "/page-scholastic-exam",
                                                                state: { branchSortCode: item.branch, chapter: item.short_code, exam_type, setlectSet, subject_id: subject.split(',')[0], set_no: set_no, chapter_no: index + 1, group_subject_id: parseInt(groupSubject.split(',')[0]) }
                                                            }} onClick={() => callExamData(item.branch, item.short_code, exam_type, setlectSet, subject.split(',')[0], set_no,)}>
                                                                <div className="chapters_listing margin_buttom_20">

                                                                    <div clsss="chapter_heading">
                                                                        <div className={`font_open_sans ${item.interm_count == 0 ? "color_gery_1" : "interm_count_exam_color"} font_13 font_weight_900`}>
                                                                            {/* {item.chapter_name} */}
                                                                            Chapter: {index + 1} {item.interm_count == 0 ? null : <><i className="interm_count_exam_done_mark bi bi-pencil-square"> </i>({item.interm_count}/3)</>}
                                                                        </div>
                                                                    </div>
                                                                    <div clsss="chapter_description">
                                                                        <div className={`font_open_sans ${item.interm_count == 0 ? "color_gery_1" : "interm_count_exam_color"} font_13`}>
                                                                            {item.sub_heading}
                                                                        </div>
                                                                    </div>
                                                                    <div clsss="chapter_description">
                                                                        <div className="font_open_sans color_gery_1 font_13">
                                                                            {/* {item.short_code} */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Link>
                                                        </>
                                                        :
                                                        <div className="exam_done_prent_container">
                                                            <div className="chapters_listing_done margin_buttom_20">
                                                                <div clsss="chapter_heading">
                                                                    <div className="font_open_sans color_done font_13">
                                                                        {/* {item.chapter_name} */}
                                                                        Chapter: {index + 1}
                                                                    </div>
                                                                </div>
                                                                <div clsss="chapter_description">
                                                                    <div className="font_open_sans color_done font_13">
                                                                        {item.sub_heading} <i className="exam_done_mark bi bi-check-square"></i>
                                                                    </div>
                                                                </div>
                                                                <div clsss="chapter_description">
                                                                    <div className="font_open_sans color_done font_13">
                                                                        {/* {item.short_code} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                :
                                                null
                                            }

                                        </React.Fragment>
                                    )
                                })
                            }


                        </div>
                    </div>
                </div>

                {/* {purchasedSubjectList.module_interm != 0 && purchasedSubjectList.module_interm != undefined ? */}
                <Modal className="fade =" show={moduleMockChapterModal}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Select Chapters for {isModuleOrMock == 1 ? "Module" : "Mock"} Test {/* ({isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock}) */} {!!checked.length ? <> You have selected({checked.length}/{isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock})</> : null}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-lg-12 modal_body_height">

                            {moduleMockChaterList ?
                                <div className="basic-form">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        {(isModuleOrMock == 1 ? maxChapterSelect <= moduleMockChaterList.length : maxChapterSelectMock <= moduleMockChaterList.length) ?
                                            <h6>Please select {isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock} {/* {isModuleOrMock == 1 ? "Module" : "Mock"} */} Chapters, for {isModuleOrMock == 1 ? "Module" : "Mock"} Test </h6>
                                            : null}

                                        {(isModuleOrMock == 1 ? maxChapterSelect > moduleMockChaterList.length : maxChapterSelectMock > moduleMockChaterList.length) ? <><h6>Please complete minimum {isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock} Chapter for attend this exam</h6></> : null}

                                        {moduleMockChaterList.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className={`list_container ${!checked.includes(index) && checked.length >= (isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock) ? null : "cursorPointer"}`} onClick={() => !checked.includes(index) && checked.length >= (isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock) ? null : checkChange(index)}>
                                                        <div className='checkBoxOcntainer'>
                                                            <div className="form-check form-check-inline checkbox_position">
                                                                <label className="form-check-label">
                                                                    <>
                                                                        <input
                                                                            key={index}
                                                                            className="form-check-input"
                                                                            value={item.chapter_id}
                                                                            onChange={() => checkChange(index)}
                                                                            type="checkbox"
                                                                            checked={checked.includes(index)}
                                                                            disabled={!checked.includes(index) && checked.length >= (isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock)}
                                                                        // disabled={!checked.includes(index) && checked.length >= 2}
                                                                        />
                                                                    </>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="chapters_listing margin_buttom_20">
                                                            <div clsss="chapter_heading">
                                                                <div className="font_open_sans color_gery_1 font_13 font_weight_900">
                                                                    {/* {item.chapter_name} */}
                                                                    {/* Chapter {index + 1} */}
                                                                </div>
                                                            </div>
                                                            {/* <div clsss="chapter_description">
                                                                <div className="font_open_sans color_gery_1 font_13">
                                                                    {item.exam_unique_id}
                                                                </div>
                                                            </div> */}
                                                            <div clsss="chapter_description">
                                                                <div className="font_open_sans color_gery_1 font_13">
                                                                    {/* {item.short_code} */}
                                                                    {item.sub_heading}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            )
                                        })}

                                    </form>
                                </div>
                                :
                                <div className="exam_list_container" >
                                    <div className="spinner-container">
                                        <div className="spinner-view">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                //size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>}
                        </div>

                    </Modal.Body >
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">

                                <Button
                                    variant="danger text-center modal_footer_button"
                                    onClick={() => [setModuleMockChapterModal(false), setChecked(''), setModule(""), setMock("")]}
                                >
                                    Close
                                </Button>

                                <Button
                                    variant="warning text-center modal_footer_button"
                                    onClick={() => moduleMockExamSubmit()}
                                    // disabled={checked.length == 2 ? false : true}
                                    disabled={checked.length == (isModuleOrMock == 1 ? maxChapterSelect : maxChapterSelectMock) ? false : true}
                                >
                                    Submit
                                </Button>

                            </div>
                        </div>

                    </Modal.Footer>
                </Modal >
                {/* : null} */}
            </div >
        </>
    )
}

const mapStateToProps = (state) => {
    // console.log("%%%%%%---", state.subject.purchasedSubjectList,)
    // console.log("%%%%%%--111-", state.chapter.examCompletedChapterList,)
    // console.log("%%%%%%--111-", state.chapter.chapterList)
    return {
        user_id: state.auth.user_id,
        // showLoading: state.board.showLoading,
        boardList: state.board.boardList,
        classStandardList: state.standard.classStandardList,
        showLoadingclassStandard: state.standard.showLoadingclassStandard,
        branchScholasticList: state.branch.branchScholasticList,
        showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
        chaptercList: state.chapter.chapterList,
        showLoadingChapter: state.chapter.showLoadingChapter,
        purchasedSubjectList: state.subject.purchasedSubjectList,
        getpurchasedSubjectListAsPerGroupId: state.subject.getpurchasedSubjectListAsPerGroupId,
        getpurchasedGrouplist: state.subject.getpurchasedGrouplist,
        branchScholasticListAgainstSubjectId: state.branch.branchScholasticListAgainstSubjectId,
        examCompletedChapterList: state.chapter.examCompletedChapterList,
    };
};
export default connect(mapStateToProps)(ScholasticExam);