import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import AssessmentDetailCard from './components/AssessmentDetailCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"
import { Link } from "react-router-dom";
import Moment from 'react-moment';


import { getDemoAssessmentListsData, demoAssessmentDetailsListRequest } from '../../../store/actions/ScholasticAction'

import ListCard from './components/ListCard';
import Loader from "../Loader"

import assessment from '../../../images/assessment.png';

const AssessmentList = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const newStudentid = useSelector(state => state.student.newStudentid);

  useEffect(() => {
    dispatch(demoAssessmentDetailsListRequest(true));
    dispatch(getDemoAssessmentListsData(newStudentid, props.history))
  }, [newStudentid]);

  const pressAssessmentList = (item) => {
    // dispatch(demoAssessmentDetailsListRequest(true));
    history.push({ pathname: "/demo-assessment-details", state: { exam_category_id: item.exam_category_id, student_status: item.student_status, student_id: item.student_id, assessmentName: item.headingmsg, assessmentName: item.headingmsg, subheading: item.subheading, exam_date: item.exam_date } })
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Demo Assessment <i className="bi bi-chevron-right"></i> Demo Assessment List</div> 
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="assessment_container_parent ">
            <>
              <div className="card-header">
                <h4>Demo Assessment List</h4>
              </div>
              <div style={{ margin: "10px" }}></div>
              {props.demoExamAessmentCountList != '' ?

                props.demoExamAessmentCountList.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="list_parent_container" >
                      {/* <Link onClick={() => pressAssessmentList()} to={{ pathname: "/demo-assessment-details", state: { exam_category_id: item.exam_category_id, student_status: item.student_status, student_id: item.student_id } }} className="link_box_container"> */}
                      <div onClick={() => item.is_expired == 0 ? pressAssessmentList(item) : null} className="link_box_container">
                        <ListCard
                          key={index}
                          // path={item.link_to}
                          // path={item.link_to}
                          icon={assessment}
                          title={item.headingmsg}
                          description={item.subheading}
                          bgColor={item.exam_category_id == 1 ? "#56c760" : "#57bad7"}
                          leftColor={item.exam_category_id == 1 ? "#56c760" : "#57bad7"}
                          iconClass={item.left_image_class}
                          iconClassRgt={item.right_image_class}
                          exam_date={<Moment format="D MMM, YYYY" withTitle>{item.exam_date}</Moment>}
                          is_expired={item.is_expired}
                          expired_text={item.expired_text}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))
                :
                <div className="no_data_text">
                  <h4>No list available</h4>
                </div>
              }
            </>
          </div>
        </div>
      </div>

      <div>
        {props.loading ?
          <Loader />
          : null}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    // demoExamAessmentDetailsList: state.questionNo.demoExamAessmentDetailsList,
    demoExamAessmentCountList: state.questionNo.demoExamAessmentCountList,
    loading: state.questionNo.loading,

  };
};
export default connect(mapStateToProps)(AssessmentList);