
import {
    registerNewStudent,
    otpSendForRegistration,
    checkStudentRecordExistInTheSystemOrNot,
    askQuestionData,
    registrationStatus,
} from '../../services/StudentService';

import {
    DEMO_STUDENT_REGISTER,
    DEMO_STUDENT_REGISTRATION_LOADER,
    VERIFICATION_OTP,
    SUBMIT_ANSWER,
    RECORD_EXISTS_SUCCESS,
    RECORD_EXISTS_FAILURE,
    GET_SEARCH_TEXT_DATA,
    GET_SEARCH_TEXT_FAILURE_DATA,
    CLICK_QUESTION_NO
} from '../constants';

import {
    logout,
    loginAction
} from '../actions/AuthActions';

import {
    loginConfirmedAction, feedbackGivenAction
} from './AuthActions';

import {
    demoExamSubmitAction,
} from './ScholasticAction';

import {
    saveTokenInLocalStorage,
} from '../../services/AuthService';

import * as utility from '../../utility/Utility';


 /* guest user data send for registration with submit demo exam */
export function newStudentRegistration(fname, lname, dob, gender, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd, userdob, history) {

    let getData = localStorage.getItem('userDetails');
    // console.log("===newStudentRegistration===", getData)
    let olduserDetailsData = JSON.parse(localStorage.userDetails);
    olduserDetailsData.fname = fname;
    olduserDetailsData.lname = lname;
    olduserDetailsData.dob = userdob;
    olduserDetailsData.email = email;
    olduserDetailsData.gender = gender;
    olduserDetailsData.address = address;
    olduserDetailsData.pincode = pincode;
    olduserDetailsData.mobile = mobile;
    olduserDetailsData.standard = std;
    olduserDetailsData.board = board.split(',')[0];
    olduserDetailsData.school_name = schoolname;
    olduserDetailsData.school_address = schooladd;
    olduserDetailsData.mobile_otp_verify = 1;
    olduserDetailsData.email_otp_verify = 1;
    olduserDetailsData.mac_address = null;
    olduserDetailsData.board_code = board.split(',')[1];
    olduserDetailsData.board_name = board.split(',')[2];

    return async (dispatch) => {
        await registerNewStudent(fname, lname, dob, gender, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd, userdob, history)
            .then((response) => {
                console.log("registerNewStudent-----", response)
                if (response.data.status == 200) {
                    if (getData == null) {
                        history.push('./page-login')
                        utility.showSuccess(response.data.msg);
                    } else {
                        dispatch(postDemoStudentRegistrationAction(response.data.studentid));
                        dispatch(loginConfirmedAction(response.data.data[0]));
                        dispatch(recordExistsSuccess(2))
                        dispatch(demoExamSubmitAction(0))
                        dispatch(feedbackGivenAction(0));
                        dispatch(showingLoaderStudentAction(false))
                        // history.push('/dashboard')
                        // utility.showSuccess(response.data.msg);
                        // olduserDetailsData.id = response.data.studentid;
                        // localStorage.setItem('userDetails', JSON.stringify(olduserDetailsData));
                        // dispatch(loginConfirmedAction(olduserDetailsData));
                        saveTokenInLocalStorage(response.data.data[0]);
                        dispatch(loginConfirmedAction(response.data.data[0]));
                    }
                } else {
                    utility.showError(response.data.msg);
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }

            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}


export function newStudentRegistrationWithoutDemo(fname, lname, dob, gender, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd, userdob, history) {
    return async (dispatch) => {
        await registerNewStudent(fname, lname, dob, gender, address, pincode, mobile, email, password, cpassword, std, board, schoolname, schooladd, userdob, history)
            .then((response) => {
                // console.log("=======newStudentRegistrationWithoutDemo===", response)
                if (response.data.status == 200) {
                    let withoutDemo = 1
                    /*  if (getData == null) {
                         history.push('./page-login')
                         utility.showSuccess(response.data.msg);
                     } else { */
                    dispatch(postDemoStudentRegistrationAction(response.data.studentid));
                    dispatch(recordExistsSuccess(2))
                    dispatch(demoExamSubmitAction(1))
                    dispatch(showingLoaderStudentAction(false))
                    dispatch(loginAction(email, password, history));

                    /* ---- */
                    /* history.push({ pathname: './page-login' })
                    utility.showSuccess(response.data.msg); */
                    /* --------- */

                    // history.push('./page-login')
                    // history.push({pathname:'./page-login', state: { tab:"Login" } })
                    // history.push({pathname:'./page-login' })
                    // utility.showSuccess(response.data.msg);
                    // olduserDetailsData.id = response.data.studentid;
                    // localStorage.setItem('userDetails', JSON.stringify(olduserDetailsData));
                    // dispatch(loginConfirmedAction(olduserDetailsData));
                }
                /*} else {
                    utility.showError(response.data.msg);
                } */
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }

            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

/* Send verification OTP to user  */
export function sendVerificationOtp(mobile, email, history) {
    return (dispatch) => {
        otpSendForRegistration(mobile, email)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(verificationCodeAction(response.data));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }

            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function demoUserRecordExistsOrNot(mobile, email, history) {
    return (dispatch) => {
        checkStudentRecordExistInTheSystemOrNot(mobile, email)
            .then((response) => {
                console.log(">>>>>>>111", response.data)
                if (response.data.status == 200) {
                    dispatch(recordExistsSuccess(response.data.exist))
                    // dispatch(demoExamSubmitAction(0))
                    // utility.showSuccess(response.data.msg);
                } else if (response.data.status == 410) {
                    dispatch(recordExistsFailure(response.data.exist))
                    utility.showError(response.data.msg);
                }

            })
            .catch((error) => {
                console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getAskQuestionData(search_text, subject_id, history) {
    return (dispatch) => {
        askQuestionData(search_text, subject_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSearTextSuccessAction(response.data.data));
                } else {
                    dispatch(getSearTextFailureAction(response.data.data));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

/* send OTP verfication details */
export function registrationStatusDetails(mobile, email, student_name, mobile_otp_status, email_otp_status, otp_timeout, standard, board, pincode, school_name) {
    
    return (dispatch) => {
        registrationStatus(mobile, email, student_name, mobile_otp_status, email_otp_status, otp_timeout, standard, board, pincode, school_name)
            .then((response) => {
                if (response.data.status == 200) {
                    // dispatch(getSearTextSuccessAction(response.data.data));
                } 
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}


/* create userid when guest user registration completed */
export function postDemoStudentRegistrationAction(data) {
    return {
        type: DEMO_STUDENT_REGISTER,
        payload: data,
    };
}

/* verification OTP data store into Action  */
export function verificationCodeAction(data) {
    return {
        type: VERIFICATION_OTP,
        payload: data,
    };
}
/* /* showing loader for demo user send OTP  */
export function showingLoaderStudentAction(status) {
    return {
        type: DEMO_STUDENT_REGISTRATION_LOADER,
        payload: status,
    };
}

export function recordExistsSuccess(success) {
    return {
        type: RECORD_EXISTS_SUCCESS,
        payload: success,
    };
}

export function recordExistsFailure(failure) {
    return {
        type: RECORD_EXISTS_FAILURE,
        payload: failure,
    };
}

export function getSearTextSuccessAction(data) {
    return {
        type: GET_SEARCH_TEXT_DATA,
        payload: data,
    };
}

export function getSearTextFailureAction(data) {
    return {
        type: GET_SEARCH_TEXT_FAILURE_DATA,
        payload: data,
    };
}

export function getClickQuestionNoAction(data) {
    return {
        type: CLICK_QUESTION_NO,
        payload: data,
    };
}





