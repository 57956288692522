import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { connect, useDispatch, } from 'react-redux';

import Loader from "../../components/Loader";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';


import { performanceRequestAction, getCompareDiffarentSubjectScoreNtseDataDetails, getScholasticAveragePerformanceSetModuleMockData } from '../../../store/actions/PerformanceScoreAction';


const ComparisonAverageScoreDifferentSubjects = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state);
    const [graphicDetails, setGraphicDetails] = useState([]);
    const [graphicSubDetails, setGraphicSubDetails] = useState();
    const totalRef = useRef(true);
    const functionalCallFirstTimeRef = useRef(true);

    const data = {
        defaultFontFamily: "Poppins",
        // labels: ["Math", "Phy", "Chem", "Bio", "Geo", "His", "Eco"],
        labels: graphicSubDetails,
        datasets: [
            {
                // label: "My First dataset",
                // data: [65, 59, 80, 81, 56, 55, 40],
                // data: [27, 35, 133, 0, 0],
                data: graphicDetails,
                borderColor: "#4472C5",
                borderWidth: "0",
                backgroundColor: "#4472C5",
                barThickness: 40

            },
        ],
    };

    const options = {
        plugins: {
            legend: false,
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start",
                callback: function(value) {
                    return value + "%"
                  }
            }
        },
        scales: {
            y:
            {
                ticks: {
                    beginAtZero: false,
                    min: 0,
                    max: 150,
                    stepSize: 3,

                },
            },

            x:
            {
                // Change here
                barPercentage: 0.5,
            },

        },
    };


    useEffect(() => {
        if (functionalCallFirstTimeRef.current) {
            // dispatch(performanceRequestAction(true))
            dispatch(getCompareDiffarentSubjectScoreNtseDataDetails(props.history))
            dispatch(getScholasticAveragePerformanceSetModuleMockData(props.history))
        }
        functionalCallFirstTimeRef.current = false;
        filterData();
    }, [props.scholasticSetMoudleMockAveragePerformance]);

    const filterData = () => {
        const keyValue = [];
        let valueData = [];
        const chapterName = Object.fromEntries(Object.entries(props.compareDiffarentSubjectScoreNtse).filter(([key, value]) => {
            keyValue.push(key)
            valueData.push(value[5][0]);
        }))
        setGraphicSubDetails(keyValue);
        setGraphicDetails(valueData)

    }

    return (
        <>
            {props.scholasticSetMoudleMockAveragePerformance != "" ?
                <div className='performance_container'>
                    <div className="text-center">
                        <h3>Subject wise comparison on</h3>
                        <h6>Set score Vs Module score Vs Mock score</h6>
                    </div>
                    <div className='performance_parent_container'>
                        <div className='score_container flex-1'>
                            <div className='score_card_parent_container'>
                                <table>
                                    <tr>
                                        <th className="text-center">Subject</th>
                                        <th className="text-center">NT 1</th>
                                        <th className="text-center">NT 2</th>
                                        <th className="text-center">NT 3</th>
                                        <th className="text-center">NT 4</th>
                                        <th className="text-center">NT 5</th>
                                        <th className="text-center">AVG</th>
                                    </tr>

                                    <tbody>
                                        {props.compareDiffarentSubjectScoreNtse != "" ?
                                            Object.keys(props.compareDiffarentSubjectScoreNtse).map((keyName, i) => (
                                                <tr className={`${i % 2 == 0 ? `tr-background` : null}`}>
                                                    <td>{keyName}</td>
                                                    {props.compareDiffarentSubjectScoreNtse[keyName].map((item, index) => (
                                                        <td>{item[0]}</td>

                                                    ))}
                                                </tr>
                                            ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='chart_container flex-1 wh400 jcc text-center flex_column'>
                            <div id="chart" className="line-chart-style bar-chart">
                                <Bar data={data} height={150} options={options} plugins={[ChartDataLabels]} />
                            </div>
                        </div>
                        
                    </div>

                </div>
                : null}
        </>
        //<Loader />
    )
}
const mapStateToProps = (state) => {
    // console.log("ComparisonAverageScoreDifferentSubjects--", state.performance.compareDiffarentSubjectScoreNtse)
    return {
        compareDiffarentSubjectScoreNtse: state.performance.compareDiffarentSubjectScoreNtse,
        scholasticSetMoudleMockAveragePerformance: state.performance.scholasticSetMoudleMockAveragePerformance,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(ComparisonAverageScoreDifferentSubjects);
