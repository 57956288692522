import React, { useEffect, } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { selectExamCategoryAction, getCategoryData, selectExamCategoryRequestAction } from '../../../store/actions/ExamCategoryAction';
import { onlineScholasticAssessmentListSuccessAction } from '../../../store/actions/ScholasticAction';

import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";

//Import Components
import CategoryCard from './components/CategoryCard';

import scholastic_img_01 from '../../../images/scholastic_img_01.png';
import scholastic_img_11 from '../../../images/scholastic_img_11.png';


const OnlineAsscessmentCategory = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(getCategoryData(props.history));
	}, [props.categorylist]);

	/* const selectAssessmentCategory = (item) => {
		console.log(item)
		history.push({ pathname: "/online-assessment-category-details", state: { item: item, } })
	} */
	
	const removePreviousAssessmentListData = () => {
		dispatch(onlineScholasticAssessmentListSuccessAction(''))
	}


	return (
		<>
			{
				props.categorylist == "" || props.categorylist == undefined ?
					<div className="exam_list_container" >
						<div className="spinner-container">
							<div className="spinner-view">
								<Spinner
									as="span"
									animation="border"
									//size="sm"
									role="status"
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>
					:
					<div className="category_list_container" >
		
						{props.categorylist.map((item, ind) => (
							<Link to={{ pathname: item.id == 1 ? "/online-assessment-category-details" : "/online-assessment-category-type", state: { categpryId: item.id } }} onClick={() => removePreviousAssessmentListData()} key={ind}  >
								<CategoryCard
									backgroundColor={item.id == 1 ? "green_background" : "competitive_color"}
									leftIcon={item.id == 1 ? scholastic_img_01 : scholastic_img_11}
									leftIconColor={item.id == 1 ? "#94AC4B" : "#58bad7"}
									id={item.id}
									category={item.category}
									sub_heading={item.sub_heading}
									rightIcon={item.id == 1 ? scholastic_img_01 : scholastic_img_11}
								/>
							</Link>
						))}
					</div>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		categorylist: state.category.examcategoryList,
	};
};
export default connect(mapStateToProps)(OnlineAsscessmentCategory);