import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import { Doughnut, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Loader from "../../components/Loader"

import { performanceRequestAction, getScholasticPerformanceMoudleData, getScholasticPerformanceMockData, scholasticPerformanceMoudleFailureAction } from '../../../store/actions/PerformanceScoreAction';
import { ComposableMap } from 'react-simple-maps';

const SubjectModule = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [chartLabel, setChartLabel] = useState();
    const [chartData, setChartData] = useState();
    const InstructionExamModalRef = useRef(true);

    const [counter, setCounter] = useState(0);
    const [showData, setShowData] = useState(false)
    const chartRef = useRef();

    const scholasticPerformanceMoudleOverAllAverage = useSelector((state) => state.performance.scholasticPerformanceMoudleOverAllAverage);
    const scholasticPerformanceMoudle = useSelector((state) => state.performance.scholasticPerformanceMoudle);


    const data = {
        // labels: ["Mod1", "Mod2", "Mod3"], 
        labels: chartLabel,
        datasets: [{
            label: 'My First Dataset',
            // data: [60,60, 70, 80, 90],
            // data: [60, 60, 60],
            data: chartData,
            // data: ['ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9','ch2', 'ch4'],
            backgroundColor: [
                '#FFC000',
                '#00B0F0',
                '#DEA900',
            ],
            hoverBackgroundColor: [
                '#FFC000',
                '#00B0F0',
                '#DEA900',
            ],
            borderWidth: 2,
            hoverOffset: 2,
            borderJoinStyle: 'round',
        }],
        text: '23%'
    };

    const options = {
        centerText: {
            display: true,
            text: `90%`
        },
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: true
            },
            title: {
                display: true,
                text: "Math PCS set",
                padding: {
                    bottom: 10
                },
                weight: "bold",
                color: "#00325c",
                font: {
                    size: 13
                },
                align: "center"
            },
            datalabels: {
                labels: {
                    name: {
                        align: 'center',
                        font: { size: 14 },
                        color: "#fff",
                        formatter: function (value, ctx) {
                            return ctx.chart.data.labels[ctx.dataIndex];
                        },
                        anchor: "center", //start, center, end
                        rotation: function (ctx) {
                            const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
                            const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                            const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
                            return rotation < 180 ? rotation - 90 : rotation + 90;
                        },
                    },
                }
            },
        }
    };

    const plugins = [ChartDataLabels, {
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = scholasticPerformanceMoudleOverAllAverage + "%",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = (height / 2) + 15;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }];


    useEffect(() => {
        if (InstructionExamModalRef.current) {
            if (previousValue.type == 2) {
                dispatch(performanceRequestAction(true))
                dispatch(getScholasticPerformanceMoudleData(previousValue.subject_id, props.history))
            } else if (previousValue.type == 3) {
                dispatch(performanceRequestAction(true))
                dispatch(getScholasticPerformanceMockData(previousValue.subject_id, props.history))
            }
        }

        InstructionExamModalRef.current = false;

        const graphLabel = [];
        var seriesLabel = null;

        for (let i = 0; i < props.scholasticPerformanceMoudle.length; i++) {
            let seriesLabel = {};
            if (previousValue.type == 2) {
                seriesLabel = "Module " + props.scholasticPerformanceMoudle[i].module_no;
            } else if (previousValue.type == 3) {
                seriesLabel = "Mock " + props.scholasticPerformanceMoudle[i].mock_no;
            }
            graphLabel.push(seriesLabel);
        }

        setChartLabel(graphLabel);

        const graphData = [];
        var seriesData = null;
        for (let i = 0; i < props.scholasticPerformanceMoudle.length; i++) {
            let seriesData = {};
            seriesData = props.scholasticPerformanceMoudle[i].total_avg;
            graphData.push(seriesData);
        }
        setChartData(graphData);

        return () => {
            setShowData(true)
        }

    }, [scholasticPerformanceMoudleOverAllAverage, scholasticPerformanceMoudle]);

    const showChartData = (event, item) => {

        /* // console.log(getElementAtEvent(chartRef.current, event,));
        const chartIndex = getElementAtEvent(chartRef.current, event);

        const keyValue = [];
        let valueData = [];

        const chapterName = Object.fromEntries(Object.entries(props.scholasticPerformanceMoudle).filter(([key, value]) => {
            keyValue.push(key)
            value.filter(item => {
                valueData.push(item);
            })
        }))

        const chName = valueData.filter(i => i.chapter == keyValue[chartIndex[0].index]);
        let subject_id = chName[0].subject_id;
        let chapter_id = chName[0].chapter_id;

        console.log("======", subject_id,) */

        //from set
        history.push({ pathname: "/online-comparative-study-set-exams", state: { subject_id: previousValue.subject_id, type: previousValue.type, type_name: previousValue.type == 2 ? 'Moudle' : 'Mock', subject_name: previousValue.subject_name } })
    }

    const ShowComparativeDetails = (item, type) => {
        history.push({ pathname: "/online-comparative-study-set-exams", state: { subject_id: previousValue.subject_id, type: type, type_name: type == 2 ? 'Moudle' : 'Mock', subject_name: previousValue.subject_name } })
    }

    return (
        <>
            {showData ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className="text-center"><h3>{previousValue.subject_name} PSC {previousValue.type_name}</h3></div>
                        <div className='performance_parent_container'>

                            <div className='score_container flex-1 aic'>
                                <div className='score_card_parent_container jcc'>
                                    <table>
                                        <tr>
                                            <th></th>
                                            <th colspan="2" className='text-center'>SWA</th>
                                            <th colspan="2" className='text-center'>HOTS</th>
                                            <th colspan="2" className='text-center'>DES</th>
                                            <th colspan="2" className='text-center'>TOT</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th className='text-center'>Correct</th>
                                            <th className='text-center'>Marks</th>
                                            <th className='text-center'>Correct</th>
                                            <th className='text-center'>Marks</th>
                                            <th className='text-center'>Correct</th>
                                            <th className='text-center'>Marks</th>
                                            <th className='text-center'>Correct</th>
                                            <th className='text-center'>Marks</th>
                                            <th className='text-center'>Percent</th>
                                            <th className='text-center'>Avg%</th>
                                        </tr>

                                        <tbody>
                                            {props.scholasticPerformanceMoudle != "" ?
                                                props.scholasticPerformanceMoudle.map((item, i) => {
                                                    return [
                                                        (i == 0) ? //className='cursorPointer average_table'
                                                            <tr key={i}>
                                                                <td className='text-center cursorPointer text_underline' onClick={() => ShowComparativeDetails(item, previousValue.type == 2 ? 2 : 3)}>{previousValue.type == 2 ? `${"Module " + item.module_no}` : previousValue.type == 3 ? `${"Mock " + item.mock_no}` : null} </td>
                                                                <td className='text-center'>{item.swa}</td>
                                                                <td className='text-center'>{item.swa_marks}</td>
                                                                <td className='text-center'>{item.hot}</td>
                                                                <td className='text-center'>{item.hot_marks}</td>
                                                                <td className='text-center'>{item.des}</td>
                                                                <td className='text-center'>{item.des_marks}</td>
                                                                <td className='text-center'>{item.total}</td>
                                                                <td className='text-center'>{item.total_marks}</td>
                                                                <td className='text-center'>{item.total_avg}</td>
                                                                <td className='text-center' rowspan={props.scholasticPerformanceMoudle.length}>{scholasticPerformanceMoudleOverAllAverage}</td>
                                                            </tr>
                                                            :
                                                            <tr key={i} >
                                                                <td className='text-center cursorPointer text_underline' onClick={() => ShowComparativeDetails(item, previousValue.type == 2 ? 2 : 3)}>{previousValue.type == 2 ? `${"Module " + item.module_no}` : previousValue.type == 3 ? `${"Mock " + item.mock_no}` : null}</td>
                                                                <td className='text-center'>{item.swa}</td>
                                                                <td className='text-center'>{item.swa_marks}</td>
                                                                <td className='text-center'>{item.hot}</td>
                                                                <td className='text-center'>{item.hot_marks}</td>
                                                                <td className='text-center'>{item.des}</td>
                                                                <td className='text-center'>{item.des_marks}</td>
                                                                <td className='text-center'>{item.total}</td>
                                                                <td className='text-center'>{item.total_marks}</td>
                                                                <td className='text-center'>{item.total_avg}</td>
                                                            </tr>
                                                    ];
                                                })
                                                : <tr>
                                                    <td colSpan={11}>No data Available</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className='chart_container flex-1 jcc wh400'>
                                <div className='chart_container wh400'>
                                    {scholasticPerformanceMoudle ?
                                        <Doughnut
                                            ref={chartRef}
                                            data={data}
                                            options={options}
                                            plugins={plugins}
                                            onClick={showChartData} /> //redraw={true}
                                        : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </>
                : <Loader />}

        </>
    )
}
const mapStateToProps = (state) => {
    // console.log("scholasticPerformanceMoudle", state.performance.scholasticPerformanceMoudle)
    return {
        scholasticPerformanceMoudle: state.performance.scholasticPerformanceMoudle,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(SubjectModule);
