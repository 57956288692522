import React, { useEffect, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

import { selectExamCategoryAction, getCategoryData, } from '../../../store/actions/ExamCategoryAction';

import IconTint from 'react-icon-tint';

import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";


const ExamList = (props) => {

	const dispatch = useDispatch();
	const callFirstTimeRef = useRef(true);

	const history = useHistory();

	useEffect(() => {
		if (callFirstTimeRef.current) {
			dispatch(getCategoryData(props.history));
		}
		callFirstTimeRef.current = false
	}, [props.categorylist]);

	/* select exam category */
	const selectExamCategoryId = (id) => {
		dispatch(selectExamCategoryAction(id));
		if (id == 1) {
			history.push({ pathname: "/page-demo-scholastic-exam", })
		} else if (id == 2) {
			history.push({ pathname: "/page-demo-competitive-exam", })
		}
	}


	return (
		<React.Fragment >
			{props.categorylist == '' || props.categorylist == undefined ?
				<div className="spinner-container">
					<div className="spinner-view">
						<Spinner
							as="span"
							animation="border"
							//size="sm"
							role="status"
							aria-hidden="true"
						/>
					</div>
				</div>
				:
				<div className="row">
					<div className="exam_inside_container" >
						{props.categorylist.map((item, ind) => (
							// <Link to={item.id == 1 ? "page-demo-scholastic-exam" : item.id == 2 ? "page-demo-competitive-exam" : null} onClick={() => selectExamCategoryId(item.id)} key={ind}>
							<div onClick={() => selectExamCategoryId(item.id)} key={ind}>
								<div className={`col-md-6 contain_container cursorPointer mb-40 ${item.id == 1 ? "green_background" : "competitive_color"}`}>
									<div className="left_container">
										{/* <IconTint src={`${item.id == 1 ? item.e_library: item.e_library}`} alt="" color={`${item.id == 1 ? "#94AC4B" : "#58bad7"}`} className={`${item.id == 1 ? "left_image" : "left_image"}`} /> */}
										<img src={`${item.id == 1 ? item.onlineexam : item.onlineexam}`} alt="" className={`${item.id == 1 ? "left_image" : "left_image"}`} />
									</div>
									<div className="middle_container">
										<div className="middle_inside_container">
											<h3 className="font_white">{item.category}</h3>
											<p className="font_white">{item.online_subheading}</p>
										</div>
									</div>
									<div className="right_container">
										<IconTint src={`${item.id == 1 ? item.onlineexam : item.onlineexam}`} alt="" color="#fff" className="right_image" />
									</div>
								</div>
							</div>
							// </Link>
						))}
					</div>
				</div>
			}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		categorylist: state.category.examcategoryList,
	};
};
export default connect(mapStateToProps)(ExamList);