import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import PurchaseCart from "./PurchaseCart";
import swal from "sweetalert";

import { sideNavAction, removeAllData, removeData, proceedbuyData, getCartData, showRightNavAction, subscribeLoading, scholasticSubscriptionSourceAction, compititiveSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';

const ProcessPanel = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [gst, setGst] = useState(18);
	const [gstAmount, setGstAmount] = useState(0);
	const [total, setTotal] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [cartListTotal, serCartListTotal] = useState(0);

	const regUserSubOrNot = useSelector(state => state.auth.is_subscribe);
	const count = useSelector(state => state.subscribe.cartList);
	const showRightNavStatus = useSelector(state => state.subscribe.rightSideNavShow);
	const is_subscribe_e_library = useSelector(state => state.auth.is_subscribe_e_library);

	useEffect(() => {
		// dispatch(getCartData(props.history));
		dispatch(getCartData(history));
		//onCalculateAmount();
		// console.log("******---1234-", "@1---"+showRightNavStatus + "@2---"+count.length )
		if (count.length == 0) {
			// console.log("******----", "@1---"+showRightNavStatus + "@2---"+count.length )
			dispatch(sideNavAction(false));
			dispatch(sideNavAction(showRightNavStatus));
		}
		setGst(props.gst)
	}, []);
	// }, [regUserSubOrNot, count]);

	useEffect(() => {
		
		if (props.cartList.length > 0) {
			let payAmount = props.cartList.map(item => parseInt(item.payment_amount)).reduce((prev, next) => prev + next)
			
			serCartListTotal(parseFloat(payAmount).toFixed(2))
		} else {
			// console.log("Process Panel")
			serCartListTotal(parseFloat(0).toFixed(2))
			dispatch(scholasticSubscriptionSourceAction([]))
			dispatch(compititiveSubscriptionSourceAction([]))
		}
	}, [props.cartList]);

	const onToggleHandler = () => {
		dispatch(showRightNavAction(false))
		// dispatch(sideNavAction(false));
		dispatch(sideNavAction(!showRightNavStatus));
	}
	const onAllDeleteHandlerWithAlert = () => {
		swal({
			title: "Do you want to submit?",
			text:
				"",
			icon: "warning",
			buttons: true,
			dangerMode: false,
		}).then((willDelete) => {
			if (willDelete) {
				onAllDeleteHandler();
			}
		})
	}

	const onAllDeleteHandler = () => {
		dispatch(showRightNavAction(false))
		dispatch(removeAllData());
	}

	const onDeleteHandlerWithAlert = (id, amount) => {
		swal({
			title: "Do you want to go back to dashboard?",
			text:
				"",
			icon: "warning",
			buttons: true,
			dangerMode: false,
		}).then((willDelete) => {
			if (willDelete) {
				onDeleteHandler(id, amount);
			}
		})
	}

	const showOnProceedBuyHandler = () => {
		swal({
			title: "Please Confirm?",
			text:
				"Are you sure you want to proceed?",
			icon: "warning",
			buttons: true,
			dangerMode: false,
		}).then((willDelete) => {
			if (willDelete) {
				onProceedBuyHandler();
			}
		})
	}

	const onDeleteHandler = (id, amount) => {
		dispatch(showRightNavAction(false))
		dispatch(removeData(id, amount));
	}

	const onProceedBuyHandler = () => {
		const timestamp = Date.now();
		dispatch(subscribeLoading(true));
		// dispatch(proceedbuyData(props.cartList, props.total, '3434343dfdf', regUserSubOrNot, is_subscribe_e_library));
		// dispatch(proceedbuyData(props.cartList, props.total, timestamp, regUserSubOrNot, is_subscribe_e_library, history));
		dispatch(proceedbuyData(props.cartList, cartListTotal, timestamp, regUserSubOrNot, is_subscribe_e_library, history));
	}

	const onCalculateAmount = () => {
		let data = props.cartList;
		let total = 0;
		for (let x = 0; data.length > x; x++) {
			total = total + parseFloat(data[x].cart_amount, 2);
		}
		setSubtotal(total);
		// setGstAmount(total * (props.gst / 100));
		setTotal(total + (total * (props.gst / 100)));
	}

	return (
		<div className={`chatbox ${props.isNav ? "active" : ""}`}>
			<div className="chatbox-close" onClick={() => onToggleHandler()}></div>
			<div className="proceed-section">
				<div className="proceed-calculation">
					<div className="proceed-set-item">
						<div className="proceed-set-item-list">
							<span>Subtotal ( {props.cartList.length} item{props.cartList.length > 1 ? 's' : null} )</span>
							<span>{cartListTotal}</span>
							{/* <span>{props.subtotal.toFixed(2)}</span> */}
						</div>
						{/* previousNTSEPurcahseValue = action.payload.filter(i => i.is_purchased == 1 && i.exam_type_id == 1).map(item => item.cart_amount).reduce((prev, next) => prev + next); */}
						{/* <div className="proceed-set-item-list">
							<span>GST {props.gst}%</span>
							<span>{props.gstAmount.toFixed(2)}</span>
						</div> */}
					</div>
					<div className="proceed-set-total-item">
						<span>Total</span>
						{/* <span>{props.total.toFixed(2)}</span> */}
						<span>{cartListTotal}</span>
					</div>

					<div className="proceed-delete-procd">

						<Button
							onClick={() => onAllDeleteHandlerWithAlert()}
							disabled={props.subtotal == 0 ? true : false}
							className="proceed-deleteBtn">
							<i className="fa fa-trash"></i>
							<span>Delete all item</span>
						</Button>
						<Button
							onClick={() => showOnProceedBuyHandler()}
							disabled={props.subtotal == 0 ? true : false}
							className="proceed-buyBtn">
							Proceed to buy
						</Button>

					</div>

				</div>

				<div className="proceed-subject-view">
					{
						props.cartList.map((item, index) => {
							return <PurchaseCart
								key={item.id}
								category={item.category}
								type={item.type_name}
								class={item.class}
								subject={item.subject_name}
								mock={item.no_mock}
								module={item.no_module}
								set={JSON.parse(item.no_set)}
								price={item.cart_amount}
								categoryId={item.exam_category_id}
								casestudy={item.no_casestudy}
								library={item.has_library}
								onlyElibrary={item.only_elibrary}
								// deleteHandler={() => onDeleteHandler(item.id, item.cart_amount)}
								deleteHandler={() => onDeleteHandlerWithAlert(item.id, item.cart_amount)}
								exam_type={item.exam_type}
							/>
						})
					}
				</div>

			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	// console.log(">>>>>cartList>>>>>", state.subscribe.cartList)
	return {
		showLoading: state.subscribe.showLoading,
		isNav: state.subscribe.isNav,
		cartList: state.subscribe.cartList,
		gst: state.subscribe.gst,
		gstAmount: state.subscribe.gstAmount,
		subtotal: state.subscribe.subtotal,
		total: state.subscribe.total,
	};
};
export default connect(mapStateToProps)(ProcessPanel);
