import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"

import {
	Row,
	Col,
} from "react-bootstrap";
import PerformanceLoader from '../../../jsx/components/PerformanceLoader'

//Import Components
import ChartCard22 from './performanceComponent/ChartCard22';
import ChartCard23 from './performanceComponent/ChartCard23';
import ChartCard24 from './performanceComponent/ChartCard24';
import ChartCard28 from './performanceComponent/ChartCard28';
import ChartCard29 from './performanceComponent/ChartCard29';
import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_2background.png'

import { getCompetitiveSubjectWiseComparisonDataDetails, performanceRequestAction, getcompetitiveNonverbalcomparisonDataDetails, whereDoYouStandCompetitiveDataDetails } from '../../../store/actions/PerformanceScoreAction';


const CompetitiveSubjectwiseAnalysisOnSet = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [previousValue, setPrevousValue] = useState(location.state);
	const [typeName, setTypeName] = useState('')
	const [pageName, setpageName] = useState('')

	useEffect(() => {
		// console.log("MMMMMM---", previousValue)
		const pathName = window.location.pathname.split('/');
		const id_path = pathName[2];
		const com_type = id_path.split('-');
		const name_com_type = com_type[0];
		setTypeName(name_com_type);
		setpageName(com_type.join(" "))

		dispatch(performanceRequestAction(true))

		// console.log("competitive-subjectwise-analysis-on-set---location.state---11->", location.state)
		// dispatch(getCompetitiveSubjectWiseComparisonDataDetails(previousValue.exam_type, previousValue.setNumber, previousValue.subType, props.history))
		// dispatch(getcompetitiveNonverbalcomparisonDataDetails('', previousValue.exam_type, previousValue.setNumber, previousValue.subType, props.history))
		// dispatch(whereDoYouStandCompetitiveDataDetails(previousValue.exam_type, previousValue.subType, previousValue.setNumber,	props.history))

		dispatch(getCompetitiveSubjectWiseComparisonDataDetails(location.state.exam_type, location.state.setNumber, location.state.subType, props.history))
		dispatch(getcompetitiveNonverbalcomparisonDataDetails('', location.state.exam_type, location.state.setNumber, location.state.subType, props.history))
		dispatch(whereDoYouStandCompetitiveDataDetails(location.state.exam_type, location.state.subType, location.state.setNumber,	props.history))

		if(location.state.exam_type == 'undefined' || location.state.exam_type == undefined){
			// console.log( "location.state.exam_type----", location.state.exam_type )
			history.push({ pathname: "/PerformanceCategoryPage" })
		}


		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
		};

	}, []);

	useEffect(() => {

		return () => {

		}
	}, [props.getcompetitiveNonverbalcomparison])

	const goPrevious = () => {
		// console.log("competitive-subjectwise-analysis-on-set-----", history)
		history.goBack()
	}



	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Competitive Performance Score <i className="bi bi-chevron-right"></i> Competitive Performance Score <i className="bi bi-chevron-right"></i> {location.state && location.state.previousPageName} <i className="bi bi-chevron-right"></i> {pageName}</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<Row>
				<Col xl={6}>
					{props.getcompetitiveSubjectwisecomparison != '' && props.getcompetitiveSubjectwisecomparison != undefined ?
						<ChartCard22
							pageHeading="Grade Grid - Subjectwise"
							dataSet={props.getcompetitiveSubjectwisecomparison}
							typeName={typeName}
							exam_type={previousValue.exam_type}
						/>
						: <PerformanceLoader />
					}
				</Col>
				<Col xl={6}>
					{props.getcompetitiveNonverbalcomparison != '' && props.getcompetitiveNonverbalcomparison != undefined ?
						<ChartCard23
							// pageHeading="Non-Verbal Score Against Each Set"
							pageHeading={`Set Analysis - ${props.getcompetitiveNonverbalcomparison.subject_name}`}
							dataSet={props.getcompetitiveNonverbalcomparison}
							typeName={typeName}
						/>
						: <PerformanceLoader />
					}
				</Col>

				<Col xl={6}>
					{props.wheredoyoustandCompetitive != '' && props.wheredoyoustandCompetitive != undefined ?
						<ChartCard24
							pageHeading="Where do you stand ?"
							dataSet={props.wheredoyoustandCompetitive}
							subType={previousValue}
						/>
						: <PerformanceLoader />
					}
				</Col>
				<Col xl={6}>
					{props.wheredoyoustandCompetitive != '' && props.wheredoyoustandCompetitive != undefined ?
						<ChartCard29
							pageHeading="Colour Guide"
						/>
						: <PerformanceLoader />
					}
				</Col>

				{/* <Link to="/chapterwise-analysis-on-set">Next Page</Link> */}
			</Row>

		</>
	)
}

const mapStateToProps = (state) => {
	
	return {
		performanceLoader: state.performance.loading,
		getcompetitiveSubjectwisecomparison: state.performance.getcompetitiveSubjectwisecomparison,
		getcompetitiveNonverbalcomparison: state.performance.getcompetitiveNonverbalcomparison,
		wheredoyoustandCompetitive: state.performance.wheredoyoustandCompetitive,
	};
};
export default connect(mapStateToProps)(CompetitiveSubjectwiseAnalysisOnSet);