import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"
import AssessmentDetailCard from './components/AssessmentDetailCard';
import Loader from "../Loader"
import Moment from 'react-moment';

import { Virtuoso } from 'react-virtuoso'

import { demoAssessmentDetailsListRequest, getDemoAssessmentList } from '../../../store/actions/ScholasticAction'


const AssessmentDetails = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const newStudentid = useSelector(state => state.student.newStudentid);

  const [demoExamListValue, setDemoExamListValue] = useState(location.state)


  useEffect(() => {

    dispatch(demoAssessmentDetailsListRequest(true));
    // dispatch(getDemoAssessmentList(newStudentid, props.history))
    dispatch(getDemoAssessmentList(demoExamListValue.exam_category_id, demoExamListValue.student_status, demoExamListValue.student_id, props.history))
  }, [newStudentid]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Demo Assessment  <i className="bi bi-chevron-right"></i> Demo Assessment Details</div>
        </div>
      </div>
      {props.demoExamAessmentDetailsList != null ?
        <div className="row">
          <div className="col-md-12">
            <div className="assessment_container_parent ">

              <>
                {/* <div className="card-header">
                  <h4>Assessment Details</h4>
                </div> */}
                {props.demoExamAessmentDetailsList != "" ?
                  <>
                    <div className="card-header">
                      {/* <h4>{demoExamListValue.assessmentName } <br /> {demoExamListValue.subheading}</h4> */}
                      <h4>{demoExamListValue.exam_category_id == 1 ? "Scholastic exam" : demoExamListValue.exam_category_id == 2 ? "Competitive exam" : demoExamListValue.assessmentName} <br /> {demoExamListValue.exam_category_id == 1 ? "Demo" : demoExamListValue.exam_category_id == 2 ? "Demo" : demoExamListValue.subheading}</h4>
                      <h5>Exam date : <Moment format="D MMM, YYYY" withTitle>{demoExamListValue.exam_date}</Moment></h5>
                      <h6 className="text_right">Total questions: {props.demoExamAessmentDetailsList.length}
                        <br /> Correct: {props.demoExamAessmentDetailsList.filter(i => i.guest_post_ans_status === 1).length}
                        <br /> Incorrect: {props.demoExamAessmentDetailsList.filter(i => i.guest_post_ans_status === 0 && i.guest_post_ans !== "undefined").length}
                        <br /> Not attempted: {props.demoExamAessmentDetailsList.filter(i => i.guest_post_ans_status === 0 && i.guest_post_ans === "undefined").length}
                      </h6>
                    </div>
                    <div className="card-header jcc aic">
                      <h5>Marks obtained : {props.demoExamAessmentDetailsList[0].marks} / Total marks : {props.demoExamAessmentDetailsList[0].total_marks}</h5>
                    </div>
                  </>
                  : null}

                {
                  props.demoExamAessmentDetailsList != "" ?
                    <Virtuoso
                      style={{ height: '100vh' }}
                      totalCount={props.demoExamAessmentDetailsList.length}
                      itemContent={index => <AssessmentDetailCard
                        statusColor={props.demoExamAessmentDetailsList[index].guest_post_ans_status}
                        guest_post_ans={props.demoExamAessmentDetailsList[index].guest_post_ans}
                        questionNo={index + 1}
                        question={props.demoExamAessmentDetailsList[index].question}
                        options={props.demoExamAessmentDetailsList[index].options}
                        studentAnswer={props.demoExamAessmentDetailsList[index].guest_post_ans === "undefined" ? "" : props.demoExamAessmentDetailsList[index].guest_post_ans}
                        correctAnswer={props.demoExamAessmentDetailsList[index].answer}
                        reason={props.demoExamAessmentDetailsList[index].reason}
                      />}
                    />
                    :
                    <div className="no_data_text">
                      <h4>No list available for online assessment</h4>
                    </div>
                }
                {/* {props.demoExamAessmentDetailsList.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <AssessmentDetailCard
                        statusColor={item.guest_post_ans_status}
                        guest_post_ans={item.guest_post_ans}
                        questionNo={index + 1}
                        question={item.question}
                        options={item.options}
                        studentAnswer={item.guest_post_ans === "undefined" ? "" : item.guest_post_ans}
                        correctAnswer={item.answer}
                        reason={item.reason}
                      />
                    </React.Fragment>
                  )
                })
                } */}
              </>

            </div>
          </div>
        </div>
        :
        null
      }
      <div>
        {props.loading ?
          <Loader />
          :
          null
        }
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    demoExamAessmentDetailsList: state.questionNo.demoExamAessmentDetailsList,
    loading: state.questionNo.loading,
  };
};
export default connect(mapStateToProps)(AssessmentDetails);