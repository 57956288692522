import React from "react";
import { Link } from "react-router-dom";

// import logo from "../../images/clv_logo.png";
import logo from "../src/images/clv_logo.png"

const MaintenanceScreen = () => {
   return (
      // <div className="authincation h-100 p-meddle">
      <div className="d-flex justify-content-center align-items-center vh-100">
         {/* <div className="container h-100"> */}
            
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-8">
                  <div className="form-input-content text-center error-page">
                     <div>
                        <img src={logo} alt="" style={{width:"40%", marginBottom:"40px"}}/>
                     </div>
                     <h1 className="error-text font-weight-bold">We’re taking time out for maintenance</h1>

                     <p>We hope to be back soon</p>
                     {/* <div>
                        <Link className="btn btn-primary" to="/dashboard">
                           Back to Home
                        </Link>
                     </div> */}
                  </div>
               </div>
            </div>
         {/* </div> */}
      </div>
   );
};

export default MaintenanceScreen;
