import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import { getChapterData, loadingChapterAction, getExamCompletedListData } from '../../../store/actions/ChapterAction';
import { getAllSubjectData, getCompititveSubjectData } from '../../../store/actions/SubjectAction';
import { getOnlineConceptMapDetails, elibraryLoading, eliraryShowCallIcon } from '../../../store/actions/LibraryAction';
import Spinner from 'react-bootstrap/Spinner';

const OnlineElibrarySubject = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [subject, setSubject] = useState('');
    const [type, setType] = useState(state)

    useEffect(() => {
        dispatch(getAllSubjectData(true));
        dispatch(getCompititveSubjectData(type, props.history))

        dispatch(eliraryShowCallIcon(1))
		return () => {
			dispatch(eliraryShowCallIcon(0))
		};


    }, []);

    /* select subject */
    const onSubjectHandler = (id) => {
        setSubject(id);
        dispatch(loadingChapterAction(true));
        dispatch(getChapterData(id, props.history));
    }

    /* select Chapter */
    const onChapterHandler = (item) => {
        dispatch(elibraryLoading(true));
        dispatch(getOnlineConceptMapDetails(2, type, item.id, props.history));
    }


    return (
        <>
            <div className="row">
                <div className="col-md-1">
                    <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-xxl-4">
                    <div className="row">
                        <div className="col-xl-12 col-sm-6">
                            <div className="card ntse-background padding_15 box_height_547">
                                <div className="form-group exam">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control form-control select_option"
                                            value={subject}
                                            onChange={(e) => onSubjectHandler(e.target.value)}
                                        >
                                            <option value="">Select Subject</option>
                                            {
                                                props.competitiveSubject.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-9 col-xxl-8">
                    <div className="card padding_15">
                        <div className="padding_15 competitive_color round_8">
                            <div className="top-heading font_16 font_white">
                                Select Chapters ({subject != '' ? `${props.chaptercList.length}` : 0})
                            </div>
                        </div>

                        <div className="botton_continer round_8 padding_15 border-blue">
                            {

                                props.chaptercList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>

                                            {subject ?
                                                <Link onClick={() => onChapterHandler(item)}>
                                                    <div className="chapters_listing margin_buttom_20">
                                                        <div clsss="chapter_heading">
                                                            <div className="font_open_sans color_gery_1 font_13 font_weight_900">
                                                                {item.chapter_name}
                                                            </div>
                                                        </div>
                                                        <div clsss="chapter_description">
                                                            <div className="font_open_sans color_gery_1 font_13">
                                                                {item.sub_heading}
                                                            </div>
                                                        </div>
                                                        <div clsss="chapter_description">
                                                            <div className="font_open_sans color_gery_1 font_13">
                                                                {item.short_code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                :
                                                null}
                                        </React.Fragment>
                                    )
                                })

                            }
                            {props.showLoadingChapter ?
                                <div className="exam_list_container" >
                                    <div className="spinner-container">
                                        <div className="spinner-view">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                //size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // console.log("OnlineElibrarySubject--xx--", state.subject.competitiveSubject)
    return {
        chaptercList: state.chapter.chapterList,
        showLoadingChapter: state.chapter.showLoadingChapter,
        purchasedSubjectList: state.subject.purchasedSubjectList,
        branchScholasticListAgainstSubjectId: state.branch.branchScholasticListAgainstSubjectId,
        subjectList: state.subject.allSubject,
        competitiveSubject: state.subject.competitiveSubject
    };
};
export default connect(mapStateToProps)(OnlineElibrarySubject);