import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard23 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [labels, setLabels] = useState(['Ch1', 'Ch2', 'Ch3', 'Ch4', 'Ch5', 'Ch6', 'Ch7', 'Ch8', 'Ch9', 'Ch10', 'Ch11', 'Ch12', 'Ch13', 'Ch14', 'Ch15', 'Ch16', 'Ch17'])
    const [chartData, setChartData] = useState([60, 60, 70, 70, 80, 80, 80, 90,])
    const [backgroundColor, setBackgroundColor] = useState(['#CBF1FA',])

    const [overallAvg, setOverallAvg] = useState(0)
    const chartRef = useRef();
    // const [colors, setColors] = useState(["#58BE4A", "#A32622", "#4BD1F6", "#A32622", "#4BD1F6", "#4BD1F6", "#A32622", "#FDC13F", "#FDC13F", "#58BE4A",],)

    // const [colors, setColors] = useState(props.dataSet.series[0].data[0] < 70 ? ["#982626"] : props.dataSet.series[0].data[0] >= 70 && props.dataSet.series[0].data[0] <= 79 ? ["#f1c431"] : props.dataSet.series[0].data[0] >= 80 && props.dataSet.series[0].data[0] <= 89 ? ["#5bba47"] : props.dataSet.series[0].data[0] <= 90 ? ["#31c5f4"] : props.dataSet.series[0].data[1] < 70 ? ["#982626"] : props.dataSet.series[0].data[1] >= 70 && props.dataSet.series[1].data[0] <= 79 ? ["#f1c431"] : props.dataSet.series[0].data[1] >= 80 && props.dataSet.series[0].data[1] <= 89 ? ["#5bba47"] : props.dataSet.series[0].data[1] >= 90 ? ["#31c5f4"]: null )

    // const [colors, setColors] = useState(["#58BE4A", "#A32622",])

    ChartJS.register(ArcElement, Tooltip, Legend);

    const [chatData, setChatData] = useState(props.dataSet)
    const [showTableModal, setShowTableModal] = useState(false)
    const [standard, setStandard] = useState('')
    const [board_name, setBoard_name] = useState('')
    const [seriesData, setSeriesData] = useState()

    useEffect(() => {
        // console.log("=====chatData==11=", Object.keys(chatData.series[0]).length)
        // console.log("=====chatData==11=", chatData.series[0].data)
        // console.log("=====chatData==12=", chatData.categories)
        // console.log("-------111---22---->>>>>>>>", props.dataSet)
    }, [chatData, props.dataSet]);

    useEffect(() => {
        setSeriesData(chatData)
        let getData = localStorage.getItem('userDetails');
        let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);
    }, [props.dataSet]);


    const barData = {

        series: [{
            // data: [82, 46, 95, 25, 98, 90, 15, 70, 78, 85]
            // data: [28,11,26,13,12,1,2,6,6,3]
            // data: [82]
            data: props.dataSet.series[0].data
        }],

        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                },
                toolbar: {
                    show: false
                },
            },
            colors: props.dataSet.series[0].colors,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false,
                style: {
                    colors: ['#000000']
                }
            },
            legend: {
                show: false
            },
            xaxis: {
                // categories: chatData.categories,
                categories: props.dataSet.categories,
                // chatData.categories
                // ['Set 1'], ['Set 2'], ['Set 3'], ['Set 4'], ['Set 5'], ['Set 6'], ['Set 7'], ['Set 8'], ['Set 9'], ['Set 10'],
                // ['Set 1'], 

                labels: {
                    show: true,
                    style: {

                        // colors: colors,
                        colors: "#000000",
                        fontSize: '10px'
                    }
                }
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 8,
            },
        }

    }

    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>

                        {props.dataSet.series[0].data.length !== 0 ?
                            <>
                                <div className="chart_body" >
                                    {/* <ReactApexChart options={barData.options} series={barData.series} type="bar" height={290} /> */}
                                    <ReactApexChart options={barData.options} series={props.dataSet.series} type="bar" height={290} />
                                </div>

                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                {/* <div className='performer_table_heading'>{props.pageHeading}</div> */}
                                <div className='performer_table_heading'>{`Set Activity - ${props.pageHeading.split('-')[1]}` }</div>
                                <p>Competitive  <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellpadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Set no.</th>
                                        <th className="text-center light_sky">Score</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {barData.options.xaxis.categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center light_gray_1">{item}</td>
                                                {/* <td className="text-center light_gray_2">{barData.series[0].data[index]}</td> */}
                                                <td className="text-center light_gray_2">{props.dataSet.series[0].data[index]}</td>
                                            </tr>
                                        )
                                    })
                                    }

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard23);