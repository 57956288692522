import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import Loader from "../../components/Loader";

import { getExamAssessmentListDetails, subscribeLoading, assessmentActivePageAction, storeAssessmentFilterDataStoreAction, backFromAssessmentDetailsAction } from '../../../store/actions/SubscribeAction';
import { onlineExamIdAction } from '../../../store/actions/ScholasticAction';
import { previousExamTypeAction } from '../../../store/actions/OnlineExamAction'
import { getCategoryData } from '../../../store/actions/ExamCategoryAction';
import { getPurchasedGroupListData, getPurchasedSubjectslistScholasticData, getPurchasedSubjectslistScholasticAction, getPurchasedSubjectData } from '../../../store/actions/SubjectAction';

import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';

const AssessmentNewList = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const assessmentActivePage = useSelector(state => state.subscribe.assessmentActivePage);
    const examCategoryloading = useSelector(state => state.category.examCategoryloading);
    const subjectLoading = useSelector(state => state.subject.loading);

    const storeAssessmentFilterDataStore = useSelector(state => state.subscribe.storeAssessmentFilterDataStore);
    const backFromAssessmentDetails = useSelector(state => state.subscribe.backFromAssessmentDetails);

    const [selectId, setSelectId] = useState('')
    const [category, setCategory] = useState(storeAssessmentFilterDataStore != '' ? storeAssessmentFilterDataStore.category : '');
    const [groupSubject, setGroupSubject] = useState(storeAssessmentFilterDataStore != '' ? storeAssessmentFilterDataStore.groupSubject : '');
    const [subject, setSubject] = useState(storeAssessmentFilterDataStore != '' ? storeAssessmentFilterDataStore.subject : '');

    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(10)
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(10)
    const [active, setActive] = useState(parseInt(assessmentActivePage));
    const [resetFirstPage, setresetFirstPage] = useState({
        selected: 0,
    })

    /* 
    if (storeAssessmentFilterDataStore != '') {
            setCategory(storeAssessmentFilterDataStore.category);
            setGroupSubject(storeAssessmentFilterDataStore.groupSubject);
            setSubject(storeAssessmentFilterDataStore.subject);

        }
    
    */


    useEffect(() => {
        // setTotalPage(props.examAssessmentList.length / pageRangeDisplayed)
        // setStart(0);
        // setEnd(10)
        dispatch(getPurchasedSubjectData(props.history))
        // console.log("assessmentActivePage---123--", end, assessmentActivePage)
        if (assessmentActivePage > 1) {
            setStart((((assessmentActivePage) - 1) * pageRangeDisplayed + 1) - 1)
            setEnd((assessmentActivePage) * pageRangeDisplayed)
        } else {
            setStart(0);
            setEnd(10)
        }
        // setEnd(props.examAssessmentList.length < 10 ? props.examAssessmentList.length : 10)
        // setEnd(10)

        /* return () => {
            if (assessmentActivePage > 1) {
                console.log("assessmentActivePage--456---", end, assessmentActivePage)
                // dispatch(assessmentActivePageAction(1))
            }
        }; */
    }, [assessmentActivePage]);

    useEffect(() => {
        // console.log("setActive(assessmentActivePage)", assessmentActivePage)
        // setActive(assessmentActivePage)
    }, [assessmentActivePage, category, groupSubject, subject, active]);

    useEffect(() => {
        // console.log("Filter data---", category, groupSubject, subject, history)
        // setActive(1)
        dispatch(subscribeLoading(true))
        dispatch(getExamAssessmentListDetails(category, groupSubject, subject, props.history));
        // console.log("props.examAssessmentList.length----", assessmentActivePage, props.examAssessmentList.length)
        // dispatch(assessmentActivePageAction(1))
        // setStart(0);
        // setEnd(10)
        // dispatch(storeAssessmentFilterDataStoreAction({ category, groupSubject, subject }))
        /* if ((assessmentActivePage * pageRangeDisplayed) > props.examAssessmentList.length) {
            console.log("===+++++++++++++++++++++++++++++++=", )
            dispatch(assessmentActivePageAction(1))
        } */

    }, [category, groupSubject, subject,]);


    useEffect(() => {
        dispatch(getCategoryData(props.history));
        dispatch(getPurchasedGroupListData(props.history))

        return () => {

        };

    }, []);

    useEffect(() => {
        dispatch(storeAssessmentFilterDataStoreAction({ category, groupSubject, subject }))
        // dispatch(assessmentActivePageAction(1))
        // setStart(0);
        // setEnd(10)
        if ((assessmentActivePage * pageRangeDisplayed) > props.examAssessmentList.length) {
            // console.log("=====112233=====", props.examAssessmentList.length)
        }

        return () => {
        };

    }, [category, groupSubject, subject, props.examAssessmentList.length]);

    useEffect(() => {
        if (!!props.examAssessmentList.length) {
            let ele = document.getElementById('assessment_list');
            let speed = 10;
            let scrollLength = 3;
            let scrolling;

            let moveLeft = false;
            let moveRight = false;
            document.getElementById('left').addEventListener("mouseenter", moveLeftStart);
            document.getElementById('right').addEventListener("mouseenter", moveRightStart);
            document.getElementById('left').addEventListener("mouseleave", moveLeftEnd);
            document.getElementById('right').addEventListener("mouseleave", moveRightEnd);
            function moveLeftStart() {
                // console.log('moving left started')
                moveLeft = true;
                moveRight = false;
                if (moveLeft) {
                    scrolling = window.setInterval(function () {
                        ele.scrollLeft -= scrollLength;
                    }, speed);
                }
            }
            function moveRightStart() {
                // console.log('moving right started');
                moveLeft = false;
                moveRight = true;
                if (moveRight) {
                    scrolling = window.setInterval(function () {
                        ele.scrollLeft += scrollLength;
                    }, speed);
                }

            }
            function moveLeftEnd() {
                // console.log('moving left End')
                moveLeft = false;
                if (!moveLeft) {
                    window.clearInterval(scrolling);
                }

            }
            function moveRightEnd() {
                // console.log('moving left End')
                moveRight = false;
                if (!moveRight) {
                    window.clearInterval(scrolling);
                }
            }
        }
    }, [props.examAssessmentList]);

    const firstPageSet = () => {
        // console.log("Call FirstPageSet")
        // setActive(1)
        setStart(0);
        setEnd(10)
        dispatch(assessmentActivePageAction(1))
        dispatch(backFromAssessmentDetailsAction(0))
        handlePageClick(resetFirstPage)
    }

    const selectSubject = (id) => {
        // console.log("Check", id)
        setSelectId(id)
    }

    const updateExamUniqueId = (exam_unique_id, category_id) => {
        const examDetails = {
            examId: exam_unique_id,
            exam: category_id,
            fromExam: 0
        }
        localStorage.setItem('refreshExamId', JSON.stringify(examDetails));

        dispatch(previousExamTypeAction(category_id))
        history.push({ pathname: "/online-assessment-details", state: { exam: category_id, exam_unique_id: exam_unique_id, category: category, groupSubject: groupSubject, subject: subject } })

        dispatch(onlineExamIdAction(exam_unique_id))

    }

    const handlePageClick = (data) => {
        // console.log("data---11-", data, active)
        dispatch(assessmentActivePageAction(data.selected + 1))
        setActive(data.selected + 1)
        setStart((((data.selected + 1) - 1) * pageRangeDisplayed + 1) - 1)
        setEnd((data.selected + 1) * pageRangeDisplayed)
        if (((data.selected + 1) * pageRangeDisplayed) > props.examAssessmentList.length) {
            // console.log("@1======")
            // setEnd(props.examAssessmentList.length);
        }
    }

    const onResetHandler = () => {
        setCategory('');
        setGroupSubject('');
        setSubject('');
    };

    const onCategoryHandler = (val) => {
        setCategory(val);
        firstPageSet();
        if (val == '') {
            setGroupSubject('')
            setSubject('')
        }
    }

    const showSubjectListAsSubjectid = (value) => {
        if (value == '') {
            dispatch(getPurchasedSubjectslistScholasticAction([]))
        }
        setSubject('');
        setGroupSubject(value);
        dispatch(getPurchasedSubjectslistScholasticData(value, props.history))
        firstPageSet();
    }

    const showBranchListAgainstSubjectid = (subjectId) => {
        // console.log("++++++", subjectId)
        setSubject(subjectId);
        firstPageSet();
    }

    const goToAssessmentDetailsPage = () => {
        // console.log("goToAssessmentDetailsPage++++++++++++++++++++++++++++")
        dispatch(backFromAssessmentDetailsAction(0))
    }


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className='text_blue'>Online Exam <i className="bi bi-chevron-right"></i> Assessment List </div>
                </div>
                <div className='mt-3'></div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card students-list">
                        <div className="card-header border-0 flex-wrap pb-0">
                            <h4>Assessment List</h4>
                        </div>


                        {/* Filter---------- */}

                        <div className="add-Question-list-view h_padding ">
                            <div className="filter-container d-flex flex-wrap justify-content-end">  {/* justify-content-between */}
                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow">
                                            {examCategoryloading ? <Spinner animation="border" size="sm" /> :
                                                <i className="bi bi-caret-down-fill"></i>
                                            }
                                        </span>

                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            // value={category}
                                            value={category}
                                            // ref={demoTypeRef}
                                            onChange={(e) => onCategoryHandler(e.target.value)}
                                        >
                                            <option value="" name="Select Type">Select category</option>
                                            {
                                                props.categorylist.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.category}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={`col-sm-3 px-1 ${category == 2 || category == '' ? "subscription-disable" : null}`}>
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow">
                                            {subjectLoading ? <Spinner animation="border" size="sm" /> :
                                                <i className="bi bi-caret-down-fill"></i>
                                            }
                                        </span>

                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={groupSubject}
                                            // ref={demoTypeRef}
                                            onChange={(e) => showSubjectListAsSubjectid(e.target.value)}
                                        >
                                            <option value="">Select group</option>
                                            {
                                                props.getpurchasedGrouplist.map((item, index) => {
                                                    return <option key={index} value={item.subejct_id}>{item.subject_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={`col-sm-2 px-1 ${category == 2 || category == '' /* || groupSubject == '' */ ? "subscription-disable" : null}`}>
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow">
                                            {subjectLoading && groupSubject == '' ? <Spinner animation="border" size="sm" /> :
                                                <i className="bi bi-caret-down-fill"></i>
                                            }
                                        </span>

                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={subject}
                                            // ref={demoTypeRef}
                                            onChange={(e) => showBranchListAgainstSubjectid(e.target.value)}
                                        >
                                            <option value="">Select subject</option>
                                            {groupSubject != "" ?
                                                <>
                                                    {
                                                        props.getpurchasedSubjectListAsPerGroupId.map((item, index) => {
                                                            return <option key={index} value={item.subject_id}>{item.subject_name}</option>
                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        props.purchasedSubjectList.map((item, index) => {
                                                            return <option key={index} value={item.subject_id}>{item.subject_name}</option>
                                                        })
                                                    }
                                                </>
                                            }

                                        </select>
                                    </div>
                                </div>

                                <div className={`col-sm-1 reset-btn-view ${category == '' ? "subscription-disable" : null}`}>
                                    <button
                                        onClick={onResetHandler}
                                        className="btn btn-secondary text-center reset_btn_background"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Filter---End------- */}

                        {props.examAssessmentList != '' ?
                            <>
                                <div className="card-body py-0 table_parent_container">
                                    <div className="table-responsive" id="assessment_list" >
                                        <div id="left"></div>
                                        <div id="right"></div>
                                        <div id="student_wrapper_list" className="dataTables_wrapper no-footer">
                                            <table className="table display mb-4 dataTablesCard order-table card-table text-black application " id="application-tbl11_next">
                                                <thead>
                                                    <tr>
                                                        <th>Exam category</th>
                                                        <th>Exam name</th>
                                                        <th>Subject group</th>
                                                        <th>Subject name</th>
                                                        <th>Chapter name</th>
                                                        <th>Type of exam</th>
                                                        <th>Appeared on</th>
                                                        <th>Submitted on</th>
                                                        <th>Status</th>
                                                        <th>View</th>
                                                        <th>Assessment <br />availability</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {props.examAssessmentList.slice(start, end).map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">

                                                                    {item.category}
                                                                </div>
                                                            </td>
                                                            <td>{item.exam_name}</td>
                                                            <td>{item.subject_group_name}</td>
                                                            <td>{item.subject_name}</td>
                                                            <td><div className='chapter_col'>{item.chapter_name}</div></td>
                                                            <td>{item.exam_type}</td>

                                                            <td>{<Moment format="MMMM Do YYYY," withTitle>{item.appeared_on}</Moment>}<br />{<Moment format="HH:mm:ss" withTitle>{item.appeared_on}</Moment>} </td>
                                                            <td>{<Moment format="MMMM Do YYYY," withTitle>{item.submitted_on}</Moment>}<br />{<Moment format="HH:mm:ss" withTitle>{item.submitted_on}</Moment>} </td>

                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="status_btn d-flex jcc aic" style={{ backgroundColor: item.status == 1 ? '#9CC2E5' : '#00FF00' }}
                                                                    >
                                                                        {item.status == 1 ? "Completed" : "Continue "}
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className={`action_btn view_action_btn_color d-flex jcc aic ${item.is_expired == 0 ? "cursorPointer" : null} `}
                                                                        onClick={() => [item.is_expired == 0 ? updateExamUniqueId(item.exam_unique_id, item.category_id) : null, goToAssessmentDetailsPage()]}
                                                                    >
                                                                        <i className={`fas ${item.is_expired == 0 ? `fa-eye` : `fa-eye-slash`} font_white`}></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{item.assessment_available}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            <div className='mt-5'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-5'></div>
                                <div className="pagination-container d-flex justify-content-between flex-row h_padding">
                                    {/* <div className="pagination-container d-flex flex-wrap justify-content-between"> */}
                                    <div className="page-result">
                                        {props.examAssessmentList.length < 11 ?
                                            <p>{(start + 1) + '-' + props.examAssessmentList.length + ' of ' + props.examAssessmentList.length + ' items'}</p>
                                            :
                                            end > props.examAssessmentList.length ? <p>{(start + 1) + '-' + props.examAssessmentList.length + ' of ' + props.examAssessmentList.length + ' items'}</p> : <p>{(start + 1) + '-' + end + ' of ' + props.examAssessmentList.length + ' items'}</p>
                                        }
                                        {/* <p>{(start + 1) + '-' + end + ' of ' + props.examAssessmentList.length + ' items'}</p> */}
                                    </div>

                                    {props.examAssessmentList.length > 10 ?
                                        < div >
                                            {/* {console.log("**active***", active, start, end, backFromAssessmentDetails)} */}
                                            <ReactPaginate
                                                containerClassName='pagination-gutter false pagination-circle pagination pagination-sm'
                                                pageClassName='page-item'
                                                pageLinkClassName='page-link'
                                                breakLabel="..."
                                                nextLabel={<i className="la la-angle-right"></i>}
                                                nextClassName='page-item'
                                                nextLinkClassName='page-link'
                                                previousLabel={<i className="la la-angle-left"></i>}
                                                previousClassName='page-item'
                                                previousLinkClassName='page-link'
                                                breakClassName='page-item'
                                                breakLinkClassName='page-link'
                                                renderOnZeroPageCount={null}
                                                activeClassName="active"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                pageCount={parseInt(Math.ceil(props.examAssessmentList.length / pageRangeDisplayed))}
                                                // initialPage={active - 1}
                                                forcePage={active - 1}
                                            />
                                        </div>
                                        : null}
                                </div>
                                <div className='mt-5'></div>
                            </>
                            :
                            <div className="card card-header border-0 flex-wrap pb-5">
                                <h4>No Data Found</h4>
                            </div>
                        }
                    </div>
                </div>

            </div >

            <div>
                {props.loading ?
                    <Loader />
                    : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // console.log("$$$$$$----", state.subscribe.examAssessmentList)
    return {
        examAssessmentList: state.subscribe.examAssessmentList,
        categorylist: state.category.examcategoryList,
        getpurchasedGrouplist: state.subject.getpurchasedGrouplist,
        getpurchasedSubjectListAsPerGroupId: state.subject.getpurchasedSubjectListAsPerGroupId,
        purchasedSubjectList: state.subject.purchasedSubjectList,
    };
};
export default connect(mapStateToProps)(AssessmentNewList);