import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

/* API call get class data */
export function getClassStandardList() {
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CLASS_STANDARD_LIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
    });
}