import { faIR } from 'date-fns/locale';
import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';

import { demoExamSubmitAction } from '../actions/ScholasticAction'

import {
    LOGIN_CONFIRMED,
    LOADING_TOGGLE,
    LOGIN_FAILED,
    LOGOUT,
    SIGNUP_CONFIRMED,
    SIGNUP_FAILED,
    DEMO_LOGIN,
    REG_USER_SUB_OR_NOT,
    USERID_DEMO_TO_REGISTER,
    FORGET_PASSWORD_EMAIL_SUCCESS,
    FORGET_PASSWORD_EMAIL_FAILURE,
    GENERATE_PASSWORD,
    REG_USER_ELIBRARU_OR_NOT,
    GO_TO_TAB,
    USER_UPDATE_PROFILE_CONFIRMED,
    CHANGE_TAB,
    TERMS_CONDITION,
    PRIVACY_POLICY,

    UNLOCK_SCREEN,

    FEEDBACK_GIVEN,
    OTP_BEFORE_LOGIN,
    PASSWORD_RESET_TIME_CHECK,
    RESET_LINK_EXIST_OR_NOT

} from '../constants';

const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
    },
    email: '',
    user_id: '',
    user_name: '',
    token: '',
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    unLock: false,
    fname: '',
    lname: '',
    is_subscribe: 0,
    standard: '',
    board: '',
    board_name: '',
    forgetEmail: '',
    generatePasswordEmail: '',
    work_status: '',
    work_status_percentage: '',
    total_scholastic_master: '',
    total_competitive_master: '',
    scholatic_details_purchase: '',
    competive_details_purchase: '',
    total_scholastic_completed: '',
    total_competitive_completed: '',
    scholatic_overall: '',
    competitive_overall: '',
    is_subscribe_e_library: null,
    tabName: 'login',
    changeTabName: '',
    termsCondition: '',
    privacyPolicy: '',
    feedback_status: null,
    total_scholastic_completed_master: '',
    total_competitive_completed_master: '',
    performance_details_comp: [],
    performance_details_sch: [],
    performance_total_exam_count: 0,

    OtpBeforeLoginScreen: '',
    resetLinkExistOrNot:1
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    /* user details store into reducer */
    if (action.type === LOGIN_CONFIRMED) {
        return {
            ...state,
            email: action.payload.email,
            user_id: action.payload.id,
            user_name: action.payload.name,
            token: action.payload.token,
            fname: action.payload.fname,
            lname: action.payload.lname,
            errorMessage: '',
            successMessage: 'You have Logged in successfully. Welcome!',
            showLoading: false,
            standard: action.payload.standard,
            is_subscribe: action.payload.is_subscribe,
            board: action.payload.board,
            board_name: action.payload.board_name,
            work_status: action.payload.work_status,
            work_status_percentage: action.payload.work_status_percentage,
            total_scholastic_master: action.payload.total_scholastic_master,
            total_competitive_master: action.payload.total_competitive_master,
            scholatic_details_purchase: action.payload.scholatic_details_purchase,
            competive_details_purchase: action.payload.competive_details_purchase,
            total_scholastic_completed: action.payload.total_scholastic_completed,
            total_competitive_completed: action.payload.total_competitive_completed,
            scholatic_overall: action.payload.scholatic_overall,
            competitive_overall: action.payload.competitive_overall,
            is_subscribe_e_library: action.payload.is_subscribe_e_library,
            feedback_status: action.payload.feedback_given,
            total_scholastic_completed_master: action.payload.total_scholastic_completed_master,
            total_competitive_completed_master: action.payload.total_competitive_completed_master,
            performance_details_comp: action.payload.performance_details_comp,
            performance_details_sch: action.payload.performance_details_sch,
            performance_total_exam_count: action.payload.performance_total_exam_count,
        };
    }
    if (action.type === USER_UPDATE_PROFILE_CONFIRMED) {
        return {
            ...state,
            fname: action.payload.fname,
            lname: action.payload.lname,
            email: action.payload.email,
            user_id: action.payload.id,
            board: action.payload.board,
            standard: action.payload.standard,
            board_name: action.payload.board_name,
        };
    }

    if (action.type === LOGOUT) {
        return {
            ...state,
            email: '',
            user_id: '',
            user_name: '',
            token: '',
            errorMessage: '',
            successMessage: '',
            showLoading: false,
            fname: '',
            lname: '',
            is_subscribe: 0,
            standard: '',
            board: '',
            board_name: '',
            forgetEmail: '',
            generatePasswordEmail: '',
            work_status: '',
            work_status_percentage: '',
            total_scholastic_master: '',
            total_competitive_master: '',
            scholatic_details_purchase: '',
            competive_details_purchase: '',
            total_scholastic_completed: '',
            total_competitive_completed: '',
            scholatic_overall: '',
            competitive_overall: '',
            is_subscribe_e_library: null,
            tabName: 'login',
            changeTabName: '',
            termsCondition: '',
            privacyPolicy: '',
            feedback_status: null,

        };
    }

    if (action.type === DEMO_LOGIN) {
        return {
            ...state,
            email: action.payload.email,
            user_id: action.payload.id,
            user_name: action.payload.name,
            token: action.payload.token,
            fname: action.payload.fname,
            lname: action.payload.lname,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
            standard: action.payload.standard,
            is_subscribe: action.payload.is_subscribe,
            board: action.payload.board,
            board_name: action.payload.board_name,
            work_status: action.payload.work_status,
            work_status_percentage: action.payload.work_status_percentage,
            total_scholastic_master: action.payload.total_scholastic_master,
            total_competitive_master: action.payload.total_competitive_master,
            scholatic_details_purchase: action.payload.scholatic_details_purchase,
            competive_details_purchase: action.payload.competive_details_purchase,
            total_scholastic_completed: action.payload.total_scholastic_completed,
            total_competitive_completed: action.payload.total_competitive_completed,
            scholatic_overall: action.payload.scholatic_overall,
            competitive_overall: action.payload.competitive_overall,
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    if (action.type === REG_USER_SUB_OR_NOT) {
        return {
            ...state,
            is_subscribe: action.payload,
        };
    }
    if (action.type === USERID_DEMO_TO_REGISTER) {
        return {
            ...state,
            user_id: action.payload,
        };
    }
    if (action.type === FORGET_PASSWORD_EMAIL_SUCCESS) {
        return {
            ...state,
            forgetEmail: action.payload,
            showLoading: false,
        };
    }
    if (action.type === FORGET_PASSWORD_EMAIL_FAILURE) {
        return {
            ...state,
            forgetEmail: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GENERATE_PASSWORD) {
        return {
            ...state,
            generatePasswordEmail: action.payload,
            showLoading: false,
        };
    }

    if (action.type === REG_USER_ELIBRARU_OR_NOT) {
        return {
            ...state,
            is_subscribe_e_library: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GO_TO_TAB) {
        return {
            ...state,
            tabName: action.payload,
            showLoading: false,
        };
    }

    if (action.type === CHANGE_TAB) {
        return {
            ...state,
            changeTabName: action.payload,
            showLoading: false,
        };
    }

    if (action.type === TERMS_CONDITION) {
        return {
            ...state,
            termsCondition: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UNLOCK_SCREEN) {
        return {
            ...state,
            unLock: action.payload,
            showLoading: false,
        };
    }



    if (action.type === PRIVACY_POLICY) {
        return {
            ...state,
            privacyPolicy: action.payload,
            showLoading: false,
        };
    }

    if (action.type === FEEDBACK_GIVEN) {
        return {
            ...state,
            feedback_status: action.payload,
            showLoading: false,
        };
    }

    if (action.type === OTP_BEFORE_LOGIN) {
        return {
            ...state,
            OtpBeforeLoginScreen: action.payload,
        };
    }
    if (action.type === RESET_LINK_EXIST_OR_NOT) {
        return {
            ...state,
            resetLinkExistOrNot: action.payload,
        };
    }
    return state;
}


