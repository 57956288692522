import React, { useEffect, useState, } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard27 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [showTableModal, setShowTableModal] = useState(false)
    const [board_name, setBoard_name] = useState('')
    const [standard, setStandard] = useState('')

    var mostVisiteQuestion = {

        /* series: [{
            name: 'Servings',
            data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
        }], */
        series: [props.series],
        options: {
            /* annotations: {
                points: [{
                    x: 'Bananas',
                    seriesIndex: 0,
                    label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                        text: 'Bananas are good',
                    }
                }]
            }, */
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                  }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2
            },

            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            xaxis: {
                labels: {
                    rotate: -45
                },
                /* categories: ['Apples', 'Oranges', 'Strawberries', 'Pineapples', 'Mangoes', 'Bananas',
                    'Blackberries', 'Pears', 'Watermelons', 'Cherries', 'Pomegranates', 'Tangerines', 'Papayas'
                ], */
                categories: props.categories,
                tickPlacement: 'on'
            },
            yaxis: {
                /* title: {
                    text: 'Servings',
                }, */
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                },
            },
        },


    };

    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);

    }, []);

    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }


    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        {mostVisiteQuestion.options != '' && mostVisiteQuestion.series != '' ?
                            <>
                                <div className="chart_body " >
                                    <ReactApexChart
                                        options={mostVisiteQuestion.options}
                                        series={mostVisiteQuestion.series}
                                        type="bar"
                                        height={300}
                                    />

                                </div>
                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData} >Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex-1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                <div className='performer_table_heading'>Most Search Questions</div>
                                <p>e-library <i className="fa fa-angle-right assessment_icon_color"></i> {/* {board_name}: {standard}  <i className="fa fa-angle-right assessment_icon_color"></i> */} Most Search Questions</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Subject</th>
                                        <th className="text-center light_sky">Most search questions</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {props.categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center light_gray_1">{item}</td>
                                                <td className="text-center light_gray_2">{props.series.data[index]}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard27);