import React, { useEffect, useState } from 'react';
import { connect, } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard18 = (props) => {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const [chatData, setChatData] = useState(props.dataSet)
    const [seriesData, setSeriesData] = useState()
    const [showTableModal, setShowTableModal] = useState(false)
    const [standard, setStandard] = useState('')
    const [board_name, setBoard_name] = useState('')
    const [fName, setFName] = useState('')


    useEffect(() => {
        setSeriesData(chatData)
        let getData = localStorage.getItem('userDetails');
        let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        let studentFirstName = JSON.parse(getData).fname;
        setBoard_name(board_name);
        setStandard(standard);
        setFName(studentFirstName);
    }, []);




    /* const barData = {
        series: [
            {
                name: 'Student',
                type: 'column',
                data: [140, 23, 33.1, 34, 44.1, 44.9, 56.5, 58.5]
                // data: props.dataSet.series[0].data
            },
            {
                name: "Market Trend",
                type: 'column',
                data: [10, 19, 27, 26, 34, 35, 40, 38]
                // data: props.dataSet.series[1].data
            },
            {
                name: "Score",
                type: 'line',
                data: [0.1, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                // data: props.dataSet.series[2].data
            },
        ],
        options: {
            chart: {
                height: 350,
                type: "line",
                stacked: false,
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#269FFB', '#26E7A5', '#FEBA38'],

            stroke: {
                width: [1, 1, 4]
            },
            plotOptions: {
                bar: {
                    columnWidth: "60%"
                }
            },
            xaxis: {
                categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
                // categories: props.dataSet.categories,
            },
            yaxis: [
                {
                    // min: 0,
                    // max: 100,
                    // tickAmount: 8,
                    seriesName: 'Student',
                    axisTicks: {
                        show: true
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#008FFB',
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    title: {
                        show: false,
                        text: "Market Trend"
                    }
                },
                {
                    seriesName: 'Market Trend',
                    show: true
                }, {
                    // min: 0,
                    // max: 100,
                    // tickAmount: 8,

                    opposite: true,
                    seriesName: 'Line C',
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: true,
                    },
                    axisBorder: {
                        show: false,
                        color: '#ff0000'
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#FEBA38',
                        }
                    },
                    title: {
                        // text: "Line"
                    }
                }
            ],
            tooltip: {
                shared: false,
                intersect: true,
                x: {
                    show: false
                }
            },

            legend: {
                show: false,
                horizontalAlign: "left",
                offsetX: 100
            }
        }
    }; */

    const barData = {
        series: [
            {
                // name: 'Student',
                name: fName,
                type: 'column',
                // data: [140, 23, 33.1, 34, 44.1, 44.9, 56.5, 58.5]
                data: props.dataSet.series[0].data
            },
            {
                name: "Market trend",
                type: 'column',
                // data: [10, 19, 27, 26, 34, 35, 40, 38]
                data: props.dataSet.series[1].data
            },
            {
                name: "Score",
                type: 'line',
                // data: [0.1, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                data: props.dataSet.series[2].data
            },
        ],
        options: {
            xaxis: {
                // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
                categories: props.dataSet.categories,
            },
        }
        
    };

    const options = {
        series: [{
            // data: [44, 55, 41, 64, 22, 43, 21]
            // name: 'Student',
            name: fName,
            data: props.dataSet.series[0].data
        }, {
            // data: [53, 32, 33, 52, 13, 44, 32]
            name: 'Market Trend',
            data: props.dataSet.series[1].data
        }],
        options: {
            chart: {
                type: 'bar',
                height: 430,
                stacked: false,
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            xaxis: {
                // categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
                categories: props.dataSet.categories,
            },
            
        }
    };

    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }


    return (
        <>
            {/* {console.log("%%%%--1--", props.dataSet.series[0].data)} */}
            {/* {console.log("%%%%--2--", props.dataSet.series[1].data)} */}
            {/* {console.log("%%%%---3-", props.dataSet.series[2].data)} */}
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        {Object.keys(chatData).length !== 0 ?
                            <>
                                <div className="chart_body" >
                                    {/* <ReactApexChart options={barData.options} series={barData.series} type="bar" height={290} /> */}
                                    <ReactApexChart options={options.options} series={options.series} type="bar" height={290} />

                                </div>
                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                <div className='performer_table_heading'>{props.pageHeading}</div>
                                <p>Competitive  <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}{/* {board_name}: {standard} */}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container tableFixHead'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead className="thableHeaderBackground">
                                    <tr>
                                        <th className="text-center light_sky">Subject</th>
                                        {
                                            barData.series.slice(1, 3).map((item, index) => {
                                                return (

                                                    <th className="text-center light_sky" key={index}>{item.name}{item.name == "Market trend" ? "(%)" : ""}</th>
                                                )
                                            })
                                        }
                                    </tr>

                                </thead>
                                <tbody>
                                    {/* // {console.log("%%%%%----", item)} barData[item].xaxis */}
                                    {/* // item.series.map((item2, i) ={}) */}
                                    {barData.options.xaxis.categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center light_gray_1">{item}</td>
                                                {/* <td className="text-center light_gray_2">{barData.series[0].data[index]}</td> */}
                                                <td className="text-center light_gray_2">{barData.series[1].data[index]}</td>
                                                <td className="text-center light_gray_2">{barData.series[2].data[index]}</td>
                                            </tr>
                                        )
                                    })
                                    }

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard18);