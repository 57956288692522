import React, {useEffect} from 'react';

import { connect, useDispatch } from 'react-redux';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';

import CategoryCard from '../../components/eLibrary/CategoryCard';

import { getExamTypeData } from '../../../store/actions/ExamTypeAction';
import { eliraryShowCallIcon } from '../../../store/actions/LibraryAction';


const ExamListData = [

	{ link_to: 'e-library-branch-Library', background_color: '#4ba5c0', left_image: ntse, left_image_class: 'left_image', title: 'NTSE', short_description: 'National Talent Search Examination', right_image_class: 'right_image', left_icon_color: '#4ba5c0', },
	{ link_to: '', background_color: '#5fc3e1', left_image: nstse, left_image_class: 'left_image', title: 'NSTSE', short_description: 'National Level Science Talent Search Examination', right_image_class: 'right_image', left_icon_color: '#4ba5c0' },

];

const TypeLibrary = (props) => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getExamTypeData(2, props.history));

		dispatch(eliraryShowCallIcon(1))
		return () => {
			dispatch(eliraryShowCallIcon(0))
		};

	}, []);

	return (
		<>
			<div className="row">
				<div className="demo-elibrary" >
					{props.typelist.map((item, index) => (
						<CategoryCard
							key={index}
							path={item.id == 1 ? 'page-e-library-exam' : ''}
							icon={item.id == 1 ? ntse : nstse}
							title={item.type_name}
							description={item.sub_heading}
							bgColor={item.id == 1 ? '#4ba5c0' : '#5fc3e1'}
							leftColor={'#4ba5c0'}
							iconClass={'left_image'}
							iconClassRgt={'right_image'}
							
						/>
					))}
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList
	};
};

export default connect(mapStateToProps)(TypeLibrary);