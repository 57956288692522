import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../../components/Loader"

import { afterPurchasedGetPurchasedData, getLastPaymentDetailsDataDetails, subscribeLoading, sideNavAction } from '../../../store/actions/SubscribeAction';

const PaymentSuccessful = (props) => {

    const location = useLocation();
    const dispatch = useDispatch();

    const [previousValue, setPrevousValue] = useState(location.state)
    const getLastPaymentDetails = useSelector(state => state.subscribe.getLastPaymentDetails);
    const showLoading = useSelector(state => state.subscribe.showLoading);

    useEffect(() => {
        // console.log("****PaymentSuccessful**",)
        // document.redirect.submit();
        dispatch(subscribeLoading());
        dispatch(afterPurchasedGetPurchasedData());
        dispatch(getLastPaymentDetailsDataDetails());
        dispatch(sideNavAction(false));
    }, []);

    /* useEffect(() => {
        
    }, [getLastPaymentDetails]); */



    return (
        <div>

            <div className="row">
                <div className="col-xl-12" >
                    <div className="card students-list payment_message_box jcc aic">
                        <div className="card-header border-0 flex-wrap pb-0 jcc aic flex-column">
                            <div className="icon_round success_color dflex jcc aic ">
                                <i className="fa fa-check font_white payment_icon_size" aria-hidden="true"></i>

                            </div>
                            <div className="mt-3 mb-3"><h1 className="font_green">Success</h1></div>
                            <div>
                                <h5>Transaction Process Successfully Done</h5>
                            </div>

                        </div>
                        {/* <div className='mt-5'></div> */}
                        {getLastPaymentDetails != '' && getLastPaymentDetails != null && getLastPaymentDetails != undefined ?
                            <div className="d-flex justify-content-center  flex-column parent_container_width">
                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom ">
                                    <div className="left_width"><h4>Amount </h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.amount}</h4></div>
                                </div>
                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom">
                                    <div className="left_width"><h4>Bank Ref No </h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.bank_ref_no}</h4></div>
                                </div>
                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom">
                                    <div className="left_width"><h4>Card Name </h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.card_name}</h4></div>
                                </div>

                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom">
                                    <div className="left_width"><h4>Order Id</h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.order_id}</h4></div>
                                </div>
                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom">
                                    <div className="left_width"><h4>Payment Mode</h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.payment_mode}</h4></div>
                                </div>
                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom">
                                    <div className="left_width"><h4>Payment Transaction Id</h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.payment_trans_id}</h4></div>
                                </div>
                                <div className="d-flex justify-content-center flex-direction-row parent_container_width border_bottom">
                                    <div className="left_width"><h4>Transaction Date</h4></div>
                                    <div className="middle_width"><h4>:</h4> </div>
                                    <div className="right_width"><h4>{getLastPaymentDetails.trans_date}</h4></div>
                                </div>
                            </div>
                            : null}
                        <div className='mt-5'></div>
                    
                    </div>
                </div>
            </div>
            <div>
                {showLoading ?
                    <Loader />
                    : null}
            </div>
        </div>
    )
}

export default PaymentSuccessful;
