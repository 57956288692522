import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../../components/Loader"

import ReactApexChart from "react-apexcharts";

import { performanceRequestAction, getScholasticAveragePerformanceData, scholasticAveragePerformanceFailureAction } from '../../../store/actions/PerformanceScoreAction';


const AvgSetExams = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [graphicDetails, setGraphicDetails] = useState([]);
    const [graphicSubDetails, setGraphicSubDetails] = useState();
    const scholasticAveragePerformance = useSelector(state => state.performance.scholasticAveragePerformance);
    // scholasticAveragePerformance

    const totalRef = useRef(true);
    const functionalCallFirstTimeRef = useRef(true);

    useEffect(() => {
        if (functionalCallFirstTimeRef.current) {
            dispatch(performanceRequestAction(true))
            dispatch(getScholasticAveragePerformanceData(previousValue.type, props.history))
        }
        functionalCallFirstTimeRef.current = false

        const graphValueDetailsData = [];
        var seriesData = null;
        for (let i = 0; i < props.scholasticAveragePerformance.length; i++) {
            let seriesData = {};
            seriesData = props.scholasticAveragePerformance[i].avg;
            graphValueDetailsData.push(seriesData);
        }
        setGraphicDetails(graphValueDetailsData);


        const graphSubNameDetailsData = [];
        var seriesNameData = null;
        for (let i = 0; i < props.scholasticAveragePerformance.length; i++) {
            let seriesNameData = {};
            seriesNameData = props.scholasticAveragePerformance[i].subject_name;
            graphSubNameDetailsData.push(seriesNameData);
        }
        setGraphicSubDetails(graphSubNameDetailsData);

    }, [scholasticAveragePerformance]);

    /* useEffect(() => {
        return () => {
            dispatch(scholasticAveragePerformanceFailureAction(''));
        };
    }, []); */

    /* chart data */
    const averageChartData = {
        // series: [71, 63, 90],
        series: graphicDetails,
        options: {
            chart: {
                type: "radialBar",
                // width:320,
                // height: 370,
                offsetY: 0,
                offsetX: 0,
            },
            plotOptions: {
                radialBar: {
                    size: undefined,
                    inverseOrder: false,
                    hollow: {
                        margin: 0,
                        size: "70%",
                        background: "transparent",
                    },

                    track: {
                        show: true,
                        background: "#ff6a59",
                        strokeWidth: "0%",
                        opacity: 1,
                        margin: 0, // margin is in pixels
                    },
                },
            },
            responsive: [
                {
                    breakpoint: 830,
                    options: {
                        chart: {
                            offsetY: 0,
                            offsetX: 0,
                        },
                        legend: {
                            position: "bottom",
                            offsetX: 0,
                            offsetY: 0,
                        },
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    size: "20%",
                                },
                            },
                        },
                    },
                },
                {
                    breakpoint: 800,
                    options: {
                        chart: {
                            offsetY: 0,
                            offsetX: 0,
                        },
                        legend: {
                            position: "bottom",
                            offsetX: 0,
                            offsetY: 0,
                        },
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    size: "10%",
                                },
                            },
                        },
                    },
                },
                {
                    breakpoint: 768,
                    options: {
                        chart: {
                            offsetY: 0,
                            offsetX: 0,
                        },
                        legend: {
                            position: "bottom",
                            offsetX: 0,
                            offsetY: 0,
                        },
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    size: "30%",
                                },
                            },
                        },
                    },
                },
                {
                    breakpoint: 330,
                    options: {
                        chart: {
                            offsetY: 0,
                            offsetX: 0,
                        },
                        legend: {
                            position: "bottom",
                            offsetX: 0,
                            offsetY: 0,
                        },
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    size: "20%",
                                },
                            },
                        },
                    },
                },
            ],

            fill: {
                opacity: 1,
            },

            // colors: ["#ff6a59", "#ff6a59", "#ff6a59"],
            // '#4472c4', '#7030a0', '#843c0c'. '#7f6000', '#2f5597', '#7f7f7f', '#548235', '#ffc000' 
            colors: ['#4472c4', '#7030a0', '#843c0c', '#7f6000', '#2f5597', '#7f7f7f', '#548235', '#ffc000'],
            // colors: ["#ff0000", "#ff6a59", "#0000ff"],
            labels: graphicSubDetails,
            // labels: ["Ticket A", "Ticket B", "Ticket C"],
            legend: {
                fontSize: "14px",
                show: true,
                position: "bottom",
            },
        },
    };

    /* const goToDetailsPage = () => {
        history.push({pathname:"/online-subject-module"})
    } */

    /* select item */
    const showItem = (item) => {
        if (previousValue.type == 1) {
            history.push({ pathname: "/online-subject-scorecard-set-wise", state: { subject_id: item.subject_id, subject_name: item.subject_name, type_name: previousValue.type_name } })
        } else if (previousValue.type == 2) {
            history.push({ pathname: "/online-subject-module", state: { subject_id: item.subject_id, subject_name: item.subject_name, type: previousValue.type, type_name: previousValue.type_name } })
        } else if (previousValue.type == 3) {
            history.push({ pathname: "/online-subject-module", state: { subject_id: item.subject_id, subject_name: item.subject_name, type: previousValue.type, type_name: previousValue.type_name } })
        }
    }

    return (
        <>
            {props.scholasticAveragePerformance != '' ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className="text-center"><h3>Avg. PSC {previousValue.type_name} Exams</h3></div>
                        <div className='performance_parent_container'>
                            <div className='score_container flex-1'>
                                <div className='score_card_parent_container'>
                                    <table>
                                        <tr>
                                            <th className="text-center">Sub</th>
                                            <th className="text-center">Avg</th>
                                        </tr>
                                        <tbody>
                                            {props.scholasticAveragePerformance != "" ?
                                                props.scholasticAveragePerformance.map((item, i) => {
                                                    return [
                                                        <tr className={`${i % 2 == 0 ? `tr-background` : null} cursorPointer average_table`} key={i} onClick={() => showItem(item)} >
                                                            <td className="text-center">{item.subject_name}</td>
                                                            <td className="text-center">{item.avg}</td>
                                                        </tr>
                                                    ];
                                                })
                                                : <tr>
                                                    <td colSpan={4}>No data Available</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className='chart_container flex-1 wh400 jcc text-center '>
                                {/* <ChartistGraph data={data} options={options} type={type} /> */}
                                <div id="chart" className="line-chart-style bar-chart">
                                    <ReactApexChart
                                        options={averageChartData.options}
                                        series={averageChartData.series}
                                        type="radialBar"
                                        height={props.height ? props.height : 370}
                                    />
                                </div>

                            </div>

                        </div>
                        {/*  */}
                        {/* {previousValue.type == 2 || previousValue.type == 3 ?
                    <div className="text-center details_text" onClick={goToDetailsPage}>
                        <div className="btn btn-danger">Details</div>
                    </div>
                    : null} */}
                    </div>
                </>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        scholasticAveragePerformance: state.performance.scholasticAveragePerformance,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(AvgSetExams);
