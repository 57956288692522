import React, { useEffect, } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { selectExamCategoryAction, getCategoryData, selectExamCategoryRequestAction } from '../../../store/actions/ExamCategoryAction';
import { onlineScholasticAssessmentListSuccessAction } from '../../../store/actions/ScholasticAction';

import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";

import graph_score from '../../../images/score_card.png';

//Import Components
import CategoryCard from '../../components/exam/components/CategoryCard';

import scholastic_img_01 from '../../../images/scholastic_img_01.png';
import scholastic_img_11 from '../../../images/scholastic_img_11.png';


const PerformanceCategory = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(getCategoryData(props.history));
	}, [props.categorylist]);


	const removePreviousAssessmentListData = () => {
		dispatch(onlineScholasticAssessmentListSuccessAction(''))
	}


	return (
		<>
			{
				props.categorylist == "" || props.categorylist == undefined ?
					<div className="exam_list_container" >
						<div className="spinner-container">
							<div className="spinner-view">
								<Spinner
									as="span"
									animation="border"
									//size="sm"
									role="status"
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>
					:
					<>
						{/* <div className="category_list_container" >
						{props.categorylist.map((item, ind) => (
							<Link to={{ pathname: item.id == 1 ? "/online-overall-scholastic-performance-score" : "/online-overall-compititive-performance-score" }} key={ind} >
								<CategoryCard
									backgroundColor={item.id == 1 ? "green_background" : "competitive_color"}
									leftIcon={item.id == 1 ? scholastic_img_01 : scholastic_img_11}
									leftIconColor={item.id == 1 ? "#56c760" : "#58bad7"}
									id={item.id}
									category={item.category}
									sub_heading={item.sub_heading}
									rightIcon={item.id == 1 ? scholastic_img_01 : scholastic_img_11}
								/>
							</Link>
						))}
					</div>
			} */}
						<div className='performance_score_category_parent_container'>
							<Link to={{ pathname: "/online-overall-scholastic-performance-score" }} >
								<div className='performance_indi_score_container score_scholatic_background'>
									<div className='graph_img_container'>
										<img src={graph_score} alt="" className='graph_img' />
									</div>
									{props.categorylist != '' ?
										<div>
											<h2 className='text-center'>{props.categorylist[0].category}</h2>
											<h6>{props.categorylist[0].sub_heading}</h6>
										</div>
										: null}
								</div>
							</Link>
							<Link to={{ pathname: "/online-overall-compititive-performance-score" }} >
								<div className='performance_indi_score_container score_compititive_background'>
									<div className='graph_img_container'>
										<img src={graph_score} alt="" className='graph_img' />
									</div>
									{props.categorylist != '' ?
										<div>
											<h2 className='text-center'>{props.categorylist[1].category}</h2>
											<h6>{props.categorylist[1].sub_heading}</h6>
										</div>
										: null}
								</div>
							</Link>
						</div>
					</>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		categorylist: state.category.examcategoryList,
	};
};
export default connect(mapStateToProps)(PerformanceCategory);