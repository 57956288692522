import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../../components/Loader"

import { Bar } from "react-chartjs-2";

import { performanceRequestAction, getCompetitiveSetWiseScoreData } from '../../../store/actions/PerformanceScoreAction';



const CompititiveSetWiseScoreComparison = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [chartData, setChartData] = useState();
    const [barChartsLabel, setBarChartsLabel] = useState();
    const [chartTotalData, setChartTotalData] = useState();
    const callOneTimeRef = useRef(true);

    const competitiveSetWiseScore = useSelector((state) => state.performance.competitiveSetWiseScore);

    useEffect(() => {
        if (callOneTimeRef.current) {
        dispatch(performanceRequestAction(true))
        dispatch(getCompetitiveSetWiseScoreData(previousValue.exam_type, props.history))
        }
        callOneTimeRef.current = false;
        
        const setTotalLabel = [];
        var setName = null;
        for (let i = 0; i < props.competitiveSetWiseScore.length; i++) {
            let setName = {};
            setName = "Set " + (i + 1);
            setTotalLabel.push(setName);
        }
        setBarChartsLabel(setTotalLabel)

        const setTotalData = [];
        var setTotal = null;
        for (let i = 0; i < props.competitiveSetWiseScore.length; i++) {
            let setTotal = {};
            setTotal = props.competitiveSetWiseScore[i].total_records;;
            setTotalData.push(setTotal);
        }
        setChartData(setTotalData);

        if (competitiveSetWiseScore != '' && chartData != '') {
            const total = setTotalData.map((num, idx) => num + setTotalData[idx])
            setChartTotalData(total)
        }

    }, [competitiveSetWiseScore]);


    //Example of 3 different data sets 
    // const data1 = [3, 1, 5, 8, 20, 10, 15, 30];
    // const data1 = chartData != '' || chartData != undefined  ? chartData : [3,6];
    // const data2 = [2, 3, 10, 5, 5, 9, 10, 10];
    // console.log("data1--", chartData, data1)
    // const total = data1.map((num, idx) => num + data1[idx]);
    //Inside data props
    const data = {
        // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        // labels: ["Set 1", "Set 2", "Set 3", "Set 4", "Set 5"],
        // labels: ["Set 11", "Set 21"],
        labels: barChartsLabel,
        datasets: [
            {
                label: "Data1",
                data: chartData,
                backgroundColor: "rgba(87, 121, 234, 0.6)",
                borderColor: "rgba(87, 121, 234, 0.6)",
                order: 1,
            },
            /* {
               label: "Data2",
               data: data2,
               backgroundColor: "rgba(18, 200, 150, 0.6)",
               borderColor: "rgba(18, 200, 150, 0.6)",
               order: 1,
           }, */
            {
                label: "Total",
                data: chartTotalData,
                backgroundColor: "rgba(234, 87, 102, 0.6)",
                borderColor: "rgba(234, 87, 102, 0.6)",
                fill: false,
                pointHoverRadius: 20,
                pointHoverBorderWidth: 5,
                type: "line",
                order: 0,
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        /* animation: {
            duration: 3000,
            easing: "easeInBounce",
        }, */
        title: {
            display: true,
            text: "Bar + Line Chart",
            fontSize: 25,
        },
        /* scales: {
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Months",
                    },
                    stacked: "true",
                },
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Values",
                    },
                    stacked: "true",
                },
            ],
        }, */
    };

    return (
        <>
            {props.competitiveSetWiseScore != '' && barChartsLabel != '' && chartData != '' ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className="text-center"><h3>Set wise score comparison({previousValue.exam_type})</h3></div>
                        <div className='performance_parent_container'>
                            <div className='score_container flex-1'>
                                <div className='score_card_parent_container jcc'>
                                    <table>
                                        <tr>
                                            <th className="text-center"></th>
                                            <th className="text-center">SAT</th>
                                            <th className="text-center">MAT</th>
                                            <th className="text-center">TOT</th>
                                        </tr>
                                        <tbody className="">
                                            {props.competitiveSetWiseScore != "" ?
                                                props.competitiveSetWiseScore.map((item, i) => {
                                                    return [
                                                        <tr className={`${i % 2 == 0 ? `tr-background` : null} cursorPointer average_table`} key={i} onClick={() => history.push({ pathname: "./online-comparative-study-set-ntse", state: { set_no: item.set_no } })} >
                                                            <td className="text-center">Set {item.set_no}</td>
                                                            <td className="text-center">{item.total_correct_sat}</td>
                                                            <td className="text-center">{item.total_correct_mat}</td>
                                                            <td className="text-center">{item.total_records}</td>
                                                        </tr>
                                                    ];
                                                })
                                                : <tr>
                                                    <td colSpan={4}>No data Available</td>
                                                </tr>}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className='chart_container flex-1 wh250 jcc text-center '>
                                {competitiveSetWiseScore != '' ?
                                    <Bar
                                        data={data}
                                        options={options}
                                    />
                                    : null}
                            </div>
                        </div>
                    </div>
                </>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        competitiveSetWiseScore: state.performance.competitiveSetWiseScore,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(CompititiveSetWiseScoreComparison);
