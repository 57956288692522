import React, { useEffect, } from "react";

const PaymentAborted = (props) => {
    

    useEffect(() => {
        // console.log("****PaymentAborted**",)

    }, []);

    return (
        <div>

            <div className="row">
                <div className="col-xl-12" >
                    <div className="card students-list payment_message_box jcc aic">
                        <div className="card-header border-0 flex-wrap pb-0 jcc aic flex-column">
                            <div className="icon_round failure_color dflex jcc aic ">
                                <i className="fa fa-times font_white payment_icon_size" aria-hidden="true"></i>
                            </div>
                            <div className="mt-3 mb-3"><h1 className="font_red">Payment Aborted</h1></div>
                            <div>
                                <h5>We were unable to process your payment</h5>
                            </div>
                        </div>
                        <div className='mt-5'></div>

                    </div>
                </div>

            </div>
            {/* <div>
                {props.loading ?
                    <Loader />
                    : null}
            </div> */}
        </div>
    )
}

export default PaymentAborted;
