
import {
    getClassStandardList,
} from '../../services/ClassStandardService';

import {
    GET_CLASS_STANDARD_LIST,
    CLASS_STANDARD_LOADING
} from '../constants';

import {
    logout,
} from '../actions/AuthActions';

import * as utility from '../../utility/Utility';

/* get class data */
export function getClassStandardData(history) {
    return (dispatch) => {
        getClassStandardList()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClassStandardAction(response.data.data)); /* store class data into action */
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                } else {

                }
                
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

/* store class data into action */
export function getClassStandardAction(data) {
    return {
        type: GET_CLASS_STANDARD_LIST,
        payload: data,
    };
}

export function loadingClassStandardAction(status) {
    return {
        type: CLASS_STANDARD_LOADING,
        payload: status,
    };
}
