import React from 'react';

//Import Components
import ExamList from '../../components/exam/ExamList';


const ScholasticLibrary = () => {
	
	return (
		<>
			<div className="row">
				<div className="exam_list_container" >
					<ExamList />
				</div>
			</div>
		</>
	)
}
export default ScholasticLibrary;