import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard21 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [labels, setLabels] = useState(['Ch1', 'Ch2', 'Ch3', 'Ch4', 'Ch5', 'Ch6', 'Ch7', 'Ch8', 'Ch9', 'Ch10', 'Ch11', 'Ch12', 'Ch13', 'Ch14', 'Ch15', 'Ch16', 'Ch17'])
    const [chartData, setChartData] = useState([60, 60, 70, 70, 80, 80, 80, 90,])
    const [backgroundColor, setBackgroundColor] = useState(['#CBF1FA',])

    const [overallAvg, setOverallAvg] = useState(0)
    const chartRef = useRef();

    const [chatData, setChatData] = useState(props.dataSet)
    const [series, setSeries] = useState('')
    const [color, setColor] = useState('')

    const [showTableModal, setShowTableModal] = useState(false)
    const [setNo, setSetNo] = useState(props.setNumber)
    const [standard, setStandard] = useState('')
    const [board_name, setBoard_name] = useState('')

    ChartJS.register(ArcElement, Tooltip, Legend);


    useEffect(() => {
        // console.log("props.dataSet.colors----", props.dataSet.colors)
        setSeries(chatData.series[0])
        setColor(chatData.colors)
    }, []);

    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        let token = JSON.parse(getData).token;
        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);
    }, []);

    const barData = {
        // series: chatData.series[0],
        series: props.dataSet.series[0],
        /* series: [{
            name: 'Verbal',
            data: [61]
        }, {
            name: 'Non - Verbal',
            data: [32]
        }], */
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            // colors: ["#9AD37F", "#E3DD70"],
            // colors: chatData.colors,
            colors: props.dataSet.colors,
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: false,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            },
                        },
                    },
                    columnWidth: "30%",
                },
            },
            xaxis: {
                type: 'text',
                categories: ["MAT"]
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 8,
            },
            legend: {
                show: true,
                position: 'left',
                offsetY: 10,
                /* labels: {
                    colors: ["#9AD37F", "#E3DD70"],
                    useSeriesColors: false
                }, */
                labels: {
                    // boxWidth: 40,
                    // padding: 13,
                    useSeriesColors: false,
                    usePointStyle: true,
                    // pointStyleWidth: 10,
                },
            },
            fill: {
                type: ['solid'],
                // colors: chatData.colors,
                colors: props.dataSet.colors,
                // colors: ["#CAF0FC", "#F8CCC5", "#FEE4AC", "#F9D1E5", "#CCE6AF", "#F7DDEC", "#7DD6C7", "#FDB1B1"],
                opacity: 1
            },
            dataLabels: {
                enabled: false,
                style: {
                    colors: ['#000000'],
                }
            },
        },


    };

    const closeTableModal = () => {
        setShowTableModal(false)
    }

    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
        // history.push({ pathname: "/competitive-subjectwise-analysis-on-set" })
    }


    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        {/* {Object.keys(chatData.series[0]).length !== 0 ? */}
                        {Object.keys(props.dataSet.series[0]).length !== 0 ?
                            <>
                                <div className="chart_body" >
                                    <ReactApexChart options={barData.options} series={barData.series} type="bar" height={290} />
                                    
                                </div>
                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                {/* <div className='performer_table_heading'>{props.pageHeading}</div> */}
                                <div className='performer_table_heading'>Subject Activity - Set</div>
                                <p>Competitive <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>

                        <div className='modal_body_container tableFixHead'>
                            <table cellPadding="10px" className="performance_table_data marginLeft-90">
                                <thead className="thableHeaderBackground">
                                    <tr>
                                        <th className="text-center light_sky">Set {props.setNumber}</th>
                                        <th className="text-center light_sky">Subject</th>
                                        <th className="text-center light_sky">Total Qs</th>
                                        <th className="text-center light_sky">Attempted</th>
                                        <th className="text-center light_sky">Correct</th>
                                        <th className="text-center light_sky">Not attempted</th>
                                        <th className="text-center light_sky">Incorrect</th>
                                        <th className="text-center light_sky">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {chatData.table_data.map((item, index) => { */}
                                    {props.dataSet.table_data.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {index == 0 ?
                                                    <tr>
                                                        {/* <td rowSpan={chatData.table_data.length} className="text-center light_gray_1">MAT</td> */}
                                                        <td rowSpan={props.dataSet.table_data.length} className="text-center light_gray_1">MAT</td>
                                                        <td className="text-center light_gray_1">{item.name}</td>
                                                        <td className="text-center light_gray_1">{item.total_questions}</td>
                                                        <td className="text-center light_gray_1">{item.attempt_record}</td>
                                                        <td className="text-center light_gray_1">{item.correct_record}</td>
                                                        <td className="text-center light_gray_1">{item.notattempt_record}</td>
                                                        <td className="text-center light_gray_1">{item.incorrect_record}</td>
                                                        <td className="text-center light_gray_1">{item.marks}</td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        {/* <td rowSpan={2} className="text-center light_gray_1">SAT</td> */}
                                                        <td className="text-center light_gray_1">{item.name}</td>
                                                        <td className="text-center light_gray_1">{item.total_questions}</td>
                                                        <td className="text-center light_gray_1">{item.attempt_record}</td>
                                                        <td className="text-center light_gray_1">{item.correct_record}</td>
                                                        <td className="text-center light_gray_1">{item.notattempt_record}</td>
                                                        <td className="text-center light_gray_1">{item.incorrect_record}</td>
                                                        <td className="text-center light_gray_1">{item.marks}</td>
                                                    </tr>
                                                }

                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </tbody>

                            </table>

                        </div>
                        {/* <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr >
                                        <th className="text-center light_sky">Set {props.setNumber}</th>
                                        <th className="text-center light_sky">Value</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        {barData.series.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <td className="text-center light_gray_1">{item.name}</td>
                                                    <td className="text-center light_gray_1">{item.data}</td>
                                                </React.Fragment>
                                            )
                                        })
                                        }
                                    </tr>
                                </tbody>

                            </table>

                        </div> */}

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard21);