import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ProgressBar } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getEventHistoryData, getEventHistoryRequestAction, likeEventHistoryData } from '../../../store/actions/DashboardAction';
import { dashboardPerformancescoreDetails } from '../../../store/actions/PerformanceScoreAction';
import { dashboardLogindataDataDetails } from '../../../store/actions/AuthActions';
import ReactStars from "react-rating-stars-component";
import Moment from 'react-moment';
import { Button, Modal, } from "react-bootstrap";
import GaugeChart from 'react-gauge-chart'
import clv from "../../../images/needle_clv.png";
import Marquee from "react-easy-marquee";
import "react-toastify/dist/ReactToastify.css";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import CalendarBlog from './Dashboard/CalendarBlog';

//images
import book from './../../../images/book.png';
import Brain_new from './../../../images/brain_new.png';


const LearningActivityChart = loadable(() =>
	pMinDelay(import("./Dashboard/LearningActivityChart"), 1000)
);

const ScoreActivityChart = loadable(() =>
	pMinDelay(import("./Dashboard/ScoreActivityChart"), 1000)
);

const ProgressChart = loadable(() =>
	pMinDelay(import("./Dashboard/ProgressChart"), 1000)
);


const Home = (props) => {

	const dispatch = useDispatch();
	const historyRef = useRef();
	const [modal, setModal] = useState(false);
	const [like, setLike] = useState(true);

	const { changeBackground } = useContext(ThemeContext);

	useEffect(() => {
		let isMounted = true;
		changeBackground({ value: "light", label: "Light" });

		return () => { isMounted = false };
	}, []);

	/* get Event History Data and Loader */
	useEffect(() => {
		dispatch(getEventHistoryRequestAction(true));
		dispatch(getEventHistoryData(props.history));

	}, [like]);

	/* get Performance and User Data */
	useEffect(() => {
		dispatch(dashboardPerformancescoreDetails(props.history));
		dispatch(dashboardLogindataDataDetails(props.history));
		return () => {

		};
	}, []);

	const [historyDescriptionModal, setHistoryDescriptionModal] = useState(false);
	const [historyDescription, setHistoryDescription] = useState();
	const [showTableModal, setShowTableModal] = useState(false)

	/* Modal open for Event History  */
	const showHistoryDescription = (item) => {
		setHistoryDescriptionModal(true)
		setHistoryDescription(item)
	}

	const demoUserOrNot = useSelector(state => state.auth.user_id);
	const work_status = useSelector(state => state.auth.work_status);
	const work_status_percentage = useSelector(state => state.auth.work_status_percentage);
	const total_scholastic_master = useSelector(state => state.auth.total_scholastic_master);
	const total_competitive_master = useSelector(state => state.auth.total_competitive_master);
	const scholatic_details_purchase = useSelector(state => state.auth.scholatic_details_purchase);
	const competive_details_purchase = useSelector(state => state.auth.competive_details_purchase);
	const total_scholastic_completed = useSelector(state => state.auth.total_scholastic_completed);
	const total_competitive_completed = useSelector(state => state.auth.total_competitive_completed);

	const competitive_overall = useSelector(state => state.auth.competitive_overall);
	const total_competitive_completed_master = useSelector(state => state.auth.total_competitive_completed_master);
	const total_scholastic_completed_master = useSelector(state => state.auth.total_scholastic_completed_master);


	/* AWS pdf download and View */
	const openCreativePdf = () => {
		window.open(process.env.REACT_APP_PDFURL + 'assets/guide.pdf', '_blank', 'noreferrer');
	}

	const chartStyle = {
		height: 125,
		width: 350,
	}

	/* Feedback rating updated */
	const ratingChanged = (newRating) => {
		//console.log('...........', newRating);
	};

	/* Event history Like option updated */
	const onLikeHandler = (item) => {
		setLike(!like)
		dispatch(likeEventHistoryData( item.id, props.history ) );
	}

	/* Modal open for performance details */
	const viewDetailsData = () => {
		setShowTableModal(true)
	}

	/* Modal close for performance details */
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
				</div>
			</div>
			<div className="col-xl-12 mb-3">
				<div className="col-xl-12 mb-4">
					<div className="home_page_top_container">
						<div className="work-progress-title">
							<h4>Work Progress</h4>
							<p>You're on your way!</p>
						</div>
						<div className='box_container'>
							<div className='home_top_left_side_container'>
								<div className='border_box'></div>
								<div className='single_box'>
									<div className='icon_box'>
										<div className="work-progress-item-thum">
											<i className={`fa-solid fa-user-plus ${work_status >= 1 ? `statusActive` : ``} `}></i>
										</div>
									</div>
									<div className={`icon_text ${work_status >= 1 ? `statusActiveText` : ``} `}>Register on Crestest</div>
								</div>
								<div className='single_box'>
									<div className='icon_box'>
										<div className="work-progress-item-thum">
											<i className={`fa-solid fa-box-open ${work_status >= 2 ? `statusActive` : ``} `}></i>
										</div>
									</div>
									<div className={`icon_text ${work_status >= 2 ? `statusActiveText` : ``} `}>Customize  your own course</div>
								</div>
								<div className='single_box'>
									<div className='icon_box'>
										<div className="work-progress-item-thum">
											<i className={`fa-solid fa-clock ${work_status >= 3 ? `statusActive` : ``}`}></i>
										</div>
									</div>
									<div className={`icon_text ${work_status >= 3 ? `statusActiveText` : ``} `}>Facilitate self discovery with online exam</div>
								</div>
								<div className='single_box'>
									<div className='icon_box'>
										<div className="work-progress-item-thum">
											<i className={`fa-solid fa-laptop ${work_status >= 4 ? `statusActive` : ``}`}></i>
										</div>
									</div>
									<div className={`icon_text ${work_status >= 4 ? `statusActiveText` : ``} `}>Access CMC</div>
								</div>
								<div className='single_box'>
									<div className='icon_box'>
										<div className="work-progress-item-thum">
											<i className={`fa-solid fa-desktop ${work_status >= 5 ? `statusActive` : ``}`}></i>
										</div>
									</div>
									<div className={`icon_text ${work_status >= 5 ? `statusActiveText` : ``} `}>Join NBF new  platform for  online classes</div>
								</div>
							</div>
							<div className='home_top_right_side_container'>
								<div className="work_content">
									<div className="work_status">
										<h4>{work_status_percentage}%</h4>
										<p>Completed</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className="row">
						<div className="col-xl-6 col-xxl-6">
							<div className="card career_card dlab-join-card card-min-hight">
								<div className="card-body">
									<div className="dlab-media d-flex justify-content-between">
										<div className="">
											<h4><span className='career_color'>Career</span> <br /><span className='guidance_color'>Guidance</span></h4>
											<p className='career_color_text'>Opportunities at a glance</p>
										</div>
										<div className=" guidance_img cursorPointer" onClick={openCreativePdf} >
											<img src={Brain_new} alt="CreasTest" className='home_image' />
										</div>
										<div className="dlab-icon">
											<i className="fa-solid fa-clock"></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-6 col-xxl-6 history_box">
							<div className="card td-history">
								<div className="history-icon">
									<img src={book} alt="Creastest" style={{ width: 120, position: "relative", right: 15, bottom: 5 }} />
								</div>
								<div className="td-history-date">
									<div className="history-date-container">
										<div className="history-date-text">
											{props.eventHistoryList != '' ?
												<>
													<h6>{<Moment format="MMMM" withTitle>{props.eventHistoryList[0].event_date}</Moment>}</h6>
													<p>{<Moment format="D" withTitle>{props.eventHistoryList[0].event_date}</Moment>}</p>
												</>
												: null}
										</div>
										<div className="arrow-view"></div>
									</div>

								</div>
								<div className="card-header border-0 td-history-title">
									<h4>Today in History</h4>
								</div>
								<div ref={historyRef} className="td-history-list">
									<ul className='history-items-list'>
										{props.eventHistoryList != '' && props.eventHistoryList[0].title != 'No Data' ?
											props.eventHistoryList.length > 2 ?
												<Marquee duration={10000}
													axis="Y"
													background="transparent"
													height="200px"
													reverse={true}
													pauseOnHover={true}
												>
													{
														props.eventHistoryList.map((item, index) => {
															return (
																<li className={`d-flex flex-direction-row ${demoUserOrNot == 0 ? 'marginLeft-15' : null} `} key={index}>
																	{demoUserOrNot == 0 ?
																		null :
																		item.is_liked == 0 ?
																			<div
																				onClick={() => onLikeHandler(item)}
																				className="history-like-btn cursorPointer left_box">
																				<i className="fa-regular fa-thumbs-up"></i>
																			</div>
																			:
																			<div
																				className="history_liked left_box">
																				<i className="fa fa-thumbs-up"></i>
																			</div>
																	}
																	<div className="td-list-item cursorPointer right_box" onClick={() => showHistoryDescription(item)}>
																		<h5>{item.title}</h5>
																		<p>{item.sub_title}</p>
																	</div>
																</li>
															)
														})
													}

												</Marquee>
												: props.eventHistoryList.map((item, index) => {
													return (
														<li onClick={() => showHistoryDescription(item)} key={index}>
															<Link to="#">
																<div className="td-list-item">
																	<h5>{item.title}</h5>
																	<p>{item.sub_title}</p>
																</div>
															</Link>
														</li>
													)
												})
											:
											<li>
												<div className="td-list-item marginLeft-15">
													<h5 className='career_color_text'>No Record Found</h5>
												</div>
											</li>}

									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<div className="row">

						<div className="col-xl-5 col-lg-6">
							<div className="card my-progress ">

								<div className="my-progress-title mb-20">
									<h4>Performance</h4>
									<p className="text-white">know where you stand</p>
								</div>
								<div className="my-progress-bar-content jcc">
									<div className='chart_container flex-1 wh250  aic text-center flex_column relative_container '>
										<div id="chart speed_chart ">
											<GaugeChart
												id="gauge-chart6"
												nrOfLevels={400}
												arcWidth={0.15}
												arcsLength={[0.9, 0.15, 0.15, 0.15]}
												colors={['#C00000', '#FFC000', '#00B050', '#00B0F0']}
												percent={(demoUserOrNot == 0 ? 0 : competitive_overall) / 100}
												arcPadding={0.00}
												textColor="#000"
												needleColor="#275D79"
												needleBaseColor="#275D79"
												cornerRadius={0}
												style={chartStyle}
												animate={false}
											/>

										</div>
										<div className='home_needle_base_image_container'>
											<img src={clv} alt="" className='home_needle_base_image' />
										</div>
									</div>
								</div>
								<div>
									{demoUserOrNot != 0 ?
										<div className="progress-more-btn ">
											<Link to={'./online-exam-performance-score'}>More details</Link>
											<div className="details_btn cursorPointer" onClick={() => viewDetailsData()}>View details</div>
										</div>
										:
										<div className="progress-more-btn">
											<Link className="subscription-disable" to="#">More details</Link>
											<Link className="details_btn subscription-disable" to="#">View details</Link>
										</div>
									}
								</div>
							</div>
						</div>

						<div className="col-xl-7 col-lg-6">
							<div className="card cus-calendar">
								<div className="card-body card-calendar home-calendar height_100_percent">
									<div className="cus-calendar-title">
										<h4>Today Class Schedule</h4>
										<p>Quick access to your classes</p>
									</div>

									<CalendarBlog />
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 mb-2">

					<div className="row">
						<div className="col-xl-6 col-lg-6">
							<div className="card process-bar">
								<div className="process-bar-profile">
									<h3>c</h3>
								</div>
								<div className="process-bar-content">

									<div className="process-purchase">
										<div className="process-purchase-content">
											<h5>Total purchase</h5>
											<div className="slash-bar"></div>
											<p>{competive_details_purchase != '' && competive_details_purchase != undefined ? competive_details_purchase : 0}%{/* /{total_competitive_master != '' && total_competitive_master != undefined ? total_competitive_master : 0} */}</p>
										</div>
										<div className="process-purchase-bar">
											<ProgressBar now={(competive_details_purchase != '' && competive_details_purchase != undefined ? competive_details_purchase : 0) / (total_competitive_master != '' && total_competitive_master != undefined ? total_competitive_master : 0) * 100} variant="lightgray" />
										</div>
									</div>

									<div className="process-purchase">
										<div className="process-purchase-content">
											<h5>Completed course</h5>
											<div className="slash-bar"></div>
											<p>{total_competitive_completed != '' && total_competitive_completed != undefined ? total_competitive_completed : 0}%{/* /{total_competitive_completed_master != '' && total_competitive_completed_master != undefined ? total_competitive_completed_master : 0} */}</p>
										</div>
										<div className="process-purchase-bar">
											<ProgressBar now={(total_competitive_completed != '' && total_competitive_completed != undefined ? total_competitive_completed : 0) / (total_competitive_completed_master != '' && total_competitive_completed_master != undefined ? total_competitive_completed_master : 0) * 100} variant="lightgray" />
										</div>

									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-6 col-lg-6">
							<div className="card process-bar green_background">
								<div className="process-bar-profile light-color-green">
									<h3>s</h3>
								</div>
								<div className="process-bar-content">

									<div className="process-purchase">
										<div className="process-purchase-content">
											<h5>Total purchase</h5>
											<div className="slash-bar"></div>
											<p>{scholatic_details_purchase != '' && scholatic_details_purchase != undefined ? scholatic_details_purchase : 0}%{/* /{total_scholastic_master != '' && total_scholastic_master != undefined ? total_scholastic_master : 0} */}</p>
										</div>
										<div className="process-purchase-bar">
											<ProgressBar now={(scholatic_details_purchase != '' && scholatic_details_purchase != undefined ? scholatic_details_purchase : 0) / (total_scholastic_master != '' && total_scholastic_master != undefined ? total_scholastic_master : 0) * 100} variant="lightgray" />
										</div>
									</div>
									<div className="process-purchase">
										<div className="process-purchase-content">
											<h5>Completed course</h5>
											<div className="slash-bar"></div>
											<p>{total_scholastic_completed != '' && total_scholastic_completed != undefined ? total_scholastic_completed : 0}%{/* /{total_scholastic_completed_master != '' && total_scholastic_completed_master != undefined ? total_scholastic_completed_master : 0} */}</p>
										</div>

										<div className="process-purchase-bar">
											<ProgressBar now={(total_scholastic_completed != '' && total_scholastic_completed != undefined ? total_scholastic_completed : 0) / (total_scholastic_completed_master != '' && total_scholastic_completed_master != undefined ? total_scholastic_completed_master : 0) * 100} variant="lightgray" />
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				{historyDescription != '' && historyDescription != undefined ?
					<Modal className="fade history" show={historyDescriptionModal}>

						<div className="history_background">
							<div className="history_heading">{historyDescription.title}</div>
							<div className="history_body">
								<div className="image">
									<img src={historyDescription.event_image} alt="Crestest" />
								</div>
								<p dangerouslySetInnerHTML={{ __html: historyDescription != '' && historyDescription != undefined ? historyDescription.description : null }}></p>
							</div>
							<div className="close_container">
								<Button
									variant="danger text-center modal_footer_button"
									onClick={() => setHistoryDescriptionModal(false)}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal>
					: null}

				<Modal
					className="fade"
					size="lg"
					show={modal}>
					<Modal.Header>
						<Modal.Title className="modal_title">Feedback</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<div className="feedback_modal_container">

							<div className="feedback_item">
								<div className="feedback_text_container">
									In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
								</div>
								<div className="feedback_rating_container">
									<ReactStars
										count={5}
										onChange={ratingChanged}
										size={28}
										isHalf={true}
										emptyIcon={<i class="fa fa-star"></i>}
										halfIcon={<i class="fa fa-star-half-o"></i>}
										fullIcon={<i class="fa fa-star-o"></i>}
										activeColor="#1177ff"
										color="#d9d5d5"
									/>
								</div>
							</div>
							<div className="feedback_item">
								<div className="feedback_text_container">
									In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
								</div>
								<div className="feedback_rating_container">
									<ReactStars
										count={5}
										onChange={ratingChanged}
										size={28}
										isHalf={true}
										emptyIcon={<i class="fa fa-star"></i>}
										halfIcon={<i class="fa fa-star-half-o"></i>}
										fullIcon={<i class="fa fa-star-o"></i>}
										activeColor="#1177ff"
										color="#d9d5d5"
									/>
								</div>
							</div>
							<div className="feedback_item">
								<div className="feedback_text_container">
									In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
								</div>
								<div className="feedback_rating_container">
									<ReactStars
										count={5}
										onChange={ratingChanged}
										size={28}
										isHalf={true}
										emptyIcon={<i class="fa fa-star"></i>}
										halfIcon={<i class="fa fa-star-half-o"></i>}
										fullIcon={<i class="fa fa-star-o"></i>}
										activeColor="#1177ff"
										color="#d9d5d5"
									/>
								</div>
							</div>
							<div className="feedback_item">
								<div className="feedback_text_container">
									In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
								</div>
								<div className="feedback_rating_container">
									<ReactStars
										count={5}
										onChange={ratingChanged}
										size={28}
										isHalf={true}
										emptyIcon={<i class="fa fa-star"></i>}
										halfIcon={<i class="fa fa-star-half-o"></i>}
										fullIcon={<i class="fa fa-star-o"></i>}
										activeColor="#1177ff"
										color="#d9d5d5"
									/>
								</div>
							</div>
						</div>
						<div className="feedback_btn_group">
							<button
								className="feedback_cancel_btn"
								onClick={() => setModal(false)}
							>
								Quit Exam
							</button>
							<button
								className="feedback_submit_btn"
							//onClick={startExam}
							>
								Start Exam
							</button>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="modal_footer_container">

						</div>
					</Modal.Footer>
				</Modal>

			</div>
			{/* </div> */}

			<Modal
				className="fade bd-example-modal-lg subscribe-modal dashboard"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								<div className='performer_table_heading'>Performance</div>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container tableFixHead'>
							<table cellPadding="5px" className="performance_analysis_table_data">
								<thead className="thableHeaderBackground">
									<tr >
										<th className="text-center light_sky1">Category</th>
										<th className="text-center light_sky1">Type</th>
										<th className="text-center light_sky1">Group</th>
										<th className="text-center light_sky1">Subject</th>
										<th className="text-center light_sky1">Chapter</th>
										<th className="text-center light_yellow">Test name</th>
										<th className="text-center light_gray_3">Score</th>
										<th className="text-center light_green1">Full marks</th>
										<th className="text-center light_red1">Percentage</th>
									</tr>
								</thead>

								<tbody>
									{props.performance_details_sch != "" && props.performance_details_sch != undefined ?

										props.performance_details_sch.map((item, i) => (
											<tr key={i} >
												<td className="text-center light_red2">SCHOLASTIC</td>
												<td className="text-center light_gray_1">-</td>
												<td className="text-center light_gray_1" >{item.group_name}</td>
												<td className="text-center light_gray_1" >{item.subject}</td>
												<td className="text-center light_gray_1" >{item.chapter_name}</td>
												<td className="text-center light_gray_1">{item.exam_type}</td>
												<td className="text-center light_gray_1">{item.correct_record}</td>
												<td className="text-center light_gray_1">{item.total_record}</td>
												<td className="text-center light_gray_1">{item.percentage}</td>

											</tr>
										))
										: <tr>
											<td className='text-center' colSpan={9}>No data Available for scholastic</td>
										</tr>}
									<tr>
										<td className='divider' colSpan={7}></td>
									</tr>
									{props.performance_details_comp != "" && props.performance_details_comp != undefined ?

										props.performance_details_comp.map((item, i) => (
											<tr key={i} >
												<td className="text-center light_red2">COMPETITIVE</td>
												<td className="text-center light_gray_1">{item.subject}</td>
												<td className="text-center light_gray_1" >{item.group_name}</td>
												<td className="text-center light_gray_1" ></td>
												<td className="text-center light_gray_1" >{item.chapter_name}</td>
												<td className="text-center light_gray_1">{item.exam_type}</td>
												<td className="text-center light_gray_1">{item.score}</td>
												<td className="text-center light_gray_1">{item.total}</td>
												<td className="text-center light_gray_1">{item.percentage}</td>

											</tr>
										))
										: <tr>
											<td className='text-center' colSpan={9}>No data Available for competitive</td>
										</tr>}
								</tbody>
								<tr>
									<td className='divider' colSpan={7}></td>
								</tr>
								<tfoot>
									<tr>
										<td className="text-center light_red2">Total</td>
										<td className="text-center light_gray_1"></td>
										<td className="text-center light_gray_1"></td>
										<td className="text-center light_gray_1"></td>
										<td className="text-center light_gray_1"></td>
										<td className="text-center light_gray_1">{props.performance_total_exam_count}</td>
										<td className="text-center light_gray_1"></td>
										<td className="text-center light_gray_1"></td>
										<td className="text-center light_gray_1">{(demoUserOrNot == 0 ? 0 : competitive_overall)}</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</>
			</Modal>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		loader: state.dashboard.loader,
		eventHistoryList: state.dashboard.eventHistoryList,
		like: state.dashboard.like,
		dashboardPerformancescore: state.performance.dashboardPerformancescore,
		performance_details_sch: state.auth.performance_details_sch,
		performance_details_comp: state.auth.performance_details_comp,
		performance_total_exam_count: state.auth.performance_total_exam_count,
		board_name: state.auth.board_name,

	};
};
export default connect(mapStateToProps)(Home);
