import React, { useEffect, useState, } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

const ChartCard28 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {

    }, []);



    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        <div className='instructionParentBox'>
                            <div className='instructionParentInsideBox'>
                                <div className='instructionindividualBox'>
                                    <div className='instructionColor color_box color_nine'></div>
                                    <div className='instructionColorInstruction'>90% and above</div>
                                </div>
                                <div className='instructionindividualBox'>
                                    <div className='instructionColor color_box color_eight'></div>
                                    <div className='instructionColorInstruction'>Greater than or equal to 80% to less than 90%</div>
                                </div>
                                <div className='instructionindividualBox'>
                                    <div className='instructionColor color_box color_seven'></div>
                                    <div className='instructionColorInstruction'>Greater than or equal to 70% to Less than 80%</div>
                                </div>
                                <div className='instructionindividualBox'>
                                    <div className='instructionColor color_box color_six'></div>
                                    <div className='instructionColorInstruction'>Less than 70%</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>


        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard28);