import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { getOnlineCompetitiveSubscriptionDetailsData, getOnlineCompetitiveQuestionListData } from '../../../store/actions/OnlineExamAction';
import { getExamCompletedListData } from '../../../store/actions/ChapterAction';
import { onlineExamIdAction } from '../../../store/actions/ScholasticAction';

const CompetitiveAssessmentSelect = (props) => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [setlectSet, setSetlectSet] = useState('')
    const [setlectIndividualSet, setSetlectIndividualSet] = useState('')
    const [purchsedSet, setPurchsedSet] = useState('');

    useEffect(() => {
        
        if(previousValue.id == 2 ){
            // console.log("@@1")
            dispatch(getOnlineCompetitiveSubscriptionDetailsData('', previousValue.exam_type, props.history))
        }else if(previousValue.subtype){
            // console.log("@@222")
            dispatch(getOnlineCompetitiveSubscriptionDetailsData(previousValue.subtype, previousValue.exam_type, props.history))
            // history.push({pathname:'./online-assessment-ntse-type', state: {exam_type: previousValue.exam_type}})
        }
    }, []);

    const setSetlectSetData = (selectSet) => {
        setSetlectSet(selectSet)
        const setTotalData = [];
        var setText = null;
        for (let i = 0; i < selectSet.split(',')[1]; i++) {
            let setText = {};
            setText['name'] = "ComSet " + (i + 1);
            setText['com_set_no'] = i + 1;
            setTotalData.push(setText);
        }
        setPurchsedSet(setTotalData)
    }

    const setlectIndividualSetData = (select_data) => {
        dispatch(onlineExamIdAction(''))
        setSetlectIndividualSet(select_data)
        dispatch(getExamCompletedListData(select_data, 0, previousValue.categoryId, previousValue.exam_type, previousValue.subtype == undefined ? 0 : previousValue.subtype, props.history))
        history.push({ pathname: "/online-assessment-details", state: { exam: 2, subtype: previousValue.subtype } })
    }

    return (
        <>
            {props.competitiveSubscriptionDetails != '' ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>

                    <div className="parent_container">

                        <div className={`card ${previousValue.id == 1 || previousValue.subtype ? `ntse_background` : previousValue.id == 2 ? `nstse_background` : null} padding_15 col-xl-4`}> {/* box_height_547 */}

                            {props.competitiveSubscriptionDetails == "" ?
                                <div className="form-group exam mb10">
                                    <div className="basic-dropdown form-control select_option unselect_text">
                                        <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                        Select Set
                                    </div>
                                </div>
                                :
                                <div className="form-group exam mb10">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control form-control select_option"
                                            value={setlectSet}
                                            // onChange={(e) => setSetlectSet(e.target.value)}
                                            onChange={(e) => setSetlectSetData(e.target.value)}
                                        >
                                            <option value="">Select Set</option>
                                            {
                                                props.competitiveSubscriptionDetails.map((item, index) => {
                                                    return <option key={index} value={[item.id, item.no_set]}>Set {item.no_set}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            }

                            {purchsedSet == "" ?
                                <div className="form-group exam"> {/* mb10 */}
                                    <div className="basic-dropdown form-control select_option unselect_text">
                                        <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                        Select Individual Set
                                    </div>
                                </div>
                                :
                                <div className="form-group exam"> {/* mb10 */}
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control form-control select_option"
                                            value={setlectIndividualSet}
                                            // onChange={(e) => setSetlectSet(e.target.value)}
                                            onChange={(e) => setlectIndividualSetData(e.target.value)}
                                        >
                                            <option value="">Select Individual Set</option>
                                            {
                                                purchsedSet.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.com_set_no}>Set {item.com_set_no}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </>
                :
                <div className="exam_list_container" >
                    <div className="spinner-container">
                        <div className="spinner-view">
                            <Spinner
                                as="span"
                                animation="border"
                                //size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    // console.log("CompetitiveAssessmentSelect----", state.onlineexam.competitiveSubscriptionDetail)
    return {
        competitiveSubscriptionDetails: state.onlineexam.competitiveSubscriptionDetails,
        user_id: state.auth.user_id,
    };
};
export default connect(mapStateToProps)(CompetitiveAssessmentSelect);