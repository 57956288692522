import React from 'react';
import IconTint from 'react-icon-tint';
import { Link, useHistory } from "react-router-dom";
import * as utility from '../../../utility/Utility';

import logofull from '../../../images/dashboard.png';


export default function ScholasticCatagoryCard(props) {

	const history = useHistory();

	const onExamTypeHandler = () => {
		if (props.isSubscribe == 1) {
			history.push({
				// pathname: "/e-library-subject",
				pathname: "/e-library-competitive-list",
				state: props.id
			})
		} else if (props.isSubscribe == 0) {
			utility.showError('No elibrary exsits');
		}

	}

	return (
		<>
			<div className="row">
				<div className="exam_inside_container" >

					{/* <div
						onClick={onExamTypeHandler}
					> */}

						<div className={`col-md-6 sub_contain_container ${props.is_exam == 0 ? "subscription-disable" : null}`} style={{ backgroundColor: props.bgColor }}>
						{/* <div className={`col-md-6 sub_contain_container`} style={{ backgroundColor: props.bgColor }}> */}
							<div className="left_container">

								{/* <IconTint
									src={props.icon}
									alt=""
									// color={props.leftColor}
									className={props.iconClass}
								/> */}
								<img
									src={props.icon != '' ? props.icon : logofull}
									alt=""
									// color={props.leftColor}
									className={props.iconClass } 
								/>
							</div>
							<div className="middle_container">
								<div className="middle_inside_container">
									<h3 className="font_white">{props.title}</h3>
									<p className="font_white">{props.description}</p>
								</div>
							</div>
							<div className="right_container">
								<IconTint
									src={props.icon != '' ? props.icon : logofull}
									alt=""
									color="#fff"
									className={props.iconClassRgt} />
							</div>
						</div>
					{/* </div> */}
				</div>
			</div>

		</>
	)
}