import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import ReactApexChart from "react-apexcharts";
import Loader from "../../components/Loader"
import GaugeChart from 'react-gauge-chart'

import { performanceRequestAction, getCompetitiveOverAllPerformanceData } from '../../../store/actions/PerformanceScoreAction';

import clv from "../../../images/clv.png"


const OverallCompititivePerformanceScore = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state)
    const [nstseAvg, setNstseAvg] = useState();
    const [ntseAvg, setNtseAvg] = useState();
    const avgRef = useRef(true);
    const functionalCallFirstTimeRef = useRef(true);

    const competitiveOverAllPerformance = useSelector((state) => state.performance.competitiveOverAllPerformance);

    const chartStyle = {
        height: 125,
        width: 290,
    }

    const ntseOverAllDetails = {
        series: [ntseAvg],
        options: {
            chart: {
                height: 300,
                type: "radialBar",
                offsetY: -10,
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "16px",
                            color: undefined,
                            offsetY: 120,
                        },
                        value: {
                            offsetY: 0,
                            fontSize: "34px",
                            color: "#ff6a59",
                            formatter: function (val) {
                                return val + "%";
                            },
                        },
                    },
                },
            },
            fill: {
                type: "gradient",
                colors: "#ff6a59",
                gradient: {
                    shade: "dark",
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91],
                },
            },
            stroke: {
                dashArray: 4,
                colors: "#ff6a59",
            },
            labels: [""],
        },
    };
    const nstseOverAllDetails = {
        // series: [props.scholasticOverAllPerformance[1].total],
        series: [nstseAvg],
        options: {
            chart: {
                height: 300,
                type: "radialBar",
                offsetY: -10,
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "16px",
                            color: undefined,
                            offsetY: 120,
                        },
                        value: {
                            offsetY: 0,
                            fontSize: "34px",
                            color: "#ff6a59",
                            formatter: function (val) {
                                return val + "%";
                            },
                        },
                    },
                },
            },
            fill: {
                type: "gradient",
                colors: "#ff6a59",
                gradient: {
                    shade: "dark",
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91],
                },
            },
            stroke: {
                dashArray: 4,
                colors: "#ff6a59",
            },
            labels: [""],
        },
    };

    useEffect(() => {
        if (functionalCallFirstTimeRef.current) {
            dispatch(performanceRequestAction(true))
            dispatch(getCompetitiveOverAllPerformanceData(props.history))
        }
        functionalCallFirstTimeRef.current = false

        if (competitiveOverAllPerformance != '') {
            if (avgRef.current) {
                setNtseAvg(props.competitiveOverAllPerformance.ntse_avg);
                setNstseAvg(props.competitiveOverAllPerformance.nstse_avg)
            }
            avgRef.current = false
        }
    }, [competitiveOverAllPerformance]); //scholasticOverAllPerformanceTotal //competitiveOverAllPerformance

    const detailsPage = () => {
        // history.push({ pathname: "/online-subject-wise-comparison-on" })
    }
    const goToDetailsPage = (i) => {
        if (i == 1) {
            history.push({ pathname: "/online-compititive-set-wise-score-comparison", state: { exam_type: "NTSE", } })
        } else if (i == 2) {
            history.push({ pathname: "/online-compititive-set-wise-score-comparison", state: { exam_type: "NSTSE", } })
        }
    }

    return (
        <>
            {props.competitiveOverAllPerformance != '' ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className="text-center"><h3>Overall Competitive(NTSE &/or NSTSE) Performance Score(Avg.)</h3></div>
                        <div className='performance_parent_container'>
                            <div className='score_container flex-1'>
                                <div className='score_card_parent_container jcc'>
                                    <table>
                                        <tr>
                                            <th></th>
                                            {props.competitiveOverAllPerformance.nstse != "" ?
                                                props.competitiveOverAllPerformance.nstse.map((item, i) => {
                                                    return [
                                                        <th className="text-center" key={i}>Set {i + 1}</th>
                                                    ];
                                                })
                                                : null}
                                            <th className="text-center">Avg%</th>
                                        </tr>
                                        <tbody>
                                            <tr className="text-center tr-background">
                                                <td className="text-center">NTSE</td>
                                                {props.competitiveOverAllPerformance.nstse != "" ?
                                                    props.competitiveOverAllPerformance.nstse.map((item, i) => {
                                                        return [
                                                            <td className="text-center" key={i}>{item.set_no}</td>
                                                        ];
                                                    })
                                                    : null}
                                                <td className="text-center">{props.competitiveOverAllPerformance.ntse_avg + "%"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            {ntseAvg !== '' && ntseAvg !== undefined ?
                                <div className='chart_container flex-1 wh400 jcc aic text-center flex_column relative_container'>
                                    <div id="chart">
                                        <GaugeChart id="gauge-chart1"
                                            nrOfLevels={400}
                                            arcWidth={0.15}
                                            arcsLength={[0.9, 0.15, 0.15, 0.15]}
                                            colors={['#C00000', '#FFC000', '#00B050', '#00B0F0']}
                                            percent={ntseAvg / 100}
                                            arcPadding={0.00}
                                            textColor="#000"
                                            needleColor="#962625"
                                            needleBaseColor="#275D79"
                                            cornerRadius="0"
                                            style={chartStyle}
                                        />
                                    </div>
                                    <div className='needle_base_image_container'>
                                        <img src={clv} alt="" className='needle_base_image' />
                                    </div>
                                    <div className="text-center details_text" onClick={() => goToDetailsPage(1)}>
                                        <div className="btn btn-danger">Details</div>
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className='performance_parent_container'>
                            <div className='score_container flex-1'>
                                <div className='score_card_parent_container jcc'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                {props.competitiveOverAllPerformance.nstse != "" ?
                                                    props.competitiveOverAllPerformance.nstse.map((item, i) => {
                                                        return [
                                                            <th className="text-center" key={i}>Set {i + 1}</th>
                                                        ];
                                                    })
                                                    : null}
                                                <th className="text-center">Avg%</th>
                                            </tr>
                                            <tr className="text-center tr-background">
                                                <td >NSTSE</td>
                                                {props.competitiveOverAllPerformance.nstse != "" ?
                                                    props.competitiveOverAllPerformance.nstse.map((item, i) => {
                                                        return [
                                                            <td key={i}>{item.set_no}</td>
                                                        ];
                                                    })
                                                    : null}
                                                <td className="text-center">{props.competitiveOverAllPerformance.nstse_avg + "%"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            {nstseAvg !== '' && nstseAvg !== undefined ?
                                <div className='chart_container flex-1 wh400 jcc aic text-center flex_column relative_container'>
                                    <div id="chart">
                                        <GaugeChart id="gauge-chart1"
                                            nrOfLevels={400}
                                            arcWidth={0.15}
                                            arcsLength={[0.9, 0.15, 0.15, 0.15]}
                                            colors={['#C00000', '#FFC000', '#00B050', '#00B0F0']}
                                            percent={nstseAvg / 100}
                                            arcPadding={0.00}
                                            textColor="#000"
                                            needleColor="#962625"
                                            needleBaseColor="#275D79"
                                            cornerRadius="0"
                                            style={chartStyle}
                                        />
                                    </div>
                                    <div className='needle_base_image_container'>
                                        <img src={clv} alt="" className='needle_base_image' />
                                    </div>
                                    <div className="text-center details_text" onClick={() => goToDetailsPage(2)}>
                                        <div className="btn btn-danger">Details</div>
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                </>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        competitiveOverAllPerformance: state.performance.competitiveOverAllPerformance,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(OverallCompititivePerformanceScore);
