import { combineReducers } from 'redux';
import { AuthReducer } from './AuthReducer';
import { BoardReducer } from './BoardReducer';
import { ClassStandardReducer } from './ClassStandardReducer';
import { BranchScholasticReducer } from './BranchScholasticReducer';
import { ChapterReducer } from './ChapterReducer';
import { DemoExamReducer } from './DemoExamReducer';
import { StudentReducer } from './StudentReducer';
import { ExamCategoryReducer } from './ExamCategoryReducer';
import { ExamTypeReducer } from './ExamTypeReducer';
import { SubscribeReducer } from './SubscribeReducer';
import { SubjectReducer } from './SubjectReducer';
import { OnlineExamReducer } from './OnlineExamReducer';
import { ElibraryReducer } from './ElibraryReducer';
import { DashboardReducer } from './DashboardReducer';
import { PerformanceScoreReducer } from './PerformanceScoreReducer';
import { ProfileReducer } from './ProfileReducer';
import PostsReducer from './PostsReducer';
import todoReducers from './Reducers';

const rootReducers = combineReducers({
	auth: AuthReducer,
	board: BoardReducer,
	standard: ClassStandardReducer,
	branch: BranchScholasticReducer,
	chapter: ChapterReducer,
	questionNo: DemoExamReducer,
	category: ExamCategoryReducer,
	student: StudentReducer,
	examtype: ExamTypeReducer,
	subscribe: SubscribeReducer,
	subject: SubjectReducer,
	onlineexam: OnlineExamReducer,
	elibrary: ElibraryReducer,
	dashboard: DashboardReducer,
	performance: PerformanceScoreReducer,
	profile: ProfileReducer,
	posts: PostsReducer,
	todoReducers
})

export default rootReducers;