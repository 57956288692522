import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom"
import { checkAutoLogin } from '../../services/AuthService'
import { connect, useDispatch } from 'react-redux';

const Error404 = (props) => {

   const history = useHistory();
   const dispatch = useDispatch();

   useEffect(() => {
      // history.push("/page-error-404")

      return () => {

      }
   }, []);

   useEffect(() => {
      history.push("/page-error-404")
      const path = window.location.pathname.split("/");
      if (path[1] !== 'page-reset-password' && path[1] !== 'page-register') {
         // console.log("checkAutoLogin----Error404",)
         checkAutoLogin(dispatch, props.history, path);
      }
   }, [dispatch, props.history]);


   return (
      <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center ">
               <div className="col-md-6">
                  <div className="form-input-content text-center error-page">
                     <h6 className="error-text new_size font-weight-bold">Error:Page Not Found</h6>
                     <h4>
                        <i className="fa fa-exclamation-triangle text-warning" />{" "}
                        Oops! It seems the page you are looking for is not found. Double-check the address or return to the homepage then try.
                     </h4>
                     {/* <p>
                        You may have mistyped the address or the page may have
                        moved.
                     </p> */}
                     <div>
                        <Link className="btn btn-primary text-center" to="/dashboard">
                           Back to Home
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Error404;
