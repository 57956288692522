import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

import { selectExamCategoryAction, getCategoryData } from '../../../store/actions/ExamCategoryAction';
import { getDemoPdfContentData, getElibraryContentRequest, eliraryCategoryAction } from '../../../store/actions/LibraryAction';


import {
	Spinner
} from "react-bootstrap";
import CategoryCard from '../../components/eLibrary/CategoryCard';

const DemoLibrary = (props) => {
	const history = useHistory();

	const dispatch = useDispatch();
	const callFirstTimeRef = useRef(true);
	const [isDemo, setIsDemo] = useState('')

	useEffect(() => {
		if (callFirstTimeRef.current) {
			dispatch(getCategoryData(props.history));
		}
		callFirstTimeRef.current = false
		// dispatch(eliraryShowCallIcon(1))

		let getData = localStorage.getItem('userDetails');
		let user_id = JSON.parse(getData).id;

		setIsDemo(user_id);
		return () => {
			// dispatch(eliraryShowCallIcon(0))
		};
	}, [props.categorylist]);

	const selectExamCategoryId = (id) => {
		dispatch(selectExamCategoryAction(id));
	}

	/* go to list item page */
	const showDemoELibrary = (item) => {
		// console.log("item--", item)
		dispatch(getElibraryContentRequest(true));
		dispatch(getDemoPdfContentData(item.id, props.history));
		dispatch(eliraryCategoryAction([]));
		dispatch(eliraryCategoryAction([item.category, "Demo"]));

		const elibraryDetails = {
			title: "Demo",
			category: item.category,
			item: '',
			subjectName: "",
			selectId: ""
		}
		localStorage.setItem('localstrageElibraryDetails', JSON.stringify(elibraryDetails));
	}


	return (
		<React.Fragment >
			<div className="row">
				<div className="col-md-12">
					<div className='text_blue'>e-Library <i className="bi bi-chevron-right"></i> Demo <i className="bi bi-chevron-right"></i> {isDemo == 0 ? `Guest User` : `Registered User`} {/* <i className="bi bi-chevron-right"></i> e-library */}</div>
				</div>
				<div className='mt-3'></div>
			</div>
			<div className="row">
				<div className="demo-elibrary" >
					{props.categorylist != '' ?
						<>
							{props.categorylist.map((item, index) => (
								<div onClick={() => showDemoELibrary(item)} key={index} className='cursorPointer mb-40'>
									<CategoryCard
										icon={item.id == 1 ? item.e_library : item.e_library}
										title={item.category}
										description={item.elibrary_subheading}
										bgColor={item.id == 1 ? '#94AC4B' : 'rgb(87, 186, 215)'}
										leftColor={item.id == 1 ? '#94AC4B' : 'rgb(87, 186, 215)'}
										iconClass={'left_image'}
										iconClassRgt={'right_image'}
									/>
								</div>
							))}
						</>

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		categorylist: state.category.examcategoryList,
	};
};
export default connect(mapStateToProps)(DemoLibrary);