import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { connect, useDispatch, } from 'react-redux';

import ReactApexChart from "react-apexcharts";
import Loader from "../../components/Loader"

import { performanceRequestAction, getScholasticAveragePerformanceSetModuleMockData } from '../../../store/actions/PerformanceScoreAction';


const SubjectWiseComparisonOn = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [previousValue, setPrevousValue] = useState(location.state);
    const [graphicDetails, setGraphicDetails] = useState([]);
    const [graphicSubDetails, setGraphicSubDetails] = useState();
    const totalRef = useRef(true);
    const functionalCallFirstTimeRef = useRef(true);

    const barChartDetails = {

        series: graphicDetails,
        options: {
            chart: {
                type: "bar",
                height: 100,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },

            legend: {
                show: true,
                fontSize: "12px",
                fontWeight: 300,

                labels: {
                    colors: "black",
                },
                position: "bottom",
                horizontalAlign: "center",
                markers: {
                    width: 19,
                    height: 19,
                    strokeWidth: 0,
                    radius: 19,
                    strokeColor: "#fff",
                    fillColors: ["#4472c4", "#ed7d31", "#a5a5a5"],
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#3e4954",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: 100,
                    },
                },
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                // categories: ["Set", "Module", "Mock"],
                categories: (graphicSubDetails),
            },
            fill: {
                colors: ["#4472c4", "#ed7d31", "#a5a5a5"],
                opacity: 1,
            },
            /* tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands";
                    },
                },
            }, */
        },
    };


    useEffect(() => {
        if (functionalCallFirstTimeRef.current) {
            dispatch(performanceRequestAction(true))
            dispatch(getScholasticAveragePerformanceSetModuleMockData(props.history))
        }
        functionalCallFirstTimeRef.current = false;

        const graphDetailsData = [{
            name: "set",
            data: [],
        },
        {
            name: "module",
            data: [],
        },
        {
            name: "mock",
            data: [],
        }
        ];

        for (let i = 0; i < props.scholasticSetMoudleMockAveragePerformance.length; i++) {
            graphDetailsData[0].data.push(props.scholasticSetMoudleMockAveragePerformance[i].set_avg);
            graphDetailsData[1].data.push(props.scholasticSetMoudleMockAveragePerformance[i].module_avg);
            graphDetailsData[2].data.push(props.scholasticSetMoudleMockAveragePerformance[i].mock_avg);
        }
        setGraphicDetails(graphDetailsData);

        const graphSubNameDetailsData = [];
        var seriesNameData = null;
        for (let i = 0; i < props.scholasticSetMoudleMockAveragePerformance.length; i++) {
            let seriesNameData = {};
            seriesNameData = props.scholasticSetMoudleMockAveragePerformance[i].subject_name;
            graphSubNameDetailsData.push(seriesNameData);
        }
        setGraphicSubDetails(graphSubNameDetailsData);
    }, [props.scholasticSetMoudleMockAveragePerformance]);

    const setPage = () => {
        history.push({ pathname: "/online-avg-set-exams", state: { type: 1, type_name: 'Set' } })
    }

    const modPage = () => {
        history.push({ pathname: "/online-avg-set-exams", state: { type: 2, type_name: 'Moudle' } })
    }

    const mockPage = () => {
        history.push({ pathname: "/online-avg-set-exams", state: { type: 3, type_name: 'Mock' } })
    }


    return (
        <>
            {props.scholasticSetMoudleMockAveragePerformance != "" ?
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <div className="top_back_container" onClick={() => history.goBack()}>
                                <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                                Back
                            </div>
                        </div>
                    </div>
                    <div className='performance_container'>
                        <div className="text-center">
                            <h3>Subject wise comparison on</h3>
                            <h6>Set score Vs Module score Vs Mock score</h6>
                        </div>
                        <div className='performance_parent_container'>
                            <div className='score_container flex-1'>
                                <div className='score_card_parent_container'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className="text-center">Set</th>
                                                <th className="text-center">Module</th>
                                                <th className="text-center">Mock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.scholasticSetMoudleMockAveragePerformance != "" ?
                                                props.scholasticSetMoudleMockAveragePerformance.map((item, i) => {
                                                    return [
                                                        <tr className={`${i % 2 == 0 ? `tr-background` : null} `} key={i} >
                                                            <td className="text-center">{item.subject_name}</td>
                                                            <td className="text-center">{item.set_avg}</td>
                                                            <td className="text-center">{item.module_avg}</td>
                                                            <td className="text-center">{item.mock_avg}</td>
                                                        </tr>
                                                    ];
                                                })
                                                : <tr>
                                                    <td colSpan={4}>No data Available</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='chart_container flex-1 wh400 jcc text-center flex_column'>
                                <div id="chart" className="line-chart-style bar-chart">
                                    <ReactApexChart
                                        options={barChartDetails.options}
                                        series={barChartDetails.series}
                                        type="bar"
                                        height={300}
                                    />
                                </div>
                                <div className="button_container">
                                    <div className="text-center details_text btn btn-danger" onClick={setPage}>Set</div>
                                    <div className="text-center details_text btn btn-danger" onClick={modPage}>Module</div>
                                    <div className="text-center details_text btn btn-danger" onClick={mockPage}>Mock</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
                : <Loader />}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        scholasticSetMoudleMockAveragePerformance: state.performance.scholasticSetMoudleMockAveragePerformance,
        loading: state.performance.loading,
    };
};
export default connect(mapStateToProps)(SubjectWiseComparisonOn);
