import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom"
import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";
import PerformanceLoader from '../../../jsx/components/PerformanceLoader'

//Import Components
import ChartCard5 from './performanceComponent/ChartCard5';
import ChartCard16Sat from './performanceComponent/ChartCard16Sat';
import ChartCard16Mat from './performanceComponent/ChartCard16Mat';
import ChartCard20 from './performanceComponent/ChartCard20';
import ChartCard21 from './performanceComponent/ChartCard21';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_2background.png'

import { getCompetitiveSetwiseSatScoreDataDetails, getCompetitiveSetwiseMatScoreDataDetails, performanceRequestAction, getCompetitiveSetwiseSatScoreSubjectDataDetails, getCompetitiveSetwiseMatScoreSubjecttDataDetails } from '../../../store/actions/PerformanceScoreAction';

const SubjectwiseCompetitiveScore = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [previousValue, setPrevousValue] = useState(location.state)

	const [typeName, setTypeName] = useState('')
	const [pageName, setpageName] = useState('')

	useEffect(() => {
		const pathName = window.location.pathname.split('/');
		const id_path = pathName[2];
		const com_type = id_path.split('-');
		const name_com_type = com_type[0];
		setTypeName(name_com_type);
		setpageName(com_type.join(" "))

		dispatch(performanceRequestAction(true))
		// console.log("subjectwise-competitive-score---location.state---333-1>", location.state, pageName)

		/* dispatch(getCompetitiveSetwiseSatScoreDataDetails(previousValue.set_no, previousValue.exam_type, props.history))
		dispatch(getCompetitiveSetwiseMatScoreDataDetails(previousValue.set_no, previousValue.exam_type, props.history))

		dispatch(getCompetitiveSetwiseSatScoreSubjectDataDetails(previousValue.set_no, previousValue.exam_type, props.history))
		dispatch(getCompetitiveSetwiseMatScoreSubjecttDataDetails(previousValue.set_no, previousValue.exam_type, props.history)) */

		dispatch(getCompetitiveSetwiseSatScoreDataDetails(location.state.set_no, location.state.exam_type, props.history))
		dispatch(getCompetitiveSetwiseMatScoreDataDetails(location.state.set_no, location.state.exam_type, props.history))

		dispatch(getCompetitiveSetwiseSatScoreSubjectDataDetails(location.state.set_no, location.state.exam_type, props.history))
		dispatch(getCompetitiveSetwiseMatScoreSubjecttDataDetails(location.state.set_no, location.state.exam_type, props.history))

		if (location.state.exam_type == 'undefined' || location.state.exam_type == undefined) {
			// console.log("location.state.exam_type----", location.state.exam_type)
			history.push({ pathname: "/PerformanceCategoryPage" })
		}

		setBodyImage(performerBackground)

		return () => {
			setBodyImage(dashboardBackground)
		};

	}, []);

	useEffect(() => {


	}, [props.getcompetitiveSetwiseSatScore, props.competitiveSetwiseSatScoreSubject, props.competitiveSetwiseMatScoreSubject]);


	const goPrevious = () => {
		// console.log("subjectwise-competitive-score-----", history)
		history.goBack()
	}



	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> Competitive Performance Score <i className="bi bi-chevron-right"></i> {pageName}</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			<Row>
				<Col xl={6}>
					{props.getcompetitiveSetwiseSatScore != '' && props.getcompetitiveSetwiseSatScore != undefined ?
						<ChartCard16Sat
							// pageHeading={typeName == "NTSE" ? "Set Wise SAT Score" : "Set Wise Score"}
							pageHeading={typeName == "NTSE" ? "Grade Grid - Set (SAT)" : "Set Wise Score"}
							dataSet={props.getcompetitiveSetwiseSatScore}
							setNumber={previousValue.set_no}
							exam_type={previousValue.exam_type}
							typeName={typeName}
							subType="SAT"
							previousPageName={pageName}
						/>
						:
						<PerformanceLoader />
					}
				</Col>

				<Col xl={6}>
					{props.competitiveSetwiseSatScoreSubject != '' && props.competitiveSetwiseSatScoreSubject != undefined ?
						<ChartCard20
							pageHeading="Subject Analysis - Set"
							dataSet={props.competitiveSetwiseSatScoreSubject}
							setNumber={previousValue.set_no}
							typeName={typeName}
						/>
						:
						<PerformanceLoader />
					}
				</Col>
				{typeName == "NTSE" ?
					<>
						<Col xl={6}>
							{props.getcompetitiveSwiseMatScore != '' && props.getcompetitiveSwiseMatScore != undefined ?
								<ChartCard16Mat
									// pageHeading="Set Wise MAT Score"
									pageHeading="Grade Grid - Set (MAT)"
									dataSet={props.getcompetitiveSwiseMatScore}
									setNumber={previousValue.set_no}
									exam_type={previousValue.exam_type}
									typeName={typeName}
									subType="MAT"
									previousPageName={pageName}
								/>
								:
								<PerformanceLoader />
							}
						</Col>
						<Col xl={6}>
							{props.competitiveSetwiseMatScoreSubject != '' && props.competitiveSetwiseMatScoreSubject != undefined ?
								<ChartCard21
									pageHeading="Subject Analysis - Set"
									dataSet={props.competitiveSetwiseMatScoreSubject}
									setNumber={previousValue.set_no}
									typeName={typeName}
								/>
								:
								<PerformanceLoader />
							}
						</Col>
					</>
					: null}
				{/* <Link to="/competitive-subjectwise-analysis-on-set">Next Page</Link> */}

			</Row>

		</>
	)
}

const mapStateToProps = (state) => {

	// console.log("-----11-----", state.performance.getcompetitiveSetwiseSatScore )
	// console.log("------<>----", state.performance.competitiveSetwiseMatScoreSubject, )

	return {
		loading: state.performance.loading,
		getcompetitiveSetwiseSatScore: state.performance.getcompetitiveSetwiseSatScore,
		getcompetitiveSwiseMatScore: state.performance.getcompetitiveSwiseMatScore,
		competitiveSetwiseSatScoreSubject: state.performance.competitiveSetwiseSatScoreSubject,
		competitiveSetwiseMatScoreSubject: state.performance.competitiveSetwiseMatScoreSubject,
	};
};
export default connect(mapStateToProps)(SubjectwiseCompetitiveScore);