import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function updateProfile(profile_pic, fname, lname, dob, email, gender, address, pincode, mobile, standard, board, school_name, school_address, password) {

    let getData = localStorage.getItem('userDetails');
    let student_id = JSON.parse(getData).id;
    let token = JSON.parse(getData).token;

    const postData = {
        student_id,
        profile_pic,
        fname,
        lname,
        dob,
        email,
        gender,
        address,
        pincode,
        mobile,
        standard,
        board,
        school_name,
        school_address,
        password
    };
    const formData = new FormData();
    formData.append("student_id", student_id);
    formData.append("profile_pic", profile_pic);
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("dob", dob);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("pincode", pincode);
    formData.append("mobile", mobile);
    formData.append("standard", standard);
    formData.append("board", board);
    formData.append("school_name", school_name);
    formData.append("school_address", school_address);
    formData.append("password", password);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROFILE_DETAILS_LIST_API,
        method: "POST",
        headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });
}