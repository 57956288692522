import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

const PaymentGateWay = (props) => {

    // const [actionUrl, setActionUrl] = useState("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction")
    const [actionUrl, setActionUrl] = useState(process.env.REACT_APP_LMS_PAYMENT_GATEWAY_ACTION_URL)

    const formRef = useRef();
    const location = useLocation();

    const [previousValue, setPrevousValue] = useState(location.state)

    useEffect(() => {
        // console.log("****PaymentGateWay**", previousValue)
        document.redirect.submit();
        // formRef.current.submit();
    }, []);

    return (
        <div>


            <form ref={formRef} method="post" name="redirect" action={actionUrl}>

                <input type='hidden' name='encRequest'
                    value={previousValue.encrypted_data} />
                <input type='hidden' name='access_code' value={previousValue.access_code} />

            </form>






            {/* <form method="post" name="redirect"
                action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                <input type='hidden' name='encRequest'
                    value='91d9321d6fbf8d3ec72bffc554acb409b6dbf44f41ea42828709ac481675fd8d14979012047ea53f957cc062bd9282e2241eaf6a4a945d85ec1570b9aeccf328ed42fc4709ac050d65d1ecb34a562592dc6f40e18bf06d8a7dd2814fc23828504110770e739f7fa9ef06b9ff590be0a79d6fcd47de27a6ea7344b18277d1b08f3bb2ea8f1d18665ca7c23437ca9a58841b1b255d9bbd8633dc938097fabdbb796accb55a66bb32dc8ca844a24f5e9ecc374e87a42bfddd2996be2680d33e6386f62301c65207b93403b8a95ea04b92bdbf1616415c4e1e81447f819108fb6bbb62769835424fb846c0748848a7da08d58ba259379414dcfce06742d91d25e786381eca61d6fdc732db5e86b97bd11f5818d2648dd9c82a4eb29094ed82d505dcdf76b536ec94d3e9ac55c0fea74f680691c0023202c71ccb29a89ae83f884dc475a588bc2b7ed210d0830c40a791b5f663bc7606846c26c6ae046dfd2339584dae0f6a3b6036cba42223aef5f7197999cff10b023c340918806332eba48e4c882963f172cc2ee053cdfe6b397d81553434629c5219dc363afb082a5bbceda42d' />
                <input type='hidden' name='access_code' value='AVWS04KC67AD29SWDA' />
            </form> */}

            {/* <script language='javascript'>document.redirect.submit();</script> */}
        </div>
    )
}

export default PaymentGateWay;
