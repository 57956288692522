import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import ntse from '../../../images/exam-type/ntse.png';
import nstse from '../../../images/exam-type/nstse.png';
import { Link } from "react-router-dom";
import CategoryCard from '../../components/eLibrary/CategoryCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom";
import { getPurchaseExamTypeData } from '../../../store/actions/ExamTypeAction';

import { eliraryShowCallIcon } from '../../../store/actions/LibraryAction';

const CompetitiveTypeLibrary = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getPurchaseExamTypeData(props.history));

		return () => {
		
		};

	}, []);


	return (
		<>
			<div className="row">
				<div className="demo-elibrary" >
					{props.purchaseList ?
						props.purchaseList.map((item, index) => (
							<CategoryCard
								key={index}
								id={item.id}
								icon={item.id == 1 ? ntse : nstse}
								title={item.type_name}
								description={item.sub_heading}
								bgColor={item.id == 1 ? '#4ba5c0' : '#5fc3e1'}
								leftColor={'#4ba5c0'}
								iconClass={'left_image'}
								iconClassRgt={'right_image'}
								isSubscribe={item.is_subscribe}
							/>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList,
		purchaseList: state.examtype.purchaseList,
	};
};

export default connect(mapStateToProps)(CompetitiveTypeLibrary);