import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

import { afterPurchasedGetPurchasedData } from '../../../store/actions/SubscribeAction';

const PaymentFailure = (props) => {

    const location = useLocation();
    const dispatch = useDispatch();

    const [previousValue, setPrevousValue] = useState(location.state)

    useEffect(() => {
        // console.log("****PaymentFailure**",)
        // document.redirect.submit();
        // dispatch(afterPurchasedGetPurchasedData());

    }, []);

    return (
        <div>

            <div className="row">
                <div className="col-xl-12" >
                    <div className="card students-list payment_message_box jcc aic">
                        <div className="card-header border-0 flex-wrap pb-0 jcc aic flex-column">
                            <div className="icon_round failure_color dflex jcc aic ">
                                <i className="fa fa-times font_white payment_icon_size" aria-hidden="true"></i>
                            </div>
                            <div className="mt-3 mb-3"><h1 className="font_red">Failure</h1></div>
                            <div>
                                <h5>We were unable to process your payment</h5>
                            </div>
                        </div>
                        <div className='mt-5'></div>

                    </div>
                </div>

            </div>
            {/* <div>
                {props.loading ?
                    <Loader />
                    : null}
            </div> */}
        </div>
    )
}

export default PaymentFailure;
