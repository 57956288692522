import {
    LOGIN_CONFIRMED,
    LOGOUT,
    DEMO_LOGIN,
    REG_USER_SUB_OR_NOT,
    FORGET_PASSWORD_EMAIL_SUCCESS,
    FORGET_PASSWORD_EMAIL_FAILURE,
    GENERATE_PASSWORD,
    REG_USER_ELIBRARU_OR_NOT,
    GO_TO_TAB,
    CHANGE_TAB,
    TERMS_CONDITION,
    PRIVACY_POLICY,
    UNLOCK_SCREEN,
    FEEDBACK_GIVEN,
    OTP_BEFORE_LOGIN,
    DASHBOARD_LOGINDATA,
    PASSWORD_RESET_TIME_CHECK,
    RESET_LINK_EXIST_OR_NOT
} from '../constants';

import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    demoLoginService,
    forgetPassword,
    newPasswordGenerate,
    getTermsCondition,
    getprivacyPolicy,
    unlockScreen,
    feedbackGivenData,
    sendOtpBeforeLoginScreen,
    dashboardLogindataData,
    checkPasswordLinkExistData

} from '../../services/AuthService';

import { demoExamSubmitAction } from '../actions/ScholasticAction'

import * as utility from '../../utility/Utility';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    localStorage.removeItem('refreshExamId');
    localStorage.removeItem('localstrageElibraryDetails');
    // localStorage.removeItem('subscriptionPreviousValue');
    // localStorage.removeItem('persist:persist-root');
    // localStorage.clear();

    history.replace("/page-login");

    return {
        type: LOGOUT,
    };

}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response.data.status === 200) {
                    saveTokenInLocalStorage(response.data.data[0]);
                    dispatch(loginConfirmedAction(response.data.data[0]));
                    dispatch(demoExamSubmitAction(response.data.demo_exam_submit)); // option close due to auto submit registered exam
                    utility.showSuccess(response.data.msg);
                    history.replace('/dashboard');
                } else if (response.data.status === 220) {
                    history.push('page-coming-soon');
                } else {
                    utility.showError(response.data.msg);
                    setTimeout(() => {
                        dispatch(loadingToggleAction(false));
                    }, 3000);

                }
            })
            .catch((error) => {
                console.log('hello error', error.data);
                // const errorMessage = formatError(error.response.data);
                // utility.showError(errorMessage);
                // dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function demoLogin(history) {
    return (dispatch) => {
        demoLoginService()
            .then((response) => {
                if (response.data.status === 200) {
                    saveTokenInLocalStorage(response.data.data[0]);
                    dispatch(demoLoginConfirmedAction(response.data.data[0]));
                    utility.showSuccess(response.data.msg);
                    history.replace('/dashboard');

                } else {
                    utility.showError(response.data.msg);
                    setTimeout(() => {
                        dispatch(loadingToggleAction(false));
                    }, 3000);

                }
            })
            .catch((error) => {
                console.log('hello', error.data);
                // const errorMessage = formatError(error.response.data);
                // utility.showError(errorMessage);

            });
    }
}

export function forgetPasswordEmail(email, history) {
    return (dispatch) => {
        forgetPassword(email)
            .then((response) => {
                if (response.data.status === 200) {

                    dispatch(forgetPasswordEmailSuccess(response.data.requestdata.email));
                    utility.showSuccess(response.data.msg);
                    // history.push('/page-login');

                    dispatch(forgetPasswordEmailSuccess(response.data.requestdata.email));
                    history.push('/page-login');
                    utility.showSuccess(response.data.msg);

                } else if (response.data.status === 410) {
                    dispatch(forgetPasswordEmailFailure(''));
                    utility.showError(response.data.msg);
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                console.log('catch(error)', error.data);
                // const errorMessage = formatError(error.response.data);
                // utility.showError(errorMessage);

            });
    }
}

export function resetPassword(password, url, history) {
    return (dispatch) => {
        newPasswordGenerate(password, url)
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(generatePassword(response.data.msg));
                    utility.showSuccess(response.data.msg);
                    setTimeout(() => {
                        history.push('/page-login');
                    }, 3000);

                } else if (response.data.status === 410) {
                    dispatch(generatePassword(response.data.msg));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                console.log('catch(error)', error.data);
                // const errorMessage = formatError(error.response.data);
                // utility.showError(errorMessage);

            });
    }
}

export function getTermsConditionDetails(history) {
    return (dispatch) => {
        getTermsCondition()
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(termsConditionAction(response.data.data));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                console.log('catch(error)', error.data);
                // const errorMessage = formatError(error.response.data);
                // utility.showError(errorMessage);

            });
    }
}

export function getPrivacyPolicyDetails(history) {
    return (dispatch) => {
        getprivacyPolicy()
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(privacyPolicyAction(response.data.data));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                console.log('catch(error)', error.data);
                // const errorMessage = formatError(error.response.data);
                // utility.showError(errorMessage);

            });
    }
}


export function unlockScreenData(password, history) {
    return (dispatch) => {
        unlockScreen(password)
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(unlockScreenAction(false));
                    // utility.showSuccess(response.data.msg);
                    // history.push("/dashboard");

                    history.push('/dashboard');
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(unlockScreenAction(false));
                    utility.showError(response.data.msg);
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                // utility.showError(errorMessage);
            });
    }
}


export function feedbackGivenDataDetails(history) {
    return (dispatch) => {
        feedbackGivenData()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(feedbackGivenAction(response.data.feedback_status));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function sendOtpBeforeLoginScreenDetails(history) {
    return (dispatch) => {
        sendOtpBeforeLoginScreen()
            .then((response) => {
                // console.log("sendOtpBeforeLoginScreenDeytails----", response )
                if (response.data.status == 200) {
                    dispatch(sendOtpBeforeLoginScreenAction(response.data.otp));
                }
                
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

/* call user details when visit dashboard when user login  */
export function dashboardLogindataDataDetails(history) {
    return (dispatch) => {
        dashboardLogindataData()
            .then((response) => {
                if (response.data.status == 200) {
                    saveTokenInLocalStorage(response.data.data[0]); /* Store user details into local storage */
                    dispatch(loginConfirmedAction(response.data.data[0])); /* login data store into action */
                    dispatch(demoExamSubmitAction(response.data.demo_exam_submit)); // option close due to auto submit registered exam
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}


export function checkPasswordLinkExistDetails(message, history) {
    return (dispatch) => {
        checkPasswordLinkExistData(message)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(resetLinkExistOrNotAction({statuPosition:1, msg:''}));
                }
                if (response.data.status == 410) {
                    dispatch(resetLinkExistOrNotAction({statuPosition:0, msg:response.data.msg}));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function demoLoginConfirmedAction(data) {
    return {
        type: DEMO_LOGIN,
        payload: data,
    };
}

export function registerdUserIsSubscribe(data) {
    return {
        type: REG_USER_SUB_OR_NOT,
        payload: data,
    };
}

export function forgetPasswordEmailSuccess(data) {
    return {
        type: FORGET_PASSWORD_EMAIL_SUCCESS,
        payload: data,
    };
}
export function forgetPasswordEmailFailure(data) {
    return {
        type: FORGET_PASSWORD_EMAIL_FAILURE,
        payload: data,
    };
}

export function generatePassword(data) {
    return {
        type: GENERATE_PASSWORD,
        payload: data,
    };
}

export function registerdUserElibraryIsSubscribe(data) {
    return {
        type: REG_USER_ELIBRARU_OR_NOT,
        payload: data,
    };
}

export function goToTab(data) {
    return {
        type: GO_TO_TAB,
        payload: data,
    };
}

/* export function changeTab(data) {
    return {
        type: CHANGE_TAB,
        payload: data,
    };
} */

export function termsConditionAction(data) {
    return {
        type: TERMS_CONDITION,
        payload: data,
    };
}
export function unlockScreenAction(status) {
    return {
        type: UNLOCK_SCREEN,
        payload: status,
    };
}
export function privacyPolicyAction(data) {
    return {
        type: PRIVACY_POLICY,
        payload: data,
    };
}

export function feedbackGivenAction(data) {
    return {
        type: FEEDBACK_GIVEN,
        payload: data,
    };
}
export function sendOtpBeforeLoginScreenAction(data) {
    return {
        type: OTP_BEFORE_LOGIN,
        payload: data,
    };
}

export function dashboardLogindataAction(data) {
    return {
        type: DASHBOARD_LOGINDATA,
        payload: data,
    };
}

export function resetLinkExistOrNotAction(data) {
    return {
        type: RESET_LINK_EXIST_OR_NOT,
        payload: data,
    };
}

