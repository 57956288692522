import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import { getPrivacyPolicyDetails } from '../../store/actions/AuthActions';

const PrivacyPolicy = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    

    useEffect(() => {
        dispatch(getPrivacyPolicyDetails(props.history)); /* call privacy policy data */
    }, []);


    return (
        <>
            <Modal className="fade =" show={props.privacyPolicyModal} size='lg'>
                <Modal.Header>
                    <Modal.Title className="modal_title">Privacy Policy </Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => props.closePrivacyPolicyModal()}
                    >
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    <div className="col-lg-12 modal_body_height">
                        {props.privacyPolicy != '' ?
                            <>
                                <div dangerouslySetInnerHTML={{ __html: props.privacyPolicy }}></div>
                            </>
                            :
                            <div className="spinner-view">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    //size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        privacyPolicy: state.auth.privacyPolicy
    };
};
export default connect(mapStateToProps)(PrivacyPolicy);