import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PurchaseCart = (props) => {

	const [isToggle, setIsToggle] = useState(true);



	return (
		<div className="purchase-item">
			<div className="purchase-lft-item" style={{ background: props.categoryId === 2 ? '#50a9c4' : props.categoryId === 3 ? '#2e999f' :  '#56c760' }}> 
				<h4>{props.category.charAt(0)}</h4>
				{/* <p>{props.class == 0 ? props.type : "Class"} {props.class == 0 ? null : props.class}</p> */}
				{props.categoryId === 1 ?
					<p>{props.class == 0 ? props.type : "Class"} {props.class == 0 ? null : props.class}</p>
					: props.categoryId === 3 ?
						<p>{/* {props.class == 0 ? props.type : "Classs"} {props.class == 0 ? null : props.class} */} {props.exam_type}</p>
						:
						<p>{props.type}</p>
				}

			</div>
			<div className="purchase-rght-item">
				<h4 style={{ color: props.categoryId === 2 ? '#50a9c4' : '#56c760' }}>{props.category} | {props.categoryId === 2 ? props.type + ' | ' : ''} {props.class == 0 ? null : 'Class'} {props.class == 0 ? null : props.class}</h4>
				<h5>{props.subject}</h5>
				<p>{props.set.length == 0 && props.categoryId == 1 ? null : props.set.length != 0 && parseInt(props.set) > 0 && props.categoryId == 2 ? ' Set ' + props.set : props.set.length != 0 && parseInt(props.set) == 0 && props.categoryId == 2 ? null : ' Test [' + props.set + ']'}{props.set.length != 0 && props.casestudy != 0 ? "+ " : null } {props.casestudy == 0 ? null : ' Case studies'} {props.set.length != 0 && props.module != 0 || props.set.length != 0 && props.mock != 0  ? ' + ' : props.set.length != 0 ? null : props.set.length == 0 && props.module == 0 || props.mock == 0 && props.onlyElibrary != 0 ? null :  props.module != 0 ? null : ' + '} {props.module == 0 ? null : '3 Modules'}  {props.mock == 0 ? null : props.module == 0 ? null : props.set.length == 0 ? null : ' + '}{props.mock == 0 ? null : '2 Mocks'}  {props.library == 1 && props.onlyElibrary == 0 && props.set.length != 0  ? " + e-Library" : null} {props.library == 1 && props.onlyElibrary == 0 && props.set.length == 0  ? "e-Library" : null} {props.library == 1 && props.onlyElibrary == 1 ? "e-Library" : null}</p>
				<div className="purchase-rght-item-pric">
					<span className="purchase-pric-value" style={{ color: props.categoryId === 2 ? '#50a9c4' : '#56c760' }}>Rs.{props.price}/-</span>
					<span>|</span>
					<Button
						onClick={() => props.deleteHandler()}
					>Delete</Button>
				</div>
			</div>
		</div>
	);
};

export default PurchaseCart;
