import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

import { getcompetitiveNonverbalcomparisonDataAction, getcompetitiveNonverbalcomparisonDataDetails } from '../../../../store/actions/PerformanceScoreAction';

const ChartCard22 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [labels, setLabels] = useState(['Ch1', 'Ch2', 'Ch3', 'Ch4', 'Ch5', 'Ch6', 'Ch7', 'Ch8', 'Ch9', 'Ch10', 'Ch11', 'Ch12', 'Ch13', 'Ch14', 'Ch15', 'Ch16', 'Ch17'])
    const [chartData, setChartData] = useState([60, 60, 70, 70, 80, 80, 80, 90,])
    const [backgroundColor, setBackgroundColor] = useState(['#CBF1FA',])

    const [overallAvg, setOverallAvg] = useState(0)
    const chartRef = useRef();

    const [chatData, setChatData] = useState(props.dataSet)

    const [showTableModal, setShowTableModal] = useState(false)
    const [standard, setStandard] = useState('')
    const [board_name, setBoard_name] = useState('')
    const [seriesData, setSeriesData] = useState()

    ChartJS.register(ArcElement, Tooltip, Legend);


    useEffect(() => {

    }, []);

    useEffect(() => {
        setSeriesData(chatData)
        let getData = localStorage.getItem('userDetails');

        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);
    }, []);

    const barData = {
        series: [{
            // data: [65, 75, 35, 39, 25, 38, 45, 55, 12, 77]
            // data: chatData.series[0].data
            data: props.dataSet.series[0].data
        }],
        options: {
            chart: {
                type: 'bar',
                height: 380,
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    barHeight: '70%',
                    borderRadius: 4,
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    },
                }
            },
            colors: ["#9AD37F", "#E3DD70", "#CAF0FC", "#F8CCC5", "#FEE4AC", "#F9D1E5", "#CCE6AF", "#F7DDEC", "#7DD6C7", "#FDB1B1"],
            dataLabels: {
                enabled: false,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: function (val, opt) {
                    return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                min: 0,
                max: 100,
                tickAmount: 10,
                // categories: ["Verbal", "Non-Verbal", "Eco", "Civ", "His", "Geo", "Bio", "Chem", "Phy", "Math" ],
                // categories: chatData.categories,
                categories: props.dataSet.categories,
            },

            yaxis: {
                labels: {
                    show: true,
                }
            },
            legend: {
                show: false,
                position: 'left',
                offsetY: 10,
                labels: {
                    // boxWidth: 40,
                    // padding: 13,
                    useSeriesColors: true,
                    usePointStyle: true,
                    // pointStyleWidth: 10,
                },
            },
            /* title: {
                text: 'Custom DataLabels',
                align: 'center',
                floating: true
            }, */
            /* subtitle: {
                text: 'Category Names as DataLabels inside bars',
                align: 'center',
            }, */
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        }
    };


    const showSetTableData = () => {
        // console.log("Show Table Data ----123");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }

    const showDetails = (item) => {
        // console.log(">>>>>>> ----showDetails", item)
        dispatch(getcompetitiveNonverbalcomparisonDataDetails(item, props.exam_type, props.history))
        /* const new_Data = {
            "series": [
                {
                    "data": [10, 23, 43, 33, 50, 50, 35, 35, 56, 30
                    ]
                }
            ],
            "categories": [
                ["Set 1"], ["Set 9"], ["Set 4"], ["Set 3"], ["Set 7"], ["Set 2"], ["Set 5"], ["Set 10"], ["Set 8"], ["Set 18"]
            ]
        }
        dispatch(getcompetitiveNonverbalcomparisonDataAction(new_Data)); */
    }

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        <div className="chart_body" >
                            {/* <ReactApexChart options={barData.options} series={barData.series} type="bar" height={290} /> */}
                            <ReactApexChart options={barData.options} series={barData.series} type="bar" height={250} />
                        </div>

                        <div className="bottom_container">
                            <div className="data_label_parent_container">
                                <div className={`data_label  ${props.dataSet.categories.length > 6 ? "justify-content-start" : "justify-content-center"}`}>

                                    {props.dataSet != '' && props.dataSet != null && props.dataSet != undefined ?
                                        props.dataSet.categories.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className='item_box cursorPointer'  style={{ background: `${index % 2 == 0 ? '#3851AB' : '#8592CB'}` }} onClick={() => showDetails(item)}>{item}</div>
                                                </React.Fragment>
                                            )
                                        })
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="bottom_details">
                            {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                            <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                {/* <div className='performer_table_heading'>{props.pageHeading}</div> */}
                                <div className='performer_table_heading'>Score grid - Subjectwise</div>
                                <p>Competitive  <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Subject</th>
                                        <th className="text-center light_sky">Score</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {barData.options.xaxis.categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center light_gray_1">{item}</td>
                                                <td className="text-center light_gray_2">{barData.series[0].data[index]}</td>
                                            </tr>
                                        )
                                    })
                                    }

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps)(ChartCard22);