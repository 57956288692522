import React from 'react';
import IconTint from 'react-icon-tint';


export default function ListCard(props) {
	return (
		<>
			{/* <div className="row"> */}
				<div className={`exam_inside_container_list ${props.is_expired == 0 ? "cursorPointer" : null}`} >
						<div className="col-md-6 contain_container_list" style={{ backgroundColor: props.bgColor }}>
							<div className="left_container_list">

								<IconTint
									src={props.icon}
									alt=""
									color={props.leftColor}
									className={props.iconClass}
								/>
							</div>
							<div className="middle_container">
								<div className="middle_inside_container">
									<h3 className="font_white screen_wise_font_size">{props.title}</h3>
									<p className="font_white">{props.description}</p>
									<p className="font_white exam_date screen_wise_font_size_exam_date">Exam date: {props.exam_date} <span className='expire_color'>{props.is_expired == 1 ? <>({props.expired_text}) </>: null}</span></p>
								</div>
							</div>
							<div className="right_container_list">
								<IconTint
									src={props.icon}
									alt=""
									color="#fff"
									className={props.iconClassRgt} />
							</div>
						</div>
				</div>
			{/* </div> */}
		</>
	)
}