import React, { useEffect, useState } from 'react';

import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "ckeditor5-build-classic-mathtype";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from "@tinymce/tinymce-react";
import GlobalConfigs from "../../../../configs/GlobalConfigs";

export default function AssessmentDetailCard(props) {

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typesetClear()
            window.MathJax.typeset()
        }
    }, []);

    /* 
    <div class="wrapper">
    <div class="left"></div>
    <div class="right"></div>
</div>
    */

    return (
        <>
            <div className={`assessment_container ${props.statusColor === 1 ? `base_green` : (props.statusColor === 0 && props.guest_post_ans !== "undefined") ? `base_red` : (props.statusColor === 0 && props.guest_post_ans === "undefined") ? `base_grey` : null}`}>
                <div className="assessment_container_inside">
                    <div className="assessment_container_top">
                        {/* <div className="assessment_question_number">{props.questionNo}</div> */}
                        <div className={`assessment_question_number ${props.statusColor === 1 ? `background_green` : (props.statusColor === 0 && props.guest_post_ans !== "undefined") ? `background_red` : (props.statusColor === 0 && props.guest_post_ans === "undefined") ? `background_grey` : null}`}>{props.questionNo}</div>
                    </div>
                    <div className="assessment_container_bottom">
                        <div className="assessment_container_bottom_left">
                            <div className="question_details helper-text flex-row d-flex wrap_text">
                                <div className='assessLeftPanelWidth'>
                                    Question :
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: props.question }}></div>

                            </div>
                            <div className='bottom_border'> </div>
                            {/* ------------ */}
                            <div className="option_container helper-text d-flex flex-row">
                                <div className='assessLeftPanelWidth '>
                                    Option :
                                </div>
                                <div className="row flex-1">
                                    {
                                        Object.keys(props.options[0]).map(([key, value]) => {

                                            return (
                                                <React.Fragment key={key}>
                                                    <div className="col-md-12 display_flex wrap_text">{`(` + key + `)  `}<div className="ml7" dangerouslySetInnerHTML={{ __html: props.options[0][key] }}></div></div>

                                                </React.Fragment>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className='bottom_border'> </div>
                            {/* ------------ */}
                            <div className="answer_details helper-text same_area mt-2">
                                <div className='assessLeftPanelWidth'>
                                    Option :
                                </div>
                                <div className='flex-1'>
                                    <div className='flex-1'>Your answer: {props.studentAnswer}</div>
                                    <div className='flex-1'>Correct answer: <span className="font_green">{props.correctAnswer}</span></div>
                                </div>
                            </div>

                            <div className='bottom_border mt-2'> </div>
                            <div className="assessment_container_bottom_right helper-text same_area d-flex flex-row mt-2 assessment_reasons">
                                <div className='assessLeftPanelWidth '>
                                    Reason :
                                </div>
                                <div className='wrap_text'><div key={props.questionNo} dangerouslySetInnerHTML={{ __html: props.reason }}></div></div>

                                <div className="modal-editor">
                                    {/* <Editor
                                        apiKey={GlobalConfigs.TINY_API_KEY}
                                        value={props.reason}
                                        disabled={true}
                                        init={{
                                            height: 300,
                                            menubar: false,
                                            external_plugins: {
                                                tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
                                            },
                                        }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}