import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import Loader from "../../components/Loader";
import { getsubscribedListDetails, subscribeLoading } from '../../../store/actions/SubscribeAction';

import testIcon from '../../../images/TestIcon.png'; // 

const studentTableBlog = [
    { image: testIcon, title: 'Mathematic', set: 1, module: 3, mock: 3, elibrary: 'Yes', caseStudies: "no" },
    { image: testIcon, title: 'Biology', set: 2, module: 3, mock: 3, elibrary: 'No', caseStudies: "Yes" },
];


const OnlineClass = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectId, setSelectId] = useState('')


    useEffect(() => {

    }, []);




    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    {/* <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i>
                    </div> */}
                    <div className='text_blue'>Online Classes {/* <i className="bi bi-chevron-right"></i> Online Classes */}</div>
                </div>
                <div className='mt-3'></div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card students-list screen_height">
                        <div className="card-header border-0 flex-wrap pb-0 jcc aic vh-100 " >
                            <h4>Online classes coming soon</h4>
                        </div>
                        {/* <div className='mt-5'></div> */}
                    </div>
                </div>
            </div>



            <div>
                {props.loading ?
                    <Loader />
                    : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // showLoading: state.subscribe.showLoading,
        // getsubscribedList: state.subscribe.getsubscribedList,
    };
};
export default connect(mapStateToProps)(OnlineClass);