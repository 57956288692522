import {
    GET_CLASS_STANDARD_LIST,
    CLASS_STANDARD_LOADING,
    LOGOUT
} from '../constants';

const initialState = {
    classStandardList: [],
    showLoadingclassStandard: false,
};

export function ClassStandardReducer(state = initialState, action) {
    
    /* store class data into reducer */
    if (action.type === GET_CLASS_STANDARD_LIST) {
        return {
            ...state,
            classStandardList: action.payload,
            showLoadingclassStandard: false,
        };
    }

    if (action.type === CLASS_STANDARD_LOADING) {
        return {
            ...state,
            showLoadingclassStandard: action.payload,
        };
    }

    if (action.type === LOGOUT) {
        return {
            ...state,
            classStandardList: [],
            showLoadingclassStandard: false,
        };
    }
    return state;
}

