import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardActions';
import { getClassStandardData, loadingClassStandardAction } from '../../../store/actions/ClassStandardAction';
import { getBranchScholasticData, loadingBranchScholasticAction, branchListAgainstSubjectid, branchListAgainstSubjectIdRequestAction, onlineExamIdAction, RemovePrevouseExamIdAction } from '../../../store/actions/ScholasticAction';
import { getChapterData, loadingChapterAction, getExamCompletedListData } from '../../../store/actions/ChapterAction';
import { getPurchasedSubjectData, purchasedSubjectsListRequestAction } from '../../../store/actions/SubjectAction';
import { onlineRequestAction, getOnlineScholasticModuleQuestionListData, getOnlineScholasticMockQuestionListData } from '../../../store/actions/OnlineExamAction';

const AssessmentCategoryWiseDetails = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const [board, setBoard] = useState('');
    const [standard, setStandard] = useState('');
    const [branch, setBranch] = useState('');
    const [branchSortCode, setBranchSortCode] = useState('');
    const [branchId, setBranchId] = useState('');

    const [subject, setSubject] = useState('');
    const [subscribSet, setSubscribSet] = useState('');
    const [chapter, setChapters] = useState('');
    const [purchsedSet, setPurchsedSet] = useState('');
    const [setlectSet, setSetlectSet] = useState('')
    const [module, setModule] = useState('')
    const [moduleDetails, setModuleDetails] = useState('')
    const [mock, setMock] = useState('')
    const [mockDetails, setMockDetails] = useState('')
    const [exam_type, setExam_type] = useState('')
    const [set_no, setSet_no] = useState()
    const [previousValue, setPrevousValue] = useState(location.state)
    const [subjectIndex, setSubjectIndex] = useState();
    const [chapterLoader, setChapterLoader] = useState(false);

    useEffect(() => {
        dispatch(loadingBoardAction(true));
        dispatch(getBoardData(props.history));
        dispatch(loadingClassStandardAction(true));
        dispatch(getClassStandardData(props.history));
        dispatch(loadingBranchScholasticAction(true));
        dispatch(getBranchScholasticData(1, props.history));
        dispatch(purchasedSubjectsListRequestAction(true));
        dispatch(getPurchasedSubjectData(props.history))
    }, []);

    const setSetlectSetData = (set_data) => {
        let splitSetValue = set_data.split(',');
        setSetlectSet(set_data)
        setExam_type(1)
        setSet_no()
        dispatch(getExamCompletedListData(splitSetValue[0], subject.split(',')[0], previousValue.categpryId, exam_type, props.history))
        dispatch(loadingChapterAction(true));
        dispatch(getChapterData(subject.split(',')[0], props.history));
    }

    const setSetlectModuleData = (module_data) => {
        // console.log("setSetlectModuleData-----" ,module_data )
        setModule(module_data)
        dispatch(RemovePrevouseExamIdAction(0))
        dispatch(onlineExamIdAction(module_data.split(',')[2]))
        history.push({ pathname: '/online-assessment-details', state: { exam: 1 } });
    }

    const setSetlectMockData = (mock_data) => {
        setMock(mock_data)
        dispatch(RemovePrevouseExamIdAction(0))
        dispatch(onlineExamIdAction(mock_data.split(',')[2]))
        history.push({ pathname: '/online-assessment-details', state: { exam: 1 } });
    }

    const showBranchListAgainstSubjectid = (subjectId) => {
        setBranch('');
        setPurchsedSet('');
        setModuleDetails('');
        setMockDetails('');
        setSetlectSet('');

        const splitSubjectId = subjectId.split(',');
        setSubject(subjectId);
        if (subjectId != '') {
            dispatch(branchListAgainstSubjectIdRequestAction(true));
            dispatch(branchListAgainstSubjectid(splitSubjectId[0], props.history))
            setSubjectIndex(splitSubjectId[1])
            setChapterLoader(true)
        }

        if (subjectId != '') {
            const setTotalData = [];
            var setText = null;
            for (let i = 0; i < props.purchasedSubjectList[splitSubjectId[1]].set_count; i++) {
                let setText = {};
                setText['name'] = "Set " + (i + 1);
                setText['set_no'] = i + 1;
                setTotalData.push(setText);
            }
            setPurchsedSet(setTotalData)
            setChapterLoader(false)
            //moduleDetails
            const moduleData = [];
            let moduleText = null;
            for (let i = 0; i < props.purchasedSubjectList[splitSubjectId[1]].module_count; i++) {
                let moduleText = {};
                moduleText['name'] = "Module " + (i + 1);
                moduleText['module_no'] = i + 1;
                moduleData.push(moduleText);
            }
            setModuleDetails(moduleData)

            //mockDetails
            const mockData = [];
            let mockText = null;
            for (let i = 0; i < props.purchasedSubjectList[splitSubjectId[1]].mock_count; i++) {
                let mockText = {};
                mockText['name'] = "Mock " + (i + 1);
                mockText['mock_no'] = i + 1;
                mockData.push(mockText);
            }
            setMockDetails(mockData)
        }

    }
    const updateExamUniqueId = (id) => {
        // console.log("updateExamUniqueId-----", id)
        // console.log("id======", id[0].exam_unique_id)
        dispatch(onlineExamIdAction(id[0].exam_unique_id))
    }

    

    return (
        <>
            <div className="row">

                <div className="col-md-1">

                    <div className="top_back_container" onClick={() => history.goBack()}>
                        <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
                        Back
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-xxl-4">
                    <div className="row">
                        <div className="col-xl-12 col-sm-6">
                            <div className={`card ${previousValue.categpryId == 1 ? `green_container` : `competitive_color`} padding_15 box_height_547`}>
                                <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control form-control select_option green_background color_white"
                                            value={subject}
                                            // onChange={(e) => setSubject(e.target.value)}
                                            onChange={(e) => showBranchListAgainstSubjectid(e.target.value)}
                                        >
                                            <option value="">Select Subject</option>
                                            {
                                                props.purchasedSubjectList.map((item, index) => {
                                                    return <option key={index} value={[item.subject_id, index]}>{item.subject_name}</option>
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>
                                {/*  } */}

                                {/* Branch--- */}
                                {/* {subject !== '' ?
                                        props.branchScholasticListAgainstSubjectId == null || props.branchScholasticListAgainstSubjectId == undefined ?
                                            <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                                <div className="basic-dropdown form-control select_option unselect_text">
                                                    <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                                    Select Branch
                                                </div>
                                            </div>
                                            :
                                            <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                                <div className="basic-dropdown">
                                                    <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                    <select
                                                        defaultValue={"option"}
                                                        className="form-control form-control select_option"
                                                        value={branch}
                                                        // onChange={(e) => setBranch(e.target.value)}
                                                        onChange={(e) => showChapterlist(e.target.value)}
                                                    >
                                                        <option value="">Select Branch</option>
                                                        {
                                                            props.branchScholasticListAgainstSubjectId.map((item, index) => {
                                                                return <option key={index} value={[item.id, item.branch_code]}>{item.branch_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        : <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                            <div className="basic-dropdown form-control select_option unselect_text">
                                                <span className="drop_down_arrow_disable"><i className="bi bi-caret-down-fill"></i></span>
                                                Select Branch
                                            </div>
                                        </div>
                                    } */}


                                {subject == '' ?
                                    null
                                    :
                                    <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                        <div className="basic-dropdown">
                                            <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control form-control select_option green_background color_white"
                                                value={setlectSet}
                                                // onChange={(e) => setSetlectSet(e.target.value)}
                                                onChange={(e) => setSetlectSetData(e.target.value)}
                                            >
                                                <option value="">Select Set</option>
                                                {
                                                    purchsedSet.map((item, index) => {
                                                        return <option key={index} value={item.set_no}>{item.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }

                                {/* module--- */}

                                {subject === '' ?
                                    null
                                    :
                                    moduleDetails != '' ?
                                        <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                            <div className="basic-dropdown">
                                                <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                                <select
                                                    defaultValue={"option"}
                                                    className="form-control form-control select_option green_background color_white"
                                                    value={module}
                                                    // onChange={(e) => setModule(e.target.value)}
                                                    onChange={(e) => setSetlectModuleData(e.target.value)}
                                                >
                                                    <option value="">Select Module</option>
                                                    {
                                                        props.purchasedSubjectList[subjectIndex].exam_completed_module.map((item, index) => {
                                                            return <option key={index} value={[item.module_no, item.subject_id, item.exam_unique_id]}>Module {item.module_no}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                }


                                {/* mock--- */}

                                {subject === '' ?
                                    null
                                    :
                                    mockDetails != "" ?
                                        <div className={`form-group exam ${previousValue.categpryId == 1 ? `` : `mb10`}`}>
                                            <div className="basic-dropdown">
                                                <span className="drop_down_arrow color_white"><i className="bi bi-caret-down-fill"></i></span>
                                                <select
                                                    defaultValue={"option"}
                                                    className="form-control form-control select_option green_background color_white"
                                                    value={mock}
                                                    // onChange={(e) => setMock(e.target.value)}
                                                    onChange={(e) => setSetlectMockData(e.target.value)}
                                                >
                                                    <option value="">Select Mock</option>
                                                    {
                                                        props.purchasedSubjectList[subjectIndex].exam_completed_mock.map((item, index) => {
                                                            return <option key={index} value={[item.mock_no, item.subject_id, item.exam_unique_id]}>Mock {item.mock_no}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-9 col-xxl-8">
                    <div className="card padding_15">
                        {/* <div className={`padding_15 green_background round_8`}> */}
                        <div className={`padding_15 ${previousValue.categpryId == 1 ? `green_background` : `competitive_color`} round_8`}>
                            <div className="top-heading font_16 font_white">
                                Select Chapters ({setlectSet != '' ? `${props.chaptercList.length}` : 0})
                            </div>
                        </div>

                        {/* <div className="botton_continer round_8 padding_15"> */}
                        <div className={`${previousValue.categpryId == 1 ? `botton_continer_scholastic` : `botton_continer_competitive`} round_8 padding_15 green_background_border`}>
                            {
                                props.chaptercList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>

                                            {setlectSet != '' ?

                                                props.examCompletedChapterList != undefined && props.examCompletedChapterList.find(element => element.chapter_id == item.id) ?
                                                    <>
                                                        {/* <Link to={{ pathname: "/page-scholastic-exam", state: { branchSortCode, chapter: item.short_code, exam_type, setlectSet } }}> */}
                                                        {/* <Link to={{ pathname: "/online-assessment-details", state: { exam: previousValue.categpryId } }} onClick={() => updateExamUniqueId(props.examCompletedChapterList[index].exam_unique_id)}> */}
                                                        <Link to={{ pathname: "/online-assessment-details", state: { exam: previousValue.categpryId } }} onClick={() => updateExamUniqueId(props.examCompletedChapterList.filter(i => i.chapter_id == item.id ))}>
                                                            <div className="chapters_listing margin_buttom_20">
                                                                <div clsss="chapter_heading">
                                                                    <div className="font_open_sans color_gery_1 font_13 font_weight_900">
                                                                        {item.chapter_name}
                                                                    </div>
                                                                </div>
                                                                <div clsss="chapter_description">
                                                                    <div className="font_open_sans color_gery_1 font_13">
                                                                        {item.sub_heading}
                                                                    </div>
                                                                </div>
                                                                <div clsss="chapter_description">
                                                                    <div className="font_open_sans color_gery_1 font_13">
                                                                        {item.short_code}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </>
                                                    :
                                                    <div className="chapters_listing_done margin_buttom_20">
                                                        <div clsss="chapter_heading">
                                                            <div className="font_open_sans color_done font_13">
                                                                {item.chapter_name}
                                                            </div>
                                                        </div>
                                                        <div clsss="chapter_description">
                                                            <div className="font_open_sans color_done font_13">
                                                                {item.sub_heading}
                                                            </div>
                                                        </div>
                                                        <div clsss="chapter_description">
                                                            <div className="font_open_sans color_done font_13">
                                                                {item.short_code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                chapterLoader ?
                                                    <div className="exam_list_container" >
                                                        <div className="spinner-container">
                                                            <div className="spinner-view">
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    //size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null}

                                        </React.Fragment>
                                    )
                                })
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user_id: state.auth.user_id,
        // showLoading: state.board.showLoading,
        boardList: state.board.boardList,
        classStandardList: state.standard.classStandardList,
        showLoadingclassStandard: state.standard.showLoadingclassStandard,
        branchScholasticList: state.branch.branchScholasticList,
        showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
        chaptercList: state.chapter.chapterList,
        showLoadingChapter: state.chapter.showLoadingChapter,
        purchasedSubjectList: state.subject.purchasedSubjectList,
        branchScholasticListAgainstSubjectId: state.branch.branchScholasticListAgainstSubjectId,
        examCompletedChapterList: state.chapter.examCompletedChapterList,
    };
};
export default connect(mapStateToProps)(AssessmentCategoryWiseDetails);