import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import AvatarEditor from "react-avatar-editor";
// import Nouislider from "nouislider-react";

import { profileImageUpdatedAction, profileImageFileUpdatedAction, profileImageModalCloseAction } from '../../../store/actions/ProfileAction';

const ProfileImgCrop = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const inputFile = useRef(null)

    const profile_image = useSelector(state => state.profile.profilePic);

    const [editor, setEditor] = useState('')
    const [picture, setPicture] = useState({
        image: "",
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 2,
        rotate: 0,
        borderRadius: 0,
        preview: null,
        width: 200,
        height: 200
    });
    // let url = 'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg'
    let url = profile_image
    useEffect(() => {
        // imageToJsFileObj()
        onImageToFile(url)
        /* setPicture(picture => ({
            ...picture,
            image: profile_image
        })) */
    }, [editor]);

    const imageToJsFileObj = () => {
        // console.log("profile_image------", profile_image)
        let fileArr = [];
        let url = 'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg'
        // let url = profile_image
        const toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))


        /* *** Here is code for converting "Base64" to javascript "File Object".*** */

        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }


        /* *** Calling both function *** */

        toDataURL(url)
            .then(dataUrl => {
                // console.log('Here is Base64 Url', dataUrl)
                var fileData = dataURLtoFile(dataUrl, "profile.png");
                // console.log("Here is JavaScript File Object", fileData)
                fileArr.push(fileData)
                // console.log("fileArr-----", fileArr)
                setPicture(picture => ({
                    ...picture,
                    image: fileArr[0]
                }))
            })


        // return fileArr
    }

    const onImageToFile = async (imgUrl) => {
        // console.log("*****", imgUrl)
        // var imgExt = getUrlExtension(imgUrl);
        if (imgUrl != '' && imgUrl != undefined) {
            const response = await fetch(imgUrl);
            const blob = await response.blob();
            const file = new File([blob], "profile.png", {
                type: blob.type,
            });
            // console.log("file------", file)
            setPicture(picture => ({
                ...picture,
                image: file
            }))

            return file;
        }

    }

    const onButtonClick = (e) => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const handleNewImage = (e) => {
       

        var fileName = e.target.files[0].name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            setPicture(picture => ({
                ...picture,
                image: e.target.files[0]
            }))
        } else {
            alert("Only jpg/jpeg and png files are allowed!");
        }
        /* setPicture(picture => ({
            ...picture,
            image: e.target.files[0]
        })) */
    };

    const handleSave = (data) => {
        // console.log("data----->", editor.getImageScaledToCanvas())
        const croppedImg = editor.getImageScaledToCanvas().toDataURL();
        const rect = editor.getCroppingRect();
        /* For Preview */
        /* setPicture(picture => ({
            ...picture,
            preview: {
                img,
                rect,
                scale: picture.scale,
                width: picture.width,
                height: picture.height,
                borderRadius: picture.borderRadius
            }
        })); */
        var file = dataURLtoFile(croppedImg, "profile.png");

        const fr = new FileReader();
        return new Promise((resolve, reject) => {
            fr.addEventListener("load", e => {
                let image = fr.result;
                // setLocalImg(image);
                dispatch(profileImageUpdatedAction(image))
                dispatch(profileImageFileUpdatedAction([file, false]))
                // setProImgFile(file);
            });
            fr.addEventListener("error", e => {
                reject();
            });
            // console.log("file-------", file)
            fr.readAsDataURL(file);
        });
    };

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleScale = (e) => {
        const scale = parseFloat(e.target.value);
        setPicture(picture => ({
            ...picture,
            scale: scale
        }))
    };

    // handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    //   this.setState({ allowZoomOut })
    // }

    const rotateLeft = (e) => {
        e.preventDefault();
        setPicture(picture => ({
            ...picture,
            rotate: picture.rotate - 90
        }))

    };

    const rotateRight = (e) => {
        e.preventDefault();
        setPicture(picture => ({
            ...picture,
            rotate: picture.rotate + 90
        }))
    };

    // handleBorderRadius = e => {
    //   const borderRadius = parseInt(e.target.value)
    //   this.setState({ borderRadius })
    // }

    const handleXPosition = (e) => {
        const x = parseFloat(e.target.value);
        setPicture(picture => ({
            ...picture,
            position: picture.position, x
        }))
    };

    const handleYPosition = (e) => {
        const y = parseFloat(e.target.value);
        setPicture(picture => ({
            ...picture,
            position: picture.position, y
        }))
    };



    const logCallback = (e) => {
        // eslint-disable-next-line
        // console.log("callback", e);
    }



    const setEditorRef = (editor) => {
        if (editor) {
            editor = editor;
            setEditor(editor)
            // const img = editor.getImageScaledToCanvas().toDataURL();
        }
    };

    const handlePositionChange = (position) => {
        setPicture(picture => ({
            ...picture,
            position: position
        }))
    };



    return (
        <>
            {/* {console.log("picture.image------", picture.image)} */}
            {/* {console.log("profile_image------", profile_image)} */}
            <div className='d-flex flex-column'>
                <div className="cursorPointer d-flex justify-content-center align-items-center add_container mb-3 " onClick={onButtonClick}>
                    <i className="bi bi-image-fill user_img_size"></i>
                    <div className='plus_container d-flex justify-content-center align-items-center'>
                        <i className="bi bi-plus user_img_size"></i>
                    </div>
                    {/* New File: */}
                    <input type="file" ref={inputFile} onChange={handleNewImage} style={{ display: 'none' }} accept="image/png, image/jpg, image/jpeg" />
                </div>
                <div className="row">
                    <div>
                        <div>
                            <AvatarEditor
                                ref={setEditorRef}
                                scale={parseFloat(picture.scale)}
                                width={picture.width}
                                height={picture.height}
                                border={50}
                                color={[0, 0, 0, 0.6]}
                                position={picture.position}
                                onPositionChange={handlePositionChange}
                                rotate={parseFloat(picture.rotate)}
                                // borderRadius={picture.width / (100 / picture.borderRadius)}
                                borderRadius={150}
                                onLoadFailure={logCallback.bind(this, "onLoadFailed")}
                                onLoadSuccess={logCallback.bind(this, "onLoadSuccess")}
                                onImageReady={logCallback.bind(this, "onImageReady")}
                                image={picture.image}
                            // crossOrigin
                            />
                        </div>
                        <div className='mt-3 mb-3'>
                            <input
                                name="scale"
                                type="range"
                                onChange={handleScale}
                                min={picture.allowZoomOut ? "0.1" : "1"}
                                max="5"
                                step="0.01"
                                defaultValue="2"
                                className='slider_container'
                            />
                        </div>

                        <div className='mt-3 mb-3 d-flex justify-content-md-between align-items-center'>
                            <div>
                                <div className="img_rotate_btn text-center cursorPointer" onClick={rotateLeft}>Left</div>
                            </div>
                            <div>Rotate</div>
                            <div>
                                <div className="img_rotate_btn text-center cursorPointer" onClick={rotateRight}>Right</div>
                            </div>

                        </div>
                        {/* {console.log("picture.image----", picture.image)} */}
                        <div>
                            <button
                                // type="submit"
                                className={`btn submit_background btn-block text-center ${picture.image == '' ? "subscription-disable" : null}`}
                                onClick={handleSave}
                            >
                                SUBMIT
                            </button>
                        </div>
                        {/* <input type="button" onClick={handleSave} value="Submit" /> */}
                        {/* For Preview */}
                        {/* <br />
                    {!!picture.preview && (
                        <img
                            src={picture.preview.img}
                            style={{
                                borderRadius: `${(Math.min(
                                    picture.preview.height,
                                    picture.preview.width
                                ) +
                                    10) *
                                    (picture.preview.borderRadius / 2 / 100)}px`
                            }}
                            alt="img"
                        />
                    )} */}

                    </div>

                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // demoExamAessmentDetailsList: state.questionNo.demoExamAessmentDetailsList,


    };
};
export default connect(mapStateToProps)(ProfileImgCrop);