import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"
import {
	Row,
	Col,
	Modal,
	Button,
	Spinner
} from "react-bootstrap";
import math from '../../../images/subject_icon/math.png';

import { addToCartData, subscribeLoading, getScSubjectData, showRightNavAction, eLibrarySelectSubjectsListAction, scholasticSubscriptionSourceAction, compititiveSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';

// let a1 = [11, 13];
// let b1 = [7, 9, 11, 13]
const LibraryScholasticCard = (props) => {

	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const history = useHistory();

	useEffect(() => {
		// console.log("props.subscriptionSelectElibrary----", props.subscriptionSelectElibrary)
		/* let a1 = [11, 13];
		let b1 = [7, 9, 11, 13]
		b1.find((val, index) => {
			console.log('index', index, val); // Stops at 0 0
			console.log(a1.includes(val))
		}) */
	}, []);

	const onProgress = () => {

		// dispatch(subscribeLoading(true));
		dispatch(addToCartData(props.category_id, props.id, [], 0, 0, props.board, props.class_no ? props.class_no : 0, props.library, 0, 1, 1, props.history));
		dispatch(showRightNavAction(true))
		setModal(false);
		// dispatch(eLibrarySelectSubjectsListAction(props.card_id))
	}

	const checkDisabledStatus = () => {
		let status = false;
		//!props.cartList.find(element => element.subscription_id == props.id && element.exam_category_id == 1) && props.card_id.every(group => !props.subject_id.includes(group))
		//check from redux value
		//console.log("props.combo_subject_ids", props.combo_subject_ids);
		if (props.subject_cards.length > 0) {
			let combo_subject_ids;
			for (let i = 0; i < props.subject_cards.length; i++) {
				combo_subject_ids = props.subject_cards[i].combo_subject_ids;
				if (combo_subject_ids) {
					for (let j = 0; j < combo_subject_ids.length; j++) {
						for (let k = 0; k < props.combo_subject_ids.length; k++) {
							//console.log(props.subjectName, "::", combo_subject_ids[j]," == ",props.combo_subject_ids[k]);
							if (combo_subject_ids[j] == props.combo_subject_ids[k]) {
								//console.log(combo_subject_ids[j]);
								status = true;
								break;
							}
						}
					}
				}
			}
		}

		//check from cart value
		//console.log("props.cartList", props.cartList);
		//console.log("subjectName", props.subjectName);
		if (props.cartList.length > 0) {
			let combo_subject_ids;
			for (let i = 0; i < props.cartList.length; i++) {
				combo_subject_ids = props.cartList[i].combo_subject_ids;
				if (combo_subject_ids) {
					for (let j = 0; j < combo_subject_ids.length; j++) {
						for (let k = 0; k < props.combo_subject_ids.length; k++) {

							// console.log(props.cartList[i].exam_category_id," == ",props.category_id);
							// console.log("props.cartList[i].has_library", props.cartList[i].has_library);
							// console.log(combo_subject_ids[j]," == ",props.combo_subject_ids[k]);
							// console.log(props.cartList[i].subscription_id," =! ",props.id);

							if (props.cartList[i].exam_category_id == props.category_id &&
								props.cartList[i].has_library == 1 &&
								combo_subject_ids[j] == props.combo_subject_ids[k]) {
								// || props.only_elibrary == 1)
								status = true;
								break;
							}
						}
					}
				}
			}
		} /* else {
			console.log("Cart-List Blank")
			dispatch(scholasticSubscriptionSourceAction([]))
			dispatch(compititiveSubscriptionSourceAction([]))
		} */

		//console.log("getDisabledStatus", status);
		//console.log("======================================");
		return status;
	}

	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					<div className={`subcribe-scholastic-card ${props.isPurchased == 1 ? "subscription-disable" : null}`}>
						<div className="question-mark-circle">
							<i className="fa-sharp fa-solid fa-question"></i>
						</div>
						{/* sticker_text: "2%" */}
						{props.sticker_text != '' ?
							<div className='sticker_base'>
								<div className='sticker_text'>{props.sticker_text}</div>
							</div>
							: null}

						<div className="subcribe-scholastic-details">
							{/* <img src={math} alt="" /> */}
							{props.subject_image != '' && props.subject_image != undefined && props.subject_image != "undefined"
								?
								<img className='sub_img_container' src={props.subject_image} alt="" />
								:
								<div className='sub_img_container'>No Image</div>
							}
							{/* <i className="bi bi-book"></i> */}
							<h4>{props.subjectName}</h4>
							{/* <h5>e-Library (id: {props.id}) (sub Id: {props.card_id}) (sub Id : {props.subject_id}) </h5> */}
							{/* <h5>props.subject_id:- {props.subject_id}</h5> */}
						</div>


						<div className="subcribe-scholastic-price-details">
							<h4>Rs.{props.library}/-</h4>

							{props.isPurchased == 1 ?
								null :
								<>
									{/* {!props.cartList.find(element => element.subscription_id == props.id && element.exam_category_id == 1) && props.card_id.every(group => !props.subject_id.includes(group)) ? */}
									{/* {!props.cartList.find(element => element.subscription_id == props.id && element.exam_category_id == 1) && props.card_id.some(group => !props.subject_id.includes(group)) ? */}
									{/* { props.card_id.findIndex(group => props.subject_id.includes(group)) ? */}
									{/* {console.log("=props.subject_id====++", props.subject_id, )} */}
									{/* {console.log("==props.card_id===++", props.card_id, typeof(props.card_id) )}    */}
									{/* {props.subject_id.find((value, index) => {
										console.log(props.subjectName, '- 11--index', index, props.card_id, value); // Stops at 0 0 // match == true   
										console.log('->>>> index', index, props.card_id.includes(value))   
										return !props.card_id.includes(value) 
									}) ? */}
									{/* {console.log("++++", typeof(props.card_id), props.card_id)} */}
									{/* {console.log("++++11=================", props.cartList, props.id, props.card_id, props.subject_id )} */}
									{checkDisabledStatus() ?
										<Button disabled>Add to Cart</Button>
										: <Button
											onClick={() => setModal(true)}
										> Add to Cart</Button>}
								</>
							}
						</div>

					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-sm subscribe-modal"
				//size="sm"
				show={modal}
			>
				<Modal.Header>
					<Modal.Title>Cart Items</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!!props.subjectName && props.subjectName != undefined ?
						<p>Details : Subject : {props.subjectName} (e-Library)</p>
						: null}
					{/* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used a s a placeholder before final copy is available. */}
				</Modal.Body>
				<Modal.Footer>
					<div className="subscribe-modal-footer">
						<Button
							className="cancelBtn"
							onClick={() => setModal(false)}
						>
							Cancel
						</Button>
						<Button
							className="proceedBtn"
							onClick={onProgress}
						>Proceed
						</Button>
					</div>

				</Modal.Footer>
			</Modal>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.subscribe.showLoading,
		isNav: state.subscribe.isNav,
		cartList: state.subscribe.cartList
	};
};
export default connect(mapStateToProps)(LibraryScholasticCard);